import { useState, useEffect } from 'react';
import { ReactDataContext } from '@themost/react';
import 'styles/css/IIAform.css';
import Loader from 'react-loader-spinner';
import RequiredSelectWithLabel from 'components/UI/RequiredSelectWithLabel';


function InitialAgreementDetails({ children, iia, setIia, handleStep, userService, countries, academicYears }) {
    const [institution, setInstitution] = useState({ "name": "", "erasmusCode": "", "city": "", "heiId": "" });
    const [startYear, setStartYear] = useState(null);
    const [endYear, setEndYear] = useState(null);
    const [country, setCountry] = useState({ "countryName": "", "countryId": "" });
    const [receivingInstitutions, setReceivingInstitutions] = useState([]);

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(userService.getToken());

    useEffect(() => {

        setEndYear((iia && iia.endYear) ? iia.endYear.id : "");

        const temp = iia;

        temp["startYear"] = { "id": (iia.startYear) ? iia.startYear.id : new Date().getUTCFullYear() + 1 };
        setStartYear(temp["startYear"]?.id);
        const partnerInstitution = iia?.institutions?.find(i => i.isLocal === false)

        if (partnerInstitution && Object.keys(partnerInstitution).length > 0) {
            setCountry(partnerInstitution.country);
            setInstitution({
                "name": partnerInstitution.name,
                "erasmusCode": partnerInstitution.erasmusCode,
                "cityName": partnerInstitution.cityName,
                "heiId": partnerInstitution.heiId,
                "isLocal": false
            })
        }


        setIia(temp);
    }, [])



    useEffect(() => {
        const fetchInstitutions = () => {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            if (country.countryId !== null) {
                fetch(process.env.REACT_APP_API_SERVER + "institutions/" + country.countryId, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setReceivingInstitutions(result)
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }
        if (country.countryId) {
            fetchInstitutions();
        }
    }, [country])

    if (iia) {

        return (

            <form className="g-3 needs-validation" noValidate={false}
                onSubmit={async (event) => {
                    event.preventDefault();
                    const temp = iia;
                    temp["startYear"] = { "id": startYear };
                    temp["endYear"] = { "id": endYear };
                    if (country.countryName === "") {
                        alert("You have to fill country of the receiving institution");
                    }
                    else if (!(iia?.institutions.length > 1)) {
                        alert("You have to fill name of the receiving institution");
                    }
                    else {
                        setIia(temp);
                        handleStep();
                    }
                }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card p-4">
                                <h3 className="text-center">Agreement Details</h3>
                                <div className="form-group">
                                    <RequiredSelectWithLabel
                                        value={startYear}
                                        clearable={true}
                                        onSelectChange={(e) => {
                                            if (e != null) {
                                                setStartYear(e.label);
                                            }
                                            else {
                                                setStartYear(null);
                                            }
                                        }}
                                        id="startYear"
                                        isOptionDisabled={(option) => option.label < 2021}
                                        listOptions={academicYears.map(i => ({ label: i.id }))}
                                        disabled={iia?.ewpStatus?.name === "Approved by all"}
                                    >Start Year *</RequiredSelectWithLabel>
                                </div>
                                <div className="form-group">
                                    <RequiredSelectWithLabel
                                        value={endYear}
                                        clearable={true}
                                        onSelectChange={(e) => {
                                            if (e != null) {
                                                setEndYear(e.label);
                                            }
                                            else {
                                                setEndYear(null);
                                            }
                                        }}
                                        id="endYear"
                                        isOptionDisabled={(option) => option.label < new Date().getUTCFullYear() - 1}
                                        listOptions={academicYears.map(i => ({ label: i.id }))}
                                        disabled={iia?.ewpStatus?.name === "Approved by all"}
                                    >End Year *</RequiredSelectWithLabel>
                                </div>
                                <div className="form-group">
                                    <RequiredSelectWithLabel
                                        required={true}
                                        clearable={true}
                                        value={country.countryName}
                                        id="country"
                                        placeholder="Type country"
                                        //isInvalid={countryID===null}
                                        onSelectChange={(e) => {
                                            let temp = iia;
                                            if (temp["departmentalCoordinatorContacts"]) {
                                                temp["departmentalCoordinatorContacts"] = temp["departmentalCoordinatorContacts"].filter(i => i?.department?.institution.erasmusCode !== institution?.erasmusCode);
                                            }
                                            if (temp["institutions"]) {
                                                temp["institutions"] = temp["institutions"].filter(i => i.erasmusCode !== institution?.erasmusCode);
                                            }
                                            temp["studentMobilitiesForStudies"] = [];
                                            temp["studentMobilitiesForTraineeship"] = [];
                                            temp["staffMobilitiesForTeaching"] = [];
                                            temp["staffMobilitiesForTraining"] = [];
                                            setIia(temp);
                                            setInstitution({
                                                "name": "",
                                                "erasmusCode": "",
                                                "cityName": "",
                                                "heiId": "",
                                                "country": "",
                                                "isLocal": ""
                                            })
                                            if (e !== null && e.label) {
                                                setCountry(countries.find(i => i.countryName === e.label));
                                            }
                                            else {
                                                setCountry({ "countyName": "" });
                                            }
                                        }}
                                        listOptions={countries.map(i => ({ label: i.countryName }))}
                                        disabled={iia?.ewpStatus?.name === "Approved by all"}
                                    >Select country of the Partner Institution *</RequiredSelectWithLabel>
                                </div>
                                <div className="form-group">
                                    <RequiredSelectWithLabel
                                        id="institution"
                                        value={(institution.name ? (institution.name + " (" + institution.erasmusCode + ")") : "")}
                                        clearable={true}
                                        placeholder="Enter name of the institution"
                                        disabled={!country.countryId || iia?.ewpStatus?.name === "Approved by all"}
                                        onSelectChange={(e) => {
                                            let temp = iia;
                                            if (temp["departmentalCoordinatorContacts"]) {
                                                temp["departmentalCoordinatorContacts"] = temp["departmentalCoordinatorContacts"].filter(i => i?.department?.institution.erasmusCode !== institution?.erasmusCode);
                                            }
                                            temp["studentMobilitiesForStudies"] = [];
                                            temp["studentMobilitiesForTraineeship"] = [];
                                            temp["staffMobilitiesForTeaching"] = [];
                                            temp["staffMobilitiesForTraining"] = [];
                                            if (temp["institutions"]) {
                                                temp["institutions"] = temp["institutions"].filter(i => i.erasmusCode !== institution?.erasmusCode);

                                            }
                                            if (e !== null) {
                                                let institutionTemp = receivingInstitutions.find(i => i.hei_id === e.value);

                                                temp["institutions"] = temp["institutions"].concat({
                                                    "name": institutionTemp.name,
                                                    "erasmusCode": institutionTemp.erasmusCode,
                                                    "cityName": institutionTemp.cityName,
                                                    "heiId": institutionTemp.hei_id,
                                                    "country": country,
                                                    "isLocal": false,
                                                })
                                                setInstitution({
                                                    "name": institutionTemp.name,
                                                    "erasmusCode": institutionTemp.erasmusCode,
                                                    "cityName": institutionTemp.cityName,
                                                    "heiId": institutionTemp.hei_id,
                                                    "country": country,
                                                    "isLocal": false
                                                })
                                            }
                                            else {
                                                setInstitution({
                                                    "name": "",
                                                    "erasmusCode": "",
                                                    "cityName": "",
                                                    "heiId": "",
                                                    "country": "",
                                                    "isLocal": ""
                                                })

                                            }
                                            setIia(temp);
                                        }}
                                        listOptions={Array.isArray(receivingInstitutions) ? receivingInstitutions.map(i => ({ value: i.hei_id, label: i.name + " (" + i.erasmusCode + ")" })) : []}
                                    >Name of the Partner Institution *</RequiredSelectWithLabel>
                                </div>
                                <div className="form-group">
                                    <label>Erasmus Code</label>
                                    <input readOnly={true} type="text" value={institution.erasmusCode} className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {children}
            </form>
        );
    }

    else {
        return (<div className="d-flex justify-content-center">
            <Loader
                className="spinner mx-auto"
                type="TailSpin"
                color="#3A435E"
                height={70}
                width={70}
            />
        </div>
        )
    }


}
export default InitialAgreementDetails
