import { useState, useEffect } from 'react'
import profile from "img/profile_icon.svg"
import 'components/Profile/View/index.css'
import { ReactDataContext } from '@themost/react';
import Loader from "react-loader-spinner";
import UserService from "services/UserService";
import { Link } from 'react-router-dom'

function ProfileCard(props) {
  const [user, setUser] = useState(null)
  
  useEffect(() => {
    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(UserService.getToken());
    // get user
    context.model(`Users/Me`).asQueryable().getItem().then(res => {
      context.model(`MobilityContacts`).asQueryable().where('uid').equal(res.id).getItem().then(response => {
        setUser([response].concat(res))
      }).catch(error => {
        console.log('error', error)
      });
    }).catch(error => {
      console.log('error', error)
    });

  }, [])

  useEffect(() => {
    document.title = props.title;
  }, [])

  if (user === null) {
    return (<div style={{
      position: 'absolute', left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)'
  }}>
      <Loader
        className="spinner mx-auto"
        type="TailSpin"
        color="#3A435E"
        height={70}
        width={70}
      />
    </div>)
  }
  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h1 className="">
            <i className="fa fa-user fa-fw mr-2"></i>My Profile
          </h1>
        </div>
        <div className="card-body pt-0 pb-3">
          <div className="profile_avatar_box text-center mb-4">
            <div><img src={profile} alt="avatar" className="rounded-circle mb-3" /></div>
            <div><span>{user[0].givenName} {user[0].familyName}</span></div>
            <div>{user[1].enabled ? <span className="text-success">Active</span> : <span className="text-danger">Not Active</span>}</div>
          </div>
          <div className="card-text d-flex justify-content-between">
            <button type="button" className="btn btn-md btn-contact text-light w-50 mr-2">Contact with email</button>
            <button type="button" className="btn btn-md bg-white border-dark w-50 ml-2">{user[0].email}</button>
          </div>
        </div>
        <div className="card-footer border-top py-4">
          <div className="form-group row mb-1">
            <div className="col-5">
              <span className="text-secondary">ID</span>
            </div>
            <div className="col-7">
              <span className="text-dark">{user[1].id}</span>
            </div>
          </div>
          <div className="form-group row mb-1">
            <div className="col-5">
              <span className="text-secondary">NAME</span>
            </div>
            <div className="col-7">
              <span className="text-dark">{user[0].givenName} {user[0].familyName}</span>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <Link to="/profile/edit" className="btn btn-primary btn-next">

              EDIT PROFILE
            </Link>
          </div>
        </div>
      </div>

    </>
  )
}
export default ProfileCard