import { useState } from 'react';
import ToggleSwitch from 'components/Common/ToggleSwitch';
import { Link } from 'react-router-dom';

const LocalFactsheetRow = ({ index, id, erasmusCode, givenName, familyName, handleActivation, handleDuplication }) => {
    const [activated, setActivated] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOnChange = async value => {
        if(loading) return;

        setLoading(true);
        setActivated(true);
        await handleActivation(id);

        setActivated(false);
        setLoading(false);
    }

    return (
        <div className={"row filter-sidebar pt-3 h-100 pb-3 " + (index !== 0 ? 'mt-3' : '')}>
            <div className="col-md-2 pl-0 text-center align-self-center">{erasmusCode}</div>
            <div className="col-md-3 pl-0 text-center align-self-center">{givenName ?? '-'}</div>
            <div className="col-md-3 pl-0 text-center align-self-center">{familyName ?? '-'}</div>
            <div className="col-md-2 pl-0 text-center d-flex justify-content-between align-self-center"> 
                <Link to={`/factsheets/edit/${id}`}><i className='fa fa-pencil fa-primary'></i></Link>
                <Link to={`/factsheets/view/${id}`}><i className='fa fa-eye fa-primary'></i></Link>
                <Link to="/factsheets" onClick={handleDuplication(id)}><i className='fa fa-clone fa-primary' aria-hidden="true"></i></Link>
            </div>
            <div className="col-md-2 pl-0 pr-2 text-center align-self-center">
                <ToggleSwitch
                    small
                    id={id} 
                    name={`local-factsheet-switch-${id}`}
                    checked={activated}
                    onChange={handleOnChange}
                />
            </div>
            
        </div>
    );
}

export default LocalFactsheetRow;