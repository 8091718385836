import { Pagination as PaginationBootstrap} from 'react-bootstrap';

const Pagination = ({ total, page, pageLimit, setPage }) => {
    let paginationItems = [];

    if(page === 0) {
      for(let i = 0; i < (Math.ceil(total / pageLimit) > 3 ? 3 : Math.ceil(total / pageLimit)); i++) {
        paginationItems.push(
          <PaginationBootstrap.Item key={i} active={i === page} onClick={(event) => {setPage(i)}}>
            {i+1}
          </PaginationBootstrap.Item>
        );
      }
    } else if(page === Math.ceil(total / pageLimit) -1) {
      for(let i = Math.ceil(total / pageLimit) - 1; i >= Math.ceil(total / pageLimit) - 3 && i >= 0; i--) {
        paginationItems.push(
          <PaginationBootstrap.Item key={i} active={i === page} onClick={(event) => {setPage(i)}}>
            {i+1}
          </PaginationBootstrap.Item>
        );
        paginationItems.sort((element1, element2) => {
          if(element1.key < element2.key) return -1;
          if(element1.key > element2.key) return 1;
          return 0;
        })
      }
    } else {
      for(let i = page-1; i < page + 2; i++) {
        paginationItems.push(
          <PaginationBootstrap.Item key={i} active={i === page} onClick={(event) => {setPage(i)}}>
            {i+1}
          </PaginationBootstrap.Item>
        );
      }
    }
    
    return (
        <PaginationBootstrap>
            <PaginationBootstrap.First onClick={() => {if(total !== 0) setPage(0);}}/>
            <PaginationBootstrap.Prev onClick={() => {if(page !== 0) setPage(page-1)}}/>
            {paginationItems.length > 3 ? <Pagination.Ellipsis /> : <></>}
            {paginationItems}
            {paginationItems.length > 3 ? <Pagination.Ellipsis /> : <></>}
            <PaginationBootstrap.Next onClick={() => {if(page !== Math.ceil(total / pageLimit) - 1) setPage(page+1)}} />
            <PaginationBootstrap.Last onClick={() => {setPage(Math.ceil(total / pageLimit) - 1)}}/>
        </PaginationBootstrap>
    );
} 

export default Pagination;