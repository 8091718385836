import { useEffect, useReducer, useCallback, useRef } from "react";
import { ReactDataContext } from "@themost/react";
import { reducer } from '../reducers/studiesFiltersReducer'
import {
    SET_PAGE_ACTION,
    SET_TOTAL_ACTION,
    UPDATE_MULTIPLE_VALUES_ACTION,
    UPDATE_STUDIES_ACTION
} from "../reducers/studiesQueryReducer";

export const defaultState = {
    studies: [],
    page: 0,
    total: 0,
    loading: true
}

export const useStudiesQuery = (filters, userService, langCode) => {

    const [state, dispatch] = useReducer(reducer, defaultState)

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(userService.getToken());

    const mountedRestOptions = useRef(false);
    const mountedPage = useRef(false);

    const setPage = useCallback((page) => {
        dispatch({ type: SET_PAGE_ACTION, payload: { page } })
    }, [])

    const updateMultipleValues = useCallback((data) => {
        dispatch({ type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data })
    }, []);

    const updateStudies = useCallback((data) => {
        dispatch({ type: UPDATE_STUDIES_ACTION, payload: data })
    }, [])

    const setTotal = useCallback((data) => {
        dispatch({ type: SET_TOTAL_ACTION, payload: data })
    }, [])

    const fetchStudies = async (pageNumber) => {
        // parentState.setLoading(true);
        let fields = [];

        if (filters.state.idSelected) {
            fields.push({
                "key": "applicationNumber",
                "value": filters.state.idSelected
            })
        }

        if (filters.state.statusSelected) {
            fields.push({
                "key": "status",
                "value": filters.state.statusSelected
            })
        }

        if (filters.state.typeSelected) {
            console.log(filters.state.typeSelected)
            var typeSelectedTemp = ''
            if (filters.state.typeSelected.includes('Short') || filters.state.typeSelected.includes('Βραχυχρόνια')) {
                typeSelectedTemp = 'short-term-studies'
            } else {
                typeSelectedTemp = 'long-term-studies'
            }
            fields.push({
                "key": "type",
                "value": typeSelectedTemp
            })
        }

        let query = context.model(`ApplicationStudentStudiesForms`).asQueryable().where("id").greaterThan(0)
            .and("isDeleted").equal(false);

        for (let i = 0; i < fields.length; i++) {
            if (i === 0) {
                query = query.where(fields[i].key).equal(fields[i].value)
            } else {
                query = query.and(fields[i].key).equal(fields[i].value)
            }
        }

        if (filters.state.mobilitySelected) {
            query = query.and('prefferedMobility/programme').equal(filters.state.mobilitySelected)
        }

        if (filters.state.dateSelected) {
            query = query.and('applicationYear').equal(filters.state.dateSelected)
        }

        if (filters.state.fullNameSelected) {
            query = query.and("studentData/nameLatin").contains(filters.state.fullNameSelected).or("studentData/familyNameLatin").contains(filters.state.fullNameSelected)
        }

        if (filters.state.fullNameGreekSelected) {
            query = query.and("studentData/nameGreek").contains(filters.state.fullNameGreekSelected).or("studentData/familyNameGreek").contains(filters.state.fullNameGreekSelected)
        }

        try {
            const studentStudiesResults = await query.getItems();
            console.log(studentStudiesResults)
            updateMultipleValues({
                studies: studentStudiesResults,
                loading: false,
                total: studentStudiesResults.length,
                page: pageNumber
            })
        } catch (err) {
            console.log(err);
        }

    }

    //runs on first mount
    useEffect(() => {
        fetchStudies(0);
    }, [])

    // runs when the user changes the rest of the options
    useEffect(() => {
        if (mountedRestOptions.current === false) {
            mountedRestOptions.current = true;
            return;
        }

        (async () => {
            fetchStudies(0);
        })()
        // fetchStudies(parentState.currentPage);
    }, [
        filters.state.idSelected,
        filters.state.statusSelected,
        filters.state.typeSelected,
        filters.state.dateSelected,
        filters.state.fullNameSelected,
        filters.state.mobilitySelected,
        filters.state.fullNameGreekSelected
    ])

    useEffect(() => {
        if (mountedPage.current === false) {
            mountedPage.current = true;
            return;
        }
        (async () => {
            fetchStudies(state.page)
        })()
    }, [state.page]);

    return { studies: state.studies, loading: state.loading, page: state.page, total: state.total, setPage, updateStudies, setTotal }
}