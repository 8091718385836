import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'

const InformationEntries = ({ institutions, factsheets, informationEntryType }) => {
    return (
        <Table
            data={
                institutions.map(i => {
                    const factsheet = factsheets.find(f => f.institutionId === i.id).factsheet;
                    let informationEntry = null;
                    if (factsheet && factsheet[informationEntryType]) {
                        informationEntry = factsheet[informationEntryType];
                    }
                    return ({
                        receiving: i.erasmusCode, person: informationEntry && ('Email:' + informationEntry.email +
                            '\n' + 'Phone: ' + informationEntry.phone), url: informationEntry && informationEntry.urls.map(i => (i.value + "\n\n")).join().replaceAll(',', ' \n')
                    });
                })
            }
        >
            <TableHeader>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Institution
                    [Erasmus code or city]
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Contact details
                    (email, phone)
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }} >
                    Website for information
                </TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.receiving} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.person} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.url} />
            </TableBody>
        </Table>

    );
};






export default InformationEntries