import { useEffect, useState, useRef, memo } from 'react';
import { Link } from 'react-router-dom';
import SelectWithLabel from 'components/UI/SelectWithLabel';
import { ReactDataContext } from '@themost/react';
import Pagination from 'components/Common/Pagination';

const PartnerFactsheets = ({ userService }) => {
    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(userService.getToken());

    const [countriesList, setCountriesList] = useState([]);
    const [countrySelected, setCountrySelected] = useState(null);
    const [institutionSelected, setInstitutionSelected] = useState(null);
    const [institutionsList, setInstitutionsList] = useState([]);
    const [factsheets, setFactsheets] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);

    const mountedCountrySelected = useRef(false);
    const mountedInstitutionSelected = useRef(false);
    const mountedPage = useRef(false);

    const fetchAndSetFactsheets = async (pageNumber) => {
        let fields = [];

        if (countrySelected) {
            fields.push({
                "key": "institution/country",
                "value": countrySelected
            })
        }

        if (institutionSelected) {
            fields.push({
                "key": "institution/id",
                "value": institutionSelected
            })
        }

        let query = context.model('MobilityFactsheets').asQueryable();

        for (let i = 0; i < fields.length; i++) {
            if (i === 0) {
                query = query.where(fields[i].key).equal(fields[i].value);
            } else {
                query = query.and(fields[i].key).equal(fields[i].value);
            }
        }

        try {
            const factsheetsResult = await query.where('institution/isLocal').equal('false').select('institution/erasmusCode', 'institution/name', 'id').take(10).skip(page * 10).getList();

            console.log(factsheetsResult);
            setFactsheets(factsheetsResult.value);
            setTotal(factsheetsResult.total);
            setPage(pageNumber);
        } catch (err) {
            console.log(err);
        }
    }

    // runs on first mount
    useEffect(() => {
        (async () => {
            try {
                const countriesResult = await context.model('MobilityCountries').asQueryable().take(-1).getItems();
                fetchAndSetFactsheets(0);

                setCountriesList(countriesResult);
            } catch (err) {
                console.log(err);
            }
        })()
    }, [])

    // runs when country changes
    useEffect(() => {
        if (mountedCountrySelected.current === false) {
            mountedCountrySelected.current = true;
            return;
        }

        (async () => {
            try {
                fetchAndSetFactsheets(0);
                const institutionsResult = await context.model(`MobilityInstitutions`).where('country').equal(countrySelected).take(-1).getItems();

                setInstitutionsList(institutionsResult);
            } catch (err) {
                console.log(err)
            }
        })()
    }, [countrySelected]);

    // runs when the user changes mobility institution selected
    useEffect(() => {
        if (mountedInstitutionSelected.current === false) {
            mountedInstitutionSelected.current = true;
            return;
        }

        (async () => {
            fetchAndSetFactsheets(0);
        })()
    }, [institutionSelected]);

    // runs when the user changes page
    useEffect(() => {
        if (mountedPage.current === false) {
            mountedPage.current = true;
            return;
        }

        (async () => {
            fetchAndSetFactsheets(page)
        })()
    }, [page]);

    return (
        <div>
            <div className="row">
                <p className="font-weight-bold">Partner Factsheets</p>
            </div>

            <div className="row">
                <div className="col-md-3 bg-light pt-2 filter-sidebar h-100 pb-2">
                    <div>
                        <div className="row">
                            <div className="col-md-5">
                                <p className="font-weight-bold">Filters</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <SelectWithLabel
                                value={(countrySelected) ? countriesList.find(i => i.id === countrySelected).countryName : ""}
                                onSelectChange={(e) => {
                                    setInstitutionSelected(null)
                                    if (e !== null) {
                                        setCountrySelected(e.value);
                                    } else {
                                        setCountrySelected(null);
                                    }
                                }}
                                id="country"
                                placeholderText="Type country"
                                clearable={true}
                                listOptions={countriesList.map(i => ({ value: i.id, label: i.countryName }))}
                            >
                                Country of Partner Institution
                            </SelectWithLabel>
                        </div>

                        <div className="form-group">
                            <SelectWithLabel
                                disabled={countrySelected ? false : true}
                                value={((institutionSelected) ? institutionsList.find(i => i.id === institutionSelected).name : "")}
                                onSelectChange={(e) => {
                                    if (e !== null) {
                                        setInstitutionSelected(e.value)
                                    }
                                    else {
                                        setInstitutionSelected(null)
                                    }
                                }}
                                id="institution"
                                placeholderText="Enter name of the institution"
                                clearable={true}
                                listOptions={institutionsList.map(i => ({ value: i.id, label: i.name + " (" + i.erasmusCode + ")" }))}
                            >
                                Name of the Partner Institution
                            </SelectWithLabel>
                        </div>
                    </div>
                </div>

                <div className="col-md-9 pt-0 pl-3 pr-0 mx-auto">
                    {(factsheets.length) ?
                        // TODO check unique 
                        <div id="no-more-tables">
                            <table className="table-striped table-condensed cf table-responsive">
                                <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                                    <tr>
                                        <th scope="col" className="col-md-1"></th>
                                        <th scope="col" className="col-md-3 text-center">Erasmus Code</th>
                                        <th scope="col" className="col-md-4 text-center">Institution Name</th>
                                        <th scope="col" className="col-md-1 text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {factsheets.map((i, idx) => (
                                        <tr key={"factsheet_" + idx}>
                                            <td className="text-center" data-title="No">{(idx + 1) + 10 * page}</td>
                                            <td data-title="erasmus-code" className="text-center">{i.institution_erasmusCode}</td>
                                            <td data-title="institution-name" className="text-center">{i.institution_name}</td>
                                            <td>
                                                <div className="text-center">
                                                    <Link to={`/factsheets/view/${i.id}`}>
                                                        <i className='fa fa-eye fa-primary m-2'>
                                                        </i>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>))
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="d-flex justify-content-center">
                            <p>No Factsheets found</p>
                        </div>
                    }
                    {total !== 0 ? <div className="py-2">Total number of partner factsheets: {total}</div> : <></>}
                    {total !== 0
                        ?
                        <div className="d-flex justify-content-center">
                            <Pagination total={total} page={page} pageLimit={10} setPage={setPage} />
                        </div>
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    );
}

export default memo(PartnerFactsheets);