import { useState, useEffect } from 'react'
import { ReactDataContext } from '@themost/react';
import { Tab, Nav, Col, Row } from 'react-bootstrap'
import Loader from "react-loader-spinner";
import EditInstitutionUsefulLinks from './components/EditInstitutionUsefulLinks';
import EditInstitutionalCoordinatorContactDepartment from './components/EditInstitutionalCoordinatorContactDepartment';
import EditDeadlinesWeeksLimit from './components/EditDeadlinesWeeksLimit';
import EditCoreInformation from './components/EditCoreInformation';
import EditAdditionalRequirements from './components/EditAdditionalRequirements';
import EditAccessibilities from './components/EditAccessibilities';
import EditAdditionalInformation from './components/EditAdditionalInformation';
import { Redirect } from 'react-router-dom';

const FactsheetEdit = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [step, setStep] = useState(1);
    const [authDepartments, setAuthDepartments] = useState([]);
    const [factsheet, setFactsheet] = useState(null);

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(props.userService.getToken());

    useEffect(() => {
        document.title = props.title;
    })

    const renderButtons = (step) => {
        return (
            <>
                {step > 1 ? <button className="btn btn-previous m-2" onClick={() => setStep(prevStep => prevStep - 1)}>Previous</button> : <></> }
                {step < 7 ? <button className="btn btn-next m-2" type="submit">Next</button> : <button className="btn btn-next m-2" type="submit">Save</button> }
            </>
        );
    }

    const removeIdFromObject = (object) => {
        if(!(typeof object === 'object' && object !== null)) {
            return;
        }

        if(object.hasOwnProperty('id')) {
            delete object.id;
        }

        if(Array.isArray(object)) {
            object.forEach(element => removeIdFromObject(element))
            return;
        }

        for(const key in object) {
            removeIdFromObject(object[key])
        }
    }


    const removeFactsheetFromObject = (object) => {
        if(!(typeof object === 'object' && object !== null)) {
            return;
        }

        if(object.hasOwnProperty('factsheet')) {
            delete object.factsheet;
        }

        if(Array.isArray(object)) {
            object.forEach(element => removeFactsheetFromObject(element))
            return;
        }

        for(const key in object) {
            removeFactsheetFromObject(object[key])
        }
    }

    const handleStep = () => {
        if(step === 7) {
            (async () => {
                setLoading(true);
                console.log(factsheet);
                let factsheetWithoutIds = { ...factsheet }
                // const institutionalCoordinatorContactDepartmentId = factsheet.institutionalCoordinatorContact.department.id;
                // console.log(institutionalCoordinatorContactDepartmentId)
                for(const key in factsheet) {
                    removeIdFromObject(factsheetWithoutIds[key])
                    removeFactsheetFromObject(factsheetWithoutIds[key])
                }

                // delete factsheetWithoutIds.institutionalCoordinatorContact.department;
                // factsheetWithoutIds.institutionalCoordinatorContact['department'] = {
                //     id: institutionalCoordinatorContactDepartmentId
                // };
                // factsheetWithoutIds.institutionalCoordinatorContact.department.id = institutionalCoordinatorContactDepartmentId
                // factsheetWithoutIds.institutionalCoordinatorContact.department['id'] = institutionalCoordinatorContactDepartmentId;
                console.log(factsheetWithoutIds);
                const result = await context.model('MobilityFactsheets').save(factsheetWithoutIds);
                setStep(1);
                console.log(result)
                setFactsheet(result)
                setLoading(false);
            })()
            
            return;
        }
        setStep(prevStep => prevStep + 1);
    }

    const handleChange = (key, newValue) => {
        setFactsheet({
            ...factsheet,
            [`${key}`]: newValue
        })
    }

    const switchCaseSteps = () => {
        switch (step) {
            case 1:
                return (
                    <Tab.Pane eventKey={"step_1"}>
                        <EditInstitutionUsefulLinks handleStep={handleStep} handleChange={handleChange} data={{institution: factsheet.institution, generalUrl: factsheet.generalUrl, facultyUrl: factsheet.facultyUrl, courseCatalogueUrl: factsheet.courseCatalogueUrl}}>
                            {renderButtons(step)}
                        </EditInstitutionUsefulLinks>
                    </Tab.Pane>
                );
            case 2:
                return (
                    <Tab.Pane eventKey={"step_2"}>
                        <EditInstitutionalCoordinatorContactDepartment handleStep={handleStep} handleChange={handleChange} context={context} data={{
                            institutionalCoordinatorContact: factsheet.institutionalCoordinatorContact,
                            authDepartments,
                            institution: factsheet.institution
                        }}
                        >
                            {renderButtons(step)}
                        </EditInstitutionalCoordinatorContactDepartment>
                    </Tab.Pane>
                );
            case 3:
                return (
                    <Tab.Pane eventKey={"step_3"}>
                        <EditDeadlinesWeeksLimit handleStep={handleStep} handleChange={handleChange} data={{decisionResponseWeeksLimit: factsheet.decisionResponseWeeksLimit, transcriptOfRecordsWeeksLimit: factsheet.transcriptOfRecordsWeeksLimit, deadlineNominationsCalendar: factsheet.deadlineNominationsCalendar, deadlineIncomingsCalendar: factsheet.deadlineIncomingsCalendar}} >
                            {renderButtons(step)}
                        </EditDeadlinesWeeksLimit>
                    </Tab.Pane>
                );
            case 4:
                return (
                    <Tab.Pane eventKey={"step_4"}>
                        <EditCoreInformation handleStep={handleStep} handleChange={handleChange} data={{
                            applicationIncomingsInformationEntry: factsheet?.applicationIncomingsInformationEntry ?? null,
                            housingInformationEntry: factsheet?.housingInformationEntry ?? null,
                            visaInformationEntry: factsheet?.visaInformationEntry ?? null,
                            insuranceInformationEntry: factsheet?.insuranceInformationEntry ?? null
                        }}>
                            {renderButtons(step)}
                        </EditCoreInformation>
                    </Tab.Pane>
                );
            case 5:
                return (
                    <Tab.Pane eventKey={"step_5"}>
                        <EditAdditionalRequirements handleStep={handleStep} handleChange={handleChange} data ={{
                            additionalRequirements: factsheet?.additionalRequirements ?? []
                        }}>
                            {renderButtons(step)}
                        </EditAdditionalRequirements>
                    </Tab.Pane>
                );
            case 6:
                return (
                    <Tab.Pane eventKey={"step_6"}>
                        <EditAccessibilities handleStep={handleStep} handleChange={handleChange} data ={{
                            accessibilities: factsheet?.accessibilities ?? []
                        }}>
                            {renderButtons(step)}
                        </EditAccessibilities>
                    </Tab.Pane>
                );
            case 7:
                return (
                    <Tab.Pane eventKey={"step_7"}>
                        <EditAdditionalInformation handleStep={handleStep} handleChange={handleChange} data ={{
                            additionalInfos: factsheet?.additionalInfos ?? []
                        }}>
                            {renderButtons(step)}
                        </EditAdditionalInformation>
                    </Tab.Pane>
                );
            default:
                return <h1>No content</h1>
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const factsheetResult = await context.model('MobilityFactsheets').where('id').equal(props?.match?.params?.id).take(1).getList();
                const authDepartments = await context.model(`MobilityDepartments`).asQueryable().where('institution/heiId').equal('auth.gr').getItems();

                if(factsheetResult.value.length === 0) {
                    setError(true);
                    return;
                }

                if(!factsheetResult.value[0].hasOwnProperty('institutionalCoordinatorContact')) {
                    factsheetResult.value[0].institutionalCoordinatorContact = {};
                }

                console.log(factsheetResult.value[0])
                if(!factsheetResult.value[0].institutionalCoordinatorContact.hasOwnProperty('department')) {
                    factsheetResult.value[0].institutionalCoordinatorContact['department'] = {};
                } else {
                    factsheetResult.value[0].institutionalCoordinatorContact['department']['institution'] = factsheetResult.value[0].institution.id
                }
                
                if(factsheetResult.value[0]?.institutionalCoordinatorContact?.department?.domain !== 'ad') {
                    factsheetResult.value[0].institutionalCoordinatorContact.department.domain = 'ad';
                }

                setFactsheet(factsheetResult.value[0]);
                setAuthDepartments(authDepartments)
                setLoading(false);
            } catch(err) {
                setError(true);
                console.log(err);
            }
        })()
    }, []);

    if(error) {
        return (
            <Redirect to="/factsheets" />
        );
    }

    if (loading) {
        return (
            <div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Loader
                    className="spinner mx-auto"
                    type="TailSpin"
                    color="#3A435E"
                    height={70}
                    width={70}
                />
            </div>
        )
    }
    else {
        return (
            <Tab.Container id="left-tabs-example" activeKey={"step_" + step}>
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey={"step_1"} >Institution - Useful Links</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"step_2"} disabled={step < 2}>Institutional Coordinator's Contact - Department</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"step_3"} disabled={step < 3}>Deadline for applications of Incoming students - Nominations - Weeks limit</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"step_4"} disabled={step < 4}>Application - Housing - Visa - Insurance - Recognition Process - Other Information</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"step_5"} disabled={step < 5}>Additional Requirements</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"step_6"} disabled={step < 6}>Accessibilities</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"step_7"} disabled={step < 7}>Additional Information</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content style={{ border: "none" }}>
                            {switchCaseSteps()}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        )
    }
}

export default FactsheetEdit;