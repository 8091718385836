const EditInstitutionUsefulLinks = ({ children, handleStep, handleChange, data }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        handleStep();
    }

    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={handleSubmit}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h3 className="text-center">Institution - Useful Links</h3>
                            <div className="form-group">
                                <label>Name *</label>
                                <input type="text" required value={data.institution.name} className="form-control" onChange={e => handleChange('institution', {
                                    ...data.institution, 
                                    name: e.target.value,
                                })} />
                            </div>
                            <div className="form-group">
                                <label>Erasmus Code *</label>
                                <input type="text" required value={data.institution.erasmusCode} className="form-control" onChange={e => handleChange('institution', {
                                    ...data.institution,
                                    erasmusCode: e.target.value,
                                })} />
                            </div>
                            <div className="form-group">
                                <label>HEI Id *</label>
                                <input type="text" required value={data.institution.heiId} className="form-control" onChange={e => handleChange('institution', {
                                    ...data.institution,
                                    heiId: e.target.value,
                                })} />
                            </div>
                            <div className="form-group">
                                <label>City</label>
                                <input type="text" value={data.institution.cityName} className="form-control" onChange={e => handleChange('institution', {
                                    ...data.institution,
                                    cityName: e.target.value
                                })} />
                            </div>
                            <div className="form-group">
                                <label>Country</label>
                                <input readOnly={true} type="text" value={'Greece'} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>Local</label>
                                <input readOnly={true} type="text" value={'Yes'} className="form-control" />
                            </div>
                            <div className="form-group">
                                    <label>General Url</label>
                                    <input type="url" placeholder="https://example.com" pattern="https?://.*" value={data.generalUrl} className="has-validation form-control" 
                                    onChange={e => handleChange('generalUrl', e.target.value)} />
                            </div>
                            <div className="form-group">
                                    <label>Faculty Url</label>
                                    <input type="url" placeholder="https://example.com" pattern="https?://.*" value={data.facultyUrl} className="has-validation form-control" 
                                    onChange={e => handleChange('facultyUrl', e.target.value)} />
                            </div>
                            <div className="form-group">
                                    <label>Course Catalogue Url</label>
                                    <input type="url" placeholder="https://example.com" pattern="https?://.*" value={data.courseCatalogueUrl} className="has-validation form-control" 
                                    onChange={e => handleChange('courseCatalogueUrl', e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                {{ ...children }}
            </div>
        </form>
    );
}

export default EditInstitutionUsefulLinks;