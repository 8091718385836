import { useRef } from 'react';
import RequiredSelectWithLabel from "components/UI/RequiredSelectWithLabel";

const EditInstitutionalCoordinatorContactDepartment = ({ children, handleStep, data, handleChange }) => {
    const departmentDomain = useRef('ad');

    const handleSubmit = (e) => {
        e.preventDefault();
        handleStep();
    }
    
    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={handleSubmit}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h3 className="text-center">Institutional Coordinator's Contact - Department</h3>
                            <div className="form-group">
                                <label>APM</label>
                                <input type="text" value={data.institutionalCoordinatorContact.apm} className="form-control" onChange={e => handleChange('institutionalCoordinatorContact', {
                                    ...data.institutionalCoordinatorContact,
                                    apm: e.target.value
                                })} />
                            </div>
                            <div className="form-group">
                                <label>Family Name *</label>
                                <input type="text"  value={data.institutionalCoordinatorContact.familyName} className="form-control" onChange={e => handleChange('institutionalCoordinatorContact', {
                                    ...data.institutionalCoordinatorContact,
                                    familyName: e.target.value
                                })} />
                            </div>
                            <div className="form-group">
                                <label>Given Name *</label>
                                <input type="text"  value={data.institutionalCoordinatorContact.givenName} className="form-control" onChange={e => handleChange('institutionalCoordinatorContact', {
                                    ...data.institutionalCoordinatorContact,
                                    givenName: e.target.value
                                })} />
                            </div>
                            <div className="form-group">
                                <label>Title</label>
                                <input type="text" value={data.institutionalCoordinatorContact.title} className="form-control" onChange={e => handleChange('institutionalCoordinatorContact', {
                                    ...data.institutionalCoordinatorContact,
                                    title: e.target.value
                                })} />
                            </div>
                            <div className="form-group">
                                <label>Email *</label>
                                <input type="text"  value={data.institutionalCoordinatorContact.email} className="form-control" onChange={e => handleChange('institutionalCoordinatorContact', {
                                    ...data.institutionalCoordinatorContact,
                                    email: e.target.value
                                })} />
                            </div>
                            <div className="form-group">
                                <label>Address</label>
                                <input type="text" value={data.institutionalCoordinatorContact.address} className="form-control" onChange={e => handleChange('institutionalCoordinatorContact', {
                                    ...data.institutionalCoordinatorContact,
                                    address: e.target.value
                                })} />
                            </div>
                            <div className="form-group">
                                <label>Url</label>
                                <input type="url" value={data.institutionalCoordinatorContact.url} placeholder="https://example.com" pattern="https?://.*" className="form-control" onChange={e => handleChange('institutionalCoordinatorContact', {
                                    ...data.institutionalCoordinatorContact,
                                    url: e.target.value
                                })} />
                            </div>
                            <div className="form-group">
                                <label>Phone</label>
                                <input type="text" value={data.institutionalCoordinatorContact.phone} placeholder="Phone number in the E.164 format, with the leading “+” sign. e.g. : +302310996727" pattern="\+30[0-9]{10}" className="form-control" onChange={e => handleChange('institutionalCoordinatorContact', {
                                    ...data.institutionalCoordinatorContact,
                                    phone: e.target.value
                                })} />
                            </div>
                            <h5 className="text-center">Department</h5>
                            <div className="form-group">
                                <label>Name *</label>
                                <RequiredSelectWithLabel
                                    onSelectChange={e => { 
                                        if(e !== null) {
                                            const selectedDepartment = data.authDepartments.find(department => department.name === e?.value);
                                            handleChange('institutionalCoordinatorContact', {
                                                id: 3,
                                                ...data.institutionalCoordinatorContact,
                                                department: {
                                                    // id: selectedDepartment.id,
                                                    // domain: 'ad',
                                                    // ounitId: selectedDepartment?.ounitId ?? null,
                                                    institution: selectedDepartment.institution.id,
                                                    name: e?.value,
                                                    // url: selectedDepartment?.url ?? null
                                                }
                                            }) 
                                        } else
                                            handleChange('institutionalCoordinatorContact', {
                                                ...data.institutionalCoordinatorContact,
                                                department: {
                                                    domain: 'ad'
                                                }
                                            }) 
                                    }}
                                    id={`name`}
                                    value={data?.institutionalCoordinatorContact?.department?.name ?? ''}
                                    clearable={true}
                                    listOptions={data.authDepartments.map(department => ({ value: department.name, label: department.name}))}
                                />
                            </div>
                            <div className="form-group">
                                <label>Domain</label>
                                <input readOnly={true} type="text" value={departmentDomain.current} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>Url</label>
                                <input 
                                    type="url" 
                                    placeholder="https://example.com" 
                                    pattern="https?://.*" 
                                    className="form-control" 
                                    value={data?.institutionalCoordinatorContact?.department?.url ?? ''} 
                                    onChange={e => 
                                        handleChange('institutionalCoordinatorContact', {
                                            ...data.institutionalCoordinatorContact,
                                            department: {
                                                ...data?.institutionalCoordinatorContact?.department,
                                                url: e.target.value
                                            }
                                        }
                                    )
                                    } 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                {{ ...children }}
            </div>
        </form>
    );
}

export default EditInstitutionalCoordinatorContactDepartment;