export default function StudentStudiesFormTranslation(fieldName, langcode = "en") {

    const fields = {
        personalData: {
            en: {
                label: "Personal Data",
                placeholder: ""
            },
            gr: {
                label: "Προσωπικά Δεδομένα",
                placeholder: ""
            }
        },
        currentStudies: {
            en: {
                label: "Current Studies",
                placeholder: ""
            },
            gr: {
                label: "Τρέχουσες Σπουδές",
                placeholder: ""
            }
        },
        preferredMobility: {
            en: {
                label: "Preferred Mobility",
                placeholder: ""
            },
            gr: {
                label: "Προτιμήσεις κινητικότητας",
                placeholder: ""
            }
        },
        languageSkills: {
            en: {
                label: "Language Skills",
                placeholder: ""
            },
            gr: {
                label: "Γλωσσικές Ικανότητες",
                placeholder: ""
            }
        },
        mobilityFunding: {
            en: {
                label: "Mobility Funding",
                placeholder: ""
            },
            gr: {
                label: "Χρηματοδότηση Κινητικότητας",
                placeholder: ""
            }
        },
        previousMobilityInformation: {
            en: {
                label: "Previous mobility information and comments",
                placeholder: ""
            },
            gr: {
                label: "Πληροφορίες προηγούμενων μετακινήσεων και σχόλια",
                placeholder: ""
            }
        },
        attachedFiles: {
            en: {
                label: "Attached files",
                placeholder: ""
            },
            gr: {
                label: "Συνημμένα αρχεία",
                placeholder: ""
            }
        },
        affirmation: {
            en: {
                label: "Affirmation and consent statement",
                placeholder: "",
            },
            gr: {
                label: "Υπεύθυνη δήλωση και δήλωση συναίνεσης",
                placeholder: ""
            }
        },
        next_button: {
            en: {
                label: 'Next'
            },
            gr: {
                label: 'Επόμενο'
            }
        },
        previous_button: {
            en: {
                label: 'Previous'
            },
            gr: {
                label: 'Προηγούμενο'
            }
        },
        submit_button: {
            en: {
                label: 'Submit'
            },
            gr: {
                label: 'Υποβολή'
            }
        },
        draft_button: {
            en: {
                label: 'Save as Draft'
            },
            gr: {
                label: 'Αποθήκευση ως πρόχειρο'
            }
        },
        validate_button: {
            en: {
                label: "Validate"
            },
            gr: {
                label: "Επικύρωση"
            }
        }
    };

    return fields[fieldName][langcode];
}