import { useEffect, useState } from "react";
import { Form } from 'react-bootstrap'
import { DropdownSelect } from "components/UI/ComplexComponents/DropdownSelect";
import StudentPreferredMobilityComponentTranslation from "../translations/StudentPreferredMobilityComponentTranslation";
import { ReactDataContext } from "@themost/react";
import UserService from "services/UserService";
import RequiredSelectWithLabel from "components/UI/RequiredSelectWithLabel";
import Loader from "react-loader-spinner";

function StudentPreferredMobilityComponent({ children, langCode, view, handleStep, studentStudiesForm, setStudentStudiesForm, setDisabledNextStep, globalCountries, saveDraft, shortTerm, currentYear }) {
    const initialState = [{
        type: StudentPreferredMobilityComponentTranslation("typeOfMobility_options", langCode, { studies: StudentPreferredMobilityComponentTranslation(shortTerm ? "shortTermStudies" : "longTermStudies", langCode)['label'] })['label'],
        programme: StudentPreferredMobilityComponentTranslation("mobilityProgram_options", langCode)['label'],
        country: "",
        institution: "",
        responsiblePerson: "",
        startSemester: "",
        semesterDuration: "",
        monthDuration: "",
        projectMobility: false,
        iiaId: "",
        id: 1,
        blended: false
    }]
    const month = new Date().getMonth();

    var ac_year;

    if (month > 7) {
        ac_year = currentYear + "/" + (currentYear + 1)
    } else {
        ac_year = (currentYear - 1) + "/" + currentYear
    }

    const [preferredMobility, setPreferredMobility] = useState(initialState)

    const [countries, setCountries] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [responsiblePersons, setResponsiblePersons] = useState([]);
    const [departmentalContacts, setDepartmentalContacts] = useState([]);

    const [loading, setLoading] = useState(false)
    const [blended, setBlended] = useState(false)

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(UserService.getToken());

    const fetchCountriesAndInstitutions = async (id) => {
        try {
            setDisabledNextStep(true)
            setLoading(true)
            const result = await context.model('MobilityAgreements').asQueryable()
                .where("studentMobilitiesForStudies/fromInstitution").equal(1).and('isDeleted').equal(false)
                .and('startYear/id').lowerThan(new Date().getFullYear() + 1).and("endYear/id").greaterThan(new Date().getFullYear())
                // .and('studentMobilitiesForStudies/fromDepartment').equal(id).or('departmentalCoordinatorContacts/department').equal(id)
                .take(-1).getItems();
            console.log(result)
            const tempInstitutions = []
            const tempCountries = []
            const tempContacts = []
            var blended = false
            result.forEach(item => {
                if (item.localStatus == 1 || (item.ewpStatus != undefined && item.ewpStatus.id == 7)) {
                    item.institutions.forEach(institution => {
                        tempInstitutions.push(institution)
                    })
                    item.studentMobilitiesForStudies.forEach(i => {
                        if (i.fromInstitution.id === 1 && i.blended === true) {
                            blended = true
                        }
                    })
                    item.departmentalCoordinatorContacts.forEach(contact => {
                        if (contact.department !== undefined && contact.department != null && contact.department.institution.id === 1 && item.institutions.length == 2 && contact.id !== 3) {
                            tempContacts.push({ contact: contact, iiaId: item.id, institution: item.institutions[1], blended: blended })
                        }
                    })
                }
                blended = false
            })

            console.log(tempContacts)
            console.log(tempInstitutions)
            setDepartmentalContacts(tempContacts)
            let uniqueInstitutions = [...new Map(tempInstitutions.map(item =>
                [item["id"], item])).values()];
            console.log(uniqueInstitutions)
            uniqueInstitutions.forEach(institution => {
                tempCountries.push(institution.country)
            })
            let uniqueCountries = [... new Map(tempCountries.map(item =>
                [item["id"], item])).values()];

            uniqueCountries.forEach(obj => {
                obj['countryNameGR'] = (globalCountries.filter(i => i.countryCode === obj.countryId)[0].countryNameGR)
            })
            uniqueInstitutions.map(obj => {
                if (!('countryNameGR' in obj.country)) {
                    console.log('no countryNameGR')
                    obj.country['countryNameGR'] = (globalCountries.filter(i => i.countryCode === obj.country.countryId)[0].countryNameGR)
                }
            })
            console.log(uniqueCountries)
            console.log(uniqueInstitutions)
            setCountries(uniqueCountries)
            setInstitutions(uniqueInstitutions)
        } catch (err) {
            console.log(err);
        }
        setDisabledNextStep(false)
    }

    useEffect(() => {
        setLoading(false)
    }, [countries, institutions])

    const startSemesterShortOpts = [];

    for (var i = 5; i <= 30; i++) {
        startSemesterShortOpts.push({ label: i });
    }

    // const fetchResponsiblePerson = async (id) => {
    //     try {
    //         setLoading(true)
    //         const result = await context.model("MobilityContacts").asQueryable()
    //             .select("familyName", "givenName", "department/name").and("department").equal(id).take(-1).getItems();
    //         console.log(result);
    //         setResponsiblePersons(result);
    //     } catch (err) {
    //         console.log(err);
    //     }
    //     setLoading(false)
    // }

    const required = view ? false : true

    const setFieldValue = (item, value) => {
        setPreferredMobility(current =>
            current.map(obj => {
                if (obj.id == item.split('_')[1]) {
                    return { ...obj, [item.split('_')[0]]: value };
                }

                return obj;
            }),
        );
    };


    useEffect(() => {
        const departmentId = studentStudiesForm?.studiesData[0]?.department?.id ? studentStudiesForm?.studiesData[0]?.department?.id : null
        console.log(departmentId)
        if (departmentId !== null) {
            fetchCountriesAndInstitutions(departmentId).catch(console.error);
            // fetchResponsiblePerson(departmentId).catch(console.error);
        }
        if (studentStudiesForm?.prefferedMobility !== undefined && studentStudiesForm?.prefferedMobility.length > 0) {
            const temp = []
            studentStudiesForm?.prefferedMobility.forEach((i, index) => {
                console.log(JSON.parse(JSON.stringify(studentStudiesForm?.prefferedMobility[index])))
                temp.push(JSON.parse(JSON.stringify(studentStudiesForm?.prefferedMobility[index])))
            })
            console.log(temp)

            temp.forEach(item => {
                item["institution"] = item['institution'].name
                if (langCode === "gr") {
                    console.log(globalCountries)
                    console.log(item)
                    console.log(globalCountries.filter(i => i.countryCode === item["country"]?.countryId))
                    item["country"] = globalCountries.filter(i => i.countryCode === item["country"]?.countryId)[0]?.countryNameGR
                } else {
                    console.log(globalCountries)
                    console.log(item)
                    console.log(globalCountries.filter(i => i.countryCode === item["country"]?.countryId)[0]?.countryName.replace(/\s/g, ""))
                    item["country"] = globalCountries.filter(i => i.countryCode === item["country"]?.countryId)[0]?.countryName.replace(/\s/g, "")
                }
                if (langCode === "gr") {
                    if (item?.startSemester.includes("Winter") || item?.startSemester.includes("Χειμερινό")) {
                        item['startSemester'] = "Χειμερινό Εξάμηνο " + ac_year
                    } else {
                        item['startSemester'] = "Εαρινό Εξάμηνο " + ac_year
                    }
                    if (item?.type.includes('Short') || item?.type.includes('Βραχυχρόνια')) {
                        item['type'] = 'Βραχυχρόνια κινητικότητα για Σπουδές'
                    } else {
                        item['type'] = 'Μακροχρόνια κινητικότητα για Σπουδές'
                    }
                    if (item.iiaId.departmentalCoordinatorContacts !== undefined) {
                        item.iiaId.departmentalCoordinatorContacts.forEach(i => {
                            if (i.department.institution.id === 1) {
                                item['responsiblePerson'] = i.givenName + ' ' + i.familyName + ' (' + i.department.nameGR + ')'
                            }
                        })
                    }
                } else {
                    if (item?.startSemester.includes("Winter") || item?.startSemester.includes("Χειμερινό")) {
                        item['startSemester'] = "Winter semester " + ac_year
                    } else {
                        item['startSemester'] = "Spring semester " + ac_year
                    }
                    if (item?.type.includes('Short') || item?.type.includes('Βραχυχρόνια')) {
                        item['type'] = 'Short-term mobility for Studies'
                    } else {
                        item['type'] = 'Long-term mobility for Studies'
                    }
                    if (item.iiaId.departmentalCoordinatorContacts !== undefined) {
                        item.iiaId.departmentalCoordinatorContacts.forEach(i => {
                            if (i.department.institution.id === 1) {
                                item['responsiblePerson'] = i.givenName + ' ' + i.familyName + ' (' + i.department.name + ')'
                            }
                        })
                    }
                }
            })
            console.log(temp)
            setPreferredMobility(temp)
        } else {
            setPreferredMobility(initialState)
        }
    }, [])

    useEffect(() => {
        console.log(studentStudiesForm)
        console.log(preferredMobility)
    }, [])

    if (loading) {
        return (
            <div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Loader
                    className="spinner mx-auto"
                    type="TailSpin"
                    color="#3A435E"
                    height={70}
                    width={70}
                />
            </div>
        )
    } else {
        return (
            <form className="g-3 needs-validation" noValidate={false} onSubmit={async (event) => {
                event.preventDefault();
                if (!view) {
                    const tempMobilities = []
                    console.log(preferredMobility)
                    preferredMobility.forEach(item => {
                        console.log(item)
                        let country = ""
                        console.log(countries)
                        if (langCode === "gr") {
                            console.log('gr')
                            country = countries.filter(i => i.countryNameGR === item.country)[0]
                        } else {
                            console.log('en')
                            country = countries.filter(i => i.countryName === item.country)[0]
                        }
                        console.log(country)
                        console.log(item.responsiblePerson)
                        var tempResponsiblePerson = item.responsiblePerson.split(' (')
                        console.log(tempResponsiblePerson)

                        const institution = institutions.filter(i => i.name === item.institution)[0]
                        console.log(departmentalContacts.filter(i => i.contact.familyName === tempResponsiblePerson[0].split(' ')[0]))
                        const iiaId = departmentalContacts.filter(item => item.contact.familyName === tempResponsiblePerson[0].split(' ')[0] && item.contact.givenName === tempResponsiblePerson[0].split(' ')[1] && item.institution.name === institution.name && (item.contact.department.name === tempResponsiblePerson[1].split(')')[0] || item.contact.department.nameGR === tempResponsiblePerson[1].split(')')[0]))
                        console.log(iiaId)
                        console.log(institution)
                        if (iiaId.length > 0) {
                            tempMobilities.push({
                                type: item.type,
                                programme: item.programme,
                                country: country,
                                institution: institution,
                                responsiblePerson: item.responsiblePerson,
                                startSemester: item.startSemester,
                                semesterDuration: item.semesterDuration,
                                monthDuration: item.monthDuration,
                                projectMobility: item.projectMobility,
                                iiaId: { id: iiaId[0].iiaId },
                                id: item.id,
                                blended: item.blended
                            })
                        } else {
                            tempMobilities.push({
                                type: item.type,
                                programme: item.programme,
                                country: country,
                                institution: institution,
                                responsiblePerson: item.responsiblePerson,
                                startSemester: item.startSemester,
                                semesterDuration: item.semesterDuration,
                                monthDuration: item.monthDuration,
                                projectMobility: item.projectMobility,
                                iiaId: item.iiaId ? item.iiaId : '',
                                id: item.id,
                                blended: item.blended
                            })
                        }

                        console.log(item);
                    })
                    console.log(tempMobilities)
                    setStudentStudiesForm({ ...studentStudiesForm, prefferedMobility: tempMobilities })
                    var activeElement = document.activeElement;
                    if (activeElement.value === 'draft') {
                        saveDraft({ ...studentStudiesForm, prefferedMobility: tempMobilities })
                    } else {
                        handleStep();
                    }
                } else {
                    handleStep();
                }
            }}>
                <div className="containter">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card p-4">
                                <h3 className="text-center">{StudentPreferredMobilityComponentTranslation("h3_title", langCode)['label']}</h3>
                                <div className="form-group">
                                    {preferredMobility?.length > 0 && preferredMobility?.map((r, idx) => {
                                        return (
                                            <div key={idx + 1}>
                                                <h5 className="text-left">{StudentPreferredMobilityComponentTranslation("prefered_mobility_options_label", langCode)['label']} {idx + 1}</h5>
                                                {
                                                    DropdownSelect({
                                                        optionsList: [],
                                                        parentSetValueSelected: setFieldValue,
                                                        parentValueSelected: preferredMobility[idx].type,
                                                        selectLabel: StudentPreferredMobilityComponentTranslation("typeOfMobility", langCode)['label'],
                                                        componentId: "type_" + Number(r.id),
                                                        required: required,
                                                        disabled: true
                                                    })
                                                }
                                                {
                                                    DropdownSelect({
                                                        optionsList: [],
                                                        parentSetValueSelected: setFieldValue,
                                                        parentValueSelected: preferredMobility[idx].programme,
                                                        selectLabel: StudentPreferredMobilityComponentTranslation("mobilityProgram", langCode)['label'],
                                                        componentId: "programme_" + Number(r.id),
                                                        required: required,
                                                        disabled: true
                                                    })
                                                }
                                                {
                                                    DropdownSelect({
                                                        optionsList: countries ? langCode === "gr" ? countries.filter(i => i.countryNameGR !== "Ελλάδα").map(i => ({ label: i?.countryNameGR, value: i?.id })) : langCode === "en" ? countries.filter(i => i.countryName !== "Greece").map(i => ({ label: i?.countryName, value: i?.id })) : [] : [],
                                                        parentSetValueSelected: setFieldValue,
                                                        parentValueSelected: preferredMobility[idx].country,
                                                        selectLabel: StudentPreferredMobilityComponentTranslation("country", langCode)['label'],
                                                        componentId: "country_" + Number(r.id),
                                                        required: required,
                                                        disabled: view,
                                                        loading: loading
                                                    })
                                                }
                                                {
                                                    DropdownSelect({
                                                        disabled: preferredMobility[idx].country == "" || view,
                                                        optionsList: preferredMobility[idx].country != "" ? langCode === "gr" ? institutions.filter(i => i.country.countryNameGR == preferredMobility[idx].country).map(i => ({ label: i?.name, value: i?.id })) : langCode === "en" ? institutions.filter(i => i.country.countryName == preferredMobility[idx].country).map(i => ({ label: i?.name, value: i?.id })) : [] : [],
                                                        parentSetValueSelected: setFieldValue,
                                                        parentValueSelected: preferredMobility[idx].institution,
                                                        selectLabel: StudentPreferredMobilityComponentTranslation("institution", langCode)['label'],
                                                        componentId: "institution_" + Number(r.id),
                                                        required: required,
                                                    })
                                                }
                                                {/* preferredMobility[idx].institution != "" ? departmentalContacts.filter(i => i.contact.department.institution.name === preferredMobility[idx].institution).map(i => ({ label: i?.contact?.familyName + " " + i?.contact?.givenName + " (" + langCode === "gr" ? i?.contact?.department?.nameGR : i?.contact?.department?.name + ")", value: i?.contact?.familyName + " " + i?.contact?.givenName + " (" + langCode === "gr" ? i?.contact?.department?.nameGR : i?.contact?.department?.name + ")" })) : [], */}
                                                {/* {
                                                    DropdownSelect({
                                                        optionsList: preferredMobility[idx].institution != "" ? langCode === "gr" ? departmentalContacts.filter(i => i.institution.name === preferredMobility[idx].institution).map(i => ({ label: i?.contact?.familyName + " " + i?.contact?.givenName + " (" + i?.contact?.department?.nameGR + ")", value: i?.contact?.familyName + " " + i?.contact?.givenName + " (" + i?.contact?.department?.nameGR + ")" })) : departmentalContacts.filter(i => i.institution.name === preferredMobility[idx].institution).map(i => ({ label: i?.contact?.familyName + " " + i?.contact?.givenName + " (" + i?.contact?.department?.name + ")", value: i?.contact?.familyName + " " + i?.contact?.givenName + " (" + i?.contact?.department?.name + ")" })) : [],
                                                        parentSetValueSelected: setFieldValue,
                                                        parentValueSelected: preferredMobility[idx].responsiblePerson,
                                                        selectLabel: StudentPreferredMobilityComponentTranslation("agreementManager", langCode)['label'],
                                                        componentId: "responsiblePerson_" + Number(r.id),
                                                        required: required,
                                                        disabled: preferredMobility[idx].institution == "" || view,
                                                    })
                                                } */}
                                                <RequiredSelectWithLabel
                                                    disabled={preferredMobility[idx].institution == "" || view}
                                                    value={preferredMobility[idx].responsiblePerson}
                                                    onSelectChange={(newValue, action) => {
                                                        if (action.action == "select-option") {
                                                            if (newValue != null) {
                                                                var temp = ''
                                                                var blended = false
                                                                if (shortTerm) {
                                                                    temp = departmentalContacts.filter((item) =>
                                                                        newValue.label.includes(item.contact.givenName) && newValue.label.includes(item.contact.familyName) && (newValue.label.includes(item.contact.department.name) || newValue.label.includes(item.contact.department.nameGR)) && item.institution.name === preferredMobility[idx].institution
                                                                    )
                                                                    if (temp.length > 0) {
                                                                        blended = temp[0].blended
                                                                    }
                                                                }

                                                                // console.log(departmentalContacts.filter((item) =>
                                                                //     newValue.label.includes(item.contact.givenName) && newValue.label.includes(item.contact.familyName) && (newValue.label.includes(item.contact.department.name) || newValue.label.includes(item.contact.department.nameGR)) && item.institution.name === preferredMobility[idx].institution
                                                                // ))
                                                                setPreferredMobility(current =>
                                                                    current.map(obj => {
                                                                        if (obj.id == r.id) {
                                                                            return { ...obj, responsiblePerson: newValue.label, blended: blended };
                                                                        }

                                                                        return obj;
                                                                    }),
                                                                );
                                                            }

                                                        }
                                                        else if (action.action == "clear") {
                                                            setPreferredMobility(current =>
                                                                current.map(obj => {
                                                                    if (obj.id == r.id) {
                                                                        return { ...obj, responsiblePerson: '', blended: false };
                                                                    }

                                                                    return obj;
                                                                }),
                                                            );
                                                        }
                                                    }}
                                                    id={"responsiblePerson_" + Number(r.id)}
                                                    clearable={true}
                                                    listOptions={preferredMobility[idx].institution != "" ? langCode === "gr" ? departmentalContacts.filter(i => i.institution.name === preferredMobility[idx].institution).map(i => ({ label: i?.contact?.familyName + " " + i?.contact?.givenName + " (" + i?.contact?.department?.nameGR + ")", value: i?.contact?.familyName + " " + i?.contact?.givenName + " (" + i?.contact?.department?.nameGR + ")" })) : departmentalContacts.filter(i => i.institution.name === preferredMobility[idx].institution).map(i => ({ label: i?.contact?.familyName + " " + i?.contact?.givenName + " (" + i?.contact?.department?.name + ")", value: i?.contact?.familyName + " " + i?.contact?.givenName + " (" + i?.contact?.department?.name + ")" })) : []}
                                                    required={required}
                                                >
                                                    {StudentPreferredMobilityComponentTranslation("agreementManager", langCode)['label']}
                                                </RequiredSelectWithLabel>
                                                {shortTerm && <div>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={idx}
                                                        checked={preferredMobility[idx].blended}
                                                        disabled={true}
                                                        label={StudentPreferredMobilityComponentTranslation("blended", langCode)['label']}>
                                                    </Form.Check>
                                                </div>}
                                                {/* {
                                                    DropdownSelect({
                                                        optionsList: StudentPreferredMobilityComponentTranslation("startSemester_options", langCode, { year: ac_year }),
                                                        parentSetValueSelected: setFieldValue,
                                                        parentValueSelected: preferredMobility[idx].startSemester,
                                                        selectLabel: StudentPreferredMobilityComponentTranslation("startSemester", langCode)['label'],
                                                        componentId: "startSemester_" + Number(r.id),
                                                        required: required,
                                                        disabled: view
                                                    })
                                                } */}
                                                <RequiredSelectWithLabel
                                                    disabled={view}
                                                    value={preferredMobility[idx].startSemester}
                                                    onSelectChange={(newValue, action) => {
                                                        if (action.action == "select-option") {
                                                            if (newValue != null) {
                                                                setPreferredMobility(current =>
                                                                    current.map(obj => {
                                                                        if (obj.id == r.id) {
                                                                            return { ...obj, startSemester: newValue.label, semesterDuration: '' };
                                                                        }

                                                                        return obj;
                                                                    }),
                                                                );
                                                            }
                                                            // else {
                                                            //     parentSetValueSelected(componentId, null)
                                                            // }
                                                        }
                                                        else if (action.action == "clear") {
                                                            setPreferredMobility(current =>
                                                                current.map(obj => {
                                                                    if (obj.id == r.id) {
                                                                        return { ...obj, startSemester: '', semesterDuration: '' };
                                                                    }

                                                                    return obj;
                                                                }),
                                                            );
                                                        }
                                                    }}
                                                    id={"startSemester_" + Number(r.id)}
                                                    clearable={true}
                                                    listOptions={StudentPreferredMobilityComponentTranslation("startSemester_options", langCode, { year: ac_year })}
                                                    required={required}
                                                >
                                                    {shortTerm ? StudentPreferredMobilityComponentTranslation("startSemesterShort", langCode)['label'] : StudentPreferredMobilityComponentTranslation("startSemester", langCode)['label']}
                                                </RequiredSelectWithLabel>
                                                <RequiredSelectWithLabel
                                                    disabled={view}
                                                    value={preferredMobility[idx].semesterDuration}
                                                    onSelectChange={(newValue, action) => {
                                                        if (action.action == "select-option") {
                                                            if (newValue != null) {
                                                                setPreferredMobility(current =>
                                                                    current.map(obj => {
                                                                        if (obj.id == r.id) {
                                                                            return { ...obj, semesterDuration: newValue.label, monthDuration: '' };
                                                                        }

                                                                        return obj;
                                                                    }),
                                                                );
                                                            }
                                                            // else {
                                                            //     parentSetValueSelected(componentId, null)
                                                            // }
                                                        }
                                                        else if (action.action == "clear") {
                                                            setPreferredMobility(current =>
                                                                current.map(obj => {
                                                                    if (obj.id == r.id) {
                                                                        return { ...obj, semesterDuration: '', monthDuration: '' };
                                                                    }

                                                                    return obj;
                                                                }),
                                                            );
                                                        }
                                                    }}
                                                    id={"semesterDuration_" + Number(r.id)}
                                                    clearable={true}
                                                    listOptions={shortTerm ? startSemesterShortOpts : langCode === "gr" && preferredMobility[idx].startSemester.includes('Εαρινό') ? [{ label: 1 }] : langCode === "en" && preferredMobility[idx].startSemester.includes('Spring') ? [{ label: 1 }] : [{ label: 1 }, { label: 2 }]}
                                                    required={required}
                                                >
                                                    {shortTerm ? StudentPreferredMobilityComponentTranslation("durationDays", langCode)['label'] : StudentPreferredMobilityComponentTranslation("durationSemesters", langCode)['label']}
                                                </RequiredSelectWithLabel>
                                                {/* {
                                                    DropdownSelect({
                                                        optionsList: [{ label: 1 }, { label: 2 }],
                                                        parentSetValueSelected: setOnChange,
                                                        parentValueSelected: preferredMobility[idx].semesterDuration,
                                                        selectLabel: StudentPreferredMobilityComponentTranslation("durationSemesters", langCode)['label'],
                                                        componentId: "semesterDuration_" + Number(r.id),
                                                        required: required,
                                                        disabled: view,
                                                    })
                                                } */}
                                                {!shortTerm &&
                                                    DropdownSelect({
                                                        optionsList: preferredMobility[idx].semesterDuration ? preferredMobility[idx].semesterDuration === 1 ? [{ label: 1 }, { label: 2 }, { label: 3 }, { label: 4 }, { label: 5 }, { label: 6 }] : preferredMobility[idx].semesterDuration === 2 ? [{ label: 7 }, { label: 8 }, { label: 9 }, { label: 10 }, { label: 11 }, { label: 12 }] : [] : [],
                                                        parentSetValueSelected: setFieldValue,
                                                        parentValueSelected: preferredMobility[idx].monthDuration,
                                                        selectLabel: StudentPreferredMobilityComponentTranslation("durationMonths", langCode)['label'],
                                                        componentId: "monthDuration_" + Number(r.id),
                                                        required: required,
                                                        disabled: view || !preferredMobility[idx].semesterDuration
                                                    })
                                                }
                                                <div>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={idx}
                                                        checked={preferredMobility[idx].projectMobility}
                                                        disabled={view}
                                                        onChange={(e) => {
                                                            if (e.target.checked === true) {
                                                                setPreferredMobility(current =>
                                                                    current.map(obj => {
                                                                        if (obj.id == (r.id)) {
                                                                            return { ...obj, projectMobility: true };
                                                                        }

                                                                        return obj;
                                                                    }),
                                                                );
                                                            } else {
                                                                setPreferredMobility(current =>
                                                                    current.map(obj => {
                                                                        if (obj.id == (r.id)) {
                                                                            return { ...obj, projectMobility: false };
                                                                        }

                                                                        return obj;
                                                                    }),
                                                                );
                                                            }
                                                        }}
                                                        label={StudentPreferredMobilityComponentTranslation("project", langCode)['label']}>
                                                    </Form.Check>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {shortTerm === false && <button className='btn btn-success m-2' type="button" disabled={view} onClick={() => {
                                        var temp = [...preferredMobility]
                                        temp.push({
                                            type: StudentPreferredMobilityComponentTranslation("typeOfMobility_options", langCode, { studies: StudentPreferredMobilityComponentTranslation(shortTerm ? "shortTermStudies" : "longTermStudies", langCode)['label'] })['label'],
                                            programme: StudentPreferredMobilityComponentTranslation("mobilityProgram_options", langCode)['label'],
                                            country: "",
                                            institution: "",
                                            responsiblePerson: "",
                                            startSemester: "",
                                            semesterDuration: "",
                                            monthDuration: "",
                                            projectMobility: false,
                                            iiaId: "",
                                            id: preferredMobility.length + 1,
                                            blended: false
                                        })
                                        setPreferredMobility(temp)
                                    }}><i className="fa fa-plus mr-2"></i> {StudentPreferredMobilityComponentTranslation("mobilityAdd", langCode)['label']}</button>}
                                    {preferredMobility?.length > 1 && <button className='btn btn-danger m-2' type="button" disabled={view} onClick={() => {
                                        var temp = [...preferredMobility]
                                        temp.splice(preferredMobility.length - 1, 1)
                                        setPreferredMobility(temp)
                                    }}><i class="fa fa-trash"></i> {StudentPreferredMobilityComponentTranslation("mobilityRemove", langCode)['label']}</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {children}
            </form >
        );
    }
}

export default StudentPreferredMobilityComponent