const Institutions = ({ institutions, departmentalCoordinators }) => {
    return (
        <>
            <div><h3>A.	Information about Higher Education Institutions</h3></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <table className="table-responsive">
                            <thead>
                                <tr style={{ backgroundColor: "#3A435E", color: "white" }} className="customtable">
                                    <th align="center">
                                        <b>Institution</b>
                                    </th>
                                    <th align="center"><b>Erasmus code</b></th>
                                    <th align="center"><b>Contact details</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                {institutions.map(i => {
                                    const contacts = departmentalCoordinators.filter(c => c?.department?.institution?.id === i.id);
                                    return <Institution institute={i} contacts={contacts} />;
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};


const Institution = ({ institute, contacts }) => {
    return (
        <tr key={'table-a-institute-' + institute.id}>
            <td>{institute.name}</td>
            <td>{institute.erasmusCode}</td>
            <td>
                <>
                    {contacts.map(c => {
                        return (<Contact contact={c} />);
                    })
                    }
                </>
            </td>
        </tr>
    );
}

const Roles = ({roles}) => {
    if(Array.isArray(roles) && roles.length > 0) {
        return <>
            {roles.map(r => {
                return r.value;
            }).toString()
            }
        </>
    } else {
        return <>-</>
    };
}

const Contact = ({ contact }) => {
    if (!contact) {
        return (
            <>
                <br />Title: -
                <br />Full name: -
                <br />Department: -
                <br />Address: -
                <br />Tel: -
                <br />Email: -
                <br />Roles: -
            </>
        );
    } else {
        return (
            <>
                <br />Title: {contact.title ? contact.title : '-'}
                <br />Full name: {contact.givenName} {contact.familyName}
                <br />Department: {contact?.department ? contact?.department?.name : '-'}
                <br />Address: {contact.address ? contact.address : '-'}
                <br />Tel: {contact.phone ? contact.phone : '-'}
                <br />Email: {contact.email ? contact.email : '-'}
                <br />Roles: <Roles roles={contact.roles} />
                <br/>
            </>
        );
    }
}

export default Institutions