export const deadlineDefaultState = {
    autumnTerm: null,
    springTerm: null
}

const Deadline = ({ number, title, calendarEntry, editable, handleTdChange }) => {
    return (
        <>
            <div>
                <h3>
                    {`${number}. ${title}`}
                </h3>
            </div>
            <div className="table-responsive mx-auto col-md-8">
                <table className="table cf" style={{ tableLayout: 'fixed'}}>
                    <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                        <tr>
                            <th scope="col" className="text-center col-md-6">Autumn Term</th>
                            <th scope="col" className="text-center col-md-6">Spring Term</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td 
                                className="text-center" 
                                style={{ wordWrap: 'break-word' }}
                                contentEditable={editable}
                                onBlur={e => {
                                    handleTdChange('autumnTerm', e.target.innerText)
                                }}
                            >
                                {calendarEntry?.autumnTerm ?? '-'}
                            </td>
                            <td 
                                className="text-center" 
                                style={{ wordWrap: 'break-word' }}
                                contentEditable={editable}
                                onBlur={e => {
                                    handleTdChange('springTerm', e.target.innerText)
                                }}
                            >
                                {calendarEntry?.springTerm ?? '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Deadline;