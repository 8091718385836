import { useEffect, useState } from "react";
import StudentStudiesFormTranslation, { translation } from "./translations/StudentStudiesFormTranslation";
import { Tab, Nav, Col, Row } from 'react-bootstrap';
import StudentDataComponent from "./components/StudentDataComponent";
import StudentStudiesComponent from "./components/StudentStudiesComponent";
import StudentPreferredMobilityComponent from "./components/StudentPreferredMobilityComponent";
import StudentLanguageSkillsComponent from "./components/StudentLanguageSkillsComponent";
import StudentMobilityFundingComponent from "./components/StudentMobilityFundingComponent";
import StudentPreviousMobilityComponent from "./components/StudentPreviousMobilityComponent";
import StudentAffirmationComponent from "./components/StudentAffirmationComponent";
import UserService from "services/UserService";
import { ReactDataContext } from '@themost/react';
import Loader from "react-loader-spinner";
import StudentDataFiles from "./components/StudentDataFilesComponent";
import { useHistory } from 'react-router-dom';
import { applicationStudiesFormEnabled, getCookie } from "components/Common/CommonFunctions";

export default function StudentStudiesForm(props) {

    const history = useHistory();
    const [shortTerm, setShortTerm] = useState(props.location.pathname.includes('short'))

    const [langCode, setLangCode] = useState(getCookie("lang") ? getCookie("lang") : "en")
    const [step, setStep] = useState(1);
    const [view, setView] = useState(props.match.path.includes('view'))
    const [formId, setFormId] = useState(props.match.params ? Number(props.match.params.formId) : null)
    const [loader, setLoader] = useState(true);
    const [studentStudiesForm, setStudentStudiesForm] = useState({})
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [cities, setCities] = useState([]);
    const [owner, setOwner] = useState();
    const [currentYear, setCurrentYear] = useState('');

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(UserService.getToken());

    const fetchInitialData = async () => {
        try {
            const ownerResponse = await context.model('Users/Me').asQueryable().take(-1).getItems();
            console.log(ownerResponse)
            setOwner(ownerResponse)
        } catch (err) {
            console.log(err);
        }

        try {
            const countriesResponse = await context.model('ApplicationGlobalCountries').asQueryable().take(-1).getItems();
            setCountries(countriesResponse);
        } catch (err) {
            console.log(err);
        }

        try {
            const citiesResponse = await context.model('ApplicationGlobalCities').asQueryable().take(-1).getItems();
            setCities(citiesResponse);
        } catch (err) {
            console.log(err)
        }

        try {
            const languagesResponse = await context.model('ApplicationGlobalLanguages').asQueryable().take(-1).getItems();
            setLanguages(languagesResponse);
            console.log(languagesResponse)
        } catch (err) {
            console.log(err);
        }

        try {
            const departments = await context.model('MobilityDepartments').asQueryable().where('institution').equal(1).take(-1).getItems();
            setDepartments(departments)
            console.log(departments)
        } catch (err) {
            console.log(err);
        }
        if (formId) {
            try {
                const form = await context.model('ApplicationStudentStudiesForms').asQueryable().where('id').equal(formId).and('isDeleted').equal(false).getItem();
                const temp = {
                    id: form.id,
                    applicationNumber: form.applicationNumber,
                    studentData: form.studentData,
                    languageData: form.languageData,
                    studiesData: form.studiesData,
                    prefferedMobility: form.prefferedMobility,
                    paymentData: form.paymentData,
                    pastMobilityData: form.pastMobilityData,
                    filesData: form.filesData,
                    pastMobilities: form.pastMobilities,
                    specialNeedsCategory: form.specialNeedsCategory,
                    communicationAccept: form.communicationAccept,
                    gradesConfirm: form.gradesConfirm,
                    gdprConfirm: form.gdprConfirm,
                    owner: form.owner,
                    type: form.type
                }
                const applicationSettings = await context.model('ApplicationSettings').asQueryable().where("applicationType").equal(temp?.type).take(-1).getItems();
                console.log(applicationSettings)
                setCurrentYear(applicationSettings[0].academicYear)
                await applicationStudiesFormEnabled();
                setShortTerm(temp?.type === 'short-term-studies' ? true : false)
                if (temp?.type === 'short-term-studies') {
                    if (getCookie("checkIfShortTermApplicationFormAcademicYearDisabled") === "true" || getCookie("checkIfShortTermApplicationFormAcademicPeriodDisabled") === "true") {
                        console.log("short term disabled")
                        history.push("/401");
                        return;
                    }
                }
                else {
                    if (getCookie("checkIfLongTermApplicationFormAcademicYearDisabled") === "true" || getCookie("checkIfLongTermApplicationFormAcademicPeriodDisabled") === "true") {
                        console.log("long term disabled")
                        history.push("/401")
                    }
                }
                console.log(temp)
                await setStudentStudiesForm(temp)

                setTimeout(() => {
                    setLoader(false);
                }, 1000);
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                const applicationSettings = await context.model('ApplicationSettings').asQueryable().where("applicationType").equal(shortTerm ? 'short-term-studies' : 'long-term-studies').take(-1).getItems();
                console.log(applicationSettings)
                setCurrentYear(applicationSettings[0].academicYear)
            } catch (err) {
                console.log(err);
            }
            await applicationStudiesFormEnabled();
            if (shortTerm === 'short-term-studies') {
                if (getCookie("checkIfShortTermApplicationFormAcademicYearDisabled") === "true" || getCookie("checkIfShortTermApplicationFormAcademicPeriodDisabled") === "true") {
                    console.log("short term disabled")
                    history.push("/401");
                    return;
                }
            }
            else {
                if (getCookie("checkIfLongTermApplicationFormAcademicYearDisabled") === "true" || getCookie("checkIfLongTermApplicationFormAcademicPeriodDisabled") === "true") {
                    console.log("long term disabled")
                    history.push("/401")
                }
            }
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }

    const [user, setUser] = useState(JSON.parse(localStorage.getItem("role")).role)
    const [edit, setEdit] = useState(props.location.pathname.includes('edit'))

    useEffect(() => {
        fetchInitialData();
        console.log(shortTerm)
    }, [])

    const [disabledNextStep, setDisabledNextStep] = useState(false)
    const [disabledSubmit, setDisabledSubmit] = useState(true)

    const renderSideNav = () => {
        return (<Nav variant="pills" className="flex-column" activeKey={"step_" + step}>
            <Nav.Item className={formId ? " " : "no-cursor"}>
                <Nav.Link className={formId ? " " : "no-cursor"}
                    eventKey={"step_1"}
                    onClick={() => formId ? setStep(prevStep => 1) : {}}>
                    {StudentStudiesFormTranslation("personalData", langCode)['label']}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={formId ? " " : "no-cursor"}>
                <Nav.Link className={formId ? " " : "no-cursor"}
                    eventKey={"step_2"}
                    disabled={user === 'IRO' ? false : formId && view ? false : step < 2 || step == 9}
                    onClick={() => formId ? setStep(prevStep => 2) : {}}>
                    {StudentStudiesFormTranslation("currentStudies", langCode)['label']}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={formId ? " " : "no-cursor"}>
                <Nav.Link className={formId ? " " : "no-cursor"}
                    eventKey={"step_3"}
                    disabled={user === 'IRO' ? false : formId && view ? false : step < 3 || step == 9}
                    onClick={() => formId ? setStep(prevStep => 3) : {}}>
                    {StudentStudiesFormTranslation("preferredMobility", langCode)['label']}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={formId ? " " : "no-cursor"} >
                <Nav.Link className={formId ? " " : "no-cursor"}
                    eventKey={"step_4"}
                    disabled={user === 'IRO' ? false : formId && view ? false : step < 4 || step == 9}
                    onClick={() => formId ? setStep(prevStep => 4) : {}}>
                    {StudentStudiesFormTranslation("languageSkills", langCode)['label']}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={formId ? " " : "no-cursor"}>
                <Nav.Link className={formId ? " " : "no-cursor"}
                    eventKey={"step_5"}
                    disabled={user === 'IRO' ? false : formId && view ? false : step < 5 || step == 9}
                    onClick={() => formId ? setStep(prevStep => 5) : {}}>
                    {StudentStudiesFormTranslation("mobilityFunding", langCode)['label']}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={formId ? " " : "no-cursor"}>
                <Nav.Link className={formId ? " " : "no-cursor"}
                    eventKey={"step_6"}
                    disabled={user === 'IRO' ? false : formId && view ? false : step < 6 || step == 9}
                    onClick={() => formId ? setStep(prevStep => 6) : {}}>
                    {StudentStudiesFormTranslation("previousMobilityInformation", langCode)['label']}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={formId ? " " : "no-cursor"}>
                <Nav.Link className={formId ? " " : "no-cursor"}
                    eventKey={"step_7"}
                    disabled={user === 'IRO' ? false : formId && view ? false : step < 7 || step == 9}
                    onClick={() => formId ? setStep(prevStep => 7) : {}}>
                    {StudentStudiesFormTranslation("attachedFiles", langCode)['label']}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={formId ? " " : "no-cursor"}>
                <Nav.Link className={formId ? " " : "no-cursor"}
                    eventKey={"step_8"}
                    disabled={user === 'IRO' ? false : formId && view ? false : step < 8 || step == 9}
                    onClick={() => formId ? setStep(prevStep => 8) : {}}>
                    {StudentStudiesFormTranslation("affirmation", langCode)['label']}
                </Nav.Link>
            </Nav.Item>
        </Nav>);
    }

    const handleStep = () => {
        if (step !== 8) {
            setStep(prevStep => prevStep + 1)
        }
    }

    const validateApplication = async () => {
        try {
            studentStudiesForm['type'] = shortTerm ? 'short-term-studies' : 'long-term-studies'
            studentStudiesForm['status'] = 'validated';
            studentStudiesForm['applicationYear'] = currentYear
            const result = await context.model('ApplicationStudentStudiesForms').save(studentStudiesForm);
            console.log(result)
            history.push('/application/outgoing/list')
        } catch (err) {
            console.log(err)
        }
    }

    const saveDraft = async (draft) => {
        try {
            draft['type'] = shortTerm ? 'short-term-studies' : 'long-term-studies'
            draft['status'] = 'draft';
            draft['owner'] = owner;
            draft['applicationYear'] = currentYear
            console.log(draft)
            const result = await context.model('ApplicationStudentStudiesForms').save(draft);
            alert("Draft Saved successfully")
            history.push('/application/outgoing/list')
            console.log(result)
        } catch (err) {
            console.log(err);
        }
    }

    const saveStudentApplication = async (status) => {
        try {
            await applicationStudiesFormEnabled();
            if (shortTerm) {
                if (getCookie("checkIfShortTermApplicationFormAcademicYearDisabled") === "true" || getCookie("checkIfShortTermApplicationFormAcademicPeriodDisabled") === "true") {
                    console.log("short term disabled")
                    history.push("/401");
                    return;
                }
            }
            else {
                if (getCookie("checkIfLongTermApplicationFormAcademicYearDisabled") === "true" || getCookie("checkIfLongTermApplicationFormAcademicPeriodDisabled") === "true") {
                    console.log("long term disabled")
                    history.push("/401")
                }
            }
            studentStudiesForm['type'] = shortTerm ? 'short-term-studies' : 'long-term-studies'
            studentStudiesForm['status'] = status;
            studentStudiesForm['owner'] = owner;
            studentStudiesForm['applicationYear'] = currentYear
            console.log("saveStudentApplication")
            console.log(studentStudiesForm)
            const result = await context.model('ApplicationStudentStudiesForms').save(studentStudiesForm);
            if (status === 'draft') {
                alert("Draft Saved successfully")
                history.push('/application/outgoing/list')
            } else {
                alert("Saved successfully")
                history.push('/application/outgoing/list')
            }
            console.log(result)
        } catch (err) {
            console.log(err);
        }
    }

    const renderButtons = () => {
        return (
            <div className="d-flex justify-content-center">
                {step > 1 ? <button className="btn btn-previous m-2" type="submit" onClick={() => setStep(prevStep => prevStep - 1)}>{StudentStudiesFormTranslation("previous_button", langCode)['label']}</button> : <></>}
                {step < 8 ?
                    <>
                        <button className='btn btn-next m-2' disabled={disabledNextStep} type='submit' action="action" value="next">{StudentStudiesFormTranslation("next_button", langCode)['label']}</button>
                        <button className='btn btn-primary btn-success btn-submit m-2' disabled={view} type='submit' action="action" value="draft">{StudentStudiesFormTranslation("draft_button", langCode)['label']}</button>
                    </>
                    :
                    <>
                        <button className="btn btn-primary btn-success btn-submit m-2" disabled={view || disabledSubmit} onClick={() => saveStudentApplication('submitted')}>{StudentStudiesFormTranslation("submit_button", langCode)['label']}</button>
                        <button className='btn btn-primary btn-success btn-submit m-2' disabled={view} type='submit' action="action" value="draft">{StudentStudiesFormTranslation("draft_button", langCode)['label']}</button>
                        {JSON.parse(localStorage.getItem("role")).role === "IRO" && <button className="btn btn-primary btn-success btn-submit m-2" disabled={view} onClick={() => validateApplication()}>{StudentStudiesFormTranslation("validate_button", langCode)['label']}</button>}
                    </>}
            </div>
        );
    }

    const switchCasesSteps = () => {
        switch (step) {
            case 1:
                return <Tab.Pane eventKey={'step_1'}>
                    <StudentDataComponent
                        studentStudiesForm={studentStudiesForm}
                        setStudentStudiesForm={(e) => setStudentStudiesForm(e)}
                        langCode={langCode}
                        handleStep={handleStep}
                        countries={countries}
                        cities={cities}
                        view={view}
                        saveDraft={saveDraft}
                    >
                        {renderButtons()}
                    </StudentDataComponent>
                </Tab.Pane>
            case 2:
                return <Tab.Pane eventKey={'step_2'}>
                    <StudentStudiesComponent
                        studentStudiesForm={studentStudiesForm}
                        setStudentStudiesForm={(e) => setStudentStudiesForm(e)}
                        langCode={langCode}
                        view={view}
                        handleStep={handleStep}
                        departments={departments}
                        saveDraft={saveDraft}
                    >
                        {renderButtons()}
                    </StudentStudiesComponent>
                </Tab.Pane>
            case 3:
                return <Tab.Pane eventKey={'step_3'}>
                    <StudentPreferredMobilityComponent
                        globalCountries={countries}
                        setDisabledNextStep={setDisabledNextStep}
                        studentStudiesForm={studentStudiesForm}
                        setStudentStudiesForm={(e) => setStudentStudiesForm(e)}
                        langCode={langCode}
                        view={view}
                        handleStep={handleStep}
                        saveDraft={saveDraft}
                        shortTerm={shortTerm}
                        currentYear={currentYear}
                    >
                        {renderButtons()}
                    </StudentPreferredMobilityComponent>
                </Tab.Pane>
            case 4:
                return <Tab.Pane eventKey={'step_4'}>
                    <StudentLanguageSkillsComponent
                        studentStudiesForm={studentStudiesForm}
                        setStudentStudiesForm={(e) => setStudentStudiesForm(e)}
                        langCode={langCode}
                        view={view}
                        handleStep={handleStep}
                        languages={languages}
                        saveDraft={saveDraft}
                    >
                        {renderButtons()}
                    </StudentLanguageSkillsComponent>
                </Tab.Pane>
            case 5:
                return <Tab.Pane eventKey={'step_5'}>
                    <StudentMobilityFundingComponent
                        studentStudiesForm={studentStudiesForm}
                        setStudentStudiesForm={(e) => setStudentStudiesForm(e)}
                        langCode={langCode}
                        view={view}
                        handleStep={handleStep}
                        saveDraft={saveDraft}
                    >
                        {renderButtons()}
                    </StudentMobilityFundingComponent>
                </Tab.Pane>
            case 6:
                return <Tab.Pane eventKey={'step_6'}>
                    <StudentPreviousMobilityComponent
                        studentStudiesForm={studentStudiesForm}
                        setStudentStudiesForm={(e) => setStudentStudiesForm(e)}
                        langCode={langCode}
                        view={view}
                        handleStep={handleStep}
                        saveDraft={saveDraft}
                    >
                        {renderButtons()}
                    </StudentPreviousMobilityComponent>
                </Tab.Pane>
            case 7:
                return <Tab.Pane eventKey={'step_7'}>
                    <StudentDataFiles
                        studentStudiesForm={studentStudiesForm}
                        setStudentStudiesForm={(e) => setStudentStudiesForm(e)}
                        langCode={langCode}
                        view={view}
                        handleStep={handleStep}
                        saveDraft={saveDraft}
                        shortTerm={shortTerm}
                    >
                        {renderButtons()}
                    </StudentDataFiles>
                </Tab.Pane>
            case 8:
                return <Tab.Pane eventKey={'step_8'}>
                    <StudentAffirmationComponent
                        setDisabledSubmit={setDisabledSubmit}
                        studentStudiesForm={studentStudiesForm}
                        setStudentStudiesForm={(e) => setStudentStudiesForm(e)}
                        langCode={langCode}
                        view={view}
                        handleStep={handleStep}
                        saveDraft={saveDraft}
                    >
                        {renderButtons()}
                    </StudentAffirmationComponent>
                </Tab.Pane>
            default:
                return <h1>No content</h1>
        }
    }

    if (loader || studentStudiesForm === null) {
        return (<div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Loader
                className="spinner mx-auto"
                type="TailSpin"
                color="#3A435E"
                height={70}
                width={70}
            />
        </div>);
    } else {
        return <Tab.Container id="left-tabs" activeKey={'step_' + step}>
            <Row>
                <Col sm={3}>
                    {renderSideNav()}
                </Col>
                <Col sm={9}>
                    {switchCasesSteps()}
                </Col>
            </Row>
        </Tab.Container>
    }


}