export const languages = [

    {
        "name": "Afar",
        "langcode": "aa"
    },
    {
        "name": "Afrikaans",
        "langcode": "af"
    },
    {
        "name": "Akan",
        "langcode": "ak"
    },
    {
        "name": "Albanian",
        "langcode": "sq"
    },
    {
        "name": "Amharic",
        "langcode": "am"
    },
    {
        "name": "Arabic",
        "langcode": "ar"
    },
    {
        "name": "Aragonese",
        "langcode": "an"
    },
    {
        "name": "Armenian",
        "langcode": "hy"
    },
    {
        "name": "Assamese",
        "langcode": "as"
    },
    {
        "name": "Avaric",
        "langcode": "av"
    },
    {
        "name": "Avestan",
        "langcode": "ae"
    },
    {
        "name": "Aymara",
        "langcode": "ay"
    },
    {
        "name": "Azerbaijani",
        "langcode": "az"
    },
    {
        "name": "Bambara",
        "langcode": "bm"
    },
    {
        "name": "Bashkir",
        "langcode": "ba"
    },
    {
        "name": "Basque",
        "langcode": "eu"
    },
    {
        "name": "Belarusian",
        "langcode": "be"
    },
    {
        "name": "Bengali",
        "langcode": "bn"
    },
    {
        "name": "Bihari",
        "langcode": "bh"
    },
    {
        "name": "Bislama",
        "langcode": "bi"
    },
    {
        "name": "Bosnian",
        "langcode": "bs"
    },
    {
        "name": "Breton",
        "langcode": "br"
    },
    {
        "name": "Bulgarian",
        "langcode": "bg"
    },
    {
        "name": "Burmese",
        "langcode": "my"
    },
    {
        "name": "Catalan",
        "langcode": "ca"
    },
    {
        "name": "Chamorro",
        "langcode": "ch"
    },
    {
        "name": "Chechen",
        "langcode": "ce"
    },
    {
        "name": "Chichewa",
        "langcode": "ny"
    },
    {
        "name": "Chinese",
        "langcode": "zh"
    },
    {
        "name": "Church Slavic",
        "langcode": "cu"
    },
    {
        "name": "Chuvash",
        "langcode": "cv"
    },
    {
        "name": "Cornish",
        "langcode": "kw"
    },
    {
        "name": "Corsican",
        "langcode": "co"
    },
    {
        "name": "Cree",
        "langcode": "cr"
    },
    {
        "name": "Croatian",
        "langcode": "hr"
    },
    {
        "name": "Czech",
        "langcode": "cs"
    },
    {
        "name": "Danish",
        "langcode": "da"
    },
    {
        "name": "Divehi",
        "langcode": "dv"
    },
    {
        "name": "Dutch",
        "langcode": "nl"
    },
    {
        "name": "Dzongkha",
        "langcode": "dz"
    },
    {
        "name": "English",
        "langcode": "en"
    },
    {
        "name": "Estonian",
        "langcode": "et"
    },
    {
        "name": "Ewe",
        "langcode": "ee"
    },
    {
        "name": "Faroese",
        "langcode": "fo"
    },
    {
        "name": "Fijian",
        "langcode": "fj"
    },
    {
        "name": "Finnish",
        "langcode": "fi"
    },
    {
        "name": "French",
        "langcode": "fr"
    },
    {
        "name": "Frisian",
        "langcode": "fy"
    },
    {
        "name": "Fulah",
        "langcode": "ff"
    },
    {
        "name": "Gaelic (Scots)",
        "langcode": "ga"
    },
    {
        "name": "Gaelic, Scottish Gaelic",
        "langcode": "gd"
    },
    {
        "name": "Gallegan",
        "langcode": "gl"
    },
    {
        "name": "Ganda",
        "langcode": "lg"
    },
    {
        "name": "Georgian",
        "langcode": "ka"
    },
    {
        "name": "German",
        "langcode": "de"
    },
    {
        "name": "Greek",
        "langcode": "el"
    },
    {
        "name": "Guarani",
        "langcode": "gn"
    },
    {
        "name": "Gujarati",
        "langcode": "gu"
    },
    {
        "name": "Haitian - Haitian Creole",
        "langcode": "ht"
    },
    {
        "name": "Hebrew",
        "langcode": "he"
    },
    {
        "name": "Herero",
        "langcode": "hz"
    },
    {
        "name": "Hindi",
        "langcode": "hi"
    },
    {
        "name": "Hiri Motu",
        "langcode": "ho"
    },
    {
        "name": "Hungarian",
        "langcode": "hu"
    },
    {
        "name": "Icelandic",
        "langcode": "is"
    },
    {
        "name": "Ido",
        "langcode": "io"
    },
    {
        "name": "Igbo",
        "langcode": "ig"
    },
    {
        "name": "Indonesian",
        "langcode": "id"
    },
    {
        "name": "Interlingue",
        "langcode": "ie"
    },
    {
        "name": "Inuktitut",
        "langcode": "iu"
    },
    {
        "name": "Inupiaq",
        "langcode": "ik"
    },
    {
        "name": "Irish",
        "langcode": "ga"
    },
    {
        "name": "Italian",
        "langcode": "it"
    },
    {
        "name": "Japanese",
        "langcode": "ja"
    },
    {
        "name": "Javanese",
        "langcode": "jv"
    },
    {
        "name": "Kalaallisut",
        "langcode": "kl"
    },
    {
        "name": "Kannada",
        "langcode": "kn"
    },
    {
        "name": "Kanuri",
        "langcode": "kr"
    },
    {
        "name": "Kashmiri",
        "langcode": "ks"
    },
    {
        "name": "Kazakh",
        "langcode": "kk"
    },
    {
        "name": "Khmer",
        "langcode": "km"
    },
    {
        "name": "Kikuyu",
        "langcode": "ki"
    },
    {
        "name": "Kinyarwanda",
        "langcode": "rw"
    },
    {
        "name": "Kirghiz",
        "langcode": "ky"
    },
    {
        "name": "Komi",
        "langcode": "kv"
    },
    {
        "name": "Kongo",
        "langcode": "kg"
    },
    {
        "name": "Korean",
        "langcode": "ko"
    },
    {
        "name": "Kuanyama",
        "langcode": "kj"
    },
    {
        "name": "Kurdish",
        "langcode": "ku"
    },
    {
        "name": "Lao",
        "langcode": "lo"
    },
    {
        "name": "Latin",
        "langcode": "la"
    },
    {
        "name": "Latvian",
        "langcode": "lv"
    },
    {
        "name": "Letzeburgesch",
        "langcode": "lb"
    },
    {
        "name": "Limburgan - Limburger - Limburgish",
        "langcode": "li"
    },
    {
        "name": "Lingala",
        "langcode": "ln"
    },
    {
        "name": "Lithuanian",
        "langcode": "lt"
    },
    {
        "name": "Luba-Katanga",
        "langcode": "lu"
    },
    {
        "name": "Macedonian",
        "langcode": "mk"
    },
    {
        "name": "Malagasy",
        "langcode": "mg"
    },
    {
        "name": "Malay",
        "langcode": "ms"
    },
    {
        "name": "Malayalam",
        "langcode": "ml"
    },
    {
        "name": "Maltese",
        "langcode": "mt"
    },
    {
        "name": "Manx",
        "langcode": "gv"
    },
    {
        "name": "Maori",
        "langcode": "mi"
    },
    {
        "name": "Marathi",
        "langcode": "mr"
    },
    {
        "name": "Marshall",
        "langcode": "mh"
    },
    {
        "name": "Moldavian",
        "langcode": "mo"
    },
    {
        "name": "Mongolian",
        "langcode": "mn"
    },
    {
        "name": "Montenegrin",
        "langcode": "sh"
    },
    {
        "name": "Nauru",
        "langcode": "na"
    },
    {
        "name": "Navajo",
        "langcode": "nv"
    },
    {
        "name": "Ndebele North",
        "langcode": "nd"
    },
    {
        "name": "Ndebele South",
        "langcode": "nr"
    },
    {
        "name": "Ndonga",
        "langcode": "ng"
    },
    {
        "name": "Nepali",
        "langcode": "ne"
    },
    {
        "name": "Northern Sami",
        "langcode": "se"
    },
    {
        "name": "Norwegian",
        "langcode": "no"
    },
    {
        "name": "Norwegian Bokmael",
        "langcode": "nb"
    },
    {
        "name": "Norwegian Nynorsk",
        "langcode": "nn"
    },
    {
        "name": "Occitan (post 1500)",
        "langcode": "oc"
    },
    {
        "name": "Ojibwa",
        "langcode": "oj"
    },
    {
        "name": "Oriya",
        "langcode": "or"
    },
    {
        "name": "Oromo",
        "langcode": "om"
    },
    {
        "name": "Ossetian",
        "langcode": "os"
    },
    {
        "name": "Pali",
        "langcode": "pi"
    },
    {
        "name": "Panjabi",
        "langcode": "pa"
    },
    {
        "name": "Persian",
        "langcode": "fa"
    },
    {
        "name": "Polish",
        "langcode": "pl"
    },
    {
        "name": "Portuguese",
        "langcode": "pt"
    },
    {
        "name": "Pushto",
        "langcode": "ps"
    },
    {
        "name": "Quechua",
        "langcode": "qu"
    },
    {
        "name": "Raeto-Romance",
        "langcode": "rm"
    },
    {
        "name": "Romanian",
        "langcode": "ro"
    },
    {
        "name": "Rundi",
        "langcode": "rn"
    },
    {
        "name": "Russian",
        "langcode": "ru"
    },
    {
        "name": "Samoan",
        "langcode": "sm"
    },
    {
        "name": "Sango",
        "langcode": "sg"
    },
    {
        "name": "Sanskrit",
        "langcode": "sa"
    },
    {
        "name": "Sardinian",
        "langcode": "sc"
    },
    {
        "name": "Serbian",
        "langcode": "sr"
    },
    {
        "name": "Serbo-Croatian",
        "langcode": "sh"
    },
    {
        "name": "Shona",
        "langcode": "sn"
    },
    {
        "name": "Sichuan Yi",
        "langcode": "ii"
    },
    {
        "name": "Sindhi",
        "langcode": "sd"
    },
    {
        "name": "Sinhalese",
        "langcode": "si"
    },
    {
        "name": "Slovak",
        "langcode": "sk"
    },
    {
        "name": "Slovenian",
        "langcode": "sl"
    },
    {
        "name": "Somali",
        "langcode": "so"
    },
    {
        "name": "Sotho Southern",
        "langcode": "st"
    },
    {
        "name": "Spanish",
        "langcode": "es"
    },
    {
        "name": "Sundanese",
        "langcode": "su"
    },
    {
        "name": "Swahili",
        "langcode": "sw"
    },
    {
        "name": "Swati",
        "langcode": "ss"
    },
    {
        "name": "Swedish",
        "langcode": "sv"
    },
    {
        "name": "Tagalog",
        "langcode": "tl"
    },
    {
        "name": "Tahitian",
        "langcode": "ty"
    },
    {
        "name": "Tajik",
        "langcode": "tg"
    },
    {
        "name": "Tamil",
        "langcode": "ta"
    },
    {
        "name": "Tatar",
        "langcode": "tt"
    },
    {
        "name": "Telugu",
        "langcode": "te"
    },
    {
        "name": "Thai",
        "langcode": "th"
    },
    {
        "name": "Tibetan",
        "langcode": "bo"
    },
    {
        "name": "Tsonga",
        "langcode": "ts"
    },
    {
        "name": "Tswana",
        "langcode": "tn"
    },
    {
        "name": "Turkish",
        "langcode": "tr"
    },
    {
        "name": "Turkmen",
        "langcode": "tk"
    },
    {
        "name": "Twi",
        "langcode": "tw"
    },
    {
        "name": "Uighur",
        "langcode": "ug"
    },
    {
        "name": "Ukrainian",
        "langcode": "uk"
    },
    {
        "name": "Urdu",
        "langcode": "ur"
    },
    {
        "name": "Uzbek",
        "langcode": "uz"
    },
    {
        "name": "Venda",
        "langcode": "ve"
    },
    {
        "name": "Vietnamese",
        "langcode": "vi"
    },
    {
        "name": "Volapok",
        "langcode": "vo"
    },
    {
        "name": "Walloon",
        "langcode": "wa"
    },
    {
        "name": "Welsh",
        "langcode": "cy"
    },
    {
        "name": "Wolof",
        "langcode": "wo"
    },
    {
        "name": "Xhosa",
        "langcode": "xh"
    },
    {
        "name": "Yiddish",
        "langcode": "yi"
    },
    {
        "name": "Zhuang",
        "langcode": "za"
    },
    {
        "name": "Zulu",
        "langcode": "zu"
    }
];
export default {
    languages
}