export default function StudentLanguageSkillsComponentTranslation(fieldName, langcode) {

    const fields = {
        language: {
            en: {
                label: "Language",
                placeholder: "Enter language"
            },
            gr: {
                label: "Γλώσσα",
                placeholder: "Εισάγετε γλώσσα"
            }
        },
        languageCertificateTitle: {
            en: {
                label: "Language Certificate Title",
                placeholder: "Enter the title of the language certificate you possess"
            },
            gr: {
                label: "Τίτλος Πιστοποιητικού Γλωσσομάθειας",
                placeholder: "Εισάγετε τίτλο πιστοποιητικού γλωσσομάθειας"
            }
        },
        languageCertificateLevel: {
            en: {
                label: "Language Certificate Level",
                placeholder: "Enter the level of the language certificate you possess"
            },
            gr: {
                label: "Επίπεδο πιστοποιητικού γλωσσομάθειας",
                placeholder: "Εισάγετε επίπεδο πιστοποιητικού γλωσσομάθειας"
            }
        },
        certificateOfLanguageProficiency: {
            en: {
                label: "Attestation of language knowledge",
                placeholder: "State whether you possess an attestation of knowledge of the aforementioned language instead of/additionally to a language certificate"
            },
            gr: {
                label: "Βεβαίωση Γλωσσομάθειας",
                placeholder: "Δηλώστε αν κατέχετε κάποια βεβαίωση γλωσσομάθειας στην ίδια γλώσσα"
            }
        },
        levelOfLanguageProficiency: {
            en: {
                label: "Level of the language knowledge attestation",
                placeholder: "Emter the level of the language knowledge based on the respective attestation"
            },
            gr: {
                label: "Επίπεδο βεβαίωσης γλωσσομάθειας",
                placeholder: "Εισάγετε το επίπεδο της σχετικής βεβαίωσης γλωσσομάθειας"
            }
        },
        h3_title: {
            en: {
                label: "Language Skills",
            },
            gr: {
                label: "Γλωσσικές ικανότητες",
            }
        },
        languageAdd: {
            en: {
                label: "Add language",
            },
            gr: {
                label: "Προσθήκη γλώσσας",
            }
        },
        languageRemove: {
            en: {
                label: "Remove language",
            },
            gr: {
                label: "Αφαίρεση γλώσσας",
            }
        },
        language_cert_level: {
            en: [{ label: 'A1 - Elementary' }, { label: 'A2 - Basic' }, { label: 'B1 - Moderate' }, { label: 'B2 - Good' }, { label: 'C1 - Very Good' }, { label: 'C2 - Excellent' }, { label: 'M - Native Language' }, { label: 'X - Insufficient' }],
            gr: [{ label: 'A1 - Στοιχειώδης' }, { label: 'A2 - Βασική' }, { label: 'B1 - Μέτρια' }, { label: 'B2 - Καλή' }, { label: 'C1 - Πολύ Καλή' }, { label: 'C2 - Άριστη' }, { label: 'M - Μητρική Γλώσσα' }, { label: 'X - Ανεπαρκής' }],
        },
        language_knowledge_opts: {
            en: [{ label: 'Yes' }, { label: 'No' }],
            gr: [{ label: 'Ναι' }, { label: 'Οχι' }],
        }
    }

    return fields[fieldName][langcode]
}