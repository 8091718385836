import { useState } from "react";


export default function EditIIAComments({ children, iia, setIia, commentsText }) {
    const [comments, setComments] = useState(commentsText)
    return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {
                            JSON.parse(localStorage.getItem("role")).role==="IRO" && 
                                <>
                                    <div>
                                        <h4 className="d-inline-block">Status:</h4> <h4 className="d-inline-block">{iia.status?.name ?? '-'}</h4>
                                    </div>
                                    <div>
                                        <h4 className="d-inline-block">EWP Status:</h4> <h4 className="d-inline-block">{iia.ewpStatus?.name ?? '-'}</h4>
                                    </div>
                                    <div>
                                        <h4 className="d-inline-block">Partner Identification ID:</h4> <h4 className="d-inline-block">{iia.partnerAgreementIdentifications && iia.partnerAgreementIdentifications?.length !== 0 ? iia.partnerAgreementIdentifications[0]?.iiaId ?? '-' : '-'}</h4>
                                    </div>
                                    <div>
                                        <h4 className="d-inline-block">Partner Identification Code:</h4> <h4 className="d-inline-block">{iia.partnerAgreementIdentifications && iia.partnerAgreementIdentifications?.length !== 0 ? iia.partnerAgreementIdentifications[0]?.iiaCode ?? '-' : '-'}</h4>
                                    </div>
                                </>
                        }
                        <div className="form-group">
                            <label htmlFor="comment"><h4>Comments</h4></label>
                            <textarea
                                className="form-control"
                                style={{ width: "100%" }}
                                id="comments"
                                wrap={true}
                                name="comments"
                                value={comments}
                                onChange={(e) => {
                                    const temp = iia;
                                    temp["comments"] = e.target.value;
                                    setIia(temp);
                                    setComments(e.target.value);
                                }
                            }
                            />

                        </div>
                    </div>
                </div>
                {children}
            </div>
    );
}