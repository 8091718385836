
const EditInformationEntryForm = ({ email, phone, urls, language, handleChange }) => {
    return (
        <>
            <div className="form-group">
                <label>Email *</label>
                <input type="email" value={email} placeholder="example@auth.gr" className="has-validation form-control" required onChange={e => handleChange('email', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Phone *</label>
                <input type="text" value={phone} pattern="\+30[0-9]{10}" placeholder="Phone number in the E.164 format, with the leading “+” sign. e.g. : +302310996727" className="form-control" required onChange={e => handleChange('phone', e.target.value)} />
            </div>
            {
                urls ? urls.map((url, idx) => (
                            <div className="form-group" key={url + idx}>
                                <label className='d-block'>Url *</label>
                                <div className="d-flex justify-content-between">
                                    <input type="url" placeholder="https://example.com" pattern="https?://.*" required value={url.value} className={`form-control ${urls.length !== 1 ? 'col-md-9' : 'col-md-12' } d-inline-block`} onChange={e => {
                                        const newArray = Array.from(urls);
                                        newArray.splice(idx, 1, { ...urls[idx], value: e.target.value })

                                        handleChange('urls', newArray);
                                    }}/>
                                    {
                                        urls.length !== 1 ? <button className='btn btn-danger col-md-2 d-inline-block align-self-center' onClick={(e) => {
                                        e.preventDefault()
                                        
                                        const newArray = Array.from(urls);
                                        newArray.splice(idx, 1)
                                        
                                        handleChange('urls', newArray);
                                    }}>
                                        <i className="fa fa-trash mr-2"></i>
                                        Delete URL
                                    </button> : <></>
                                    }
                                </div>
                            </div>
                )) : <></>
            }
            <div className="d-flex justify-content-around">
                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={(e) => {
                    e.preventDefault()
                    handleChange('urls', urls ? [
                        ...urls,
                        {
                            value: '',
                            language: 'en'
                        }
                    ] : [{ value: '', language: 'en' }])
                }}>
                    <i className="fa fa-plus mr-2"></i>
                    Add URL
                </button>
            </div>
            <div className="form-group">
                <label>Language</label>
                <input type="text" readOnly={true} value={language} className="form-control" />
            </div>
        </>
    );
}

export default EditInformationEntryForm;