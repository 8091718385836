import { useState, useEffect } from "react";
import { Button, Modal, Alert } from 'react-bootstrap'
import { ReactDataContext } from '@themost/react';
import Loader from "react-loader-spinner";
import Pagination from 'components/UI/Pagination'


function ECTSCoordinatorsTable(props) {
  // Defining a state named rows
  // which we can update by calling on setRows function
  const [rows, setRows] = useState(null);
  const [editRow, setEditRow] = useState(null)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(null)
  const [open, setOpen] = useState(false);
  const [isEdit, setEdit] = useState(null);
  const [disable, setDisable] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [rowSelected,setRowSelected]=useState({})

  useEffect(() => {
    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(props.userService.getToken());

    context.model(`ECTSCoordinatorTables`).take(25).skip(page * 25).getList()
      .then(result => {
        setTotal(result.total)
        setRows(result.value)
      }).catch(err => {
        console.log(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (open === true) {
      const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
      context.setBearerAuthorization(props.userService.getToken());
      context.model(`ECTSCoordinatorTables`).asQueryable().getItems().then(res => {
        setRows(res)
      })
    }
  }, [open])
  
  useEffect(() => {
    document.title = props.title;
  }, [])

  // Function For closing the alert snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Function to handle edit
  const handleEdit = (row,i) => {
    // If edit mode is true setEdit will 
    // set it to false and vice versa
    setEdit(i);
    setRowSelected(row)
  };

  // Function to handle save
  const handleSave = () => {

        var raw = {
          "name": typeof (rowSelected.department) === "string" ? rowSelected.department : rowSelected.department.name,
          "institution": 1
        }
        const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
        context.setBearerAuthorization(props.userService.getToken());
        context.model('MobilityDepartments').save(raw)
          .then(result => {
            if (rowSelected.id) {
              var raw = {
                "firstName": rowSelected.firstName,
                "lastName": rowSelected.lastName,
                "department": result,
                "purpose": rowSelected.purpose,
                "email": rowSelected.email,
                "id": rowSelected.id
              }
            }
            else {
              raw = {
                "firstName": rowSelected.firstName,
                "lastName": rowSelected.lastName,
                "department": result,
                "purpose": rowSelected.purpose,
                "email": rowSelected.email,
              }
            }
            context.model('ECTSCoordinatorTables').save(raw)
              .then(eCTSCoordinatorTableResponse => {
                  setEdit(null);
                  setRowSelected(null);
                  setDisable(true);
                  setOpen(true);
                  const temp=rows
                  temp[isEdit]=result
                  setRows(temp)

              })
              .catch(err => {
                console.log(err);


              })
          })
          .catch(err => {
            console.log(err);


          })



  };

  // The handleInputChange handler can be set up to handle
  // many different inputs in the form, listen for changes 
  // to input elements and record their values in state
  const handleInputChange = (e, index) => {
    setDisable(false);
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
  };

  // Showing delete confirmation to users
  const handleConfirm = () => {
    setShowConfirm(true);
  };

  // Handle the case of delete confirmation where 
  // user click yes delete a specific row of id:i
  const handleRemoveClick = () => {
    let list = [...rows];
    var raw = { "id": rowSelected.id,"$state":4 }
    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(props.userService.getToken());
    context.model('ECTSCoordinatorTables').save(raw)
      .then(result => {
        list.pop(rowSelected);
        setRows(list);
        setShowConfirm(false);
      })
      .catch(error => console.log('error', error));

  };

  // Handle the case of delete confirmation 
  // where user click no 
  const handleNo = () => {
    setShowConfirm(false);
  };

  if (rows === null) {
    return (<div style={{
      position: 'absolute', left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)'
    }}>
      <Loader
        className="spinner mx-auto"
        type="TailSpin"
        color="#3A435E"
        height={70}
        width={70}
      />
    </div>)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Alert
            onClose={handleClose}
            variant="success"
            dismissible
            show={open}>
            <Alert.Heading>
              Record saved successfully!
            </Alert.Heading>
          </Alert>

          <div >
            <div>
              {isEdit !== null ? (
                <div>
                  {rows.length !== 0 && (
                    <div>
                      {disable ? (
                        <Button variant="outline-primary" disabled align="right" onClick={handleSave}>
                          <i className="fa fa-floppy-o mr-2"></i>SAVE
                          
                        </Button>
                      ) : (
                        <Button variant="outline-primary" align="right" onClick={handleSave}>
                          <i className="fa fa-floppy-o mr-2"></i>
                          SAVE
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                </div>
              )}
            </div>
          </div>
          <tr align="center"> </tr>


          <div >
            <table className="table-striped table-condensed cf table-responsive" >
              <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                <tr>
                  <th scope="col" className="col-md-1">ID</th>
                  <th scope="col" className="col-md-1">First Name</th>
                  <th scope="col" className="col-md-1">Last Name</th>
                  <th scope="col" className="col-md-1">Email</th>
                  <th scope="col" className="col-md-1">Department</th>
                  <th scope="col" className="col-md-1">Purpose</th>
                  <th scope="col" className="col-md-1">Actions</th>


                </tr>
              </thead>
              <tbody>
                {rows.map((row, i) => {
                  return (
                    <>
                      <tr>
                        {isEdit === i ? (
                          <>
                            <td data-title="ID" className="col-md-1">
                              {row.id}
                            </td>
                            <td data-title="First Name" className="col-md-1">
                              <input
                                value={row.firstName}
                                name="firstName"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </td>
                            <td data-title="Last Name" className="col-md-1">
                              <input
                                value={row.lastName}
                                name="lastName"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </td>
                            <td data-title="Email" className="col-md-1">
                              <input
                                value={row.email}
                                name="email"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </td>
                            <td data-title="Department" className="col-md-1">
                              <input
                                value={typeof (row.department) === "string" ? row.department : row.department.name}
                                name="department"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </td>
                            <td data-title="Purpose" className="col-md-1">
                              <input
                                value={row.purpose}
                                name="purpose"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </td>
                            <td
                              data-title="Actions"
                              className="col"
                            >
                              <Button variant="outline-primary" style={{border:"none"}} onClick={()=>{
                                  setRowSelected(row)
                                  handleConfirm()
                              }}>
                                <i className="fa fa-times"></i>
                              </Button>
                            </td>
                          </>
                        ) : (
                          <>
                            <td data-title="ID"
                              className="col-md-1">
                              {row.id}
                            </td>
                            <td data-title="First Name"
                              className="col-md-1">
                              {row.firstName}
                            </td>
                            <td data-title="Last Name"
                              className="col-md-1">
                              {row.lastName}
                            </td>
                            <td data-title="Email"
                              className="col-md-1">
                              {row.email}
                            </td>
                            <td data-title="Department"
                              className="col-md-1">
                              {typeof (row.department) === "string" ? row.department : row.department.name}
                            </td>
                            <td data-title="Purpose"
                              className="col-md-1">
                              {row.purpose}
                            </td>

                            <td
                              data-title="Actions"
                              className="col-md-1"
                            >
                              <Button variant="outline-primary" style={{border:"none"}} onClick={() => {
                                handleEdit(row,i)
                              }}>
                                <i className="fa fa-pencil fa-primary"></i>
                              </Button>
                              <Button variant="outline-primary" style={{border:"none"}} onClick={()=>{
                                setRowSelected(row)
                                handleConfirm()
                              }}>
                                <i className="fa fa-trash fa-primary"></i>
                              </Button>
                            </td>
                          </>
                        )}



                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <div>
              <Modal
                show={showConfirm}
                onClose={handleNo}
              >
                <Modal.Header id="alert-dialog-title">
                  {"Confirm Delete"}
                </Modal.Header>
                <Modal.Body>
                  <div id="alert-dialog-description">
                    Are you sure to delete
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => handleRemoveClick(editRow)}
                    variant="primary"
                    autoFocus
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={handleNo}
                    variant="primary"
                    autoFocus
                  >
                    No
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <Pagination
                  totalRecords={total}
                  pageLimit={25}
                  pageNeighbours={1}
                  onPageChanged={data => {
                    setPage(data.currentPage - 1)
                  }
                  }
              />
            </div>
          </div>
        </div>
      </div>
    </div>


  );
}

export default ECTSCoordinatorsTable;
