import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import {
    Text
} from '@react-pdf/renderer';


const AdditionalInfo = ({ institution, additionalInfoTable, styles }) => {
    return (
        <>
            <Text style={styles.h4}>{institution}:{"\n"}</Text>
            <Table
                data={
                    additionalInfoTable && additionalInfoTable.map(a => ({
                        type: a.type,
                        detail: a.description,
                        contact: a.info && ('Email:' + a.info.email +
                        '\n' + 'Phone: ' + a.info.phone),
                        url: a.info && a.info.urls.map(i => (i.value + "\n\n")).join().replaceAll(',', ' \n')
                    }))}>
                <TableHeader >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Information on:
                    </TableCell >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Contact details (email, phone)
                    </TableCell >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Website for information
                    </TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.type} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.contact} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.url} />
                </TableBody>
            </Table>
            <Text>{"\n"}</Text>
            <Text>{"\n"}</Text>
        </>

    );
};



export default AdditionalInfo;