import { Form } from 'react-bootstrap';

const EditSingleAdditionalInformation = ({ additionalInfo, index, handleChange, language }) => {
    return (
        <Form.Group>
            <div className="my-1">
                    <Form.Label>Type *</Form.Label>
                    <Form.Control type="text" required value={additionalInfo.type} onChange={e => handleChange(index, 'type', e.target.value )} />
            </div>
            <div className="row my-1">
                <div className="col-md-4">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control type="email" placeholder="example@auth.gr" required value={additionalInfo.info.email} onChange={e => handleChange(index, 'info', { ...additionalInfo.info, email: e.target.value })} />
                </div>
                <div className="col-md-8">
                    <Form.Label>Phone *</Form.Label>
                    <Form.Control type="text" pattern="\+30[0-9]{10}" placeholder="Phone number in the E.164 format, with the leading “+” sign. e.g. : +302310996727" required value={additionalInfo.info.phone} onChange={e => handleChange(index, 'info', { ...additionalInfo.info, phone: e.target.value })} />
                </div>
            </div>
            {/* <div>
                <Form.Label>Url *</Form.Label>
                <Form.Control type="url" required placeholder="https://example.com" pattern="https?://.*" value={additionalInfo.info.urls[0].value} onChange={e => handleChange(index, 'info', { ...additionalInfo.info, urls: [{ value: e.target.value, language }]})}/>
            </div> */}
            {
                additionalInfo?.info?.urls ? 
                additionalInfo.info.urls.map((url, idx) => (
                                <div className="form-group" key={url + idx}>
                                    <label className='d-block'>Url *</label>
                                    <div className="d-flex justify-content-between">
                                        <input type="url" placeholder="https://example.com" pattern="https?://.*" required value={url.value} className={`form-control ${additionalInfo.info.urls.length !== 1 ? 'col-md-9' : 'col-md-12' } d-inline-block`} onChange={e => {
                                            const newArray = Array.from(additionalInfo.info.urls);
                                            newArray.splice(idx, 1, { ...additionalInfo.info.urls[idx], value: e.target.value })

                                            handleChange(index, 'info', { ...additionalInfo.info, urls: newArray });
                                        }}/>
                                        {
                                            additionalInfo.info.urls.length !== 1 ? 
                                                <button className='btn btn-danger col-md-2 d-inline-block align-self-center' onClick={(e) => {
                                                    e.preventDefault()
                                                    
                                                    const newArray = Array.from(additionalInfo.info.urls);
                                                    newArray.splice(idx, 1)
                                                    
                                                    handleChange(index, 'info', { ...additionalInfo.info, urls: newArray });
                                                }}>
                                                    <i className="fa fa-trash mr-2"></i>
                                                    Delete URL
                                                </button> 
                                            : 
                                                <></>
                                        }
                                    </div>
                                </div>
                    )) 
                :
                    <></>
            }
            <div className="d-flex justify-content-around">
                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={(e) => {
                    e.preventDefault()
                    handleChange(index, 'info', additionalInfo?.info?.urls ? 
                        ({
                            ...additionalInfo.info,
                            urls: [
                                ...additionalInfo.info.urls,
                                {
                                    value: '',
                                    language: 'en'
                                }
                            ]
                        })
                    : 
                        {
                            ...additionalInfo.info,
                            urls: [{ value: '', language: 'en' }]
                        }
                    )
                }}>
                    <i className="fa fa-plus mr-2"></i>
                    Add URL
                </button>
            </div>
            <div>
                <Form.Label>Language</Form.Label>
                <Form.Control type="text" readOnly value={language}/>
            </div>
        </Form.Group>
    );
}

export default EditSingleAdditionalInformation;