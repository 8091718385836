import { useEffect, useState } from "react";
import StudentDataFilesComponentTranslation from "../translations/StudentDataFilesComponentTranslation";
import UserService from "services/UserService";
import { ReactDataContext } from '@themost/react';

function StudentDataFilesComponent({ children, langCode, view, handleStep, studentStudiesForm, setStudentStudiesForm, saveDraft, shortTerm }) {
    const initialState = {
        europassCv: { fileName: "", fileBase64: "", userMail: studentStudiesForm?.studentData[0].email, id: "" },
        academicIdentity: { fileName: "", fileBase64: "", userMail: studentStudiesForm?.studentData[0].email, id: "" },
        proposedCurriculum: { fileName: "", fileBase64: "", userMail: studentStudiesForm?.studentData[0].email, id: "" },
        foreignLanguageCertificate: { fileName: "", fileBase64: "", userMail: studentStudiesForm?.studentData[0].email, id: "" },
        detailedDegreeScore: { fileName: "", fileBase64: "", userMail: studentStudiesForm?.studentData[0].email, id: "" },
        detailedMastersDegreeScore: { fileName: "", fileBase64: "", userMail: studentStudiesForm?.studentData[0].email, id: "" },
        disabilityCertificate: { fileName: "", fileBase64: "", userMail: studentStudiesForm?.studentData[0].email, id: "" },
        letterOfAcceptance: { fileName: "", fileBase64: "", userMail: studentStudiesForm?.studentData[0].email, id: "" },
        recommendationLetter: { fileName: "", fileBase64: "", userMail: studentStudiesForm?.studentData[0].email, id: "" },
    }

    let uploadTemp = {}

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(UserService.getToken());

    const [files, setFiles] = useState(initialState);

    if (studentStudiesForm?.filesData?.length > 0) {
        uploadTemp = {
            europassCv: studentStudiesForm?.filesData[0]?.europassCv !== "" && studentStudiesForm?.filesData[0]?.europassCv ? true : false,
            academicIdentity: studentStudiesForm?.filesData[0]?.academicIdentity !== "" && studentStudiesForm?.filesData[0]?.academicIdentity ? true : false,
            proposedCurriculum: studentStudiesForm?.filesData[0]?.proposedCurriculum !== "" && studentStudiesForm?.filesData[0]?.proposedCurriculum ? true : false,
            foreignLanguageCertificate: studentStudiesForm?.filesData[0]?.foreignLanguageCertificate && studentStudiesForm?.filesData[0]?.foreignLanguageCertificate !== "" ? true : false,
            detailedDegreeScore: studentStudiesForm?.filesData[0]?.detailedDegreeScore !== "" && studentStudiesForm?.filesData[0]?.detailedDegreeScore ? true : false,
            detailedMastersDegreeScore: studentStudiesForm?.filesData[0]?.detailedMastersDegreeScore !== "" && studentStudiesForm?.filesData[0]?.detailedMastersDegreeScore ? true : false,
            disabilityCertificate: studentStudiesForm?.filesData[0]?.disabilityCertificate !== "" && studentStudiesForm?.filesData[0]?.disabilityCertificate ? true : false,
            letterOfAcceptance: studentStudiesForm?.filesData[0]?.letterOfAcceptance !== "" && studentStudiesForm?.filesData[0]?.letterOfAcceptance ? true : false,
            recommendationLetter: studentStudiesForm?.filesData[0]?.recommendationLetter !== "" && studentStudiesForm?.filesData[0]?.recommendationLetter ? true : false,
        }
    } else {
        uploadTemp = {
            europassCv: false,
            academicIdentity: false,
            proposedCurriculum: false,
            foreignLanguageCertificate: false,
            detailedDegreeScore: false,
            detailedMastersDegreeScore: false,
            disabilityCertificate: false,
            letterOfAcceptance: false,
            recommendationLetter: false
        }
    }
    const [upload, setUpload] = useState(uploadTemp);

    const getBase64 = async (fileName) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(fileName)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    const decodeBase64 = (dataUrl, filename) => {
        var arr = dataUrl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = window.atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const onFileChange = async (event) => {

        const target = event.target
        if (target.files && target.files[0]) {

            const maxAllowedSize = 10 * 1024 * 1024;
            if (target.files[0].size > maxAllowedSize) {
                alert("Please select a file smaller than 10MB")
                console.log("File exceeds size of 10MB")
                target.value = ''
            }
            else if (target.files[0].type != "application/pdf") {
                alert("File not pdf")
                console.log("File not pdf")
                target.value = ''
            }
            else {
                const result = await getBase64(event.target.files[0])
                console.log(result)
                setFiles({ ...files, [event.target.name]: { fileName: event.target.files[0].name, fileBase64: result.split(',')[1], userMail: studentStudiesForm?.studentData[0].email } })
            }

        }
    }

    const onFileUpload = async (id) => {
        // let temp = files[id]
        // // if (studentStudiesForm?.filesData && studentStudiesForm?.filesData[0] != undefined) {
        // //     temp = JSON.parse(JSON.stringify(studentStudiesForm?.filesData[0]))
        // //     console.log(temp)
        // //     temp[id] = files[id]
        // // } else {
        // //     temp = initialState
        // //     temp[id] = files[id]
        // // }
        // console.log(temp)
        let filesResponse
        if (files[id] != undefined) {
            filesResponse = await context.model('ApplicationFiles').save(files[id])
        }
        console.log(filesResponse)
        let tempData = {}
        if (studentStudiesForm?.filesData !== undefined && studentStudiesForm?.filesData[0] !== undefined) {
            tempData = JSON.parse(JSON.stringify(studentStudiesForm?.filesData[0]))
            console.log(tempData)
            tempData[id] = filesResponse.id
            setStudentStudiesForm({ ...studentStudiesForm, filesData: [tempData] })
        } else {
            setStudentStudiesForm({ ...studentStudiesForm, filesData: [{ [id]: filesResponse.id }] })
        }
        setUpload({ ...upload, [id]: true })
        console.log(studentStudiesForm)
    }

    const onFileDeletion = (id) => {
        setFiles({ ...files, [id]: { fileName: "", fileBase64: "", userMail: studentStudiesForm?.studentData[0].email, id: "" } })
        let temp = {}
        if (studentStudiesForm?.filesData && studentStudiesForm?.filesData.length > 0) {
            temp = JSON.parse(JSON.stringify(studentStudiesForm?.filesData[0]))
            console.log(temp)
            temp[id] = ""
            setStudentStudiesForm({ ...studentStudiesForm, filesData: [temp] })
        }
        // setStudentStudiesForm({ ...studentStudiesForm, filesData: [{ ...studentStudiesForm?.filesData, [id]: "" }] })
        setUpload({ ...upload, [id]: false })
    }

    const required = true;

    useEffect(() => {
        if (studentStudiesForm?.filesData && studentStudiesForm?.filesData[0] != undefined) {
            const temp = JSON.parse(JSON.stringify(studentStudiesForm?.filesData[0]))
            // let temp = false;
            // if (studentStudiesForm?.filesData[0] != undefined) {
            //     temp = JSON.parse(JSON.stringify(studentStudiesForm?.filesData[0]))
            // }
            console.log(studentStudiesForm?.filesData)
            console.log(temp)
            // temp.europassCv = temp.europassCv.split(",")[0] + ",data:text/plain;base64," + temp.europassCv.split(",")[1]
            // temp.europassCv = decodeBase64(("data:text/plain;base64," + temp.europassCv.split(",")[1]), temp.europassCv.split(",")[0])
            console.log(temp.europassCv)
            // console.log(temp.europassCv.split(",")[0], temp.europassCv.split(",")[1] + "," + temp.europassCv.split(",")[2])

            console.log(temp)
            // setFiles(temp)
        } else {
            setFiles(initialState)
        }
    }, [])

    const downloadPdf = async (id) => {
        try {
            console.log(id)
            const filesResponse = await context.model('ApplicationFiles').asQueryable().where('id').equal(id).take(-1).getItems()
            console.log(filesResponse)
            const fileSource = "data:application/pdf;base64," + filesResponse[0].fileBase64
            const link = document.createElement('a');
            const fileName = filesResponse[0].fileName;

            link.href = fileSource
            link.download = fileName
            link.click();
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        console.log(files)
    }, [])

    useEffect(() => {
        console.log(studentStudiesForm)
    }, [])

    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={async (event) => {
            event.preventDefault();
            console.log(studentStudiesForm)
            let temp = []
            if (studentStudiesForm?.filesData !== undefined && studentStudiesForm?.filesData.length > 0) {
                temp = JSON.parse(JSON.stringify(studentStudiesForm?.filesData[0]))
                console.log(temp)
                var activeElement = document.activeElement;
                if (activeElement.value === 'draft') {
                    saveDraft({ ...studentStudiesForm, filesData: [temp] })
                } else {
                    handleStep();
                }
            } else {
                var activeElement = document.activeElement;
                if (activeElement.value === 'draft') {
                    saveDraft({ ...studentStudiesForm, filesData: [] })
                } else {
                    handleStep();
                }
            }
        }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h3 className="text-center">{StudentDataFilesComponentTranslation("h3_title", langCode)['label']}</h3>
                            <div className="form-group mb-3">
                                <label for="formFile" class="form-label">{StudentDataFilesComponentTranslation("europassCv", langCode)['label']}</label>
                                {upload.europassCv === false ?
                                    <div>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type="file"
                                                required={required}
                                                name="europassCv"
                                                id="europassCv"
                                                disabled={view}
                                                onChange={onFileChange}
                                                accept=".pdf"
                                            />
                                            <button className='btn btn-primary' disabled={files.europassCv.fileBase64 === "" || view} type="button" onClick={() => onFileUpload('europassCv')}><i className="fa fa-upload mr-2"></i>{StudentDataFilesComponentTranslation("upload_btn", langCode)['label']}</button>
                                        </div>
                                        <label className="small">{StudentDataFilesComponentTranslation("europassCv", langCode)['placeholder']}</label>
                                    </div>
                                    :
                                    <div>
                                        <button className='btn btn-danger' disabled={view} type="button" onClick={() => onFileDeletion('europassCv')}><i className="fa  fa-trash mr-2"></i>{StudentDataFilesComponentTranslation("delete_btn", langCode)['label']}</button>
                                        <button className='btn btn-primary' type="button" onClick={() => downloadPdf(studentStudiesForm?.filesData[0]?.europassCv)}><i className="fa fa-download mr-2"></i>{StudentDataFilesComponentTranslation("download_btn", langCode)['label']}</button>
                                    </div>
                                }
                            </div>
                            <div className="form-group mb-3">
                                <label for="formFile" class="form-label">{StudentDataFilesComponentTranslation("academicIdentity", langCode)['label']}</label>
                                {upload.academicIdentity === false ?
                                    <div>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type="file"
                                                required={required}
                                                disabled={view}
                                                name="academicIdentity"
                                                id="academicIdentity"
                                                onChange={onFileChange}
                                                accept=".pdf"
                                            />
                                            <button className='btn btn-primary' disabled={files.academicIdentity.fileBase64 === "" || view} type="button" onClick={() => onFileUpload('academicIdentity')}><i className="fa fa-upload mr-2"></i>{StudentDataFilesComponentTranslation("upload_btn", langCode)['label']}</button>
                                        </div>
                                        <label className="small">{StudentDataFilesComponentTranslation("academicIdentity", langCode)['placeholder']}</label>
                                    </div>
                                    :
                                    <div className="input-group">
                                        <button className='btn btn-danger' disabled={view} type="button" onClick={() => onFileDeletion('academicIdentity')}><i className="fa  fa-trash mr-2"></i>{StudentDataFilesComponentTranslation("delete_btn", langCode)['label']}</button>
                                        <button className='btn btn-primary' type="button" onClick={() => downloadPdf(studentStudiesForm?.filesData[0]?.academicIdentity)}><i className="fa fa-download mr-2"></i>{StudentDataFilesComponentTranslation("download_btn", langCode)['label']}</button>
                                    </div>
                                }
                            </div>
                            <div className="form-group mb-3">
                                <label for="formFile" class="form-label">{StudentDataFilesComponentTranslation("proposedCurriculum", langCode)['label']}</label>
                                {upload.proposedCurriculum === false ?
                                    <div>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type="file"
                                                required={required}
                                                disabled={view}
                                                name="proposedCurriculum"
                                                id="proposedCurriculum"
                                                onChange={onFileChange}
                                                accept=".pdf"
                                            />
                                            <button className='btn btn-primary' disabled={files.proposedCurriculum.fileBase64 === "" || view} type="button" onClick={() => onFileUpload('proposedCurriculum')}><i className="fa fa-upload mr-2"></i>{StudentDataFilesComponentTranslation("upload_btn", langCode)['label']}</button>
                                        </div>
                                        <label className="small">{StudentDataFilesComponentTranslation("proposedCurriculum", langCode)['placeholder']}</label>
                                    </div>
                                    :
                                    <div className="input-group">
                                        <button className='btn btn-danger' disabled={view} type="button" onClick={() => onFileDeletion('proposedCurriculum')}><i className="fa  fa-trash mr-2"></i>{StudentDataFilesComponentTranslation("delete_btn", langCode)['label']}</button>
                                        <button className='btn btn-primary' type="button" onClick={() => downloadPdf(studentStudiesForm?.filesData[0]?.proposedCurriculum)}><i className="fa fa-download mr-2"></i>{StudentDataFilesComponentTranslation("download_btn", langCode)['label']}</button>
                                    </div>
                                }
                            </div>
                            <div className="form-group mb-3">
                                <label for="formFile" class="form-label">{StudentDataFilesComponentTranslation("foreignLanguageCertificate", langCode)['label']}</label>
                                {upload.foreignLanguageCertificate === false ?
                                    <div>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type="file"
                                                required={required}
                                                disabled={view}
                                                name="foreignLanguageCertificate"
                                                id="foreignLanguageCertificate"
                                                onChange={onFileChange}
                                                accept=".pdf"
                                            />
                                            <button className='btn btn-primary' disabled={files.foreignLanguageCertificate.fileBase64 === "" || view} type="button" onClick={() => onFileUpload('foreignLanguageCertificate')}><i className="fa fa-upload mr-2"></i>{StudentDataFilesComponentTranslation("upload_btn", langCode)['label']}</button>
                                        </div>
                                        <label className="small">{StudentDataFilesComponentTranslation("foreignLanguageCertificate", langCode)['placeholder']}</label>
                                    </div>
                                    :
                                    <div className="input-group">
                                        <button className='btn btn-danger' disabled={view} type="button" onClick={() => onFileDeletion('foreignLanguageCertificate')}><i className="fa  fa-trash mr-2"></i>{StudentDataFilesComponentTranslation("delete_btn", langCode)['label']}</button>
                                        <button className='btn btn-primary' type="button" onClick={() => downloadPdf(studentStudiesForm?.filesData[0]?.foreignLanguageCertificate)}><i className="fa fa-download mr-2"></i>{StudentDataFilesComponentTranslation("download_btn", langCode)['label']}</button>
                                    </div>
                                }
                            </div>
                            <div className="form-group mb-3">
                                <label for="formFile" class="form-label">{StudentDataFilesComponentTranslation("detailedDegreeScore", langCode)['label']}</label>
                                {upload.detailedDegreeScore === false ?
                                    <div>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type="file"
                                                disabled={view}
                                                name="detailedDegreeScore"
                                                id="detailedDegreeScore"
                                                onChange={onFileChange}
                                                accept=".pdf"
                                            />
                                            <button className='btn btn-primary' disabled={files.detailedDegreeScore.fileBase64 === "" || view} type="button" onClick={() => onFileUpload('detailedDegreeScore')}><i className="fa fa-upload mr-2"></i>{StudentDataFilesComponentTranslation("upload_btn", langCode)['label']}</button>
                                        </div>
                                        <label className="small">{StudentDataFilesComponentTranslation("detailedDegreeScore", langCode)['placeholder']}</label>
                                    </div>
                                    :
                                    <div className="input-group">
                                        <button className='btn btn-danger' disabled={view} type="button" onClick={() => onFileDeletion('detailedDegreeScore')}><i className="fa  fa-trash mr-2"></i>{StudentDataFilesComponentTranslation("delete_btn", langCode)['label']}</button>
                                        <button className='btn btn-primary' type="button" onClick={() => downloadPdf(studentStudiesForm?.filesData[0]?.detailedDegreeScore)}><i className="fa fa-download mr-2"></i>{StudentDataFilesComponentTranslation("download_btn", langCode)['label']}</button>
                                    </div>
                                }
                            </div>
                            <div className="form-group mb-3">
                                <label for="formFile" class="form-label">{StudentDataFilesComponentTranslation("detailedMastersDegreeScore", langCode)['label']}</label>
                                {upload.detailedMastersDegreeScore === false ?
                                    <div>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type="file"
                                                disabled={view}
                                                name="detailedMastersDegreeScore"
                                                id="detailedMastersDegreeScore"
                                                onChange={onFileChange}
                                                accept=".pdf"
                                            />
                                            <button className='btn btn-primary' disabled={files.detailedMastersDegreeScore.fileBase64 === "" || view} type="button" onClick={() => onFileUpload('detailedMastersDegreeScore')}><i className="fa fa-upload mr-2"></i>{StudentDataFilesComponentTranslation("upload_btn", langCode)['label']}</button>
                                        </div>
                                        <label className="small">{StudentDataFilesComponentTranslation("detailedMastersDegreeScore", langCode)['placeholder']}</label>
                                    </div>
                                    :
                                    <div className="input-group">
                                        <button className='btn btn-danger' disabled={view} type="button" onClick={() => onFileDeletion('detailedMastersDegreeScore')}><i className="fa  fa-trash mr-2"></i>{StudentDataFilesComponentTranslation("delete_btn", langCode)['label']}</button>
                                        <button className='btn btn-primary' type="button" onClick={() => downloadPdf(studentStudiesForm?.filesData[0]?.detailedMastersDegreeScore)}><i className="fa fa-download mr-2"></i>{StudentDataFilesComponentTranslation("download_btn", langCode)['label']}</button>
                                    </div>
                                }
                            </div>
                            {shortTerm &&
                                <>
                                    <div className="form-group mb-3">
                                        <label for="formFile" class="form-label">{StudentDataFilesComponentTranslation("letterOfAcceptance", langCode)['label']}</label>
                                        {upload.letterOfAcceptance === false ?
                                            <div>
                                                <div className="input-group">
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        required={required}
                                                        disabled={view}
                                                        name="letterOfAcceptance"
                                                        id="letterOfAcceptance"
                                                        onChange={onFileChange}
                                                        accept=".pdf"
                                                    />
                                                    <button className='btn btn-primary' disabled={files.letterOfAcceptance.fileBase64 === "" || view} type="button" onClick={() => onFileUpload('letterOfAcceptance')}><i className="fa fa-upload mr-2"></i>{StudentDataFilesComponentTranslation("upload_btn", langCode)['label']}</button>
                                                </div>
                                                <label className="small">{StudentDataFilesComponentTranslation("letterOfAcceptance", langCode)['placeholder']}</label>
                                            </div>
                                            :
                                            <div className="input-group">
                                                <button className='btn btn-danger' disabled={view} type="button" onClick={() => onFileDeletion('detailedMastersDegreeScore')}><i className="fa  fa-trash mr-2"></i>{StudentDataFilesComponentTranslation("delete_btn", langCode)['label']}</button>
                                                <button className='btn btn-primary' type="button" onClick={() => downloadPdf(studentStudiesForm?.filesData[0]?.detailedMastersDegreeScore)}><i className="fa fa-download mr-2"></i>{StudentDataFilesComponentTranslation("download_btn", langCode)['label']}</button>
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group mb-3">
                                        <label for="formFile" class="form-label">{StudentDataFilesComponentTranslation("recommendationLetter", langCode)['label']}</label>
                                        {upload.recommendationLetter === false ?
                                            <div>
                                                <div className="input-group">
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        required={required}
                                                        disabled={view}
                                                        name="recommendationLetter"
                                                        id="recommendationLetter"
                                                        onChange={onFileChange}
                                                        accept=".pdf"
                                                    />
                                                    <button className='btn btn-primary' disabled={files.recommendationLetter.fileBase64 === "" || view} type="button" onClick={() => onFileUpload('recommendationLetter')}><i className="fa fa-upload mr-2"></i>{StudentDataFilesComponentTranslation("upload_btn", langCode)['label']}</button>
                                                </div>
                                                <label className="small">{StudentDataFilesComponentTranslation("recommendationLetter", langCode)['placeholder']}</label>
                                            </div>
                                            :
                                            <div className="input-group">
                                                <button className='btn btn-danger' disabled={view} type="button" onClick={() => onFileDeletion('detailedMastersDegreeScore')}><i className="fa  fa-trash mr-2"></i>{StudentDataFilesComponentTranslation("delete_btn", langCode)['label']}</button>
                                                <button className='btn btn-primary' type="button" onClick={() => downloadPdf(studentStudiesForm?.filesData[0]?.detailedMastersDegreeScore)}><i className="fa fa-download mr-2"></i>{StudentDataFilesComponentTranslation("download_btn", langCode)['label']}</button>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            {studentStudiesForm?.specialNeedsCategory === true && <div className="form-group mb-3">
                                <label for="formFile" class="form-label">{StudentDataFilesComponentTranslation("disabilityCertificate", langCode)['label']}</label>
                                {upload.disabilityCertificate === false ?
                                    <div>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type="file"
                                                required={required}
                                                disabled={view}
                                                name="disabilityCertificate"
                                                id="disabilityCertificate"
                                                onChange={onFileChange}
                                                accept=".pdf"
                                            />
                                            <button className='btn btn-primary' disabled={files.disabilityCertificate.fileBase64 === "" || view} type="button" onClick={() => onFileUpload('disabilityCertificate')}><i className="fa fa-upload mr-2"></i>{StudentDataFilesComponentTranslation("upload_btn", langCode)['label']}</button>
                                        </div>
                                        <label className="small">{StudentDataFilesComponentTranslation("disabilityCertificate", langCode)['placeholder']}</label>
                                    </div>
                                    :
                                    <div className="input-group">
                                        <button className='btn btn-danger' disabled={view} type="button" onClick={() => onFileDeletion('disabilityCertificate')}><i className="fa  fa-trash mr-2"></i>{StudentDataFilesComponentTranslation("delete_btn", langCode)['label']}</button>
                                        <button className='btn btn-primary' type="button" onClick={() => downloadPdf(studentStudiesForm?.filesData[0]?.disabilityCertificate)}><i className="fa fa-download mr-2"></i>{StudentDataFilesComponentTranslation("download_btn", langCode)['label']}</button>
                                    </div>
                                }
                            </div>}
                        </div>
                    </div>

                </div>

            </div>
            {children}
        </form>
    );
}

export default StudentDataFilesComponent