import { useState, useEffect } from 'react';
import { ReactDataContext } from '@themost/react';
import 'styles/css/IIAform.css';
import Loader from 'react-loader-spinner';


const Notifications = ({ iia, userService }) => {
    const [notifications, setNotifications] = useState([]);
    const [fetched, setFetched] = useState(false);

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(userService.getToken());

    useEffect(() => {
        (async () => {
            try {

                let result = context.model(`MobilityNotifications`).asQueryable().where('changedElementIds').equal(new String(iia.id));
                if (iia?.partnerAgreementIdentifications.length > 0) {
                    for (let i = 0; i < iia?.partnerAgreementIdentifications.length; i++) {
                        result = result.or('changedElementIds').equal(new String(iia?.partnerAgreementIdentifications[i]?.iiaId));
                    }
                }
                result = await result.getItems();

                setFetched(true)
                setNotifications(result)
            } catch (error) {
                console.log(error)
            }
        })()
    }, []);


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h3 className="text-center">Notifications</h3>
                </div>
            </div>
            {
                (notifications.length !== 0)
                    ?
                    <div id="no-more-tables">
                        <table className="table-striped table-condensed cf table-responsive">
                            <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                                <tr>
                                    <th scope="col" className="col-md-1 text-center align-self-center">ID</th>
                                    <th scope="col" className="col-md-1 text-center align-self-center">Type</th>
                                    <th scope="col" className="col-md-1 text-center align-self-center">Hei ID</th>
                                    <th scope="col" className="col-md-1 text-center align-self-center">Notifier Hei ID</th>
                                    <th scope="col" className="col-md-1 text-center align-self-center">Approving Hei ID</th>
                                    <th scope="col" className="col-md-1 text-center align-self-center">Owner Hei ID</th>
                                    <th scope="col" className="col-md-1 text-center align-self-center">Changed Element IDs</th>
                                    <th scope="col" className="col-md-1 text-center align-self-center">Date Created</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notifications.map((notification, idx) => (
                                    <tr key={'notifications' + notification.id + idx}>
                                        <td key={"ID_" + idx} className="text-center align-self-center" data-title="No">{notification?.id ?? '-'}</td>
                                        <td key={"Type_" + idx} className="text-center align-self-center" data-title="Type">{notification?.type ?? '-'}</td>
                                        <td key={"Hei_ID_" + idx} className="text-center align-self-center" data-title="Hei ID">{notification?.heiId ?? '-'}</td>
                                        <td key={"Notifier_Hei_ID_" + idx} className="text-center align-self-center" data-title="Notifier Hei ID">{notification?.notifierHeiId ?? '-'}</td>
                                        <td key={"Approving_Hei_ID_" + idx} className="text-center align-self-center" data-title="Approving Hei ID">{notification?.approvingHeiId ?? '-'}</td>
                                        <td key={"Owner_Hei_ID_" + idx} className="text-center align-self-center" data-title="Owner Hei ID">{notification?.ownerHeiId ?? '-'}</td>
                                        <td key={"Changed_Element_IDs_" + idx} className="text-center align-self-center" data-title="Changed Element IDs">{notification?.changedElementIds ?? '-'}</td>
                                        <td key={"Date_Created_" + idx} className="text-center align-self-center" data-title="Date Created">{notification.dateCreated ?
                                            (notification.dateCreated.getDate() + "-" + (notification.dateCreated.getMonth() + 1) + "-" + notification.dateCreated.getFullYear() + " " + notification.dateCreated.getHours() + ":" + notification.dateCreated.getSeconds())
                                            : "-"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    :
                    (fetched && notifications.length == 0) ?
                        <div className="d-flex justify-content-center">
                            <p>Notifications for this Inter-Institutional Agreement were not found</p>
                        </div>
                        : <div className="d-flex justify-content-center">
                            <Loader
                                className="spinner mx-auto"
                                type="TailSpin"
                                color="#3A435E"
                                height={70}
                                width={70}
                            />
                        </div>
            }
        </div>
    );
}

export default Notifications;