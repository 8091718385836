const Languages = ({ studentMobilitiesForStudies, studentMobilitiesForTraineeship, staffMobilitiesForTeaching, staffMobilitiesForTraining }) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <table className="table-responsive" cellPadding="7">
                        <tbody>
                            <tr style={{ backgroundColor: "#3A435E", color: "white" }} className="customtable">
                                <td rowSpan="2" align="center"><b>Receiving institution</b><br /> [Erasmus code] </td>
                                <td rowSpan="2" align="center"><b>Subject area (Optional)</b></td>
                                <td rowSpan="2" align="center"><b>Language of instruction </b></td>
                                <td colSpan="4" align="center"><b>Recommended language of instruction</b></td>
                            </tr>
                            <tr style={{ backgroundColor: "#3A435E", color: "white" }} className="customtable">
                                <td align="center"><b>Student Mobility for Studies</b> <br /></td>
                                <td align="center"><b>Student Mobility for Traineeship</b> </td>
                                <td align="center"><b>Staff Mobility for Teaching</b> <br /></td>
                                <td align="center"><b>Staff Mobility for Training</b> </td>
                            </tr>
                            {studentMobilitiesForStudies?.map((i) =>
                            (i?.recommendedLanguageSkills.map((j) => (
                                <tr key={"student-studies-" + i.id}>
                                    <td>{i?.toInstitution?.erasmusCode}</td>
                                    <td>{j?.subjectAreaWithClarification?<>{j?.subjectAreaWithClarification?.subjectArea?.name} [{j?.subjectAreaWithClarification?.subjectArea?.code}]</>:""}</td>
                                    <td>{j?.language}</td>
                                    <td>{j?.languageLevel}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            ))))}

                            {studentMobilitiesForTraineeship?.map((i) =>
                            (i?.recommendedLanguageSkills.map((j) => (
                                <tr key={"student-traineeship-" +i.id+"-" +j.id}>
                                    <td>{i?.toInstitution?.erasmusCode}</td>
                                    <td>{j?.subjectAreaWithClarification?<>{j?.subjectAreaWithClarification?.subjectArea?.name} [{j?.subjectAreaWithClarification?.subjectArea?.code}]</>:""}</td>
                                    <td>{j?.language}</td>
                                    <td></td>
                                    <td>{j?.languageLevel}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            ))))}

                            {staffMobilitiesForTeaching?.map((i) =>
                            (i?.recommendedLanguageSkills.map((j) =>
                            (<tr key={"staff-teaching-"  +i.id+"-" +j.id}>
                                <td>{i?.toInstitution?.erasmusCode}</td>
                                <td>{j?.subjectAreaWithClarification?<>{j?.subjectAreaWithClarification?.subjectArea?.name} [{j?.subjectAreaWithClarification?.subjectArea?.code}]</>:""}</td>
                                <td>{j?.language}</td>
                                <td></td>
                                <td></td>
                                <td>{j?.languageLevel}</td>
                                <td></td>
                            </tr>
                            ))))}

                            {staffMobilitiesForTraining?.map((i) =>
                            (i?.recommendedLanguageSkills.map((j) =>
                            (<tr key={"staff-training-"  +i.id+"-" +j.id}>
                                <td>{i?.toInstitution?.erasmusCode}</td>
                                <td>{j?.subjectAreaWithClarification?<>{j?.subjectAreaWithClarification?.subjectArea?.name} [{j?.subjectAreaWithClarification?.subjectArea?.code}]</>:""}</td>
                                <td>{j?.language}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{j?.languageLevel}</td>
                            </tr>
                            ))))}


                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};




export default Languages