import React from 'react';
import { ReactDataContext } from '@themost/react';
import 'styles/css/table.css'
import 'styles/css/viewIIA.css'
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import Loader from "react-loader-spinner";
import Restricted from "PermissionProvider/Restricted";
import UserService from "services/UserService";
import { Link } from 'react-router-dom';
import ErrorPage from 'components/ErrorPage';
import Institutions from 'components/Iia/View/components/Institutions';
import StudentMobilities from 'components/Iia/View/components/StudentMobilities';
import StaffMobilities from 'components/Iia/View/components/StaffMobilities';
import Languages from 'components/Iia/View/components/Languages';
import generatePDFDocument from 'components/Iia/Pdf/generatePDFDocument';
import { notifyPartnerCNR, approveIIA, getDate, isApproveButtonDisabled, getLocalIIACNRList } from 'components/Common/CommonFunctions';
import Notifications from 'components/Iia/View/components/MobilityNotifications';
import MobilityAgreementApprovalResponse from 'components/Iia/View/components/MobilityAgreementApprovalResponse';


const notAllowed = (<div className="container">
</div>);

/**
 * brings is local institution first
 * @param a
 * @param b
 * @returns {number}
 */
function compareInstitutions(a, b) {
    if (a.isLocal > b.isLocal) {
        return -1;
    }
    if (a.isLocal < b.isLocal) {
        return 1;
    }
    // a must be equal to b
    return 0;
}

class ViewIIA extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            agreement: null,
            factsheets: [],
            downloadPdf: false,
            displayConfirmDelete: false,
            revertIiaConfirm: false,
            localInstitutionErasmusCode: null,
            localIiaCnrList: null,
            isError: false,
            errorMessage: ""
        }
        this.delete = this.delete.bind(this);
    }
    finalizeIIA = async () => {
        var agreementToPost = {}
        agreementToPost["id"] = this.state.agreement.id;
        agreementToPost["status"] = {
            "name": "Final"
        };
        try {
            const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
            context.setBearerAuthorization(UserService.getToken());
            await context.model(`MobilityAgreements`).save(agreementToPost);
            const tempAgreement = this.state.agreement;
            tempAgreement["status"] = {
                "name": "Final"
            }
            this.setState({ agreement: tempAgreement })

        } catch (err) {
            console.log(err);
        }
    }

    changeLocalStatus = async () => {
        var agreementToPost = {}
        agreementToPost["id"] = this.state.agreement.id;
        if (this.state.agreement.localStatus == 1) {
            agreementToPost["localStatus"] = 0
        } else {
            agreementToPost["localStatus"] = 1
        }

        try {
            const context = new ReactDataContext(process.env.REACT_APP_API_SERVER)
            context.setBearerAuthorization(UserService.getToken());
            await context.model(`MobilityAgreements`).save(agreementToPost);
            const tempAgreement = this.state.agreement;
            if (this.state.agreement.localStatus == 1) {
                tempAgreement["localStatus"] = 0
            } else {
                tempAgreement["localStatus"] = 1
            }
            this.setState({ agreement: tempAgreement })
        } catch (err) {
            console.log(err);
        }
    }

    saveIIAAsFinalAndNotifyPartner = async () => {

        await this.finalizeIIA();
        await notifyPartnerCNR(this.state.agreement.id);
    }

    approveAndNotifyPartner = async () => {
        await approveIIA(this.state.agreement.id);
        var currentMobilityEwpStatus = this.state.agreement.ewpStatus;
        if (currentMobilityEwpStatus?.name === 'Notified by all' || currentMobilityEwpStatus?.name === 'Notified by partner' || currentMobilityEwpStatus?.name === 'Notified by us') {
            currentMobilityEwpStatus = {
                name: 'Approved by us'
            }
        } else if (currentMobilityEwpStatus?.name === 'Approved by partner') {
            currentMobilityEwpStatus = {
                name: 'Approved by all'
            }
        } else {
            // log something
        }
        var tempAgreement = this.state.agreement;
        tempAgreement['ewpStatus'] = currentMobilityEwpStatus;
        this.setState({ agreement: tempAgreement })

    }
    handleClose = () => this.setState({ displayConfirmDelete: false, revertIiaConfirm: false });

    delete = async () => {
        this.setState({ agreement: null })
        const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
        context.setBearerAuthorization(UserService.getToken());
        // fetch the agreement
        try {
            var raw = { "isDeleted": true, "id": this.props.match.params.name };

            const agreement = await context.model(`MobilityAgreements`).save(raw)
            if (agreement) {
                window.location.href = "/"
            }

            this.notifyPartnerCNR(this.state.agreement.id)
        } catch (error) {
            console.log('error', error)
        }
    }
    revertIia = async () => {
        var requestOptions = {
            method: 'GET'
        };

        fetch(process.env.REACT_APP_EWP_API_IIAS_REVERT + this.state.agreement.id, requestOptions)
            .then(response => response.text())
            .then(() => alert("Revert request successfully sent"))
            .catch(() => alert("An error occured"));
    }

    terminateIia = async () => {
        const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
        context.setBearerAuthorization(UserService.getToken());

        var raw = { "terminated": 1, "id": this.state.agreement.id };

        try {
            const agreement = await context.model(`MobilityAgreements`).save(raw)
            var tempAgreement = this.state.agreement;
            tempAgreement['terminated'] = 1
            this.setState({ agreement: tempAgreement })

            await notifyPartnerCNR(this.state.agreement.id);
        } catch (error) {
            console.log('error', error)
        }
    }

    initiateChanges = async () => {
        const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
        context.setBearerAuthorization(UserService.getToken());

        var raw = { "ewpStatus": { "id": 2 }, "id": this.state.agreement.id };

        try {
            await context.model(`MobilityAgreements`).save(raw);
            const tempAgreement = this.state.agreement;
            tempAgreement['ewpStatus'] = {
                name: 'Notified by us',
                id: 2
            };
            this.setState({ agreement: tempAgreement })
        } catch (error) {
            console.log('error', error)
        }
    }

    approveButtonDispalyName = (ewpStatus, terminated) => {
        if (ewpStatus != null && ewpStatus.id == 7 && (terminated == 1 || terminated == 2 || terminated == 3)) {
            return "Approve IIA termination and Notify Partner"
        }

        return "Approve and Notify Partner"
    }

    terminationLabelDispalyName = (terminated) => {
        switch (terminated) {
            case 0:
                return "Not terminated";
            case 1:
                return "Termination initiated - awaiting approvals";
            case 2:
                return "Termination initiated - approved by us";
            case 3:
                return "Termination initiated - approved by partner";
            case 4:
                return "IIA Terminated";
            default:
                return "Not terminated";
        }

    }

    /**
     * Gets the institutions, short local (auth) as first, get factsheet for each institution.
     * @returns {Promise<void>}
     */
    async componentDidMount() {
        //console.log("[ViewIIA] (componentDidMount) hi");
        // set up context
        const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
        context.setBearerAuthorization(UserService.getToken());

        document.title = this.props.title;

        // fetch the agreement
        try {
            //console.log("[ViewIIA] Get the agreement with id: ", this.props.match.params.name);
            const agreement = await context.model(`MobilityAgreements`).where("id").equal(this.props.match.params.name).getItem();

            if (!agreement) {
                this.setState({
                    isError: true,
                    errorMessage: `Agreement ${this.props.match.params.name} was not found.`
                });
                return;
            }
            //console.log("[ViewIIA] (componentDidMount) agreement=", agreement);
            this.setState({ agreement: agreement });
        } catch (e) {
            console.log("[ViewIIA] Error fetching the agreement with id ", this.props.match.params.name);
            this.setState({
                isError: true,
                errorMessage: `Error fetching the agreement with id ${this.props.match.params.name}.`
            });
            return;
        }

        // sort institutions by isLocal true first
        const sortedInstitutions = await this.state.agreement.institutions.sort(compareInstitutions);
        // console.log("[ViewIIA] (componentDidMount) sortedInstitutions=", sortedInstitutions);
        // set local institution is auth
        this.setState({ localInstitutionErasmusCode: sortedInstitutions[0].erasmusCode });
        //console.log("[ViewIIA] (componentDidMount) localInstitutionErasmusCode=", this.state.localInstitutionErasmusCode);
        // get all factsheets
        for (const i of sortedInstitutions) {
            //console.log(`[ViewIIA] (componentDidMount) Get factsheet for institution ${i.id} from the list`, this.state.agreement.factsheets);
            // get its factsheet that was active during this state
            // factsheet object is:
            // { "id",
            // "institution/id",
            // "institution/isLocal",
            // "institution/erasmusCode" }
            const factsheetObject = this.state.agreement.factsheets.find(f => f.institution.id === i.id);

            // console.log("[ViewIIA] (componentDidMount) Found factsheet: ", factsheetObject);
            if (!factsheetObject) {
                console.log(`[ViewIIA] (componentDidMount) There is no Factsheet for institution ${i.id}.`);
                // TODO get the factsheet from the EWP
                const factsheetJson = {
                    institutionId: i.id,
                    erasmusCode: i.erasmusCode,
                    name: i.name,
                    isLocal: i.isLocal,
                    factsheet: null
                };
                // append the empty factsheet
                //console.log(`[ViewIIA] (componentDidMount) Append the empty factsheet for institution: ${i.id} to `, this.state.factsheets);
                this.setState({ factsheets: [...this.state.factsheets, factsheetJson] });
                // console.log("[ViewIIA] (componentDidMount) factsheets updated: ", this.state.factsheets);
            } else {
                // console.log(`[ViewIIA] (componentDidMount) Get factsheet with id: ${factsheetObject.id}`);
                const factsheet = await context.model(`MobilityFactsheets`).where("id").equal(factsheetObject.id).getItem();
                // console.log("[ViewIIA] (componentDidMount) Factsheet found: ", factsheet);
                if (!factsheet) {
                    console.log(`Error: Factsheet for institution ${i.id} was not found.`);
                    this.setState({
                        isError: true,
                        errorMessage: `Error: Factsheet for institution ${i.id} was not found.`
                    });
                    return;
                }

                const factsheetJson = {
                    institutionId: i.id,
                    erasmusCode: i.erasmusCode,
                    name: i.name,
                    isLocal: i.isLocal,
                    factsheet: factsheet,
                    institutionalCoordinator: factsheet.institutionalCoordinatorContacts

                };
                // append the factsheet
                //console.log(`[ViewIIA] (componentDidMount) Append the factsheet for institution ${i.id}.`);
                this.setState({ factsheets: [...this.state.factsheets, factsheetJson] });
                // console.log("[ViewIIA] (componentDidMount) Factsheets updated: ", this.state.factsheets);
            }
        }
        // console.log("[ViewIIA] (componentDidMount) Factsheets: ", this.state.factsheets);

        const localIIACNRList = await getLocalIIACNRList(context, this.state.agreement?.id);
        this.setState({ localIiaCnrList: localIIACNRList })
    }

    render() {

        const { isError, errorMessage } = this.state;
        // const localFactsheet = this.state.factsheets.find(i => i.isLocal === true);
        // const partnerFactsheet = this.state.factsheets.filter(i => i.isLocal !== true);

        if (isError) {
            return <ErrorPage errorMessage={errorMessage} />
        }

        if (this.state.agreement === null || this.state.localIiaCnrList === null) {
            return (<div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Loader
                    className="spinner mx-auto"
                    type="TailSpin"
                    color="#3A435E"
                    height={70}
                    width={70}
                />
            </div>)
        }

        return (

            <div className="py-5 print-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="page-header" align="center">Inter-institutional Agreement between Programme Countries</h1>
                                </div>
                            </div>
                            <div className="row mt-2 mb-2">
                                <div className="col-md-12">
                                    <div align="center">
                                        <h3>Agreement duration: {this.state.agreement.startYear.id} - {this.state.agreement.endYear.id}</h3>
                                        {
                                            JSON.parse(localStorage.getItem("role")).role === "IRO" &&
                                            <div className="mb-2">
                                                <div>
                                                    <h4 className="d-inline-block">Status:</h4> <h4 className="d-inline-block">{this.state.agreement.status?.name ?? '-'}</h4>
                                                </div>
                                                <div>
                                                    <h4 className="d-inline-block">EWP Status:</h4> <h4 className="d-inline-block">{this.state.agreement.ewpStatus?.name ?? '-'}</h4>
                                                </div>
                                                <div>
                                                        <h4 className="d-inline-block">Local Finalized:</h4> <h4 className="d-inline-block">{this.state.agreement?.localStatus == 0 ? 'False' : this.state.agreement?.localStatus == 1 ? 'True' : '-'}</h4>
                                                    </div>
                                                    <div>
                                                    <h4 className="d-inline-block">Local ID:</h4> <h4 className="d-inline-block">{this.state.agreement.id}</h4>
                                                </div>
                                                <div>
                                                    <h4 className="d-inline-block">Partner ID:</h4> <h4 className="d-inline-block">{this.state.agreement.partnerAgreementIdentifications && this.state.agreement.partnerAgreementIdentifications?.length !== 0 ? this.state.agreement.partnerAgreementIdentifications[0]?.iiaId ?? '-' : '-'}</h4>
                                                </div>
                                                    <div>
                                                        <h4 className="d-inline-block">Termination Status:</h4> <h4 className="d-inline-block">{this.terminationLabelDispalyName(this.state.agreement.terminated)}</h4>
                                                    </div>
                                            </div>
                                        }
                                        <p style={{ textAlign: "justify" }}>
                                            The institutions agree to cooperate for the exchange of students and/or staff in the context of the Erasmus+
                                            programme. They commit to respect the quality requirements of the <a href="https://ec.europa.eu/programmes/erasmus-plus/resources/documents/applicants/higher-education-charter_en">Erasmus Charter for Higher Education</a> in all aspects related to the organisation and management of the mobility, including <a href="https://ec.europa.eu/education/education-in-the-eu/proposal-for-a-council-recommendation-on-the-automatic-mutual-recognition-of-diplomas-and-learning-periods-abroad_en">automatic recognition</a> of the credits
                                            awarded to students by the partner institution as agreed in the Learning Agreement and confirmed in the Transcript
                                            of Records, or according to the learning outcomes of the modules completed abroad, as described in the Course
                                            Catalogue, in line with the <a href="https://ec.europa.eu/education/resources-and-tools/european-credit-transfer-and-accumulation-system-ects_en">European Credit Transfer and Accumulation System</a>. The institutions agree on exchanging
                                            their mobility related data in line with the technical standards of <a href="https://ec.europa.eu/education/education-in-the-eu/european-student-card-initiative_en">the European Student Card Initiative</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <Tabs defaultActiveKey="details" className="mb-3">
                                <Tab eventKey="details" title="Details">
                                    <div className='mt-2 mb-2'>
                                        <Institutions institutions={this.state.agreement.institutions} //institutionalCoordinators={this.state.agreement.institutionalCoordinatorContacts}
                                            departmentalCoordinators={this.state.agreement.departmentalCoordinatorContacts} factsheets={this.state.factsheets} />
                                    </div>
                                    <div>
                                        <h3>B.	Mobility numbers for the academic years {this.state.agreement.startYear.id} - {this.state.agreement.endYear.id}</h3>
                                    </div>
                                    <div style={{ textAlign: "justify" }}>
                                        <p>
                                            <i>The partners commit to amend the table below in case of changes in the mobility data by no later than the end
                                                of January in the preceding academic year</i>
                                        </p>
                                    </div>

                                    {this.state.agreement?.studentMobilitiesForStudies.length > 0 && <StudentMobilities studentMobilities={this.state.agreement.studentMobilitiesForStudies} localInstitutionErasmusCode={this.state.localInstitutionErasmusCode} type="Studies" />}
                                    {this.state.agreement?.studentMobilitiesForTraineeship.length > 0 && <StudentMobilities studentMobilities={this.state.agreement.studentMobilitiesForTraineeship} localInstitutionErasmusCode={this.state.localInstitutionErasmusCode} type="Traineeship" />}

                                    {this.state.agreement?.staffMobilitiesForTeaching.length > 0 && <StaffMobilities staffMobilities={this.state.agreement.staffMobilitiesForTeaching} localInstitutionErasmusCode={this.state.localInstitutionErasmusCode} type="Teaching" />}
                                    {this.state.agreement?.staffMobilitiesForTraining.length > 0 && <StaffMobilities staffMobilities={this.state.agreement.staffMobilitiesForTraining} localInstitutionErasmusCode={this.state.localInstitutionErasmusCode} type="Training" />}

                                    <div style={{ margin: "10px", textAlign: "justify" }}>
                                        <p>
                                            Optional: subject area code and name and study cycle are optional. Inter-institutional agreements are not compulsory for Student Mobility for Traineeships
                                            or Staff Mobility for Training. Institutions may agree to cooperate on the organisation of traineeships; in this case they should indicate the number of
                                            students that they intend to send to the partner. Total duration in months/days of the student/staff mobility periods can be indicated if relevant.

                                        </p>
                                        <p>(*) By checking this box, the partners confirm that they are willing to exchange students who wish to carry out their
                                            mobility in a blended format, a combination of a short-term physical mobility with a virtual component.</p>
                                    </div>
                                    <div>
                                        <h3>C.	Recommended language skills</h3>
                                    </div>
                                    <p style={{ textAlign: "justify" }}>
                                        The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates
                                        so that they can have the recommended <a href="http://europass.cedefop.europa.eu/en/resources/european-language-levels-cefr">language skills</a> at the start of the study or teaching period:
                                    </p>
                                    <Languages studentMobilitiesForStudies={this.state.agreement?.studentMobilitiesForStudies} studentMobilitiesForTraineeship={this.state.agreement?.studentMobilitiesForTraineeship} staffMobilitiesForTeaching={this.state.agreement.staffMobilitiesForTeaching} staffMobilitiesForTraining={this.state.agreement?.staffMobilitiesForTraining} />

                                    <div className="my-5 mx-auto col-md-6 d-flex justify-content-around">
                                        {this.state?.agreement?.factsheets.map((f) =>
                                            <Link className="btn btn-previous m-2 col-md-10" to={`/factsheets/view/${f.id}`}>
                                                <i className='fa fa-eye mr-2' />
                                                View factsheet of {f.institution.name}
                                            </Link>
                                        )}
                                    </div>

                                    <div className="row mt-2 mb-2">
                                        <div className="col">
                                            <div>
                                                <h3>D.	SIGNATURES OF THE INSTITUTIONS (legal representatives)</h3>
                                            </div>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table-responsive">
                                                            <tbody>
                                                                <tr style={{ backgroundColor: "#3A435E", color: "white" }} className="customtable">
                                                                    <td><b>Institution </b><br /> [Erasmus code or city] </td>
                                                                    <td><b>Name, function</b></td><td><b>Date </b></td>
                                                                </tr>
                                                                {/*auth signer start */}
                                                                {
                                                                    this.state?.agreement?.signings?.filter(f => f.institution.isLocal === true).map(i =>
                                                                    (<tr key={i.institution.id}>
                                                                        <td>
                                                                            {i.institution.erasmusCode}
                                                                        </td>
                                                                        <td>{/* Legal Representative details - Name and Title*/}{i.signer && ((i.signer.title ? i.signer.title : "") + " " + (i.signer.givenName ? i.signer.givenName : "") + " " + (i.signer.familyName ? i.signer.familyName : "") + (i.signer.address ? "\n" + "Adress: " + i.signer.address : "") + (i.signer.email ? "\n Email: " + i.signer.email : ""))}
                                                                            {/*Legal Representative details - Function*/} </td>
                                                                        <td>{i.signingDate ? getDate(i.signingDate) : ""}</td>
                                                                    </tr>)
                                                                    )
                                                                }
                                                                {/*auth signer end */}
                                                                {/* partners signer start */}
                                                                {this.state.agreement.signings.filter(f => f.institution.isLocal === false) &&
                                                                    this.state.agreement.signings.filter(f => f.institution.isLocal === false).map(i =>
                                                                    (<tr key={i.institution.id}>
                                                                        <td>
                                                                            {i.institution.erasmusCode}
                                                                        </td>
                                                                        <td>{/* Legal Representative details - Name and Title*/}{i.signer && ((i.signer.title ? i.signer.title : "") + " " + (i.signer.givenName ? i.signer.givenName : "") + " " + (i.signer.familyName ? i.signer.familyName : "") + (i.signer.address ? "\n" + "Adress: " + i.signer.address : "") + (i.signer.email ? "\n Email: " + i.signer.email : ""))}
                                                                            {/*Legal Representative details - Function*/} </td>
                                                                        <td>{i.signingDate ? getDate(i.signingDate) : ""}</td>
                                                                    </tr>)
                                                                    )
                                                                }
                                                                {/* partners signer end */}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Restricted to="iia_edit_iro" fallback={notAllowed}>
                                        <div className='mt-2 mb-2'>
                                            <h3>
                                                Comments
                                            </h3>
                                            {this.state.agreement.comments ? <div dangerouslySetInnerHTML={{ __html: this.state.agreement.comments }} /> : "No comments found"}
                                        </div>

                                    </Restricted>
                                </Tab>
                                {JSON.parse(localStorage.getItem("role")).role === "IRO" &&
                                    <Tab eventKey="notifications" title="Notifications">
                                        <div className="row mt-2 mb-2">
                                            <div className="col">

                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Notifications iia={this.state.agreement} userService={this.props.userService} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>}
                                {JSON.parse(localStorage.getItem("role")).role === "IRO" &&
                                    <Tab eventKey="approvals" title="Approvals">
                                        <div className="row mt-2 mb-2">
                                            <div className="col">

                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <MobilityAgreementApprovalResponse iia={this.state.agreement} userService={this.props.userService} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>}
                            </Tabs>
                            <div className="d-flex justify-content-center">
                                {
                                    ((this.state.agreement.status.id === 1 && JSON.parse(localStorage.getItem("role")).role === "Faculty") ||
                                        (JSON.parse(localStorage.getItem("role")).role === "IRO" && (this.state.agreement.ewpStatus == null || this.state.agreement.ewpStatus.id != 7))) &&
                                    <Link className="btn btn-previous m-2" to={"/iia/edit/" + this.props.match.params.name}><i className='fa fa-pencil mr-2'></i>Edit IIA</Link>
                                }
                                {
                                    (JSON.parse(localStorage.getItem("role")).role === "IRO" && this.state.agreement.ewpStatus != null && this.state.agreement.ewpStatus.id === 7) &&
                                    <button className="btn btn-primary m-2" onClick={this.initiateChanges}>
                                        Initiate changes
                                    </button>
                                }
                                {
                                    (JSON.parse(localStorage.getItem("role")).role === "IRO" &&
                                        this.state.agreement.ewpStatus != null && this.state.agreement.ewpStatus.id != 7 &&
                                        (this.state.agreement.approvedCopy != null && this.state.agreement.approvedCopy != "") ||
                                        this.state.agreement.terminated > 0 && this.state.agreement.terminated < 4) &&
                                    <button className="btn btn-primary m-2" onClick={() => {
                                        this.setState({ revertIiaConfirm: true })
                                    }}>
                                        <i className="fa fa-undo mr-2"></i> Revert
                                    </button>
                                }
                                <Modal show={this.state.revertIiaConfirm} onHide={this.handleClose}>
                                    <Modal.Body>
                                        <h4>Are you sure that you want to revert status for this IIA?</h4>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="btn btn-cancel m-2" onClick={this.revertIia}>
                                            Yes
                                        </Button>
                                        <Button variant="secondary" onClick={this.handleClose}>
                                            No
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {
                                    (JSON.parse(localStorage.getItem("role")).role === "IRO" &&
                                        this.state.agreement.ewpStatus != null && this.state.agreement.ewpStatus.id === 7 &&
                                        this.state.agreement.terminated === 0) &&
                                    <button className="btn btn-primary m-2" onClick={this.terminateIia}>
                                        Initiate IIA Termination
                                    </button>
                                }
                                {
                                    (this.state.agreement.status.name === "Submitted" || this.state.agreement.status.name === "Final") &&
                                    <button className="btn btn-secondary btn-exportpdf m-2" onClick={() => generatePDFDocument(this.state.agreement, this.state.factsheets)}>
                                        <i className="fa fa-file-pdf-o mr-2"></i> Download PDF
                                    </button>
                                }

                                {JSON.parse(localStorage.getItem("role")).role === "IRO" &&
                                    <>
                                    <button className="btn btn-danger m-2"
                                        disabled={(this.state.agreement.ewpStatus == null || this.state.agreement.ewpStatus.id == 7) &&
                                            (this.state.agreement.approvedCopy == null || this.state.agreement.approvedCopy != "")}
                                        onClick={() => {
                                            this.setState({ displayConfirmDelete: true })
                                        }}> <i className="fa fa-trash mr-2"></i>DELETE</button>
                                        <Modal show={this.state.displayConfirmDelete} onHide={this.handleClose}>
                                            <Modal.Body>
                                                <h4>Are you sure that you want to delete this IIA? This action cannot be reverted!</h4>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className="btn btn-cancel m-2" onClick={this.delete}>
                                                    Yes
                                                </Button>
                                                <Button variant="secondary" onClick={this.handleClose}>
                                                    No
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                    <button className="btn btn-primary m-2" onClick={this.changeLocalStatus}>{this.state.agreement?.localStatus == 0 ? 'Set Local Finalized True' : this.state.agreement?.localStatus == 1 ? 'Set Local Finalized False' : 'Set Local Finalized True'}</button>
                                        <button className="btn btn-primary m-2" disabled={!(this.state.agreement?.status?.name === "Submitted" || this.state.agreement?.status?.name === "Final")} onClick={this.saveIIAAsFinalAndNotifyPartner}>Finalize and Notify Partner</button>
                                        <button className="btn btn-primary m-2"
                                            disabled={isApproveButtonDisabled(this.state.agreement?.ewpStatus?.name, this.state.agreement?.partnerAgreementIdentifications, this.state.localIiaCnrList)}
                                            onClick={this.approveAndNotifyPartner}>
                                            {this.approveButtonDispalyName(this.state.agreement.ewpStatus, this.state.agreement.terminated)}
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}
export default ViewIIA
