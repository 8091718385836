export default function StudentStudiesComponentTranslation(fieldName, langcode) {

    const fields = {
        universityRegistryNumber: {
            en: {
                label: "University Registry Number",
                placeholder: "Enter university registry number"
            },
            gr: {
                label: "Αριθμός Πανεπιστημιακού Μητρώου",
                placeholder: "Εισάγετε αριθμό πανεπιστημιακού μητρώου"
            }
        },
        europeanStudentIdentifier: {
            en: {
                label: "European Student Identifier *",
                placeholder: "Enter european student identifier"
            },
            gr: {
                label: "Ευρωπαϊκό Αναγνωριστικό Φοιτητή/Φοιτήτριας *",
                placeholder: "Εισάγετε ευρωπαϊκό αναγνωριστικό φοιτητή/φοιτήτριας"
            }
        },
        faculty: {
            en: {
                label: "School *",
                placeholder: "Enter faculty"
            },
            gr: {
                label: "Σχολή *",
                placeholder: "Εισάγετε σχολή"
            }
        },
        department: {
            en: {
                label: "Department *",
                placeholder: "Enter department"
            },
            gr: {
                label: "Τμήμα *",
                placeholder: "Εισάγετε τμήμα"
            }
        },
        diploma: {
            en: {
                label: "Degree (to be obtained) *",
                placeholder: "Enter degree title"
            },
            gr: {
                label: "Τίτλος Σπουδών (που πρόκειται να αποκτηθεί) *",
                placeholder: "Εισάγετε τίτλο σπουδών"
            }
        },
        specialization: {
            en: {
                label: "Specialization",
                placeholder: "Specialization (if applicable)"
            },
            gr: {
                label: "Ειδίκευση (εφόσον ισχύει)",
                placeholder: "Εισάγετε ειδίκευση"
            }
        },
        educationLevel: {
            en: {
                label: "Level of education *",
                placeholder: "Enter level of education"
            },
            gr: {
                label: "Επίπεδο Σπουδών *",
                placeholder: "Εισάγετε επίπεδο σπουδών"
            }
        },
        yearOfEntry: {
            en: {
                label: "Year of enrollment at AUTh *",
                placeholder: "Enter the year of your enrollment at AUTh (eg 2010)"
            },
            gr: {
                label: "Έτος εισαγωγής στο Α.Π.Θ. *",
                placeholder: "Εισάγετε το έτος εισαγωγής σας στο Α.Π.Θ. (π.χ. 2010)"
            }
        },
        currentSemester: {
            en: {
                label: "Current semester of studies *",
                placeholder: "Enter your current semester of studies"
            },
            gr: {
                label: "Τρέχον εξάμηνο σπουδών *",
                placeholder: "Εισάγετε το τρέχον εξάμηνο σπουδών σας"
            }
        },
        semesterOnMobility: {
            en: {
                label: "Semester of studies at AUTh at the beginning of the mobility *",
                placeholder: "Enter your expected semester of studies at AUTh at the beginning of the mobility"
            },
            gr: {
                label: "Εξάμηνο φοίτησης στο Α.Π.Θ. κατά την έναρξη της μετακίνησης *",
                placeholder: "Εισάγετε το αναμενόμενο εξάμηνο φοίτησής σας στο Α.Π.Θ. κατά την έναρξη της μετακίνησης"
            }
        },
        h3_title: {
            en: {
                label: "Current Studies",
            },
            gr: {
                label: "Τρέχουσες σπουδές",
            }
        },
        education_level_options: {
            en: [{ label: 'Undergraduate' }, { label: 'Postgraduate' }, { label: 'Ph.D' }],
            gr: [{ label: 'Προπτυχιακό' }, { label: 'Μεταπτυχιακό' }, { label: 'Διδακτορικό' }],
        }
    }

    return fields[fieldName][langcode]
}