import { useEffect, useState } from "react";
import { ReactDataContext } from '@themost/react';

export const fallbackInstitution = {
    name: null,
    erasmusCode: null,
    heiId: null,
    cityName: null,
    country: null,
    isLocal: null
};

const Institution = ({ userService, institution, number, title, editable, handleTdChange }) => {
    const [countryName, setCountryName] = useState("-");

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(userService.getToken());

    // fetch and display country name instead of country code 
    useEffect(() => {
        (async () => {
            try {
                const countryResult = await context.model(`MobilityCountries`).where('id').equal(institution?.country).take(1).getItems();
                
                if(countryResult.length !== 0) {
                    setCountryName(countryResult[0].countryName);
                }
            } catch (err) {
                console.log(err);
            }
        })()
        
    }, []);

    return (
        <>
            <div>
                <h3>
                    {`${number}. ${title}`}
                </h3>
            </div>
            <div className="table-responsive">
                <table className="table cf" style={{ tableLayout: 'fixed'}}>
                    <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                        <tr>
                            <th scope="col" className="text-center col-md-2">Name</th>
                            <th scope="col" className="text-center col-md-2">Erasmus code</th>
                            <th scope="col" className="text-center col-md-2">HEI Id</th>
                            <th scope="col" className="text-center col-md-2">City</th>
                            <th scope="col" className="text-center col-md-2">Country</th>
                            <th scope="col" className="text-center col-md-2">Local</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        {
                            Object.keys(institution ? institution : fallbackInstitution).map(key => key !=='id' &&
                            <td 
                                key={key} 
                                className="text-center"
                                style={{ wordWrap: 'break-word' }}
                                contentEditable={editable}
                                onBlur={e => {
                                    handleTdChange(key, e.target.innerText)
                                }}
                            >
                                {
                                    key === 'isLocal' 
                                    ? ((institution ? institution : fallbackInstitution)[key] === true ? 'Yes' : ((institution ? institution : fallbackInstitution)[key] === false ? 'No' : '-')) 
                                    : (key === 'country' ? (countryName) : ((institution.hasOwnProperty(key) ? institution : fallbackInstitution)[key] ?? '-'))
                                }
                            </td>)
                        }
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Institution;