import { useRef, Fragment } from 'react';
import EditSingleAccessibility from './EditSingleAccessibility';

const EditAccessibilities = ({ children, handleStep, handleChange, data }) => {
    const language = useRef('en');

    const handleSubmit = (e) => {
        e.preventDefault();
        handleStep();
    }
    
    const handleLocalChange = (index, propertyName, newValue) => {
        const newArray = Array.from(data.accessibilities);
        newArray.splice(index, 1, { ...data.accessibilities[index], [propertyName]: newValue });

        handleChange('accessibilities', newArray);
    }

    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={handleSubmit}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h3 className="text-center">Accessibilities</h3>
                            {data.accessibilities.map((accessibility, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        <div className="d-flex justify-content-around">
                                            <h5 className="text-center">{`Accessibility ${idx+1}`}</h5>
                                            <button className='btn btn-danger btn-sm mb-2' onClick={(e) => {
                                                e.preventDefault()
                                                const newArray = Array.from(data.accessibilities);
                                                newArray.splice(idx, 1)
                                                
                                                handleChange('accessibilities', newArray);
                                            }}>
                                                <i className="fa fa-trash mr-2"></i>
                                                Remove accessibility
                                            </button>
                                        </div>
                                        <EditSingleAccessibility accessibility={accessibility} index={idx} handleChange={handleLocalChange} language={language.current} />
                                        <hr />
                                    </Fragment>
                                );
                            })}
                            <div className="d-flex justify-content-center">
                                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={(e) => {
                                    e.preventDefault()
                                    handleChange('accessibilities', [...data.accessibilities, {
                                        type: 'infrastructure', 
                                        services: '', 
                                        description: '', 
                                        info: { 
                                            phone: '', 
                                            email: '', 
                                            urls: [
                                                { 
                                                    value: '', 
                                                    language: 'en' 
                                                }
                                            ] 
                                        }
                                    }])
                                }}>
                                    <i className="fa fa-plus mr-2"></i>
                                    Add accessibility
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                {
                    { ...children }
                }
            </div>
        </form>
    );
}

export default EditAccessibilities;

{/* <Form.Control as="select" custom required
    value={field.language}
    onChange={e => {
        const values = [...studentOutgoing1];
        values[idx]["language"] = e.target.value;
        setStudentOutgoing1(values);
    }}>
    {languages.length !== 0 && languages.map(i => (
        <option value={i.name}>{i.name}</option>
    ))}
</Form.Control> */}