import RequiredSelectWithLabel from "components/UI/RequiredSelectWithLabel";

const options = ['Motivation letter', 'Transcript of Records', 'Language certificate', 'Reference letter', 'Portfolio', 'CV', 'Other document'];

const EditSingleRequirement = ({ requirement, language, index, handleChange }) => {
    return (
        <>
            <div className="form-group">
                <label>Name *</label>
                <RequiredSelectWithLabel
                    onSelectChange={e => { 
                        if(e !== null) 
                            handleChange(index, 'name', e?.value) 
                        else
                            handleChange(index, 'name', null) 
                    }}
                    id={`requirement${index}name`}
                    value={requirement.name}
                    clearable={true}
                    listOptions={options.map(option => ({ value: option, label: option}))}
                />
            </div>
            <div className="form-group">
                <label>Description *</label>
                <input type="text" value={requirement.description} required className="form-control" onChange={e => { handleChange(index, 'description', e.target.value) }} />
            </div>
            {
                requirement?.urls && requirement.urls.length !== 0 ? requirement.urls.map((url, idx) => (
                    <div className="form-group" key={url + idx}>
                        <label className='d-block'>Url *</label>
                        <div className="d-flex justify-content-between">
                            <input type="url" placeholder="https://example.com" pattern="https?://.*" required value={url.value} className={`form-control col-md-9 d-inline-block`} onChange={e => {
                                const newArray = Array.from(requirement.urls);
                                newArray.splice(idx, 1, { ...requirement.urls[idx], value: e.target.value })

                                handleChange(index, 'urls', newArray)
                            }}/>
                            <button className='btn btn-danger col-md-2 d-inline-block align-self-center' onClick={(e) => {
                                e.preventDefault()
                                
                                const newArray = Array.from(requirement.urls);
                                newArray.splice(idx, 1)
                                
                                handleChange(index, 'urls', newArray);
                            }}>
                                <i className="fa fa-trash mr-2"></i>
                                Delete URL
                            </button>
                        </div>
                    </div>
                ))
                : <></>
            }
            <div className="d-flex justify-content-around">
                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={(e) => {
                    e.preventDefault()
                    handleChange(index, 'urls', requirement.urls ? [
                        ...requirement.urls,
                        {
                            value: '',
                            language: 'en'
                        }
                    ] : [{ value: '', language: 'en' }])
                }}>
                    <i className="fa fa-plus mr-2"></i>
                    Add URL
                </button>
            </div>
            <div className="form-group">
                <label>Language</label>
                <input type="text" readOnly={true} value={language} className="form-control" />
            </div>
        </>
    );
}

export default EditSingleRequirement;