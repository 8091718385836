const EditDeadlinesWeeksLimit = ({ children, handleStep, data, handleChange }) => {
    const handleSubmit = (e) => {
        console.log(data)
        e.preventDefault();
        handleStep();
    }

    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={handleSubmit}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h3 className="text-center">Deadline for applications of Incoming students - Nominations - Weeks limit</h3>
                            <h5 className="text-center">Deadline for applications of Incoming Students</h5>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Autumn Term *</label>
                                    <input type="text" placeholder='--MM-dd' pattern="--[0-1]?[0-9]-[0-3]?[0-9]" value={data.deadlineIncomingsCalendar?.autumnTerm ?? null} required className="form-control" onChange={e => handleChange('deadlineIncomingsCalendar', {
                                        ...data.deadlineIncomingsCalendar,
                                        autumnTerm: e.target.value,
                                    })}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Spring Term *</label>
                                    <input type="text" placeholder='--MM-dd' pattern="--[0-1]?[0-9]-[0-3]?[0-9]" value={data.deadlineIncomingsCalendar?.springTerm ?? null} required className="form-control" onChange={e => handleChange('deadlineIncomingsCalendar', {
                                        ...data.deadlineIncomingsCalendar,
                                        springTerm: e.target.value,
                                    })}/>
                                </div>
                            </div>

                            <h5 className="text-center">Deadline for Nominations</h5>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Autumn Term *</label>
                                    <input type="text" placeholder='--MM-dd' pattern="--[0-1]?[0-9]-[0-3]?[0-9]" value={data.deadlineNominationsCalendar?.autumnTerm ?? null} required className="form-control" onChange={e => handleChange('deadlineNominationsCalendar', {
                                        ...data.deadlineNominationsCalendar,
                                        autumnTerm: e.target.value,
                                    })}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Spring Term *</label>
                                    <input type="text" placeholder='--MM-dd' pattern="--[0-1]?[0-9]-[0-3]?[0-9]" value={data.deadlineNominationsCalendar?.springTerm ?? null} required className="form-control" onChange={e => handleChange('deadlineNominationsCalendar', {
                                        ...data.deadlineNominationsCalendar,
                                        springTerm: e.target.value,
                                    })}/>
                                </div>
                            </div>

                            <h5 className="text-center">Weeks limits</h5>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>For decision response *</label>
                                    <input type="number" value={data.decisionResponseWeeksLimit} min='0' placeholder='0' className="form-control" required onChange={e => handleChange('decisionResponseWeeksLimit', parseInt(e.target.value))} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>For transcript of records *</label>
                                    <input type="number" value={data.transcriptOfRecordsWeeksLimit} min='0' className="form-control" placeholder='0' required onChange={e => handleChange('transcriptOfRecordsWeeksLimit', parseInt(e.target.value))}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                {{ ...children }}
            </div>
        </form>
    );
}

export default EditDeadlinesWeeksLimit;