export const SET_START_YEAR_SELECTED_ACTION = "SET_START_YEAR_SELECTED";
export const SET_END_YEAR_SELECTED_ACTION = "SET_END_YEAR_SELECTED";
export const SET_COUNTRY_SELECTED_ACTION = "SET_COUNTRY_SELECTED";
export const SET_INSTITUTION_SELECTED_ACTION = "SET_INSTITUTION_SELECTED";
export const SET_STATUS_SELECTED_ACTION = "SET_STATUS_SELECTED";
export const SET_EWP_STATUS_SELECTED_ACTION = "SET_EWP_STATUS_SELECTED";
export const SET_DEPARTMENT_SELECTED_ACTION = "SET_DEPARTMENT_SELECTED";
export const SET_DEPARTMENTAL_COORDINATOR_SELECTED_ACTION = "SET_DEPARTMENTAL_COORDINATOR_SELECTED";
export const SET_COUNTRIES_LIST_ACTION = "SET_COUNTRILES_LIST";
export const SET_EWP_STATUSES_LIST_ACTION = "SET_EWP_STATUSES_LIST_ACTION";
export const SET_DEPARTMENTS_ACTION = "SET_DEPARTMENTS";
export const SET_ACADEMIC_YEARS_ACTION = "SET_ACADEMIC_YEARS";
export const SET_INSTITUTIONS_LIST_ACTION = "SET_INSTITUTIONS_LIST";
export const SET_DEPARTMENTAL_COORDINATORS_LIST_ACTION = "SET_DEPARTMENTAL_COORDINATORS_LIST";
export const UPDATE_MULTIPLE_VALUES_ACTION = "UPDATE_MULTIPLE_VALUES_ACTION";
export const SET_AGREEMENT_ID_SELECTED_ACTION = "SET_AGREEMENT_ID_SELECTED";
export const SET_EWP_ID_SELECTED_ACTION = "SET_EWP_ID_SELECTED";
export const SET_LOCAL_FINALIZED_SELECTED_ACTION = "SET_LOCAL_FINALIZED_SELECTED";

/**
 * Reducer for handling filter associated actions and state changes
 */
export const reducer = (state, action) => {
    return {
        ...state,
        ...action.payload,
    }
}