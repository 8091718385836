const UsefulLinks = ({ generalUrl, facultyUrl, courseCatalogueUrl, editable, handleValueChange }) => {
    return (
        <div className="table-responsive">
            <table className="table cf" style={{ tableLayout: 'fixed'}}>
                <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                    <tr>
                        <th scope="col" className="text-center col-md-2">General</th>
                        <th scope="col" className="text-center col-md-2">Faculty</th>
                        <th scope="col" className="text-center col-md-2">Course Catalogue</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td 
                            className="text-center align-middle" 
                            style={{ wordWrap: 'break-word' }}
                            contentEditable={editable}
                            onBlur={e => {
                                handleValueChange('generalUrl', e.target.innerText)
                            }}
                        >
                            <a href={generalUrl}>
                                { generalUrl ?? '-'}
                            </a>
                        </td>
                        <td 
                            className="text-center align-middle"
                            style={{ wordWrap: 'break-word' }}
                            contentEditable={editable}
                            onBlur={e => {
                                handleValueChange('facultyUrl', e.target.innerText)
                            }}
                        >
                            <a href={facultyUrl}>
                                { facultyUrl ?? '-'}
                            </a>
                        </td>
                        <td 
                            className="text-center align-middle"
                            style={{ wordWrap: 'break-word' }}
                            contentEditable={editable}
                            onBlur={e => {
                                handleValueChange('courseCatalogueUrl', e.target.innerText)
                            }}
                        >
                            <a href={courseCatalogueUrl}>
                                { courseCatalogueUrl ?? '-' }
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>  
    );
}

export default UsefulLinks;