import { useState, useEffect } from 'react'
import { Form, Alert} from 'react-bootstrap'
import { ReactDataContext } from '@themost/react';
import UserService from "services/UserService";
import Loader from 'react-loader-spinner'

function EditProfile(props) {
    const [openAlert,setOpenAlert]=useState(false)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [email, setEmail] = useState(null)
    const [address, setAddress] = useState(null)
    const [phone, setPhone] = useState(null)
    const [id, setId] = useState(null)
    const [title, setTitle] = useState(null)
    
    useEffect(() => {
        const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
        context.setBearerAuthorization(UserService.getToken());
        // get user
        context.model(`Users/Me`).asQueryable().getItem().then(res => {
            context.model(`MobilityContacts`).asQueryable().where('uid').equal(res.id).getItem().then(response => {
                setId(response.id)
                setFirstName(response.givenName)
                setLastName(response.familyName)
                setEmail(response.email)
                setPhone(response.phone)
                setAddress(response.address)
                setTitle(response.title)


            }).catch(error => {
                console.log('error', error)
            });
        }).catch(error => {
            console.log('error', error)
        });

    }, [])

    useEffect(() => {
        document.title = props.title
    }, [])

    if(email===null){
        return(
            <div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Loader
                  className="spinner mx-auto"
                  type="TailSpin"
                  color="#3A435E"
                  height={70}
                  width={70}
                />
              </div>
        )
    }
    return (
        <div className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <Alert
                        onClose={()=>setOpenAlert(false)}
                        variant="success"
                        dismissible
                        show={openAlert}>
                        <Alert.Heading>
                            Record saved successfully!
                        </Alert.Heading>
                    </Alert>
                    <div className="ml-2 p-3 shadow">

                        <h1 className="">
                            <i className="fa fa-user fa-fw mr-2"></i>My Profile
                        </h1>

                        <form noValidate={false} onSubmit={(e) => {
                            e.preventDefault();
                            const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
                            context.setBearerAuthorization(UserService.getToken());
                            context.model('MobilityContacts').save({
                                givenName: firstName,
                                familyName: lastName,
                                email: email,
                                address: address,
                                phone: phone,
                                title:title,
                                id: id


                            }).then((res) => {
                                // a new user has been created
                                // so return token info
                                setOpenAlert(true)
                            })
                        }}>
                            <label><h5>Contact Details</h5></label>
                            <div className="row">
                                <div className="col-md-12">
                                <Form.Group>
                                    <label>Title *</label>
                                    <Form.Control as="select" custom onChange={(e) => setTitle(e.target.value)} id="institutionaltitle" className="form-control has-validation font-link" required>
                                        <option selected={title === null} disabled value="">Choose</option>
                                        <option selected={title === ""} ></option>

                                        <option selected={title === "Mr."} >Mr.</option>
                                        <option selected={title === "Ms."} >Ms.</option>
                                        <option selected={title === "Mrs."} >Mrs.</option>

                                        <option selected={title === "Professor"} >Professor</option>
                                        <option selected={title === "Assistant Professor"} >Assistant Professor</option>
                                        <option selected={title === "Lecturer"} >Lecturer</option>
                                        <option selected={title === "Dr"} >Dr</option>
                                        <option selected={title === "Special Teaching Staff"} >Special Teaching Staff</option>
                                        <option selected={title === "Special Laboratory Teaching Staff"} >Special Laboratory Teaching Staff</option>
                                        <option selected={title === "Special Technical Laboratory Teaching Staff"} >Special Technical Laboratory Teaching Staff</option>
                                        <option selected={title === "Scientific Teaching Staff"} >Scientific Teaching Staff</option>
                                        <option selected={title === "Laboratory Teaching Staff"} >Laboratory Teaching Staff</option>
                                        <option selected={title === "Temporary Teaching Staff"} >Temporary Teaching Staff</option>
                                        <option selected={title === "External Teaching Staff"} >External Teaching Staff</option>
                                        <option selected={title === "Temporary Staff"} >Temporary Staff</option>
                                        <option selected={title === "Administrative Staff"} >Administrative Staff</option>

                                    </Form.Control>
                                </Form.Group>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        {" "}
                                        <label>First Name *</label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={firstName}
                                            required
                                            placeholder="(empty)"
                                            onChange={(e) => { setFirstName(e.target.value) }
                                            }
                                        />{" "}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        {" "}
                                        <label>Last Name *</label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={lastName}
                                            required
                                            placeholder="(empty)"
                                            onChange={(e) => { setLastName(e.target.value) }
                                            }
                                        />{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email *</label>

                                        <input
                                            type="text"
                                            className="form-control"
                                            value={email}
                                            required
                                            placeholder="(empty)"
                                            onChange={(e) => { setEmail(e.target.value) }
                                            }
                                        />{" "}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Phone Number *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={phone}
                                            required
                                            placeholder="(empty)"
                                            onChange={(e) => {
                                                setPhone(e.target.value)
                                            }}
                                        />{" "}
                                    </div>
                                </div>
                            </div>
                                <div className="row">
                                <div className="col-md-12">
                                <div className="form-group">
                                        {" "}
                                        <label>Address *</label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            required
                                            value={address}
                                            placeholder="(empty)"
                                            onChange={(e) => { setAddress(e.target.value) }
                                            }
                                        />{" "}
                                    </div>
                                </div>
                            </div>
                                <div className="d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary btn-next"
                                        type="submit"
                                    >
                                        <i className='fa fa-save mr-2'></i>SAVE
                                    </button>
                                </div>

                        </form>

                    </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default EditProfile
