import { useState, useRef, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
});

const RequiredCreatableSelectWithLabel = ({
    id,
    value,
    onSelectChange,
    clearable,
    disabled,
    isOptionDisabled,
    placeholderText,
    children,
    loading,
    handleOnCreate,
    listOptions
}) => {
    const selectRef = useRef(null);
    const [isLoading, setIsLoading] = useState(loading)

    useEffect(()=>{
        setIsLoading(loading)

    },[loading])
    
    const handleCreate = (inputValue) => {
        setIsLoading(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            //  listOptions.push(newOption)
            handleOnCreate(newOption)
            setIsLoading(false)
        }, 1000);
    };

    return (
        <>
            <label htmlFor={id}>{children}</label>
            <CreatableSelect getOptionValue={option => option.label}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary: '#3A435E',
                        primary25: 'rgba(58, 67, 94, 0.25)'
                    },
                })}
                isDisabled={disabled}
                isOptionDisabled={isOptionDisabled}
                isClearable={clearable}
                autoFocus={false}
                isLoading={(isLoading)}
                isSearchable
                value={[{ label: value ? value : '' }]}
                id={id}
                placeholder={placeholderText}
                onChange={onSelectChange}
                onCreateOption={handleCreate}
                options={listOptions}
                ref={selectRef}
            />
            <input
                tabIndex={-1}
                autoComplete="off"
                style={{
                    opacity: 0,
                    width: "100%",
                    height: 0,
                    position: "absolute"
                }}
                value={value ?? ''}
                onChange={onSelectChange}
                onFocus={() => selectRef.current.focus()}
                required={true}
            />
        </>
    )
}

export default RequiredCreatableSelectWithLabel;