export default function StudentPreferredMobilityComponentTranslation(fieldName, langcode, options = {}) {

    const fields = {
        typeOfMobility: {
            en: {
                label: "Type of mobility *",
                placeholder: "Enter type of mobility"
            },
            gr: {
                label: "Τύπος κινητικότητας *",
                placeholder: "Εισάγετε τύπο κινητικότητας"
            }
        },
        mobilityProgram: {
            en: {
                label: "Mobility Program *",
                placeholder: "Enter mobility program"
            },
            gr: {
                label: "Πρόγραμμα κινητικότητας *",
                placeholder: "Εισάγετε πρόγραμμα κινητικότητας"
            }
        },
        country: {
            en: {
                label: "Host country *",
                placeholder: "Enter the desired host country"
            },
            gr: {
                label: "Χώρα υποδοχής *",
                placeholder: "Εισάγετε χώρα υποδοχής"
            }
        },
        institution: {
            en: {
                label: "Host institution *",
                placeholder: "Enter the desired host institution"
            },
            gr: {
                label: "Ίδρυμα υποδοχής *",
                placeholder: "Εισάγετε ίδρυμα υποδοχής"
            }
        },
        agreementManager: {
            en: {
                label: "Responsible faculty member for the agreement *",
                placeholder: "Enter responsible faculty member for the agreement"
            },
            gr: {
                label: "Υπεύθυνος συμφωνίας *",
                placeholder: "Εισάγετε υπεύθυνο συμφωνίας"
            }
        },
        startSemester: {
            en: {
                label: "Start semester of the mobility *",
                placeholder: "Enter start semester of the mobility"
            },
            gr: {
                label: "Εξάμηνο " + "έναρξης " + "μετακίνησης *",
                placeholder: "Εισάγετε εξάμηνο έναρξης της μετακίνησης"
            }
        },
        startSemesterShort: {
            en: {
                label: "Semester of the mobility *",
                placeholder: "Enter semester of the mobility"
            },
            gr: {
                label: "Εξάμηνο " + "μετακίνησης *",
                placeholder: "Εισάγετε εξάμηνο της μετακίνησης"
            }
        },
        durationSemesters: {
            en: {
                label: "Duration of the mobility (number of semesters) *",
                placeholder: "Enter the number of semesters of the mobility"
            },
            gr: {
                label: "Διάρκεια μετακίνησης (αριθμός εξαμήνων) *",
                placeholder: "Εισάγετε τον αριθμό των εξαμηνών που θα διαρκέσει η μετακίνηση"
            }
        },
        durationDays: {
            en: {
                label: "Duration of the mobility *",
                placeholder: "Enter the duration of the mobility"
            },
            gr: {
                label: "Διάρκεια μετακίνησης *",
                placeholder: "Εισάγετε την διάρκεια της μετακίνησης"
            }
        },
        durationMonths: {
            en: {
                label: "Duration of the mobility (number of months) *",
                placeholder: "Enter the number of months of the mobility, according to the bilateral agreement"
            },
            gr: {
                label: "Διάρκεια μετακίνησης (αριθμός μηνών) *",
                placeholder: "Εισάγετε τον αριθμό των μηνών που θα διαρκέσει η μετακίνηση βάσει της διμερούς συμφωνίας"
            }
        },
        project: {
            en: {
                label: "Mobility for project work",
                placeholder: "Choose this option in case you have already agreed not to attend courses but to do project work"
            },
            gr: {
                label: "Μετακίνηση για εκπόνηση project",
                placeholder: "Επιλέξτε τη συγκεκριμένη δυνατότητα σε περίπτωση που έχετε συνεννοηθεί να μην παρακολουθήσετε μαθήματα κατά την μετακίνησή σας αλλά να εκπονήσετε/συμμετέχετε σε κάποιο project"
            }
        },
        h3_title: {
            en: {
                label: "Mobility preferences",
            },
            gr: {
                label: "Προτιμήσεις κινητικότητας",
            }
        },
        mobilityAdd: {
            en: {
                label: "Add mobility preference",
            },
            gr: {
                label: "Προσθήκη προτίμησης κινητικότητας",
            }
        },
        mobilityRemove: {
            en: {
                label: "Remove mobility preference",
            },
            gr: {
                label: "Αφαίρεση προτίμησης κινητικότητας",
            }
        },
        typeOfMobility_options: {
            en: {
                label: options.studies + " mobility for Studies"
            },
            gr: {
                label: options.studies + " κινητικότητα για Σπουδές"
            }
        },
        longTermStudies: {
            en: {
                label: "Long-term"
            },
            gr: {
                label: "Μακροχρόνια"
            }
        },
        shortTermStudies: {
            en: {
                label: "Short-term"
            },
            gr: {
                label: "Βραχυχρόνια"
            }
        },
        mobilityProgram_options: {
            en: {
                label: "Erasmus+ Studies"
            },
            gr: {
                label: "Erasmus+ Σπουδές"
            }
        },
        prefered_mobility_options_label: {
            en: {
                label: "Choice"
            },
            gr: {
                label: "Επιλογή"
            }
        },
        startSemester_options: {
            en: [{ label: "Winter semester " + options.year }, { label: "Spring semester " + options.year }],
            gr: [{ label: "Χειμερινό Εξάμηνο " + options.year }, { label: "Εαρινό Εξάμηνο " + options.year }],
        },
        blended: {
            en: {
                label: "Blended"
            },
            gr: {
                label: "Blended"
            }
        }

    }

    return fields[fieldName][langcode]
}