import { useState, useEffect } from 'react';
import 'styles/css/IIAform.css';
import RequiredCreatableSelectWithLabel from 'components/UI/RequiredCreatableSelectWithLabel';
import RequiredSelectWithLabel from 'components/UI/RequiredSelectWithLabel';
import SelectWithLabel from 'components/UI/SelectWithLabel';
import { ReactDataContext } from '@themost/react';
import UserService from "services/UserService";
import { contactTitles } from 'contactTitles';


function EditContactDetails({ children, iia, setIia, handleStep, isLocal, departmentList, setDepartmentList, contactList, setContactList }) {

    const [roles, setRoles] = useState([])
    const titles = contactTitles;
    const [departmentalContacts, setDepartmentalContacts] = useState([{
        "title": null,
        "roles": [{
            "value": null,
            "language": null
        }],
        "givenName": null,
        "familyName": null,
        "email": null,
        "phone": null,
        "address": null,
        "url": null,
        "department": {
            "name": null
        }
    }])

    useEffect(async () => {
        const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
        context.setBearerAuthorization(UserService.getToken());

        try {
            const rolesResult = await context.model(`MobilityRoleDescriptions`).getItems();
            setRoles(rolesResult);
        }
        catch (err) {
            console.log('err', err)
        }

        const departmentalCoordinators = iia.departmentalCoordinatorContacts ? iia.departmentalCoordinatorContacts.filter(i => i?.department?.institution.isLocal === isLocal) : [];

        if (departmentalCoordinators.length > 0) {
            setDepartmentalContacts(departmentalCoordinators);
        }

        if (isLocal && !departmentalCoordinators.find(d=>d.id === Number(process.env.REACT_APP_LOCAL_INSTITUTIONAL_ID))) {
            try {
                const localInstitutionalCoordinator = await context.model(`MobilityContacts`).where('id').equal(process.env.REACT_APP_LOCAL_INSTITUTIONAL_ID).getItem();

                if (!iia.id) {

                    setDepartmentalContacts([localInstitutionalCoordinator, {
                        "title": null,
                        "roles": [{
                            "value": null,
                            "language": null
                        }],
                        "givenName": null,
                        "familyName": null,
                        "email": null,
                        "phone": null,
                        "address": null,
                        "url": null,
                        "department": {
                            "name": null
                        }
                    }]);
                }
                else {
                    setDepartmentalContacts([localInstitutionalCoordinator].concat(departmentalCoordinators));
                }
            }
            catch (err) {
                console.log('err', err)
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, []);

    return (

        <form className="g-3 needs-validation" noValidate={false}
            onSubmit={async (event) => {
                event.preventDefault();
                if (departmentList && departmentList.length === 0) {
                    setDepartmentList(departmentalContacts.map(i => i.department));
                }
                const temp = iia
                if (temp["departmentalCoordinatorContacts"]) {
                    temp["departmentalCoordinatorContacts"] = temp["departmentalCoordinatorContacts"].filter(i => i.department.institution.isLocal !== isLocal)
                }
                else {
                    temp["departmentalCoordinatorContacts"] = []
                }
                temp["departmentalCoordinatorContacts"] = temp["departmentalCoordinatorContacts"].concat(departmentalContacts)

                setIia(iia);
                handleStep();
            }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <h3 className="text-center">Contacts of the {isLocal ? 'Local' : 'Partner'} Institution</h3>
                            {departmentalContacts.map((departmentalContact, index) =>
                                <div className="card p-4" key={`departmental-coordinator-${index}`}>
                                    <div className="d-flex flex-row-reverse">
                                        <button className='btn btn-primary btn-sm btn-danger btn-remove-language' onClick={(e) => {
                                            e.preventDefault();
                                            const values = [...departmentalContacts];
                                            values.splice(index, 1);
                                            setDepartmentalContacts(values);
                                        }}>
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </div>
                                    {(departmentList && departmentList.length > 0) ?
                                        <div className="form-group">
                                            <RequiredCreatableSelectWithLabel
                                                value={departmentalContact?.department?.name}
                                                placeholderText="Type department"
                                                id="department"
                                                clearable={true}
                                                onSelectChange={(newValue) => {
                                                    if (newValue != null) {
                                                        let tempDepartmentalContacts = [...departmentalContacts];
                                                        tempDepartmentalContacts[index]['department'] = departmentList.find(i => i.name === newValue.label);
                                                        setDepartmentalContacts(tempDepartmentalContacts);
                                                    }
                                                    else {

                                                        let tempDepartmentalContacts = [...departmentalContacts];
                                                        tempDepartmentalContacts[index]['department'] = {
                                                            "name": null
                                                        };
                                                        setDepartmentalContacts(tempDepartmentalContacts);
                                                    }
                                                }}
                                                handleOnCreate={(newValue) => {
                                                    let temp = departmentList;
                                                    temp.push({ "name": newValue.label, "institution": iia?.institutions?.find(i => i.isLocal === isLocal) });
                                                    setDepartmentList(temp);
                                                    let tempDepartmentalContacts = [...departmentalContacts];
                                                    tempDepartmentalContacts[index]['department'] = {
                                                        'name': newValue.label,
                                                        'institution': iia?.institutions?.find(i => i.isLocal === isLocal)
                                                    }
                                                    setDepartmentalContacts(tempDepartmentalContacts);
                                                }}
                                                loading={departmentList === null}
                                                listOptions={departmentList ? departmentList.map(i => ({ label: i?.name })) : []}
                                            >Department *</RequiredCreatableSelectWithLabel>
                                        </div> : <div className='row'>
                                            <div className="col-md-12">
                                                <div className="form-group">

                                                    <label>Department *</label>
                                                    <input
                                                        value={departmentalContact?.department?.name}
                                                        disabled={departmentList === null}
                                                        required id="departmentalContactDepartment" type="text" className="form-control has-validation" onChange={(e) => {
                                                            let tempDepartmentalContacts = [...departmentalContacts];
                                                            tempDepartmentalContacts[index]['department'] = {
                                                                'name': e.target.value,
                                                                'institution': iia?.institutions?.find(i => i.isLocal === isLocal)
                                                            }
                                                            setDepartmentalContacts(tempDepartmentalContacts);

                                                        }} />
                                                    {departmentList === null ?
                                                        <small style={{ color: "red" }}>Loading Partner's Departments from EWP</small>
                                                        : <small style={{ color: "red" }}>
                                                            Fill the department. Partner does not list its departments in the EWP.
                                                        </small>}
                                                </div>
                                            </div>
                                        </div>}
                                    <label>
                                        <h5>Contact Details</h5>
                                    </label>
                                    {(contactList && contactList.length > 0) ?
                                        <div className="form-group">
                                            <div className="mb-2">
                                                <RequiredCreatableSelectWithLabel
                                                    value={departmentalContact?.email}
                                                    placeholderText="Type department"
                                                    id="departmentalContactEmail"
                                                    clearable={true}
                                                    disabled={!departmentalContact?.department?.name}
                                                    onSelectChange={(newValue) => {
                                                        if (newValue != null) {
                                                            let tempDepartmentalContacts = [...departmentalContacts];
                                                            tempDepartmentalContacts[index] = contactList.find(i => i.email === newValue.label);
                                                            setDepartmentalContacts(tempDepartmentalContacts);
                                                        }
                                                        else {
                                                            let tempDepartmentalContacts = [...departmentalContacts];
                                                            tempDepartmentalContacts[index]['email'] = null;
                                                            tempDepartmentalContacts[index]['familyName'] = null;
                                                            tempDepartmentalContacts[index]['givenName'] = null;
                                                            tempDepartmentalContacts[index]['phone'] = null;
                                                            tempDepartmentalContacts[index]['address'] = null;
                                                            setDepartmentalContacts(tempDepartmentalContacts);
                                                        }
                                                    }}
                                                    handleOnCreate={(newValue) => {
                                                        let temp = contactList;
                                                        temp.push({ "email": newValue.label });
                                                        setContactList(temp);
                                                        let tempDepartmentalContacts = [...departmentalContacts];
                                                        delete tempDepartmentalContacts[index]["id"];
                                                        delete tempDepartmentalContacts[index]["apm"];
                                                        delete tempDepartmentalContacts[index]["uid"];
                                                        tempDepartmentalContacts[index]["email"] = newValue.label;
                                                        setDepartmentalContacts(tempDepartmentalContacts);
                                                    }}
                                                    loading={contactList === null}
                                                    // listOptions={contactList ? contactList.map(i => ({ label: i?.email })) : []}
                                                    listOptions={contactList ? contactList.filter(i => i.department?.name === departmentalContact.department?.name).map(i => ({ label: i?.email })) : []}

                                                >Email *</RequiredCreatableSelectWithLabel>
                                            </div>
                                        </div> :
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div className="form-group">

                                                    <label>Email *</label>
                                                    <input
                                                        value={departmentalContact?.email}
                                                        disabled={contactList === null}
                                                        type="email"
                                                        required id="departmentalContactEmail" className="form-control has-validation" onChange={(e) => {
                                                            let tempDepartmentalContacts = [...departmentalContacts];
                                                            tempDepartmentalContacts[index]['email'] = e.target.value;
                                                            setDepartmentalContacts(tempDepartmentalContacts);

                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="form-group">
                                        <SelectWithLabel
                                            clearable={false}
                                            value={departmentalContact?.title}
                                            id="title"
                                            isMulti={false}
                                            placeholder="Enter title"
                                            onSelectChange={(e) => {
                                                var temp = [...departmentalContacts];
                                                temp[index]['title'] = e.value;
                                                setDepartmentalContacts(temp);

                                            }}
                                            listOptions={titles.map(i => ({ value: i, label: i }))}
                                        >Select title</SelectWithLabel>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6">
                                            <label>First Name *</label>
                                            <input
                                                value={departmentalContact?.givenName}
                                                required id="departmentalContactGivenName" className="form-control has-validation" onChange={(e) => {
                                                    let tempDepartmentalContacts = [...departmentalContacts];
                                                    tempDepartmentalContacts[index]['givenName'] = e.target.value;
                                                    setDepartmentalContacts(tempDepartmentalContacts);

                                                }} />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Last Name *</label>
                                            <input
                                                value={departmentalContact?.familyName}
                                                required id="departmentalContactFamilyName" type="text" className="form-control has-validation" onChange={(e) => {
                                                    let tempDepartmentalContacts = [...departmentalContacts];
                                                    tempDepartmentalContacts[index]['familyName'] = e.target.value;
                                                    setDepartmentalContacts(tempDepartmentalContacts);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-2">
                                        <div className="col-md-12">
                                            <label>Phone</label>
                                            <input /*required*/ id="departmentalContactPhone"
                                                className="form-control has-validation"
                                                placeholder="Phone number in the E.164 format, with the leading “+” sign and 12 digits. e.g. : +302310996727"
                                                pattern="\+[0-9]{12}"
                                                value={departmentalContact?.phone}
                                                onChange={(e) => {
                                                    let tempDepartmentalContacts = [...departmentalContacts];
                                                    tempDepartmentalContacts[index]['phone'] = e.target.value;
                                                    setDepartmentalContacts(tempDepartmentalContacts);
                                                }} />
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input id="departmentalContactAddress"
                                            type="text" className="form-control"
                                            value={departmentalContact?.address}
                                            onChange={(e) => {
                                                let tempDepartmentalContacts = [...departmentalContacts];
                                                tempDepartmentalContacts[index]['address'] = e.target.value;
                                                setDepartmentalContacts(tempDepartmentalContacts);
                                            }} />
                                    </div>
                                    <div className="form-group">
                                        <label>Website for information </label>
                                        <input type="text" className="form-control"
                                            placeholder="https://example.com" pattern="https?://.*"
                                            value={departmentalContact?.url}
                                            onChange={(e) => {
                                                let tempDepartmentalContacts = [...departmentalContacts];
                                                tempDepartmentalContacts[index]['url'] = e.target.value;
                                                setDepartmentalContacts(tempDepartmentalContacts);
                                            }} />
                                    </div>
                                    <fieldset className="shadow p-2 mt-2 mb-2">

                                        {departmentalContact?.roles?.length > 0 ? departmentalContact?.roles.map((r, idx) =>
                                            <div className="row">
                                                <div className="d-flex justify-content-center">

                                                    <div className="form-group col-md-10">
                                                        <RequiredSelectWithLabel
                                                            clearable={false}
                                                            value={r.value}
                                                            id="role"
                                                            isMulti={false}
                                                            placeholder="Enter role"
                                                            onSelectChange={(e) => {
                                                                var temp = [...departmentalContacts];
                                                                temp[index]['roles'][idx] = roles.find(ro => ro.value === e.value);
                                                                setDepartmentalContacts(temp);

                                                            }}
                                                            listOptions={roles.map(i => ({ value: i.value, label: i.value }))}
                                                        >Select role *</RequiredSelectWithLabel>
                                                    </div>
                                                    <div className="col-md-2 my-auto">
                                                        <button className='btn btn-primary btn-sm btn-danger btn-remove-language' onClick={(e) => {
                                                            e.preventDefault();
                                                            const values = [...departmentalContacts[index].roles];
                                                            values.splice(idx, 1);
                                                            var temp = [...departmentalContacts]
                                                            temp[index]['roles'] = values
                                                            setDepartmentalContacts(temp);
                                                        }}>
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>) : <></>}
                                        <div className="d-flex justify-content-center">
                                            <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={() => {
                                                var temp = [...departmentalContacts]
                                                if (!Array.isArray(temp[index]['roles'])) {
                                                    temp[index]['roles'] = [];
                                                }
                                                temp[index]['roles'].push(
                                                    {
                                                        "value": null,
                                                        "language": null
                                                    }
                                                )
                                                setDepartmentalContacts(temp)
                                            }}>
                                                <i className="fa fa-plus mr-2"></i>
                                                Add new role</button>
                                        </div>
                                    </fieldset>

                                </div>)}
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={() => {
                                var temp = [...departmentalContacts]
                                temp.push({
                                    "title": null,
                                    "roles": [{
                                        "value": null,
                                        "language": null
                                    }],
                                    "givenName": null,
                                    "familyName": null,
                                    "email": null,
                                    "phone": null,
                                    "address": null,
                                    "url": null,
                                    "department": {
                                        "name": null
                                    }
                                })
                                setDepartmentalContacts(temp)
                            }}>
                                <i className="fa fa-plus mr-2"></i>
                                Add new Contact</button>
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </form >

    );




}
export default EditContactDetails
