export default function StudentAffirmationComponentTranslation(fieldName, langcode = "en") {

    const fields = {
        h3_title: {
            en: {
                label: "Affirmation and consent statement",
            },
            gr: {
                label: "Υπεύθυνη δήλωση και δήλωση συναίνεσης",
            }
        }
    };

    return fields[fieldName][langcode];

}