import { reducer } from '../reducers/studiesFiltersReducer'
import { useReducer, useCallback } from "react";

import {
    SET_ID_SELECTED_ACTION,
    SET_STATUS_SELECTED_ACTION,
    SET_DATE_SELECTED_ACTION,
    SET_FULLNAME_SELECTED_ACTION,
    SET_MOBILITY_SELECTED_ACTION,
    SET_TYPE_SELECTED_ACTION,
    SET_FULLNAME_GREEK_SELECTED_ACTION,
} from '../reducers/studiesFiltersReducer'

export const defaultState = {
    idSelected: "",
    statusSelected: "",
    dateSelected: "",
    typeSelected: "",
    fullNameSelected: "",
    mobilitySelected: ""
}

export const useStudiesFilters = (initialState = defaultState) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setIdSelected = useCallback((data) => {
        dispatch({ type: SET_ID_SELECTED_ACTION, payload: { idSelected: data } })
    })

    const setStatusSelected = useCallback((data) => {
        dispatch({ type: SET_STATUS_SELECTED_ACTION, payload: { statusSelected: data } })
    })

    const setDateSelected = useCallback((data) => {
        dispatch({ type: SET_DATE_SELECTED_ACTION, payload: { dateSelected: data } })
    })

    const setTypeSelected = useCallback((data) => {
        dispatch({ type: SET_TYPE_SELECTED_ACTION, payload: { typeSelected: data } })
    })

    const setFullNameSelected = useCallback((data) => {
        dispatch({ type: SET_FULLNAME_SELECTED_ACTION, payload: { fullNameSelected: data } })
    })

    const setFullNameGreekSelected = useCallback((data) => {
        dispatch({ type: SET_FULLNAME_GREEK_SELECTED_ACTION, payload: { fullNameGreekSelected: data } })
    })

    const setMobilitySelected = useCallback((data) => {
        dispatch({ type: SET_MOBILITY_SELECTED_ACTION, payload: { mobilitySelected: data } })
    })

    return {
        state,
        setIdSelected,
        setStatusSelected,
        setDateSelected,
        setTypeSelected,
        setFullNameSelected,
        setMobilitySelected,
        setFullNameGreekSelected,
    }
}