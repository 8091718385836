import { useRef, useState, useEffect } from 'react';
import { ReactDataContext } from '@themost/react';
import ActiveFactsheet from './components/ActiveFactsheet';
import LocalFactsheets from './components/LocalFactsheets';
import PartnerFactsheets from './components/PartnerFactsheets';
import Toast from 'react-bootstrap/Toast';


const FactsheetsList = ({ userService, title }) => {
    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(userService.getToken());
    
    const [localNonActiveFactsheets, setLocalNonActiveFactsheets] = useState([]);
    const [activeLocalFactsheet, setActiveLocalFactsheet] = useState(null);
    const [totalLocalNonActiveFactsheets, setTotalNonActiveFactsheets] = useState(0);
    const [page, setPage] = useState(0);

    const [showActivationToast, setShowActivationToast] = useState(false);
    const [showDuplicationToast, setShowDuplicationToast] = useState(false);

    const mountedPage = useRef(false);
    const pageLimit = useRef(3);

    useEffect(() => {
        document.title = title;
    }, [])

    const handleActivation = async (factsheetIdToBeActive) => {
        const factsheetToBeActive = localNonActiveFactsheets.find(factsheet => factsheet.id === factsheetIdToBeActive);

        const newLocalNonActiveFactsheets = localNonActiveFactsheets.filter(factsheet => factsheet.id !== factsheetIdToBeActive);
        newLocalNonActiveFactsheets.push(activeLocalFactsheet);
        
        await context.model('MobilityFactsheets').save({ id: factsheetIdToBeActive, isActive: true });
        await context.model('MobilityFactsheets').save({ id: activeLocalFactsheet.id, isActive: false });

        setShowActivationToast(true);
        setActiveLocalFactsheet(factsheetToBeActive);
        setLocalNonActiveFactsheets(newLocalNonActiveFactsheets);
    }

    const handleDuplication = (localFactsheetToDuplicateId) => {
        return async (event) => {
            event.preventDefault();

            let query = context.model('MobilityFactsheets').asQueryable();

            let localFactsheetToDuplicate = await query.where('id').equal(localFactsheetToDuplicateId).getList();
            localFactsheetToDuplicate = localFactsheetToDuplicate.value[0];

            delete localFactsheetToDuplicate.id;
        
            if(localFactsheetToDuplicate.isActive) {
                localFactsheetToDuplicate.isActive = false; // user selected to duplicate the current active factsheet
            }

            await context.model("MobilityFactsheets").save(localFactsheetToDuplicate);

            // refetch the local non active factsheets
            query = context.model('MobilityFactsheets').asQueryable();
            try {
                const localNonActiveFactsheetsResult = await query.where('isActive').equal('false').where('institution/isLocal').equal('true').select('institution/erasmusCode','institutionalCoordinatorContact/familyName', 'institutionalCoordinatorContact/givenName', 'isActive', 'id').take(pageLimit.current).getList();

                setLocalNonActiveFactsheets(localNonActiveFactsheetsResult.value);
                setTotalNonActiveFactsheets(localNonActiveFactsheetsResult.total);
                setPage(0);
                setShowDuplicationToast(true);
            } catch(err) {
                console.log(err);
            }
        }
    }

    const fetchAndSetFactsheets = async (pageNumber) => {
        let query = context.model('MobilityFactsheets').asQueryable();

        try {
            const localNonActiveFactsheetsResult = await query.where('isActive').equal('false').where('institution/isLocal').equal('true').select('institution/erasmusCode','institutionalCoordinatorContact/familyName', 'institutionalCoordinatorContact/givenName', 'isActive', 'id').take(pageLimit.current).skip(page * pageLimit.current).getList();
            query = context.model('MobilityFactsheets').asQueryable();
            const activeFactsheetResult = await query.where('institution/isLocal').equal('true').where('isActive').equal('true').select('institution/erasmusCode','institutionalCoordinatorContact/familyName', 'institutionalCoordinatorContact/givenName', 'isActive', 'id').take(1).getList();

            setActiveLocalFactsheet(activeFactsheetResult.value[0])
            setLocalNonActiveFactsheets(localNonActiveFactsheetsResult.value);
            setTotalNonActiveFactsheets(localNonActiveFactsheetsResult.total);
            setPage(pageNumber);
        } catch(err) {
            console.log(err);
        }
    }

    // runs on first mount
    useEffect(() => {
        (async () => {
            try {
                fetchAndSetFactsheets(0);
            } catch(err) {
                console.log(err);
            }
        })()
    }, [])

    // runs when the user changes page
    useEffect(() => {
        if(mountedPage.current === false) {
            mountedPage.current = true;
            return;
        }
    
        (async () => {
            fetchAndSetFactsheets(page)
        })()
    }, [page]);

    return (
        <>
            <div style={{ position: 'fixed', bottom: '3em', left: '3em', width: '50%', zIndex: 1 }}>
                <Toast autohide delay={3000} show={showDuplicationToast} onClose={() => setShowDuplicationToast(false)}>
                    <Toast.Header>
                        <img
                            src="/favicon.ico"
                            className="mb-1"
                            alt="mobisis logo"
                        />
                        <p className="d-inline-block ml-1 mr-auto mb-1 align-middle">Just now</p>
                    </Toast.Header>
                    <Toast.Body>Successfully duplicated factsheet</Toast.Body>
                </Toast>
            </div>
            <div style={{ position: 'fixed', bottom: '3em', left: '3em', width: '50%', zIndex: 1 }}>
                <Toast autohide delay={3000} show={showActivationToast} onClose={() => setShowActivationToast(false)}>
                    <Toast.Header>
                        <img
                            src="/favicon.ico"
                            className="mb-1"
                            alt="mobisis logo"
                        />
                        <p className="d-inline-block ml-1 mr-auto mb-1 align-middle">Just now</p>
                    </Toast.Header>
                    <Toast.Body>Successfully activated factsheet</Toast.Body>
                </Toast>
            </div>
            <div className="py-3">
                <div className="container">
                    <div className="col-md-10 mx-auto">
                        <ActiveFactsheet activeFactsheet={activeLocalFactsheet} handleDuplication={handleDuplication} />
                        <LocalFactsheets
                            pageLimit={pageLimit.current}
                            page={page} 
                            setPage={setPage}
                            totalLocalNonActiveFactsheets={totalLocalNonActiveFactsheets} 
                            localFactsheets={localNonActiveFactsheets}
                            handleActivation={handleActivation}
                            handleDuplication={handleDuplication}
                        />
                        <PartnerFactsheets userService={userService} />
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default FactsheetsList;