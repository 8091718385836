export const SET_PAGE_ACTION = "SET_PAGE_ACTION";
export const UPDATE_MULTIPLE_VALUES_ACTION = "UPDATE_MULTIPLE_VALUES_ACTION";
export const UPDATE_STUDIES_ACTION = "UPDATE_STUDIES_ACTION";
export const SET_TOTAL_ACTION = "SET_TOTAL_ACTION"

export const reducer = (state, action) => {
    return {
        ...state,
        ...action.payload
    }
}