import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import {
    Text
} from '@react-pdf/renderer';


const AdditionalRequirements = ({ institution, additionalRequirementsTable, styles }) => {
    return (
        <>
            <Text style={styles.h4}>{institution}:{"\n"}</Text>
            <Table
                data={
                    additionalRequirementsTable && additionalRequirementsTable.map(a => ({
                        requirement: a.name,
                        detail: a.description,
                        url: a.urls && a.urls.map(i => (i.value + "\n\n")).join().replaceAll(',', ' \n')
                    }))}>
                <TableHeader >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Requirement
                    </TableCell >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Details
                    </TableCell >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Website for information (if applicable)
                    </TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.requirement} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.detail} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.url} />
                </TableBody>
            </Table>
            <Text>{"\n"}</Text>
            <Text>{"\n"}</Text>
        </>

    );
};



export default AdditionalRequirements;