import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import {getDate} from 'components/Common/CommonFunctions';

const Signing = ({ institutions, signings }) => {

    let signings_filtered = []
    signings.forEach((element) => {
        if (element.signer) {
            signings_filtered.push(element)
        }
    })

    return (
        <Table data={institutions.map(i => {
            let signing
            if (signings_filtered) {
                signing = signings_filtered.find(f => f.institution.id === i.id);
            }
            return ({ receiving: i.erasmusCode, person: signing && (signing.signer.familyName + " " + signing.signer.givenName + "\n email: " + signing.signer.email + "\n\n").toString(), 
            date: signing && signing.signingDate ? getDate(signing.signingDate) : ""        }); })}>
            <TableHeader>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Institution
                    [Erasmus code or city]
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Name, function
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }} >
                    Date
                </TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.receiving} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.person} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.date} />
            </TableBody>
        </Table>

    );
};






export default Signing;