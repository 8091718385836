export default function StudentDataComponentTranslation(fieldName, langcode = "en") {

    const fields = {
        nameGreek: {
            en: {
                label: "First Name",
                placeholder: "Enter name"
            },
            gr: {
                label: "Όνομα",
                placeholder: "Εισάγετε Όνομα"
            }
        },
        familyNameGreek: {
            en: {
                label: "Last Name",
                placeholder: "Enter last name"
            },
            gr: {
                label: "Επίθετο",
                placeholder: "Εισάγετε Επίθετο"
            }
        },
        nameLatin: {
            en: {
                label: "First Name with latin characters",
                placeholder: "Enter name"
            },
            gr: {
                label: "Όνομα με λατινικούς χαρακτήρες",
                placeholder: "Εισάγετε Όνομα"
            }
        },
        familyNameLatin: {
            en: {
                label: "Last Name with latin characters",
                placeholder: "Enter last name"
            },
            gr: {
                label: "Επίθετο με λατινικούς χαρακτήρες",
                placeholder: "Εισάγετε Επίθετο"
            }
        },
        gender: {
            en: {
                label: "Gender *",
                placeholder: "Enter gender"
            },
            gr: {
                label: "Φύλο *",
                placeholder: "Εισάγετε φύλο"
            }
        },
        birthDate: {
            en: {
                label: "Date of birth *",
                placeholder: "Enter date of birth"
            },
            gr: {
                label: "Ημερομηνία γέννησης *",
                placeholder: "Εισάγετε ημερομηνία γέννησης"
            }
        },
        birthCountry: {
            en: {
                label: "Country of birth *",
                placeholder: "Enter country of birth"
            },
            gr: {
                label: "Χώρα γέννησης *",
                placeholder: "Εισάγετε χώρα γέννησης"
            }
        },
        placeOfBirth: {
            en: {
                label: "Place of birth *",
                placeholder: "Enter place of birth",
            },
            gr: {
                label: "Τόπος γέννησης *",
                placeholder: "Εισάγετε τόπο γέννησης"
            }
        },
        nationality: {
            en: {
                label: "Nationality *",
                placeholder: "Enter nationality"
            },
            gr: {
                label: "Εθνικότητα *",
                placeholder: "Εισάγετε εθνικότητα"
            }
        },
        citizenship: {
            en: {
                label: "Citizenship *",
                placeholder: "Enter citizenship"
            },
            gr: {
                label: "Υπηκοότητα *",
                placeholder: "Εισάγετε υπηκοότητα"
            }
        },
        identityNumber: {
            en: {
                label: "Identity number *",
                placeholder: "Enter identity number"
            },
            gr: {
                label: "Αριθμός ταυτότητας ή Διαβατηρίου *",
                placeholder: "Εισάγετε αριθμό ταυτότητας ή διαβατηρίου"
            }
        },
        identificationIssueDate: {
            en: {
                label: "Date of issue *",
                placeholder: "Enter date of issue"
            },
            gr: {
                label: "Ημερομηνία έκδοσης *",
                placeholder: "Εισάγετε ημερομηνία έκδοσης"
            }
        },
        identityAuthority: {
            en: {
                label: "Issuing Authority *",
                placeholder: "Enter issuing authority"
            },
            gr: {
                label: "Αρχή Έκδοσης *",
                placeholder: "Εισάγετε αρχή έκδοσης"
            }
        },
        fathersName: {
            en: {
                label: "Father's name *",
                placeholder: "Enter father's name"
            },
            gr: {
                label: "Όνομα πατέρα *",
                placeholder: "Εισάγετε όνομα πατέρα"
            }
        },
        mothersName: {
            en: {
                label: "Mother's name *",
                placeholder: "Enter mother's name"
            },
            gr: {
                label: "Όνομα μητέρας *",
                placeholder: "Εισάγετε όνομα μητέρας"
            }
        },
        publicFinanceService: {
            en: {
                label: "Tax Office *",
                placeholder: "Enter the Tax Office you belong to"
            },
            gr: {
                label: "ΔΟΥ *",
                placeholder: "Εισάγετε ΔΟΥ"
            }
        },
        taxIdentificationNumber: {
            en: {
                label: "Tax Identification Number *",
                placeholder: "Enter tax identification number"
            },
            gr: {
                label: "Α.Φ.Μ *",
                placeholder: "Εισάγετε Α.Φ.Μ"
            }
        },
        socialSecurityRegistrationNumber: {
            en: {
                label: "Social Security Registration Number *",
                placeholder: "Enter social security registration number"
            },
            gr: {
                label: "Α.Μ.Κ.Α *",
                placeholder: "Εισάγετε Α.Μ.Κ.Α"
            }
        },
        homePhoneNumber: {
            en: {
                label: "Home Phone Number *",
                placeholder: "Enter home phone number"
            },
            gr: {
                label: "Σταθερό Τηλέφωνο *",
                placeholder: "Εισάγετε σταθερό τηλέφωνο"
            }
        },
        mobilePhoneNumber: {
            en: {
                label: "Mobile Phone Number *",
                placeholder: "Enter mobile phone number"
            },
            gr: {
                label: "Κινητό τηλέφωνο *",
                placeholder: "Εισάγετε κινητό τηλέφωνο"
            }
        },
        email: {
            en: {
                label: "Email *",
                placeholder: "Enter email"
            },
            gr: {
                label: "Email *",
                placeholder: "Εισάγετε email"
            }
        },
        street: {
            en: {
                label: "Street *",
                placeholder: "Enter street"
            },
            gr: {
                label: "Οδός *",
                placeholder: "Εισάγετε οδό"
            }
        },
        postalCode: {
            en: {
                label: "Postal code *",
                placeholder: "Enter postal code"
            },
            gr: {
                label: "Ταχυδρομικός Κωδικός *",
                placeholder: "Εισάγετε ταχυδρομικό κωδικό"
            }
        },
        city: {
            en: {
                label: "City *",
                placeholder: "Enter city"
            },
            gr: {
                label: "Πόλη *",
                placeholder: "Εισάγετε πόλη"
            }
        },
        country: {
            en: {
                label: "Country *",
                placeholder: "Enter country"
            },
            gr: {
                label: "Χώρα *",
                placeholder: "Εισάγετε χώρα"
            }
        },
        phoneNumber: {
            en: {
                label: "Phone number *",
                placeholder: "Enter phone number"
            },
            gr: {
                label: "Τηλέφωνο *",
                placeholder: "Εισάγετε τηλέφωνο"
            }
        },
        currentStreet: {
            en: {
                label: "Street",
                placeholder: "Enter street"
            },
            gr: {
                label: "Οδός",
                placeholder: "Εισάγετε οδό"
            }
        },
        currentPostalCode: {
            en: {
                label: "Postal code",
                placeholder: "Enter postal code"
            },
            gr: {
                label: "Ταχυδρομικός Κωδικός",
                placeholder: "Εισάγετε ταχυδρομικό κωδικό"
            }
        },
        currentCity: {
            en: {
                label: "City",
                placeholder: "Enter city"
            },
            gr: {
                label: "Πόλη",
                placeholder: "Εισάγετε πόλη"
            }
        },
        currentCountry: {
            en: {
                label: "Country",
                placeholder: "Enter country"
            },
            gr: {
                label: "Χώρα",
                placeholder: "Εισάγετε χώρα"
            }
        },
        emergencyName: {
            en: {
                label: "Name *",
                placeholder: "Enter name"
            },
            gr: {
                label: "Όνομα *",
                placeholder: "Εισάγετε όνομα"
            }
        },
        emergencyEmail: {
            en: {
                label: "Email",
                placeholder: "Enter email"
            },
            gr: {
                label: "Email",
                placeholder: "Εισάγετε email"
            }
        },
        emergencyPhone: {
            en: {
                label: "Phone number *",
                placeholder: "Enter phone number"
            },
            gr: {
                label: "Τηλέφωνο *",
                placeholder: "Εισάγετε τηλέφωνο"
            }
        },
        h3_title: {
            en: {
                label: "Personal Data",
            },
            gr: {
                label: "Προσωπικά Δεδομένα",
            }
        },
        h5_personalData: {
            en: {
                label: "Personal information",
            },
            gr: {
                label: "Προσωπικά Στοιχεία",
            }
        },
        h5_permanentAddress: {
            en: {
                label: "Permanent Address",
            },
            gr: {
                label: "Διεύθυνση μόνιμης κατοικίας",
            }
        },
        h5_currentAddress: {
            en: {
                label: "Current address (if different)",
            },
            gr: {
                label: "Τρέχουσα διεύθυνση (αν είναι διαφορετική)",
            }
        },
        h5_emergencyDetails: {
            en: {
                label: "Contact person in case of an emergency",
            },
            gr: {
                label: "Στοιχεία ατόμου σε περίπτωση έκτακτης ανάγκης",
            }
        },
        gender_options: {
            en: [{ label: 'Male' }, { label: 'Female' }, { label: 'Other' }],
            gr: [{ label: 'Άνδρας' }, { label: 'Γυναίκα' }, { label: 'Άλλο' }]

        }
    };

    return fields[fieldName][langcode];

}