import {splitCooperationConditionsOnSubjectArea} from 'components/Common/CommonFunctions'

/**
 *
 * @param previousValue
 * @param currentValue
 * @param index
 * @returns {*|string|string}
 */
const otherInfoTermsReducer = (previousValue, currentValue, index) => {
    return index === 0 ? (currentValue.otherInfoTerms !== null ? currentValue.otherInfoTerms : '') :
        previousValue + '' + (currentValue.otherInfoTerms !== null ? currentValue.otherInfoTerms : '');
};

/**
 *
 * @param studentMobilities
 * @param localInstitutionErasmusCode
 * @returns {JSX.Element}
 * @constructor
 */
const StudentMobilities = ({ studentMobilities, localInstitutionErasmusCode, type }) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h6 className='mt-2'><b>Student Mobility for {type}</b> </h6>
                    <table className="table-responsive mt-2 mb-2">
                        <tbody>
                            <tr style={{ backgroundColor: "#3A435E", color: "white" }} className="customtable" key={'table-b-student-mobility-id-row1'}>
                                <td colSpan="2" align="center"><b>FROM</b></td>
                                <td colSpan="2" align="center"><b>TO</b></td>
                                <td rowSpan="2" align="center"><b>Subject area </b><br />(optional)<br />[ISCED]</td>
                                <td rowSpan="2" align="center"><b>Field of Education - Clarification</b><br />(optional)<br /></td>
                                <td rowSpan="2" align="center"><b>Study cycle</b> <br />[short cycle, 1st , 2nd or 3rd]</td>
                                <td rowSpan="2" align="center"><b>Other Info Terms</b></td>
                                <td rowSpan="2" align="center"><b>Blended (*)</b> </td>
                                <td colSpan="2" align="center"><b>Number of student mobility periods</b></td>
                            </tr>
                            <tr style={{ backgroundColor: "#3A435E", color: "white" }} className="customtable" key={'table-b-student-mobility-id-row2'}>
                                <td align="center"><b>Institution</b> <br /> [Erasmus code] </td>
                                <td align="center"><b>Department</b></td>
                                <td align="center"><b>Institution</b> <br /> [Erasmus code] </td>
                                <td align="center"><b>Department</b> </td>
                                <td align="center"><b>Total number of students per year</b></td>
                                <td align="center"><b>Total number of months</b></td>
                            </tr>
                            {studentMobilities && splitCooperationConditionsOnSubjectArea(studentMobilities).map((m,i) => <StudentMobility studentMobility={m} index={i} />)}
                        </tbody>
                    </table>

                    {/*otherInfoTerms*/}
                    {/* <div style={{ textAlign: "justify" }}>
                        <div><h5>Other info terms:</h5></div>
                        <div>
                            {studentMobilities && studentMobilities.reduce(otherInfoTermsReducer, '') === '' && '-'}
                            {studentMobilities && studentMobilities.reduce(otherInfoTermsReducer, '') !== '' && studentMobilities.map(m => <OtherInfoTerms studentMobility={m} />)}
                        </div>
                    </div> */}
                    {/* <br />
                    <input type="checkbox" readOnly disabled checked={studentMobilities && studentMobilities.find(m => m.from.erasmusCode === localInstitutionErasmusCode)
                        && studentMobilities.find(m => m.from.erasmusCode === localInstitutionErasmusCode).blended && true} />&nbsp; &nbsp; Blended mobility option for students (*)
                    <br /> */}

                </div>
            </div>
        </div>
    );
};

/**
 *
 * @param studentMobility
 * @returns {JSX.Element}
 * @constructor
 */
const OtherInfoTerms = ({ studentMobility }) => {
    return (
        <>{studentMobility.otherInfoTerms && <p>{studentMobility.otherInfoTerms}</p>}</>
    );
}

/**
 *
 * @param studentMobility
 * @returns {JSX.Element}
 * @constructor
 */
const StudentMobility = ({ studentMobility,index}) => {
    return (

        <tr key={'table-b-student-mobility-id-' + studentMobility.id+index}>
            <td>{studentMobility.fromInstitution.erasmusCode}</td>
            <td>{studentMobility?.fromDepartment?.name}</td>
            <td>{studentMobility.toInstitution.erasmusCode}</td>
            <td>{studentMobility?.toDepartment?.name}</td>
            <td>{studentMobility?.subjectAreaWithClarification?.subjectArea?.name} [{studentMobility?.subjectAreaWithClarification?.subjectArea?.code}]</td>
            <td>{studentMobility?.subjectAreaWithClarification?.clarification}</td>
            <td>{studentMobility.studyCycles.map((d, j) => {
                if (j + 1 === studentMobility.studyCycles.length) {
                    // Last one.
                    return <>{d.studyCycle}</>;
                } else {
                    // Not last one.
                    return <>{d.studyCycle}, </>;
                }
            })}
            </td>
            <td>{studentMobility.otherInfoTerms}</td>
            <td>{studentMobility.blended === true ? "Yes" : "No"}</td>
            <td>{studentMobility.mobilitiesPerYear} </td>
            <td>{studentMobility.totalMonthsPerYear}</td>
            
        </tr>
    );
}

export default StudentMobilities;