import { useRef, Fragment } from 'react';
import EditSingleAdditionalInformation from './EditSingleAdditionalInformation';

const EditAdditionalInformation = ({ children, handleStep, handleChange, data }) => {
    const language = useRef('en');

    const handleSubmit = (e) => {
        e.preventDefault();
        handleStep();
    }

    const handleLocalChange = (index, propertyName, newValue) => {
        const newArray = Array.from(data.additionalInfos);
        newArray.splice(index, 1, { ...data.additionalInfos[index], [propertyName]: newValue })

        handleChange('additionalInfos', newArray);
    }

    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={handleSubmit}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                        <h3 className="text-center">Additional Information</h3>
                            {data.additionalInfos.map((additionalInfo, idx) => {
                                return (
                                    <Fragment key={`${idx}`} >
                                        <div className="d-flex justify-content-around">
                                            <h5 className="text-center">{`Additional information ${idx+1}`}</h5>
                                            <button className='btn btn-danger btn-sm mb-2' onClick={(e) => {
                                                e.preventDefault()
                                                const newArray = Array.from(data.additionalInfos);
                                                newArray.splice(idx, 1)
                                                
                                                handleChange('additionalInfos', newArray);
                                            }}>
                                                <i className="fa fa-trash mr-2"></i>
                                                Remove additional information
                                            </button>
                                        </div>
                                        <EditSingleAdditionalInformation additionalInfo={additionalInfo} index={idx} handleChange={handleLocalChange} language={language.current}/>
                                        <hr />
                                    </Fragment>
                                );
                            })}
                            <div className="d-flex justify-content-center">
                                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={(e) => {
                                    e.preventDefault()
                                    handleChange('additionalInfos', [...data.additionalInfos, {
                                        type: '', 
                                        info: { 
                                            phone: '', 
                                            email: '', 
                                            urls: [
                                                { 
                                                    url: '', 
                                                    language: 'en' 
                                                }
                                            ] 
                                        }
                                    }])
                                }}>
                                    <i className="fa fa-plus mr-2"></i>
                                    Add additional information
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                {
                    { ...children }
                }
            </div>
        </form>
    );
}

export default EditAdditionalInformation; 