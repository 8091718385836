import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import {
    Text
} from '@react-pdf/renderer';


const Accessibility = ({ institution, accessibilitiesTable, styles }) => {
    return (
        <>
            <Text style={styles.h4}>{institution}:{"\n"}</Text>
            <Table
                data={
                    accessibilitiesTable.map(accessibility => ({
                        type: accessibility.type,
                        service: accessibility.services,
                        description: accessibility.description,
                        contact: accessibility.info && ('Email:' + accessibility.info.email +
                            '\n' + 'Phone: ' + accessibility.info.phone),
                        url: accessibility.info && accessibility.info.urls.map(u => (u.value)).join().replaceAll(',', ' \n')

                    }))}>
                <TableHeader >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Type
                    </TableCell >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Available support services for
                        people with
                    </TableCell >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Description of support
                        services (optional)
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Contact details
                        (email, phone)
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Website for information
                    </TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.type} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.service} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.description} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.contact} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.url} />
                </TableBody>
            </Table>
            <Text>{"\n"}</Text>
            <Text>{"\n"}</Text>
        </>

    );
};



export default Accessibility;