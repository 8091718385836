export const SET_ID_SELECTED_ACTION = "SET_ID_SELECTED";
export const SET_STATUS_SELECTED_ACTION = "SET_STATUS_SELECTED";
export const SET_DATE_SELECTED_ACTION = "SET_DATE_SELECTED";
export const SET_FULLNAME_SELECTED_ACTION = "SET_FULLNAME_SELECTED";
export const SET_FULLNAME_GREEK_SELECTED_ACTION = "SET_FULLNAME_GREEK_SELECTED";
export const SET_TYPE_SELECTED_ACTION = "SET_TYPE_SELECTED";
export const SET_MOBILITY_SELECTED_ACTION = "SET_MOBILITY_SELECTED";

export const reducer = (state, action) => {
    return {
        ...state,
        ...action.payload,
    }
}