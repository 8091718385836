import {
    Text
} from '@react-pdf/renderer';


const DecisionLimitAndTor = ({ factsheets, styles }) => (
    factsheets.map(f => (
        <Text>
            <Text style={styles.h3}>{f.institution.name}:</Text>
                {"\n"}
                {f.decisionResponseWeeksLimit!==null ? <Text style={styles.text}>
                    - The institution will send its decision within {f.decisionResponseWeeksLimit} weeks, and no later than 5 weeks.
                    {"\n"}
                </Text>:<></>}
                {f.transcriptOfRecordsWeeksLimit!==null ? <Text style={styles.text}>
                    {"\n"}
                    - A Transcript of Records will be issued by the institution no later than {f.transcriptOfRecordsWeeksLimit} weeks after the assessment period has finished.                 {"\n"}
                    {"\n"}
                </Text>:<></>}
        </Text>
    ))

);



export default DecisionLimitAndTor;