import { useEffect, useState } from 'react'
import { NavDropdown } from 'react-bootstrap'
import logo from 'img/mobisis-logo-vector.png'
import { Link } from 'react-router-dom'
import Restricted from "PermissionProvider/Restricted";
import {applicationStudiesFormEnabled,getCookie,setCookie} from '../components/Common/CommonFunctions';
import { FALSE } from 'sass';

function Navbar(props) {
    const notAllowed = <></>
    const [openAgreements, setOpenAgreements] = useState(false)
    const [openECTSCoordinators, setOpenECTSCoordinators] = useState(false)
    const [openApplications, setOpenApplications] = useState(false)
    const [tooltip,setTooltip] = useState("");

    // const [lang, setLang] = useState(getCookie('lang') ? getCookie('lang') : "");
    return (
        <div>
            <nav className="navbar-expand-lg navbar-light app-header navbar mb-auto" style={{ position: "relative" }}>
                <div className="nav navbar-nav h-100">
                    <button appmobilesidebartoggler="" className="navbar-toggler d-lg-none" type="button">
                        <span className="navbar-toggler-icon" onClick={() => props.show()}></span>
                    </button>
                    <Link className="nav-item nav-link sis-brand px-3 d-block d-lg-none h-100" to="/">
                        <img alt="logo" className="sis-brand-logo mr-3 h-100" src={logo} />
                    </Link>
                    <Link className="nav-item nav-link sis-brand pl-3 d-none d-lg-block h-100" to="/">
                        <img alt="logo" className="sis-brand-logo mr-3 h-100" src={logo} />
                    </Link>
                </div>
                <ul className="nav navbar-nav d-md-down-none ml-auto">
                    <Restricted to="institution_list" fallback={notAllowed}>
                        <NavDropdown title={
                            <div>
                                <i className="fa fa-file-signature"></i> Institutions
                                <i className="ml-1 fa fa-angle-down"></i>
                            </div>
                        } id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/institutions">
                                <i className="fa fa-table mr-2"></i> List
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Restricted>

                    <Restricted to="department_list" fallback={notAllowed}>
                        <NavDropdown title={
                            <div>
                                <i className="fa fa-file-signature"></i> Departments
                                <i className="ml-1 fa fa-angle-down"></i>
                            </div>
                        } id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/departments">
                                <i className="fa fa-table mr-2"></i> List
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Restricted>

                    <NavDropdown title={
                        <div>
                            <i className="fa fa-file-signature"></i> Agreements
                            <i className="ml-1 fa fa-angle-down"></i>
                        </div>
                    } id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/iia/form">
                            <i className="fa fa-plus mr-2"></i> Create
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/">
                            <i className="fa fa-table mr-2"></i> List
                        </NavDropdown.Item>
                        <Restricted to="iia_edit" fallback={notAllowed}>
                            <NavDropdown.Item as={Link} to="/iia/export">
                                <i className="fa fa-file-excel-o mr-2"></i> Export to Excel
                            </NavDropdown.Item>
                        </Restricted>
                    </NavDropdown>

                    <NavDropdown title={
                        <div>
                            <i className="fa fa-file-signature"></i> Factsheets
                            <i className="ml-1 fa fa-angle-down"></i>
                        </div>
                    } id="basic-nav-dropdown">
                        {/* <NavDropdown.Item as={Link} to="/iia/form">
                            <i className="fa fa-plus mr-2"></i> Create
                        </NavDropdown.Item> */}
                        <NavDropdown.Item as={Link} to="/factsheets">
                            <i className="fa fa-table mr-2"></i> List
                        </NavDropdown.Item>
                        {/* <Restricted to="edit" fallback={notAllowed}>
                            <NavDropdown.Item as={Link} to="/iia/export">
                                <i className="fa fa-file-excel-o mr-2"></i>Export to Excel
                            </NavDropdown.Item>
                        </Restricted> */}
                    </NavDropdown>

                    <NavDropdown title={
                        <div>
                            <i className="fa fa-file-signature"></i> Application
                            <i className="ml-1 fa fa-angle-down"></i>
                        </div>
                    } id="basic-nav-dropdown" onClick={async()=>{
                        await applicationStudiesFormEnabled();
                    }}>
                        <div onMouseOver={() => {
                            if(getCookie("checkIfLongTermApplicationFormAcademicYearDisabled")==="true") {
                                console.log("checkIfLongTermApplicationFormAcademicYearDisabled")
                                setTooltip("checkIfLongTermApplicationFormAcademicYearDisabled");
                            }
                            if(getCookie("checkIfLongTermApplicationFormAcademicPeriodDisabled")==="true")
                            {
                                console.log("checkIfLongTermApplicationFormAcademicPeriodDisabled")
                                setTooltip("checkIfLongTermApplicationFormAcademicPeriodDisabled");   
                            }
                            }}
                            onMouseOut={() => setTooltip("")}>
                            <NavDropdown.Item as={Link} to="/application/outgoing/student/new" disabled={getCookie("checkIfLongTermApplicationFormAcademicYearDisabled")==="true" ||getCookie("checkIfLongTermApplicationFormAcademicPeriodDisabled")==="true"}>
                                <i className="fa fa-plus mr-2"></i> New student studies application
                                {tooltip.includes("checkIfLongTermApplicationForm") &&<span className='p-2 tooltip'>{tooltip==="checkIfLongTermApplicationFormAcademicYearDisabled"&&<>You already have an application for the period.</>}{tooltip==="checkIfLongTermApplicationFormAcademicPeriodDisabled"&&<>Applications are closed for the current period.</>}</span>}
                            </NavDropdown.Item>
                        </div>
                        <div onMouseOver={() => {
                            if(getCookie("checkIfShortTermApplicationFormAcademicYearDisabled")==="true") {
                                setTooltip("checkIfShortTermApplicationFormAcademicYearDisabled");
                                }
                                if(getCookie("checkIfShortTermApplicationFormAcademicPeriodDisabled")==="true")
                                {
                                 setTooltip("checkIfShortTermApplicationFormAcademicPeriodDisabled");   
                                }
                                }} onMouseOut={() => setTooltip("")}>
                            <NavDropdown.Item as={Link} to="/application/outgoing/short/student/new" disabled={getCookie("checkIfShortTermApplicationFormAcademicYearDisabled")==="true"||getCookie("checkIfShortTermApplicationFormAcademicPeriodDisabled")==="true"}>
                                <i className="fa fa-plus mr-2"></i> New short term application
                                {tooltip.includes("checkIfShortTermApplicationForm") &&<span className='p-2 tooltip'>{tooltip==="checkIfShortTermApplicationFormAcademicYearDisabled"&&<>You already have an application for the period.</>}{tooltip==="checkIfShortTermApplicationFormAcademicPeriodDisabled"&&<>Applications are closed for the current period.</>}</span>}
                            </NavDropdown.Item>
                        </div>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={Link} to="/application/outgoing/list">
                            <i className="fa fa-table mr-2"></i> List
                        </NavDropdown.Item>
                        {JSON.parse(localStorage.getItem("role")).role === "IRO" &&<><NavDropdown.Divider />
                        <NavDropdown.Item as={Link} to="/application/settings">
                            <i className="fa fa-cog mr-2"></i> Application Settings
                        </NavDropdown.Item>
                        </>}
                    </NavDropdown>

                    <Restricted to="ects_coordinators_view" fallback={notAllowed}>
                        <NavDropdown title={
                            <div>
                                <i className="fa fa-file-signature"></i> ECTS Coordinators
                                <i className="ml-1 fa fa-angle-down"></i>
                            </div>
                        } id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/ects-coordinators/form">
                                <i className="fa fa-plus mr-2"></i> Create
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/ects-coordinators">
                                <i className="fa fa-table mr-2"></i> List
                            </NavDropdown.Item>
                        </NavDropdown>

                    </Restricted>
                    <NavDropdown title={
                        <div>
                            Profile
                            <i className="ml-1 fa fa-angle-down"></i>
                        </div>
                    } id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/profile">
                            <i className="fa fa-user fa-fw mr-2"></i> View
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/profile/edit">
                            <i className="fa fa-pencil fa-fw mr-2"></i> Edit
                        </NavDropdown.Item>

                        <NavDropdown.Item onClick={() => {
                            props.userService.doLogout()
                        }}>
                            <i className="fa fa-sign-out mr-2"></i> Logout
                        </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title={
                        <div>
                            <i class="fa fa-globe"></i>
                            <i className="fa fa-file-signature"></i> {props.lang === "gr" ? "Greek" : props.lang === "en" ? "English" : "Language"}
                            <i className="ml-1 fa fa-angle-down"></i>
                        </div>
                    } id="basic-nav-dropdown">
                        <NavDropdown.Item id="gr" onClick={() => {
                            props.setLang("gr")
                            setCookie("lang", "gr")
                        }}
                        >
                            Greek
                        </NavDropdown.Item>
                        <NavDropdown.Item id="en" onClick={() => {
                            props.setLang("en")
                            setCookie("lang", "en")
                        }}
                        >
                            English
                        </NavDropdown.Item>
                    </NavDropdown>

                </ul>
            </nav>
            <div className="d-lg-none d-md-block sidebar h-100">
                <div className="sidebar-nav" >
                    <ul className="nav">
                        <li className="nav-title">Main Menu</li>

                        <li className={openAgreements ? "nav-item nav-dropdown open" : "nav-item nav-dropdown"} onClick={() => setOpenAgreements(!openAgreements)}>
                            <div className="nav-link nav-dropdown-toggle">
                                <i className="nav-icon icon-note"></i> Agreements
                            </div>
                            <ul className="nav-dropdown-items">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/iia/form">
                                        <i className="fa fa-plus mr-2"></i> Create
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">
                                        <i className="fa fa-table mr-2"></i> List
                                    </Link>
                                </li>
                                <Restricted to="iia_edit" fallback={notAllowed}>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/iia/export">
                                            <i className="fa fa-file-excel-o mr-2"></i> Export to Excel
                                        </Link>
                                    </li>
                                </Restricted>
                            </ul>
                        </li>
                        <Restricted to="ects_coordinators_view" fallback={notAllowed}>
                            <li className={openECTSCoordinators ? "nav-item nav-dropdown open" : "nav-item nav-dropdown"} onClick={() => setOpenECTSCoordinators(!openECTSCoordinators)}>
                                <div className="nav-link nav-dropdown-toggle">
                                    <i className="nav-icon icon-note"></i> ECTS Coordinators
                                </div>
                                <ul className="nav-dropdown-items">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/ects-coordinators/form">
                                            <i className="fa fa-plus mr-2"></i> Create
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/ects-coordinators">
                                            <i className="fa fa-table mr-2"></i> List
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/ects-coordinators">
                                            <div>ECTS Coordinators</div>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </Restricted>
                            <li className={openApplications ? "nav-item nav-dropdown open" : "nav-item nav-dropdown"} onClick={async() => {
                                await applicationStudiesFormEnabled();
                                setOpenApplications(!openApplications)
                                }}>
                                <div className="nav-link nav-dropdown-toggle">
                                    <i className="nav-icon icon-note"></i> Application
                                </div>
                                <ul className="nav-dropdown-items">
                                    <li className="nav-item" disabled={getCookie("checkIfLongTermApplicationFormAcademicYearDisabled")==="true" ||getCookie("checkIfLongTermApplicationFormAcademicPeriodDisabled")==="true"}>
                                        <Link className="nav-link" to="/application/outgoing/student/new">
                                            <i className="fa fa-plus mr-2"></i> New student studies application
                                        </Link>
                                    </li>
                                    <li className="nav-item" disabled={getCookie("checkIfShortTermApplicationFormAcademicYearDisabled")==="true"||getCookie("checkIfShortTermApplicationFormAcademicPeriodDisabled")==="true"}>
                                        <Link className="nav-link" to="/application/outgoing/short/student/new">
                                            <i className="fa fa-plus mr-2"></i> New short term application
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/application/outgoing/list">
                                            <i className="fa fa-table mr-2"></i> List
                                        </Link>
                                    </li>
                                    {JSON.parse(localStorage.getItem("role")).role === "IRO" &&  <li className="nav-item">
                                        <Link className="nav-link" to="/application/settings">
                                        <i className="fa fa-cog mr-2"></i> Application Settings
                                        </Link>
                              
                                    </li>}
                                </ul>
                            </li>
                        <li className="nav-divider"></li>
                        <li className="nav-title">Profile Menu</li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/profile">
                                <i className="fa fa-user fa-fw mr-2"></i> View
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/profile/edit">
                                <i className="fa fa-pencil fa-fw mr-2"></i> Edit
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/" className="nav-link" onClick={() => {
                                props.userService.doLogout()
                            }} >
                                <i className="fa fa-sign-out mr-2"></i> Logout </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default Navbar