import { Tab, Nav, Col, Row } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ApplicationSettingsFormTranslation from './translations/ApplicationSettingsFormTranslation';
import { TextInput } from 'components/UI/ComplexComponents/TextInput';
import { getCookie, parseDMY } from 'components/Common/CommonFunctions';
import { ReactDataContext } from '@themost/react';
import UserService from 'services/UserService';



export default function StudentStudiesList(props) {
    const [loader, setLoader] = useState(false);
    const [type, setType] = useState("long-term-studies");
    const [langCode, setLangCode] = useState(getCookie("lang") ? getCookie("lang") : "en")
    const [applicationData, setApplicationData] = useState();


    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(UserService.getToken());

    const fetchInitialData = async () => {

        try {
            setLoader(true);
            const applicationSettingsResponse = await context.model('ApplicationSettings').asQueryable().where("applicationType").equal(type).getItem();
            setApplicationData(applicationSettingsResponse);
            setLoader(false);
        } catch (err) {
            console.log(err)
        }

    }


    useEffect(() => {
        fetchInitialData();
    }, [type])


    function setFieldValue(setterFunc) {

        return function (item, value) {
            setterFunc({ ...applicationData, [item]: value })
        }
    }
    const renderSettingsForm = (type) => {
        return (
            <div className="containter">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h3 className="text-center">{ApplicationSettingsFormTranslation("applicationType" + type, langCode)['label']}</h3>
                            <div className="row">
                                <div className='col-md-10'>
                                    {
                                        TextInput({
                                            //   parentSetValueSelected: setFieldValue(setApplicationData),
                                            parentValueSelected: applicationData?.applicationCounter,
                                            inputLabel: ApplicationSettingsFormTranslation("applicationCounter", langCode)['label'],
                                            componentId: "applicationCounter",
                                            disabled: true,
                                            type: "text",

                                        })
                                    }
                                </div>
                                <div className="col-md-2 mt-auto mb-auto">
                                    <button className="btn btn-primary btn-next p-2"
                                        onClick={(e) => {
                                            setApplicationData({ ...applicationData, applicationCounter: 1 })
                                        }
                                        }>{ApplicationSettingsFormTranslation("resetCounter", langCode)['label']}</button>
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="identificationIssueDate">{ApplicationSettingsFormTranslation("applicationStart", langCode)['label']}</label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control" popperPlacement='bottom'
                                    selected={applicationData?.applicationStart ? applicationData?.applicationStart : null}
                                    onChange={(e) => {
                                        if (e !== null) {
                                            setApplicationData({ ...applicationData, applicationStart: e })
                                        }
                                        else {
                                            setApplicationData({ ...applicationData, applicationStart: null })
                                        }
                                    }} />


                            </div>

                            <div className="form-group">
                                <label for="identificationIssueDate">{ApplicationSettingsFormTranslation("applicationStop", langCode)['label']}</label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control" popperPlacement='bottom'
                                    selected={applicationData?.applicationStop ? applicationData?.applicationStop : null}
                                    onChange={(e) => {
                                        if (e !== null) {
                                            setApplicationData({ ...applicationData, applicationStop: e })
                                        }
                                        else {
                                            setApplicationData({ ...applicationData, applicationStop: null })
                                        }
                                    }} />


                            </div>

                            <div className="form-group">
                                <label for="identificationIssueDate">{ApplicationSettingsFormTranslation("applicationYear", langCode)['label']}</label>
                                <DatePicker
                                    dateFormat="yyyy"
                                    showYearPicker
                                    className="form-control" popperPlacement='bottom'
                                    selected={applicationData?.academicYear ? new Date(String(applicationData?.academicYear)) : null}
                                    onChange={(e) => {
                                        if (e !== null) {
                                            setApplicationData({ ...applicationData, academicYear: e.getFullYear() })
                                        }
                                        else {
                                            setApplicationData({ ...applicationData, academicYear: null })
                                        }
                                    }} />
                            </div>
                            <div className="d-flex justify-content-center">

                                <button className="btn btn-primary btn-success btn-submit m-2"
                                    onClick={async () => {
                                        try {
                                            const result = await context.model('ApplicationSettings').save(applicationData);
                                            alert("Application Settings Saved successfully")
                                        } catch (err) {
                                            console.log(err)
                                        }
                                    }
                                    }>{ApplicationSettingsFormTranslation("submit", langCode)['label']}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
    const renderSideNav = () => {
        return (<Nav variant="pills" className="flex-column" activeKey={type}>
            <Nav.Item>
                <Nav.Link
                    eventKey={"long-term-studies"}
                    onClick={() => setType("long-term-studies")}>
                    Long Term Student Studies Application
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    eventKey={"short-term-studies"}
                    onClick={() => setType("short-term-studies")}>
                    Short Term Student Studies Application

                </Nav.Link>
            </Nav.Item>

        </Nav >);
    }
    const switchCasesSteps = () => {
        switch (type) {
            case "long-term-studies":
                return <Tab.Pane eventKey={"long-term-studies"}>
                    {renderSettingsForm("Long")}
                </Tab.Pane>
            case "short-term-studies":
                return <Tab.Pane eventKey={"short-term-studies"}>
                    {renderSettingsForm("Short")}
                </Tab.Pane>

            default:
                return <h1>No content</h1>
        }
    }

    if (loader) {
        return (<div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Loader
                className="spinner mx-auto"
                type="TailSpin"
                color="#3A435E"
                height={70}
                width={70}
            />
        </div>);
    } else {
        return <Tab.Container id="left-tabs" activeKey={type}>
            <Row>
                <Col sm={3}>
                    {renderSideNav()}
                </Col>
                <Col sm={9}>
                    {switchCasesSteps()}
                </Col>
            </Row>
        </Tab.Container>
    }
}