import { useState, useEffect } from 'react'
import { ReactDataContext } from '@themost/react';
import { Alert } from 'react-bootstrap'
import UserService from "services/UserService";
import SelectWithLabel from 'components/UI/SelectWithLabel';

function Form(props) {
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [email, setEmail] = useState(null)
    const [address, setAddress] = useState(null)
    const [phone, setPhone] = useState(null)
    const [purpose, setPurpose] = useState(null)
    const [department, setDepartment] = useState(null)
    const [departments, setDepartments] = useState([])
    const [open, setOpen] = useState(false);

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(UserService.getToken());

    useEffect(() => {
        document.title = props.title;
    }, [])

    useEffect(async () => {

        try {
            const authDepartmentsResult = await context.model(`MobilityDepartments`).where('institution/isLocal').equal(true).take(-1).getItems();
            setDepartments(authDepartmentsResult)
        } catch (err) {
            console.log(err)
        }

    }, [])
    // Function For closing the alert snackbar
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };


    return (
        <div className="py-5">
            <div className="container">
                <div className="row">

                    <div className="ml-2 col-md-12 p-3 shadow">
                        <Alert
                            onClose={handleClose}
                            variant="success"
                            dismissible
                            show={open}>
                            <Alert.Heading>
                                Record saved successfully!
                            </Alert.Heading>
                        </Alert>
                        <h1 className="">
                            <i className="fa fa-plus mr-2"> </i>Add ECTS Coordinator
                        </h1>

                        <form noValidate={false} onSubmit={(e) => {
                            e.preventDefault();

                            context.model('MobilityDepartments').save({
                                name: departments.find(i => i.id === department).name,
                                institution: 1
                            }).then(departmentResponse => {
                                context.model('ECTSCoordinatorTables').save({
                                    firstName: firstName,
                                    lastName: lastName,
                                    email: email,
                                    address: address,
                                    phone: phone,
                                    purpose: purpose,
                                    department: departmentResponse
                                }).then((res) => {
                                    // a new user has been created
                                    // so return token info
                                    setOpen(true)
                                }).catch(err => console.log(err))
                            }).catch(err => console.log(err))
                        }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        {" "}
                                        <SelectWithLabel
                                            value={department ? departments.find(i => i.id === department).name : ""}
                                            onSelectChange={(e) => {
                                                if (e != null) {
                                                    setDepartment(e.value)
                                                }
                                                else {
                                                    setDepartment(null)
                                                }
                                            }}
                                            id="department"
                                            placeholderText="Type department"
                                            clearable={true}
                                            listOptions={departments.map(i => ({ value: i.id, label: i.name }))}
                                        >
                                            Department *
                                        </SelectWithLabel>

                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        {" "}
                                        <label>First Name *</label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={firstName}
                                            required
                                            placeholder="(empty)"
                                            onChange={(e) => { setFirstName(e.target.value) }
                                            }
                                        />{" "}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        {" "}
                                        <label>Last Name *</label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={lastName}
                                            required
                                            placeholder="(empty)"
                                            onChange={(e) => { setLastName(e.target.value) }
                                            }
                                        />{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email *</label>

                                        <input
                                            type="text"
                                            className="form-control"
                                            value={email}
                                            required
                                            placeholder="(empty)"
                                            onChange={(e) => { setEmail(e.target.value) }
                                            }
                                        />{" "}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Phone Number *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={phone}
                                            required
                                            placeholder="(empty)"
                                            onChange={(e) => {
                                                setPhone(e.target.value)
                                            }}
                                        />{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        {" "}
                                        <label>Address *</label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            required
                                            value={address}
                                            placeholder="(empty)"
                                            onChange={(e) => { setAddress(e.target.value) }
                                            }
                                        />{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        {" "}
                                        <label>Purpose *</label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={purpose}
                                            required
                                            placeholder="(empty)"
                                            onChange={(e) => {
                                                setPurpose(e.target.value)
                                            }
                                            }
                                        />{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button
                                    className="btn btn-primary btn-next"
                                    type="submit"
                                >
                                    <i className='fa fa-save mr-2'></i>SAVE
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>)
}

export default Form
