import { useEffect, useState } from "react";
import { ReactDataContext } from '@themost/react';

export const fallbackDepartment = {
    institution: {
        name: null,
        erasmusCode: null,
        heiId: null,
        cityName: null,
        isLocal: null,
        country: null
    },
    url: null,
    domain: null,
    name: null
};

const CoordinatorDepartment = ({ userService, number, department, editable, handleTdChange }) => { 
    let institution, url, domain, name;
    if(department)
        ({ url, domain, name } = department);  
    else {
        ({ url, domain, name } = fallbackDepartment); 
    }

    if(department?.institution) {
        ({ institution } = department);
    }  else {
        ({ institution } = fallbackDepartment);
    }

    const [countryName, setCountryName] = useState("-");

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(userService.getToken());

    useEffect(() => {
        (async () => {
            try {
                const countryResult = await context.model(`MobilityCountries`).where('id').equal(institution?.country?.id).take(1).getItems();
                
                if(countryResult.length !== 0) {
                    setCountryName(countryResult[0].countryName);
                }
            } catch (err) {
                console.log(err);
            }
        })()
    }, []);

    return (
        <>
            <div>
                <h4>
                    {`${number}. Institutional Coordinator's Department`}
                </h4>
            </div>
            <div className="table-responsive">
                <table className="table cf" style={{ tableLayout: 'fixed'}}>
                    <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                        <tr className="customTable">
                            <th scope="col" className="text-center align-middle col-md-6" colSpan="6">Institution Information</th>
                            <th scope="col" className="text-center align-middle col-md-2" rowSpan="2">Name</th>
                            <th scope="col" className="text-center align-middle col-md-2" rowSpan="2">Domain</th>
                            <th scope="col" className="text-center align-middle col-md-2" rowSpan="2">URL</th>
                        </tr>
                        <tr style={{ backgroundColor: "#3A435E", color: "white" }}>
                                <th className="text-center align-middle">Name</th>
                                <th className="text-center align-middle">Erasmus Code</th>
                                <th className="text-center align-middle">HEI Id</th>
                                <th className="text-center align-middle">City</th>
                                <th className="text-center align-middle">Local</th>
                                <th className="text-center align-middle">Country</th>                                
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {   
                                Object.keys(institution).map(key =>
                                    key !== 'id' &&
                                    <td 
                                        key={key} 
                                        className="text-center"
                                        style={{ wordWrap: 'break-word' }}
                                        contentEditable={editable}
                                        onBlur={e => {
                                            handleTdChange('department', {...fallbackDepartment, ...department, institution: { ...institution, [`${key}`]: e.target.innerText }})
                                        }}
                                    >
                                        { 
                                            key === 'country' ? (countryName)
                                            : 
                                                (
                                                    key === 'isLocal' ?
                                                    (
                                                        institution[key] === true ? 'Yes' 
                                                        : (institution[key] === false ? 'No' : '-') 
                                                    )
                                                    : (institution[key] ?? '-') 
                                                ) 
                                        }
                                    </td>
                                )
                            }
                            <td 
                                className="text-center"
                                style={{ wordWrap: 'break-word' }}
                                contentEditable={editable}
                                onBlur={e => {
                                    handleTdChange('department', {...fallbackDepartment, ...department, name: e.target.innerText})
                                }}
                            >
                                {name ?? '-'}
                            </td>
                            <td 
                                className="text-center"
                                style={{ wordWrap: 'break-word' }}
                                contentEditable={editable}
                                onBlur={e => {
                                    handleTdChange('department', {...fallbackDepartment, ...department, domain: e.target.innerText})
                                }}
                            >
                                {domain ?? '-'}
                            </td>
                            <td 
                                className="text-center"
                                style={{ wordWrap: 'break-word' }}
                                contentEditable={editable}
                                onBlur={e => {
                                    handleTdChange('department', {...fallbackDepartment, ...department, url: e.target.innerText})
                                }}
                            >
                                {url ?? '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default CoordinatorDepartment;