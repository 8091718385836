import { useEffect, useState } from "react";
import { TextInput } from "components/UI/ComplexComponents/TextInput";
import { DropdownSelect } from "components/UI/ComplexComponents/DropdownSelect";
import StudentPreviousMobilityComponentTranslation from "../translations/StudentPreviousMobilityComponentTranslation";
import { Form } from 'react-bootstrap'
import RequiredSelectWithLabel from "components/UI/RequiredSelectWithLabel";

function StudentPreviousMobilityComponent({ children, langCode, view, handleStep, studentStudiesForm, setStudentStudiesForm, saveDraft }) {
    const initialState = {
        previousMobilityStatus: false,
        erasmusStudiesStudyCycle: "",
        erasmusStudiesMobilityYear: "",
        erasmusStudiesMobilityDuration: "",
        erasmusStudiesMobilityInstitution: "",
        erasmusWorkshopStudyCycle: "",
        erasmusWorkshopMobilityYear: "",
        erasmusWorkshopMobilityDuration: "",
        erasmusWorkshopMobilityInstitution: "",
        specialNeedsCategory: false
    }
    const [previousMobility, setPreviousMobility] = useState(initialState)

    const [previousMobilityStatus, setPreviousMobilityStatus] = useState('')
    const [specialNeedsCategory, setSpecialNeedsCategory] = useState(false)
    const [erasmusStudiesForm, setErasmusStudiesForm] = useState(false)
    const [erasmusWorkshopForm, setErasmusWorkshopForm] = useState(false)

    const required = true;

    function setFieldValue(setterFunc) {

        return function (item, value) {
            setterFunc({ ...previousMobility, [item]: value })
        }
    }

    const renderCheckboxForms = (type) => {
        if (type === "erasmusStudies") {
            return (<div>
                {
                    DropdownSelect({
                        optionsList: StudentPreviousMobilityComponentTranslation("education_level_options", langCode),
                        parentSetValueSelected: setFieldValue(setPreviousMobility),
                        parentValueSelected: previousMobility.erasmusStudiesStudyCycle,
                        selectLabel: StudentPreviousMobilityComponentTranslation("studyCycle", langCode)['label'],
                        componentId: "erasmusStudiesStudyCycle",
                        disabled: view
                    })
                }
                {
                    TextInput({
                        parentSetValueSelected: setFieldValue(setPreviousMobility),
                        parentValueSelected: previousMobility.erasmusStudiesMobilityYear,
                        inputLabel: StudentPreviousMobilityComponentTranslation("mobilityYear", langCode)['label'],
                        componentId: "erasmusStudiesMobilityYear",
                        type: "text",
                        disabled: view
                    })
                }
                {
                    TextInput({
                        parentSetValueSelected: setFieldValue(setPreviousMobility),
                        parentValueSelected: previousMobility.erasmusStudiesMobilityDuration,
                        inputLabel: StudentPreviousMobilityComponentTranslation("mobilityDuration", langCode)['label'],
                        componentId: "erasmusStudiesMobilityDuration",
                        type: "text",
                        disabled: view
                    })
                }
                {
                    TextInput({
                        parentSetValueSelected: setFieldValue(setPreviousMobility),
                        parentValueSelected: previousMobility.erasmusStudiesMobilityInstitution,
                        inputLabel: StudentPreviousMobilityComponentTranslation("mobilityInstitution", langCode)['label'],
                        componentId: "erasmusStudiesMobilityInstitution",
                        type: "text",
                        disabled: view
                    })
                }
            </div>)
        } else {
            return (<div>
                {
                    DropdownSelect({
                        optionsList: StudentPreviousMobilityComponentTranslation("education_level_options", langCode),
                        parentSetValueSelected: setFieldValue(setPreviousMobility),
                        parentValueSelected: previousMobility.erasmusWorkshopStudyCycle,
                        selectLabel: StudentPreviousMobilityComponentTranslation("studyCycle", langCode)['label'],
                        componentId: "erasmusWorkshopStudyCycle",
                        disabled: view
                    })
                }
                {
                    TextInput({
                        parentSetValueSelected: setFieldValue(setPreviousMobility),
                        parentValueSelected: previousMobility.erasmusWorkshopMobilityYear,
                        inputLabel: StudentPreviousMobilityComponentTranslation("mobilityYear", langCode)['label'],
                        componentId: "erasmusWorkshopMobilityYear",
                        type: "text",
                        disabled: view
                    })
                }
                {
                    TextInput({
                        parentSetValueSelected: setFieldValue(setPreviousMobility),
                        parentValueSelected: previousMobility.erasmusWorkshopMobilityDuration,
                        inputLabel: StudentPreviousMobilityComponentTranslation("mobilityDuration", langCode)['label'],
                        componentId: "erasmusWorkshopMobilityDuration",
                        type: "text",
                        disabled: view
                    })
                }
                {
                    TextInput({
                        parentSetValueSelected: setFieldValue(setPreviousMobility),
                        parentValueSelected: previousMobility.erasmusWorkshopMobilityInstitution,
                        inputLabel: StudentPreviousMobilityComponentTranslation("mobilityInstitution", langCode)['label'],
                        componentId: "erasmusWorkshopMobilityInstitution",
                        type: "text",
                        disabled: view
                    })
                }
            </div>)
        }
    }

    useEffect(() => {
        let specialCategory = false;
        if (studentStudiesForm?.specialNeedsCategory != undefined) {
            specialCategory = JSON.parse(JSON.stringify(studentStudiesForm?.specialNeedsCategory))
            setSpecialNeedsCategory(specialCategory)
        }

        let status = false;
        if (studentStudiesForm?.pastMobilities != undefined) {
            status = JSON.parse(JSON.stringify(studentStudiesForm?.pastMobilities))
        }

        if (langCode === "gr") {
            setPreviousMobilityStatus(status ? 'Ναι' : 'Οχι')
        } else {
            console.log('here')
            setPreviousMobilityStatus(status ? 'Yes' : 'No')
        }

        if (studentStudiesForm?.pastMobilityData !== undefined && studentStudiesForm?.pastMobilityData.length > 0) {
            const temp = JSON.parse(JSON.stringify(studentStudiesForm?.pastMobilityData))

            const previousMobilityTemp = initialState

            // if (langCode === "gr") {
            //     setPreviousMobilityStatus(status ? 'Ναι' : 'Οχι')
            // } else {
            //     console.log('here')
            //     setPreviousMobilityStatus(status ? 'Yes' : 'No')
            // }

            let studiesTemp = studentStudiesForm?.pastMobilityData.find(i => i.programme == "erasmusStudies")
            let workshopTemp = studentStudiesForm?.pastMobilityData.find(i => i.programme == "erasmusWorkshop")
            if (studiesTemp != null) {
                setErasmusStudiesForm(true)
                let studyCycleStudies = ''
                if (langCode === "gr") {
                    studyCycleStudies = studiesTemp.studyCycle == 6 ? 'Προπτυχιακό' : studiesTemp.studyCycle == 7 ? 'Μεταπτυχιακό' : studiesTemp.studyCycle == 8 ? 'Διδακτορικό' : ''
                } else {
                    studyCycleStudies = studiesTemp.studyCycle == 6 ? 'Undergraduate' : studiesTemp.studyCycle == 7 ? 'Postgraduate' : studiesTemp.studyCycle == 8 ? 'Ph.D' : ''
                }
                previousMobilityTemp.erasmusStudiesStudyCycle = studyCycleStudies
                previousMobilityTemp.erasmusStudiesMobilityYear = studiesTemp.year
                previousMobilityTemp.erasmusStudiesMobilityDuration = studiesTemp.semesterDuration
                previousMobilityTemp.erasmusStudiesMobilityInstitution = studiesTemp.sendingInstitution
            }

            if (workshopTemp != null) {
                setErasmusWorkshopForm(true)
                let studyCycleWorkshop = ''
                if (langCode === "gr") {
                    studyCycleWorkshop = workshopTemp.studyCycle == 6 ? 'Προπτυχιακό' : workshopTemp.studyCycle == 7 ? 'Μεταπτυχιακό' : workshopTemp.studyCycle == 8 ? 'Διδακτορικό' : ''
                } else {
                    studyCycleWorkshop = workshopTemp.studyCycle == 6 ? 'Undergraduate' : workshopTemp.studyCycle == 7 ? 'Postgraduate' : workshopTemp.studyCycle == 8 ? 'Ph.D' : ''
                }
                previousMobilityTemp.erasmusWorkshopStudyCycle = studyCycleWorkshop
                previousMobilityTemp.erasmusWorkshopMobilityYear = workshopTemp.year
                previousMobilityTemp.erasmusWorkshopMobilityDuration = workshopTemp.semesterDuration
                previousMobilityTemp.erasmusWorkshopMobilityInstitution = workshopTemp.sendingInstitution
            }
            if (workshopTemp == null && studiesTemp == null) {
                setErasmusStudiesForm(false)
                setErasmusWorkshopForm(false)
                previousMobilityTemp.erasmusWorkshopStudyCycle = ""
                previousMobilityTemp.erasmusWorkshopMobilityYear = ""
                previousMobilityTemp.erasmusWorkshopMobilityDuration = ""
                previousMobilityTemp.erasmusWorkshopMobilityInstitution = ""
                previousMobilityTemp.erasmusStudiesStudyCycle = ""
                previousMobilityTemp.erasmusStudiesMobilityYear = ""
                previousMobilityTemp.erasmusStudiesMobilityDuration = ""
                previousMobilityTemp.erasmusStudiesMobilityInstitution = ""
            }
            console.log(studiesTemp)
            console.log(workshopTemp)
            console.log(previousMobilityTemp)

            setPreviousMobility(previousMobilityTemp)
            console.log(previousMobility)
        }
        else {
            setPreviousMobility(initialState)
        }
    }, [])

    useEffect(() => {
        console.log(studentStudiesForm)
        console.log(previousMobility)
    }, [])

    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={async (event) => {
            event.preventDefault();
            const temp = []
            let mobilitiesStatus = ''
            if (langCode === "gr") {
                mobilitiesStatus = previousMobilityStatus === 'Ναι' ? true : false
            } else {
                mobilitiesStatus = previousMobilityStatus === 'Yes' ? true : false
            }

            if (mobilitiesStatus) {
                if (erasmusStudiesForm) {
                    let studyCycleStudies = ''
                    if (langCode === "gr") {
                        studyCycleStudies = previousMobility.erasmusStudiesStudyCycle === 'Προπτυχιακό' ? 6 : previousMobility.erasmusStudiesStudyCycle === 'Μεταπτυχιακό' ? 7 : previousMobility.erasmusStudiesStudyCycle === 'Διδακτορικό' ? 8 : 0
                    } else {
                        studyCycleStudies = previousMobility.erasmusStudiesStudyCycle === 'Undergraduate' ? 6 : previousMobility.erasmusStudiesStudyCycle === 'Postgraduate' ? 7 : previousMobility.erasmusStudiesStudyCycle === 'Ph.D' ? 8 : 0
                    }
                    temp.push({
                        programme: "erasmusStudies",
                        studyCycle: studyCycleStudies,
                        year: previousMobility.erasmusStudiesMobilityYear,
                        semesterDuration: previousMobility.erasmusStudiesMobilityDuration,
                        sendingInstitution: previousMobility.erasmusStudiesMobilityInstitution
                    })
                }
                if (erasmusWorkshopForm) {
                    let studyCycleWorkshop = ''
                    if (langCode === "gr") {
                        studyCycleWorkshop = previousMobility.erasmusWorkshopStudyCycle === 'Προπτυχιακό' ? 6 : previousMobility.erasmusWorkshopStudyCycle === 'Μεταπτυχιακό' ? 7 : previousMobility.erasmusWorkshopStudyCycle === 'Διδακτορικό' ? 8 : 0
                    } else {
                        studyCycleWorkshop = previousMobility.erasmusWorkshopStudyCycle === 'Undergraduate' ? 6 : previousMobility.erasmusWorkshopStudyCycle === 'Postgraduate' ? 7 : previousMobility.erasmusWorkshopStudyCycle === 'Ph.D' ? 8 : 0
                    }
                    temp.push({
                        programme: "erasmusWorkshop",
                        studyCycle: studyCycleWorkshop,
                        year: previousMobility.erasmusWorkshopMobilityYear,
                        semesterDuration: previousMobility.erasmusWorkshopMobilityDuration,
                        sendingInstitution: previousMobility.erasmusWorkshopMobilityInstitution
                    })
                }
            }
            console.log(temp)

            // const studentData = JSON.parse(localStorage.getItem('studentData'))
            // studentData['pastMobilityData'] = temp;
            // localStorage.setItem('studentData', JSON.stringify(studentData))

            setStudentStudiesForm({ ...studentStudiesForm, pastMobilityData: temp, specialNeedsCategory: specialNeedsCategory, pastMobilities: mobilitiesStatus })
            var activeElement = document.activeElement;
            if (activeElement.value === 'draft') {
                saveDraft({ ...studentStudiesForm, pastMobilityData: temp, specialNeedsCategory: specialNeedsCategory, pastMobilities: mobilitiesStatus })
            } else {
                handleStep();
            }
        }}>
            <div className="containter">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h3 className="text-center">{StudentPreviousMobilityComponentTranslation("h3_title", langCode)['label']}</h3>
                            <div className="form-group">
                                <RequiredSelectWithLabel
                                    value={previousMobilityStatus}
                                    onSelectChange={(newValue, action) => {
                                        console.log(newValue)
                                        if (action.action == "select-option") {
                                            if (newValue != null) {
                                                setPreviousMobilityStatus(newValue.label)
                                            }
                                            else {
                                                setPreviousMobilityStatus(null)
                                            }
                                        }
                                        else if (action.action == "clear") {
                                            setPreviousMobilityStatus()
                                        }
                                    }}
                                    id={'previousMobilityStatus'}
                                    clearable={true}
                                    listOptions={StudentPreviousMobilityComponentTranslation("previousMobility_opts", langCode)}
                                    required={required}
                                    disabled={view}
                                >
                                    {StudentPreviousMobilityComponentTranslation("pastMobility", langCode)['label']}
                                </RequiredSelectWithLabel>
                                {previousMobilityStatus === 'Ναι' || previousMobilityStatus === 'Yes' ?
                                    <div>
                                        <Form.Check
                                            type="checkbox"
                                            id={'erasmusStudies'}
                                            checked={erasmusStudiesForm}
                                            disabled={view}
                                            onChange={(e) => {
                                                if (e.target.checked === true) {
                                                    setErasmusStudiesForm(true)
                                                } else {
                                                    setErasmusStudiesForm(false)
                                                }
                                            }}
                                            label={StudentPreviousMobilityComponentTranslation("erasmusStudies", langCode)['label']}>
                                        </Form.Check>
                                        {erasmusStudiesForm === true &&
                                            renderCheckboxForms("erasmusStudies")
                                        }
                                        <Form.Check
                                            type="checkbox"
                                            id={'erasmusWorkshop'}
                                            checked={erasmusWorkshopForm}
                                            disabled={view}
                                            onChange={(e) => {
                                                if (e.target.checked === true) {
                                                    setErasmusWorkshopForm(true)
                                                } else {
                                                    setErasmusWorkshopForm(false)
                                                }
                                            }}
                                            label={StudentPreviousMobilityComponentTranslation("erasmusWorkshop", langCode)['label']}>
                                        </Form.Check>
                                        {erasmusWorkshopForm === true &&
                                            renderCheckboxForms("erasmusWorkshop")
                                        }
                                    </div>
                                    : <></>}
                                <Form.Check
                                    type="checkbox"
                                    id={'specialNeedsCategory'}
                                    checked={specialNeedsCategory}
                                    disabled={view}
                                    onChange={(e) => {
                                        if (e.target.checked === true) {
                                            setSpecialNeedsCategory(true)
                                        } else {
                                            setSpecialNeedsCategory(false)
                                        }
                                    }}
                                    label={StudentPreviousMobilityComponentTranslation("specialNeedsCategory", langCode)['label']}>
                                </Form.Check>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </form >
    );
}

export default StudentPreviousMobilityComponent