import './ToggleSwitch.scss';

const ToggleSwitch = ({ id, name, checked, onChange, optionLabels, small, disabled }) => {
    const handleKeyPress = e => {
        if (e.keyCode !== 32) return;

        e.preventDefault();
        onChange(!checked);
    }
    
    return (
        <div className={`toggle-switch ${small ? 'toggle-switch-small' : ''}`}>
            <input
                type="checkbox"
                className="toggle-switch-checkbox"
                name={name}
                id={id}
                checked={checked}
                onChange={e => onChange(e.target.checked)}
                disabled={disabled}
            />
            {(id && optionLabels.length === 2) ? (
                <label 
                        className="toggle-switch-label" 
                        htmlFor={id} 
                        tabIndex={ disabled ? -1 : 1 }
                        onKeyDown={handleKeyPress}
                >
                    <span
                        className={`toggle-switch-inner ${disabled ? 'toggle-switch-disabled' : ''}`} 
                        data-yes={!small && optionLabels[0]} 
                        data-no={!small && optionLabels[1]}
                        tabIndex={-1}
                    />
                    <span 
                        className={`toggle-switch-switch ${disabled ? 'toggle-switch-disabled' : ''}`} 
                        tabIndex={-1}    
                    />
                </label>
            ) : <></>
            }
        </div>
    );
}

ToggleSwitch.defaultProps = {
    optionLabels: ["", ""],
};



export default ToggleSwitch;