export default function StudentDataFilesComponentTranslation(fieldName, langcode = "en") {

    const fields = {
        europassCv: {
            en: {
                label: "Short CV (Europass CV) *",
                placeholder: "Please upload a short CV, based on the customized Europass CV template (allowed files: pdf) (maximum file size 10MB)."
            },
            gr: {
                label: "Σύντομο βιογραφικό σημείωμα (Europass CV) *",
                placeholder: "Παρακαλούμε ανεβάστε ένα σύντομο βιογραφικό σημείωμα, βάσει του προσαρμοσμένου υποδείγματος Europass CV (επιτρεπτά αρχεία: pdf) (μέγιστο μέγεθος αρχείου 10MB)."
            }
        },
        academicIdentity: {
            en: {
                label: "Copy of the Academic ID *",
                placeholder: "Please upload a copy of your academic ID (allowed files: pdf) (maximum file size 10MB).                "
            },
            gr: {
                label: "Αντίγραφο της ακαδημαϊκής ταυτότητας *",
                placeholder: "Παρακαλούμε ανεβάστε αντίγραφο της ακαδημαϊκής ταυτότητάς σας (επιτρεπτά αρχεία: pdf) (μέγιστο μέγεθος αρχείου 10MB).  "
            }
        },
        proposedCurriculum: {
            en: {
                label: "Motivation letter and proposed study plan *",
                placeholder: "Please upload a text describing your motivation for participation and your proposed mobility curriculum (allowed files: pdf) (maximum file size 10MB)."
            },
            gr: {
                label: "Κίνητρα συμμετοχής και προτεινόμενο πρόγραμμα σπουδών *",
                placeholder: "Παρακαλούμε ανεβάστε ένα κείμενο όπου θα περιγράφετε τα κίνητρα συμμετοχής και το προτεινόμενο πρόγραμμα σπουδών της κινητικότητάς σας (επιτρεπτά αρχεία: pdf) (μέγιστο μέγεθος αρχείου 10MB)."
            }
        },
        foreignLanguageCertificate: {
            en: {
                label: "Copy of language certificate/attestation *",
                placeholder: "Please upload a copy (not certified) of the certificate of knowledge of the foreign language in which the courses are taught at the host university, or certificate of attendance of foreign language courses, which meet the level of language proficiency required by the Host Institution (allowed files: pdf) (maximum file size 10MB)."
            },
            gr: {
                label: "Αντίγραφο του πιστοποιητικού γνώσης ξένης γλώσσας / Βεβαίωση παρακολούθησης μαθημάτων *",
                placeholder: "Παρακαλούμε ανεβάστε αντίγραφο (μη επικυρωμένο) του πιστοποιητικού γνώσης ξένης γλώσσας στην οποία διδάσκονται τα μαθήματα στο πανεπιστήμιο υποδοχής, ή βεβαίωση παρακολούθησης μαθημάτων ξένης γλώσσας, που να ανταποκρίνονται στο απαιτούμενο από το Ίδρυμα Υποδοχής επίπεδο γλωσσικής επάρκειας(επιτρεπτά αρχεία: pdf) (μέγιστο μέγεθος αρχείου 10MB)."
            }
        },
        detailedDegreeScore: {
            en: {
                label: "Copy of transcript of records for Bachelor studies (applicable only to current Master and PhD students)",
                placeholder: "(Master's students and PhD candidates only) Please upload a copy of detailed degree transcript (allowed files: pdf) (maximum file size 10MB)."
            },
            gr: {
                label: "Αντίγραφο αναλυτικής βαθμολογίας πτυχίου (Μόνο για μεταπτυχιακούς φοιτητές και υποψήφιους διδάκτορες)",
                placeholder: "(Μόνο για μεταπτυχιακούς φοιτητές και υποψήφιους διδάκτορες) Παρακαλούμε ανεβάστε αντίγραφο αναλυτικής βαθμολογίας πτυχίου (επιτρεπτά αρχεία: pdf) (μέγιστο μέγεθος αρχείου 10MB)."
            }
        },
        detailedMastersDegreeScore: {
            en: {
                label: "Copy of transcript of records for Master studies (applicable only to current PhD students)",
                placeholder: "(For PhD candidates only) Please upload a copy of detailed master's degree transcript (allowed files: pdf) (maximum file size 10MB)."
            },
            gr: {
                label: "Αντίγραφο αναλυτικής βαθμολογίας μεταπτυχιακού διπλώματος (Μόνο για υποψήφιους διδάκτορες)                ",
                placeholder: "(Μόνο για υποψήφιους διδάκτορες) Παρακαλούμε ανεβάστε αντίγραφο αναλυτικής βαθμολογίας μεταπτυχιακού διπλώματος (επιτρεπτά αρχεία: pdf) (μέγιστο μέγεθος αρχείου 10MB)."
            }
        },
        disabilityCertificate: {
            en: {
                label: "Disability Certificate *",
                placeholder: "Disability Certificate"
            },
            gr: {
                label: "Πιστοποιητικό αναπηρίας *",
                placeholder: "Πιστοποιητικό αναπηρίας"
            },
        },
        letterOfAcceptance: {
            en: {
                label: "Letter of Acceptance *",
                placeholder: "Letter of Acceptance"
            },
            gr: {
                label: "Επιστολή αποδοχής *",
                placeholder: "Επιστολή αποδοχής"
            },
        },
        recommendationLetter: {
            en: {
                label: "Recommendation Letter *",
                placeholder: "Recommendation Letter"
            },
            gr: {
                label: "Συστατική Επιστολή (μη εμπιστευτική) από μέλος της τριμελούς επιτροπής *",
                placeholder: "Συστατική Επιστολή"
            },
        },
        h3_title: {
            en: {
                label: "Attached files",
            },
            gr: {
                label: "Συνημμένα αρχεία",
            }
        },
        upload_btn: {
            en: {
                label: "Upload",
            },
            gr: {
                label: "Αποστολή"
            }
        },
        delete_btn: {
            en: {
                label: "Delete",
            },
            gr: {
                label: "Διαγραφή"
            }
        },
        download_btn: {
            en: {
                label: "Download",
            },
            gr: {
                label: "Λήψη αρχείου"
            }
        },
    };

    return fields[fieldName][langcode];

}