const fallbackInfos = [
    {
        id: null,
        type: null,
        info : {
            id: null,
            email: null,
            phone: null,
            urls: [
                {
                    id: null,
                    value: null,
                    language: null
                }
            ]
        }
    }
];

const AdditionalInformation = ({ infos }) => {
    return (
        <div className="table-responsive">
            <table className="table cf">
                <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                    <tr>
                        <th scope="col" className="text-center col-md-1" rowSpan="2">Type</th>
                        <th scope="col" className="text-center col-md-11" colSpan="5">Information</th>
                    </tr>
                    <tr>
                        <th scope="col" className="text-center col-md-1">Email</th>
                        <th scope="col" className="text-center col-md-1">Phone</th>
                        <th scope="col" className="text-center col-md-1">URL</th>
                        <th scope="col" className="text-center col-md-1">Language</th>
                    </tr>
                </thead>
                <tbody>
                    { 
                        ((infos && infos.length !== 0) ? infos : fallbackInfos ).map(informationEntry => 
                            informationEntry.info.urls.length !== 0 && informationEntry.info.urls.map((url, idx) =>
                                <tr key={url}>
                                    {idx == 0 && <td className="text-center align-middle" rowSpan={informationEntry.info.urls.length}>
                                        {informationEntry.type ?? '-'}
                                    </td> }
                                    {idx == 0 && <td className="text-center align-middle" rowSpan={informationEntry.info.urls.length}>
                                        {idx == 0 && ( informationEntry.info.email ?? '-' )}
                                    </td> }
                                    {idx == 0 && <td className="text-center align-middle" rowSpan={informationEntry.info.urls.length}>
                                        {idx == 0 && ( informationEntry.info.phone ?? '-' )}
                                    </td> }
                                    <td className="text-center align-middle">
                                        {url.value ?? '-'}
                                    </td>
                                    <td className="text-center align-middle">
                                        {url.language ?? '-'}
                                    </td>
                                </tr>
                            )
                        ) 
                    }
                </tbody>
            </table>
        </div>  
    );
}

export default AdditionalInformation;