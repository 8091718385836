import 'styles/sass/theme.scss';
import './index.css'
import { useState, useEffect } from 'react';
import {
  BrowserRouter
} from "react-router-dom";
import PermissionProvider from "PermissionProvider/PermissionProvider";
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import Navbar from 'components/Navbar';
import LoginCallback from 'components/LoginCallback';
import Routes from 'components/Routes';
import RenderOnAnonymous from 'components/RenderOnAnonymous';
import RenderOnAuthenticated from 'components/RenderOnAuthenticated';
import Loader from "react-loader-spinner";
import fetchPermission from 'PermissionProvider/fetchPermission';
import "react-datepicker/dist/react-datepicker.css";
import {applicationStudiesFormEnabled, getCookie} from './components/Common/CommonFunctions';

function App({ userService }) {
  const [show, setShow] = useState(false)
  const handleShow = () => {
    setShow(!show)
  }


  const [lang, setLang] = useState(getCookie('lang') ? getCookie('lang') : "en");


  useEffect(() => {
    if (localStorage.getItem("role") && !userService.isLoggedIn()) {
      userService.doLogin()
    }
    if (userService.isLoggedIn()){
      applicationStudiesFormEnabled();
    }
  }, [])
  if (localStorage.getItem("role") && !userService.isLoggedIn()) {
    return (
      <div style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <Loader
          className="spinner mx-auto"
          type="TailSpin"
          color="#3A435E"
          height={70}
          width={70}
        />
      </div>
    )
  }
  else {
    return (
      <BrowserRouter>

        <RenderOnAnonymous userService={userService} >
          <LoginCallback userService={userService} />
        </RenderOnAnonymous>
        <RenderOnAuthenticated userService={userService} >
          <PermissionProvider fetchPermission={fetchPermission(JSON.parse(localStorage.getItem("role")))}>
            <div className={show ? "header-fixed sidebar-lg-show sidebar-show" : "header-fixed sidebar-lg-show"}>
              <Navbar show={handleShow} userService={userService} lang={lang} setLang={setLang} />

              <main className="main">
                <div className="container-fluid pt-4">
                  <Routes userService={userService} />
                </div>
              </main></div>
          </PermissionProvider>
        </RenderOnAuthenticated>
      </BrowserRouter >
    );
  }



}
export default App;
