import CoordinatorDepartment from "./components/Department";

export const fallbackCoordinator = {
    apm: null,
    familyName: null,
    givenName: null,
    title: null,
    email: null,
    address: null,
    url: null,
    phone: null,
};

const InstitutionalCoordinatorContact = ({ userService, coordinator, number, title, subnumberingType, editable, handleTdChange }) => {
    return (
        <>
            <div>
                <h3>
                    {`${number}. ${title}`}
                </h3>
            </div>
            <div className="table-responsive">
                <table className="table cf" style={{ tableLayout: 'fixed'}}>
                    <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                        <tr className="customTable">
                            <th scope="col" className="text-center col-md-1 align-middle">APM</th>
                            <th scope="col" className="text-center col-md-1 align-middle">Family Name</th>
                            <th scope="col" className="text-center col-md-1 align-middle">Given Name</th>
                            <th scope="col" className="text-center col-md-1 align-middle">Title</th>
                            <th scope="col" className="text-center col-md-2 align-middle">Email</th>
                            <th scope="col" className="text-center col-md-2 align-middle">Address</th>
                            <th scope="col" className="text-center col-md-2 align-middle">URL</th>
                            <th scope="col" className="text-center col-md-2 align-middle">Phone</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        {
                            
                            Object.keys(coordinator ? coordinator : fallbackCoordinator).map(key => 
                                key !== 'id' && 
                                key !== 'department' &&
                                key !== 'roles' &&
                                key !== 'uid' &&
                                <td 
                                    key={key} 
                                    className="text-center" 
                                    style={{ wordWrap: 'break-word' }}
                                    contentEditable={editable}
                                    onBlur={e => {
                                        handleTdChange(key, e.target.innerText)
                                    }}
                                >
                                    { coordinator ? (coordinator[key] ?? '-') : '-' }
                                </td>
                            )
                        }
                        </tr>
                    </tbody>
                </table>
            </div>

            <CoordinatorDepartment 
                department={coordinator?.department}
                number={subnumberingType[0]}
                userService={userService}
                editable={editable}
                handleTdChange={handleTdChange}
            />
        </>
    );
}

export default InstitutionalCoordinatorContact;