import {splitCooperationConditionsOnSubjectArea} from 'components/Common/CommonFunctions'


const StaffMobilities = ({ staffMobilities, localInstitutionErasmusCode, type }) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h6 className="mt-2"><b>Staff Mobility for {type}</b></h6>
                    <table className="table-responsive mt-2 mb-2">
                        <tbody>
                            <tr style={{ backgroundColor: "#3A435E", color: "white" }} className="customtable" key={'table-b-staff-mobility-id-row1'}>
                                <td colSpan="2" align="center"><b>FROM</b></td>
                                <td colSpan="2" align="center"><b>TO</b></td>
                                <td rowSpan="2" align="center"><b>Subject area</b><br />(optional)<br />[ISCED]</td>
                                <td rowSpan="2" align="center"><b>Field of Education - Clarification</b><br />(optional)<br /></td>
                                <td rowSpan="2" align="center"><b>Other Info Terms</b></td>
                                <td rowSpan="2" align="center"><b>Blended (*)</b> </td>
                                <td colSpan="4" align="center"><b>Number of staff mobility periods</b></td>
                            </tr>
                            <tr style={{ backgroundColor: "#3A435E", color: "white" }} className="customtable" key={'table-b-staff-mobility-id-row2'}>
                                <td align="center"><b>Institution</b> <br /> [Erasmus code] </td>
                                <td align="center"><b>Department</b></td>
                                <td align="center"><b>Institution</b> <br /> [Erasmus code] </td>
                                <td align="center"><b>Department</b> </td>
                                <td align="center"><b>Total number of staff per year</b></td>
                                <td align="center"><b>Total number of days</b></td>

                            </tr>
                            {staffMobilities && splitCooperationConditionsOnSubjectArea(staffMobilities).map((m,i) =><StaffMobility staffMobility={m} index={i}/>)}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    );
};

const StaffMobility = ({ staffMobility,index }) => {
    return (
        <tr key={'table-b-staff-mobility-id-' + staffMobility.id+index}>
            <td>{staffMobility?.fromInstitution?.erasmusCode}</td>
            <td>{staffMobility?.fromDepartment?.name}</td>
            <td>{staffMobility?.toInstitution?.erasmusCode}</td>
            <td>{staffMobility?.toDepartment?.name}</td>
            <td>{staffMobility?.subjectAreaWithClarification?.subjectArea?.name} [{staffMobility?.subjectAreaWithClarification?.subjectArea?.code}]</td>
            <td>{staffMobility?.subjectAreaWithClarification?.clarification}</td>
            <td>{staffMobility.otherInfoTerms}</td>
            <td>{staffMobility.blended === true ? "Yes" : "No"}</td>
            <td>{staffMobility.mobilitiesPerYear} </td>
            <td>{staffMobility.totalDaysPerYear}</td>
        </tr>
    );
}

export default StaffMobilities;