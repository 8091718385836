export default function StudentPreviousMobilityComponentTranslation(fieldName, langcode) {

    const fields = {
        pastMobility: {
            en: {
                label: "Have you participated in an Erasmus+ mobility before? *",
                placeholder: "Select"
            },
            gr: {
                label: "Έχετε μετακινηθεί στο παρελθόν με το πρόγραμμα Erasmus; *",
                placeholder: "Επιλέξτε"
            }
        },
        erasmusStudies: {
            en: {
                label: "As part of the Erasmus+ for Studies programme",
            },
            gr: {
                label: "Στο πλαίσιο του προγράμματος Erasmus για Σπουδές",
            }
        },
        erasmusWorkshop: {
            en: {
                label: "As part of the Erasmus+ for Traineeships programme",
            },
            gr: {
                label: "Στο πλαίσιο του προγράμματος Erasmus για Πρακτική Άσκηση",
            }
        },
        specialNeedsCategory: {
            en: {
                label: "I belong to the persons with disabilities/special needs category",
            },
            gr: {
                label: "Ανήκω στην κατηγορία των ατόμων με αναπηρία/ειδικές ανάγκες",
            }
        },
        studyCycle: {
            en: {
                label: "Study cycle/level of education during previous mobility",
                placeholder: "Enter study cycle/level of education you belonged to during your previous mobility"
            },
            gr: {
                label: "Κύκλος/επίπεδο σπουδών κατά την προηγούμενη μετακίνηση",
                placeholder: "Εισάγετε τον κύκλο/επίπεδο σπουδών που ανήκατε κατά την προηγούμενη μετακίνηση"
            }
        },
        mobilityYear: {
            en: {
                label: "Start year of previous mobility",
                placeholder: "Enter start year of your previous mobility"
            },
            gr: {
                label: "Έτος έναρξης προηγούμενης μετακίνησης",
                placeholder: "Εισάγετε το έτος κατά το οποίο ξεκίνησε η προηγούμενη μετακίνησή σας"
            }
        },
        mobilityDuration: {
            en: {
                label: "Previous mobility duration",
                placeholder: "Enter the number of months of your previous mobility's duration"
            },
            gr: {
                label: "Διάρκεια προηγούμενης μετακίνησης",
                placeholder: "Εισάγετε τον αριθμό μηνών της διάρκειας της προηγούμενης μετακίνησής σας"
            }
        },
        mobilityInstitution: {
            en: {
                label: "Home Institution of previous mobility",
                placeholder: "Enter the name of your home institution during your previous mobility"
            },
            gr: {
                label: "Ίδρυμα αποστολής κατά την προηγούμενη μετακίνηση",
                placeholder: "Εισάγετε το όνομα του ιδρύματος αποστολής σας κατά την προηγούμενή σας μετακίνηση"
            }
        },
        h3_title: {
            en: {
                label: "Information of previous mobilities and comments",
            },
            gr: {
                label: "Πληροφορίες προηγούμενων μετακινήσεων και σχόλια",
            }
        },
        previousMobility_opts: {
            en: [{ label: 'Yes' }, { label: 'No' }],
            gr: [{ label: 'Ναι' }, { label: 'Οχι' }],
        },
        education_level_options: {
            en: [{ label: 'Undergraduate' }, { label: 'Postgraduate' }, { label: 'Ph.D' }],
            gr: [{ label: 'Προπτυχιακό' }, { label: 'Μεταπτυχιακό' }, { label: 'Διδακτορικό' }],
        }

    }

    return fields[fieldName][langcode]
}