export default function StudentStudiesListTranslation(fieldName, langcode = "en") {

    const fields = {
        filters: {
            en: {
                label: "Filters",
            },
            gr: {
                label: "Φίλτρα αναζήτησης",
            }
        },
        id: {
            en: {
                label: "Application number",
            },
            gr: {
                label: "Αριθμός αίτησης",
            }
        },
        status: {
            en: {
                label: "Status",
            },
            gr: {
                label: "Κατάσταση",
            }
        },
        date: {
            en: {
                label: "Submitted Date",
            },
            gr: {
                label: "Ημερομηνία υποβολής",
            }
        },
        fullName: {
            en: {
                label: "Full Name (Latin)",
            },
            gr: {
                label: "Ονοματεπώνυμο (Λατινικά)",
            }
        },
        fullNameGreek: {
            en: {
                label: "Full Name (Greek)",
            },
            gr: {
                label: "Ονοματεπώνυμο (Ελληνικά)",
            }
        },
        fullNameList: {
            en: {
                label: "Full Name",
            },
            gr: {
                label: "Ονοματεπώνυμο",
            }
        },
        type: {
            en: {
                label: "Type of mobility",
            },
            gr: {
                label: "Τύπος κινητικότητας",
            }
        },
        mobility: {
            en: {
                label: "Mobility Action",
            },
            gr: {
                label: "Δράση κινητικότητας",
            }
        },
        applicationDate: {
            en: {
                label: "Application Year",
            },
            gr: {
                label: "Έτος πρόσκλησης",
            }
        },
        actions: {
            en: {
                label: "Actions",
            },
            gr: {
                label: "Ενέργειες",
            }
        },
        totalStudies: {
            en: {
                label: "Total number of applications: ",
            },
            gr: {
                label: "Συνολικός αριθμός αιτήσεων: ",
            }
        },
        noStudiesFound: {
            en: {
                label: "No applications found",
            },
            gr: {
                label: "Δεν υπάρχουν αιτήσεις προς επεξεργασία",
            }
        },
        status_opts: {
            en: {
                label: [{ label: "Draft", value: "Draft" }, { label: "Submitted", value: "Submitted" }, { label: "Validated", value: "Validated" }],
            },
            gr: {
                label: [{ label: "Αποθηκευμένη", value: "Αποθηκευμένη" }, { label: "Καταχωρημένη", value: "Καταχωρημένη" }, { label: "Έγκυρη", value: "Έγκυρη" }],
            }
        },
        type_opts: {
            en: {
                label: [{ label: "Long-term mobility for Studies", value: "Long-term mobility for Studies" }, { label: "Short-term mobility for Studies", value: "Short-term mobility for Studies" }],
            },
            gr: {
                label: [{ label: "Μακροχρόνια κινητικότητα για Σπουδές", value: "Μακροχρόνια κινητικότητα για Σπουδές" }, { label: "Βραχυχρόνια κινητικότητα για Σπουδές", value: "Βραχυχρόνια κινητικότητα για Σπουδές" }],
            }
        },
        mobility_opts: {
            en: {
                label: [{ label: "Erasmus+ Studies", value: "Erasmus+ Studies" }],
            },
            gr: {
                label: [{ label: "Erasmus+ Σπουδές", value: "Erasmus+ Σπουδές" }],
            }
        },
    };

    return fields[fieldName][langcode];

}