import { ReactDataContext } from '@themost/react';
import UserService from "services/UserService";

export function convert(string) {
  var parser = new DOMParser;
  var dom = parser.parseFromString(
    '<!doctype html><body>' + string,
    'text/html');
  var decodedString = dom.body.textContent;
  return decodedString
}

export function splitCooperationConditionsOnSubjectArea(cooperationConditions) {
  return cooperationConditions.reduce((carry, item) => {
    if (!item.subjectAreasWithClarifications.length) {
      carry.push(item);
    } else {
      const subjectAreasWithClarifications = [...item.subjectAreasWithClarifications];

      subjectAreasWithClarifications.forEach(s => {
        let newItem = {};
        newItem = Object.assign({}, item);
        delete newItem.subjectAreasWithClarifications;
        newItem.subjectAreaWithClarification = s;
        carry.push(newItem);
      })
    }

    return carry;
  }, []);

}

export function getDate(dt) {
  return `${dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth() + 1).toString().padStart(2, '0')}/${dt.getFullYear().toString().padStart(4, '0')} ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}:${dt.getSeconds().toString().padStart(2, '0')}`
}

export function getHeisEndpoint() {
  return process.env.REACT_APP_EWP_API_HEIS_MOEBIUS;
}

export function getHeiByHeiIdEndpoint(hei_id) {
  return process.env.REACT_APP_EWP_API_HEIS_MOEBIUS + '/' + hei_id;
}

export function getOunitsByHeiIdEndpoint(hei_id, ounitId) {
  return process.env.REACT_APP_EWP_API_HEIS_MOBISIS + '/' + hei_id + '/ounits/' + ounitId;
}

export function getIIACNREndpoint(iia_id) {
  return process.env.REACT_APP_EWP_API_IIAS + iia_id + '/cnr';
}

export function getIIAApproveEndpoint(iia_id) {
  return process.env.REACT_APP_EWP_API_IIAS + '/' + iia_id + '/approve';
}

export function getFactsheetEndpoint(hei_id) {
  return process.env.REACT_APP_EWP_API_FACTSHEET + hei_id;
}

export async function notifyPartnerCNR(iia_id) {

  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  fetch(getIIACNREndpoint(iia_id), requestOptions)
    .then(response => response.text())
    .then(() => alert("CNR was successfully sent"))
    .catch(() => alert("An error occured"));
}



export async function approveIIA(iia_id) {

  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  fetch(getIIAApproveEndpoint(iia_id), requestOptions)
    .then(response => response.text())
    .then(() => alert("Approval was successfully sent"))
    .catch(() => alert("An error occured"));
}

export const getNestedPropertyByString = (object, string) => {
  string = string.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  string = string.replace(/^\./, '');           // strip a leading dot
  const requestedNestedProperties = string.split('.');
  for (var i = 0, n = requestedNestedProperties.length; i < n; ++i) {
    const currentProperty = requestedNestedProperties[i];
    if (currentProperty in object) {
      object = object[currentProperty];
    } else {
      throw new Error(`Invalid property ${currentProperty}`);
    }
  }
  return object;
}

export const calculateCurrentAcademicYear = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // getMonth returns the date's 0-indexed month

  if (currentMonth < 9) {
    return (currentDate.getFullYear() - 1) + '-' + currentDate.getFullYear()
  }

  return currentDate.getFullYear() + '-' + (currentDate.getFullYear() + 1)
}


export async function getLocalIIACNRList(context, iiaId) {
  try {

    let result = context.model(`MobilityNotifications`).asQueryable().where('changedElementIds').equal(new String(iiaId)).and('notifierHeiId').equal(process.env.REACT_APP_LOCAL_HEI_ID);
    return result.getItems();


  } catch (error) {
    console.log(error)
  }
}

export const isApproveButtonDisabled = (ewpStatus, partnerIdentificationList, localIIACNRList) => {

  if (ewpStatus) {
    if (ewpStatus === "Notified by partner" && localIIACNRList.length > 0) {
      return false;
    }
    else if (ewpStatus === "Notified by us" && partnerIdentificationList.length > 0) {
      return false;
    }
    else if (ewpStatus === "Approved by us") {
      return false;
    }
    else if (ewpStatus === "Approved by partner" && partnerIdentificationList.length > 0) {
      return false;
    }
    else if (ewpStatus === "Approved by all") {
      return false;
    }
    else {
      return true;
    }

  }
  return true;


}
const checkCurrentDateBetween = (start, end) => {
  var dateFrom = Date.parse(start);
  var dateTo = Date.parse(end);
  var dateCheck = new Date();
  return (dateCheck > dateFrom && dateCheck < dateTo)
}

export const applicationStudiesFormEnabled = async () => {

  const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
  context.setBearerAuthorization(UserService.getToken());
  if (JSON.parse(localStorage.getItem("role")).role === "Student") {
    try {
      const applicationSettings = await context.model('ApplicationSettings').asQueryable().take(-1).getItems();
      const academicYearLongTerm = applicationSettings.find(a => a?.applicationType === "long-term-studies")?.academicYear;
      const academicYearShortTerm = applicationSettings.find(a => a?.applicationType === "short-term-studies")?.academicYear;

      const ownerResponse = await context.model('Users/Me').asQueryable().take(-1).getItems();
      const submittedLongApplications = await context.model('ApplicationStudentStudiesForms').asQueryable().where('owner/id').equal(ownerResponse?.id).and('isDeleted').equal(false).and("applicationYear").equal(academicYearLongTerm).and("status").equal("submitted").and("type").equal("long-term-studies").getItems();
      const validatedLongApplications = await context.model('ApplicationStudentStudiesForms').asQueryable().where('owner/id').equal(ownerResponse?.id).and('isDeleted').equal(false).and("applicationYear").equal(academicYearLongTerm).and("status").equal("validated").and("type").equal("long-term-studies").getItems();
      const checkIfLongTermApplicationFormEnabled = submittedLongApplications.concat(validatedLongApplications);
      const submittedShortApplications = await context.model('ApplicationStudentStudiesForms').asQueryable().where('owner/id').equal(ownerResponse?.id).and('isDeleted').equal(false).and("applicationYear").equal(academicYearShortTerm).and("status").equal("submitted").and("type").equal("short-term-studies").getItems();
      const validatedShortApplications = await context.model('ApplicationStudentStudiesForms').asQueryable().where('owner/id').equal(ownerResponse?.id).and('isDeleted').equal(false).and("applicationYear").equal(academicYearShortTerm).and("status").equal("validated").and("type").equal("short-term-studies").getItems();
      const checkIfShortTermApplicationFormEnabled = submittedShortApplications.concat(validatedShortApplications);
      var applicationStartLongTerm = applicationSettings.find(a => a?.applicationType === "long-term-studies")?.applicationStart;
      var applicationStopLongTerm = applicationSettings.find(a => a?.applicationType === "long-term-studies")?.applicationStop;

      var applicationStartShortTerm = applicationSettings.find(a => a?.applicationType === "short-term-studies")?.applicationStart;
      var applicationStopShortTerm = applicationSettings.find(a => a?.applicationType === "short-term-studies")?.applicationStop;

      const longTermApplicationsOpen = checkCurrentDateBetween(applicationStartLongTerm, applicationStopLongTerm);
      const shortTermApplicationsOpen = checkCurrentDateBetween(applicationStartShortTerm, applicationStopShortTerm);
      setCookie("checkIfLongTermApplicationFormAcademicYearDisabled", (checkIfLongTermApplicationFormEnabled.length !== 0 ));
      setCookie("checkIfShortTermApplicationFormAcademicYearDisabled", (checkIfShortTermApplicationFormEnabled.length !== 0 ));
      setCookie("checkIfLongTermApplicationFormAcademicPeriodDisabled", (!longTermApplicationsOpen));
      setCookie("checkIfShortTermApplicationFormAcademicPeriodDisabled", (!shortTermApplicationsOpen));

    } catch (err) {
      console.log(err);
    }
  }
}

export const getCookie = (name) => {
  const cookie = {}
  document.cookie.split(';').forEach((e) => {
    const [key, value] = e.split('=');
    cookie[key.trim()] = value;
  })
  return cookie[name];
}

export const setCookie = (name, value) => {
  document.cookie = `${name}=${value}; path=/`
}

export const parseDMY = (s) => {
  var b = s.split(/\D+/);
  var d = new Date(b[2], b[1] - 1, b[0]);
  d.setFullYear(b[2]);
  return d && d.getMonth() == b[1] - 1 ? d : new Date(NaN);
}