export const informationEntryDefaultState = {
    email: null,
    phone: null,
    urls: [
        {
            value: null,
            language: null
        }
    ]
};

const Information = ({ informationEntry, titles=['Email', 'Phone', 'URL', 'Language'], editable, handleTdChange }) => {
    let fallbackInformationEntry;
    if(!informationEntry) {
        fallbackInformationEntry = {};

        for(const title of titles) {
            if(title === 'URL' || title === 'Language' || title === 'url' || title === 'LANGUAGE') {
                fallbackInformationEntry['urls'] = [{value: null, language: null}];
            } else {
                fallbackInformationEntry[title.toLowerCase()] = null;
            }
        }
    }

    return (
        <div className="table-responsive">
            <table className="table cf" style={{ tableLayout: 'fixed'}}>
                <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                    <tr>
                    {
                        titles.map(title => <th key={title} scope="col" className="text-center col-md-2">{title}</th>)
                    }
                    </tr>
                </thead>
                <tbody>
                    {

                        (informationEntry ? informationEntry.urls.length : fallbackInformationEntry.urls.length ) !== 0 
                            && (informationEntry ? informationEntry : fallbackInformationEntry).urls.map((url, idx) =>
                            <tr key={idx}>
                                {
                                    Object.keys(informationEntry ? informationEntry : fallbackInformationEntry).map(key => {
                                        return idx == 0 // return the fields except the urls only once 
                                            && key !== 'urls' 
                                            && key !== 'id' 
                                            && key !== 'factsheet' 
                                            && 
                                            <td 
                                                key={key + idx} 
                                                className="text-center align-middle" 
                                                rowSpan={(informationEntry ? informationEntry : fallbackInformationEntry).urls.length ?? 1}
                                                style={{ wordWrap: 'break-word' }}
                                                contentEditable={editable}
                                                onBlur={e => {
                                                    console.log('key inside information')
                                                    console.log(key)
                                                    handleTdChange(key, e.target.innerText)
                                                }}
                                            >
                                                { (informationEntry ? informationEntry : fallbackInformationEntry)[key] ?? '-' }
                                            </td>
                                    })
                                }
                                <td 
                                    className="text-center align-middle" 
                                    contentEditable={editable} 
                                    style={{ wordWrap: 'break-word' }}
                                    onBlur={e => {
                                        handleTdChange('urls', [...Array.from(informationEntry?.urls ? informationEntry.urls : [], (element, index) => {
                                                return index !== idx ? element : {...element, value: e.target.innerText}
                                            }),
                                            
                                        ])
                                    }}
                                >
                                    {url.value ?? '-'}
                                </td>
                                <td 
                                    className="text-center align-middle" 
                                    contentEditable={editable} 
                                    style={{ wordWrap: 'break-word' }}
                                    onBlur={e => {
                                        handleTdChange('urls', [...Array.from(informationEntry?.urls ? informationEntry.urls : [], (element, index) => {
                                            return index !== idx ? element : {...element, language: e.target.innerText}
                                        }),
                                        ])
                                    }}
                                >
                                    {url.language ?? '-'}
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>  
    );
}

export default Information;