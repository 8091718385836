import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'

const Calendar = ({ institutions, factsheets, calendarType }) => {
    return (
        <Table
            data={
                institutions.map(i => {
                    const factsheet = factsheets.find(f => f.institutionId === i.id).factsheet;
                    let calendar = null;
                    if (factsheet) {
                        calendar = factsheet[calendarType];
                    }
                    return ({ receiving: i.erasmusCode, autumn: calendar && calendar.autumnTerm, spring: calendar && calendar.springTerm });
                })
            }
        >
            <TableHeader>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Receiving institution
                    [Erasmus code or city]
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Autumn term
                    [month]
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }} >
                    Spring term
                    [month]
                </TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.receiving} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.autumn} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.spring} />
            </TableBody>
        </Table>

    );
};


export default Calendar