import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import {
    Text
} from '@react-pdf/renderer';
import {splitCooperationConditionsOnSubjectArea} from 'components/Common/CommonFunctions'

const StaffMobilities = ({ staffMobilities, purpose, styles }) => {
    return (
        <>
            <Text style={styles.h4}>Staff Mobility for {purpose}</Text>
            <Table
                data={splitCooperationConditionsOnSubjectArea(staffMobilities).map(staffMobility => ({
                    from: <Text><Text style={styles.em}>Erasmus Code:</Text>  {staffMobility.fromInstitution.erasmusCode} <Text style={styles.em}>{'\n'} Department: </Text> {staffMobility?.fromDepartment?.name}</Text>,
                    to: <Text><Text style={styles.em}>Erasmus Code:</Text>  {staffMobility.toInstitution.erasmusCode} <Text style={styles.em}>{'\n'} Department: </Text> {staffMobility?.toDepartment?.name}</Text>,
                    subjectArea:staffMobility?.subjectAreaWithClarification?.subjectArea?.name + " [" + staffMobility?.subjectAreaWithClarification?.subjectArea?.code+"]",
                    clarification:staffMobility?.subjectAreaWithClarification?.clarification,
                    otherInfoTerms: staffMobility.otherInfoTerms,
                    blended: staffMobility.blended === true ? "Yes" : "No",
                    mobilitiesPerYear: staffMobility.mobilitiesPerYear,
                    totalDaysPerYear: staffMobility.totalDaysPerYear
                }))}>
                <TableHeader >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        FROM
                        [Erasmus code and department if provided]
                    </TableCell >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        TO
                        [Erasmus code and department if provided]
                    </TableCell>

                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Subject area
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Clarification
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Other Info Terms
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Blended (*)
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Total number of staff
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Total number of days
                    </TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.from} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.to} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.subjectArea} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.clarification} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.otherInfoTerms} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.blended} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.mobilitiesPerYear} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.totalDaysPerYear} />
                </TableBody>
            </Table>
            <Text>{'\n'}</Text>

            <Text>{'\n'}</Text>
        </>

    );
};

export default StaffMobilities;