import { useRef } from 'react';
import Select from 'react-select';


const RequiredSelectWithLabel = ({
    id,
    value,
    onSelectChange,
    clearable,
    disabled,
    isOptionDisabled,
    placeholderText,
    children,
    loading,
    isMulti,
    listOptions,
    required = true,
}) => {
    const selectRef = useRef(null);

    return (
        <>
            <label htmlFor={id}>{children}</label>
            <Select getOptionValue={option => option.label}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary: '#3A435E',
                        primary25: 'rgba(58, 67, 94, 0.25)'
                    },
                })}
                isDisabled={disabled}
                isMulti={isMulti}
                isLoading={loading}
                isOptionDisabled={isOptionDisabled}
                isClearable={clearable}
                autoFocus={false}
                isSearchable
                value={[{ label: value ? value : '' }]}
                id={id}
                placeholder={placeholderText}
                onChange={onSelectChange}
                options={listOptions}
                ref={selectRef}
            />
            <input
                tabIndex={-1}
                autoComplete="off"
                style={{
                    opacity: 0,
                    width: "100%",
                    height: 0,
                    position: "absolute"
                }}
                value={value ?? ''}
                onChange={onSelectChange}
                onFocus={() => selectRef.current.focus()}
                required={required}
            />
        </>
    )
}

export default RequiredSelectWithLabel;