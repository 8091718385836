import { reducer } from '../reducers/iiaFiltersReducer';
import { useReducer, useCallback } from 'react';
import {
    SET_START_YEAR_SELECTED_ACTION,
    SET_END_YEAR_SELECTED_ACTION,
    SET_COUNTRY_SELECTED_ACTION,
    SET_INSTITUTION_SELECTED_ACTION,
    SET_STATUS_SELECTED_ACTION,
    SET_EWP_STATUS_SELECTED_ACTION,
    SET_EWP_STATUSES_LIST_ACTION,
    SET_DEPARTMENT_SELECTED_ACTION,
    SET_DEPARTMENTAL_COORDINATOR_SELECTED_ACTION,
    SET_COUNTRIES_LIST_ACTION,
    SET_DEPARTMENTS_ACTION,
    SET_ACADEMIC_YEARS_ACTION,
    SET_INSTITUTIONS_LIST_ACTION,
    SET_DEPARTMENTAL_COORDINATORS_LIST_ACTION,
    UPDATE_MULTIPLE_VALUES_ACTION,
    SET_AGREEMENT_ID_SELECTED_ACTION,
    SET_EWP_ID_SELECTED_ACTION,
    SET_LOCAL_FINALIZED_SELECTED_ACTION,
} from '../reducers/iiaFiltersReducer';

// fallback state
export const defaultState = {
    startYearSelected: "",
    endYearSelected: "",
    countrySelected: "",
    institutionSelected: "",
    agreementIdSelected: "",
    ewpIdSelected: "",
    localFinalizedSelected: "",
    statusSelected: "",
    ewpStatusSelected: "",
    departmentSelected: "",
    departmentalCoordinatorSelected: "",
    countriesList: [],
    ewpStatusesList: [],
    departments: [],
    academicYears: [],
    institutionsList: [],
    departmentalCoordinatorsList: [],
}

/**
 * Used to handle filters for IIA queries
 * 
 * @param {Object} initialState the initial state of the filters, fallbacks to a default state with empty string and lists if no initial state is provided
 * @returns Filters state as well as handlers for changing the state
 */
export const useIiaFilters = (initialState = defaultState) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setStartYearSelected = useCallback((data) => {
        dispatch({type: SET_START_YEAR_SELECTED_ACTION, payload: { startYearSelected: data }});
    }, []);

    const setEndYearSelected = useCallback((data) => {
        dispatch({type: SET_END_YEAR_SELECTED_ACTION, payload: { endYearSelected: data }});
    }, []);

    const setCountrySelected = useCallback((data) => {
        dispatch({type: SET_COUNTRY_SELECTED_ACTION, payload: { countrySelected: data }});
    }, []);

    const setInstitutionSelected = useCallback((data) => {
        dispatch({type: SET_INSTITUTION_SELECTED_ACTION, payload: { institutionSelected: data }});
    }, []);
    
    const setStatusSelected = useCallback((data) => {
        dispatch({type: SET_STATUS_SELECTED_ACTION, payload: { statusSelected: data }});
    }, []);

    const setEwpStatusSelected = useCallback((data) => {
        dispatch({type: SET_EWP_STATUS_SELECTED_ACTION, payload: { ewpStatusSelected: data }});
    }, []);

    const setDepartmentSelected = useCallback((data) => {
        dispatch({type: SET_DEPARTMENT_SELECTED_ACTION, payload: { departmentSelected: data }});
    }, []);

    const setDepartmentalCoordinatorSelected = useCallback((data) => {
        dispatch({type: SET_DEPARTMENTAL_COORDINATOR_SELECTED_ACTION, payload: { departmentalCoordinatorSelected: data }});
    }, []);

    const setCountriesList = useCallback((data) => {
        dispatch({type: SET_COUNTRIES_LIST_ACTION, payload: { countriesList: data }});
    }, []);

    const setDepartments = useCallback((data) => {
        dispatch({type: SET_DEPARTMENTS_ACTION, payload: { departments: data }});
    }, []);

    const setAcademicYears = useCallback((data) => {
        dispatch({type: SET_ACADEMIC_YEARS_ACTION, payload: { academicYears: data }});
    }, []);

    const setInstitutionsList = useCallback((data) => {
        dispatch({type: SET_INSTITUTIONS_LIST_ACTION, payload: { institutionsList: data }});
    }, []);

    const setDepartmentalCoordinatorsList = useCallback((data) => {
        dispatch({type: SET_DEPARTMENTAL_COORDINATORS_LIST_ACTION, payload: { departmentalCoordinatorsList: data }});
    }, []);

    const setEwpStatusesList = useCallback((data) => {
        dispatch({type: SET_EWP_STATUSES_LIST_ACTION, payload: { ewpStatusesList: data }});
    }, []);

    const updateMultipleValues = useCallback((data) => {
        dispatch({type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data})
    }, []);

    const setAgreementIdSelected = useCallback((data) => {
        dispatch({type: SET_AGREEMENT_ID_SELECTED_ACTION, payload: { agreementIdSelected: data }});
    }, []);

    const setEwpIdSelected = useCallback((data) => {
        dispatch({type: SET_EWP_ID_SELECTED_ACTION, payload: { ewpIdSelected: data }});
    }, []);

    const setLocalFinalizedSelected = useCallback((data) => {
        dispatch({ type: SET_LOCAL_FINALIZED_SELECTED_ACTION, payload: { localFinalizedSelected: data } })
    }, []);

    return {
        state, 
        setStartYearSelected, 
        setEndYearSelected, 
        setCountrySelected, 
        setInstitutionSelected,
        setStatusSelected,
        setEwpStatusSelected,
        setEwpStatusesList,
        setDepartmentSelected,
        setDepartmentalCoordinatorSelected,
        setCountriesList,
        setDepartments,
        setAcademicYears,
        setInstitutionsList,
        setDepartmentalCoordinatorsList,
        updateMultipleValues,
        setAgreementIdSelected,
        setEwpIdSelected,
        setLocalFinalizedSelected
    };
}