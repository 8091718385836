import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import PDFDocument from './PDFDocument'

const generatePDFDocument = async (agreement,factsheets) => {
    const blob = await pdf(
      <PDFDocument agreement={agreement} factsheets={factsheets}/>
    ).toBlob();
    
    saveAs(blob, "IIA_"+agreement.id);
  };

export default generatePDFDocument;