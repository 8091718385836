import { Fragment, useRef } from 'react';
import EditSingleRequirement from './EditSingleRequirement';

const EditAdditionalRequirements = ({ children, handleStep, handleChange, data }) => {
    const language = useRef('en');
    
    const handleSubmit = (e) => {
        e.preventDefault();
        handleStep();
    }
    
    const handleLocalChange = (index, propertyName, newValue) => {
        const newArray = Array.from(data.additionalRequirements);
        newArray.splice(index, 1, { ...data.additionalRequirements[index], [propertyName]: newValue })
        
        handleChange('additionalRequirements', newArray);
    }

    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={handleSubmit}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                        <h3 className="text-center">Additional Requirements</h3>
                            {data.additionalRequirements.map((requirement, idx) => {
                                return (
                                    <Fragment key={`${idx}`} >
                                        <div className="d-flex justify-content-around">
                                            <h5 className="text-center">{`Requirement ${idx+1}`}</h5>
                                            <button className='btn btn-danger btn-sm mb-2' onClick={(e) => {
                                                e.preventDefault()
                                                const newArray = Array.from(data.additionalRequirements);
                                                newArray.splice(idx, 1)
                                                
                                                handleChange('additionalRequirements', newArray);
                                            }}>
                                                <i className="fa fa-trash mr-2"></i>
                                                Remove requirement
                                            </button>
                                        </div>
                                        <EditSingleRequirement requirement={requirement} language={language.current} index={idx} handleChange={handleLocalChange}/>
                                        <hr />
                                    </Fragment>
                                );
                            })}
                            <div className="d-flex justify-content-center">
                                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={(e) => {
                                    e.preventDefault()
                                    handleChange('additionalRequirements', [...data.additionalRequirements, { name: '', description: '' }])
                                }}>
                                    <i className="fa fa-plus mr-2"></i>
                                    Add requirement
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                {
                    { ...children }
                }
            </div>
        </form>
    );
}

export default EditAdditionalRequirements;
