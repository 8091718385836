export default function StudentMobilityFundingComponentTranslation(fieldName, langcode) {

    const fields = {
        bank: {
            en: {
                label: "Bank",
                placeholder: "Enter the name of the bank where you hold a personal bank account"
            },
            gr: {
                label: "Τράπεζα",
                placeholder: "Εισάγετε το όνομα της τράπεζας στην οποία διατηρείτε λογαριασμό"
            }
        },
        iban: {
            en: {
                label: "IBAN",
                placeholder: "Enter IBAN of the bank account that you are a holder of."
            },
            gr: {
                label: "IBAN",
                placeholder: "Εισάγετε το IBAN του λογαριασμού στον οποίο είστε δικαιούχος"
            }
        },
        accountNumber: {
            en: {
                label: "Bank account number",
                placeholder: "Enter the number of the bank account that you are a holder of."
            },
            gr: {
                label: "Αριθμός τραπεζικού λογαριασμού",
                placeholder: "Εισάγετε αριθμό του τραπεζικού λογαριασμού στον οποίο είστε δικαιούχος"
            }
        },
        accountOwner: {
            en: {
                label: "Bank account holder",
                placeholder: "Enter the full name of the main holder of the bank account (in case you are not the main holder)"
            },
            gr: {
                label: "Κύριος δικαιούχος του λογαριασμού",
                placeholder: "Εισάγετε το ονοματεπώνυμο του κύριου δικαιούχου του τραπεζικού λογαριασμού (σε περίπτωση που δεν είστε εσείς κύριος δικαούχος)"
            }
        },
        h3_title: {
            en: {
                label: "Bank account details",
            },
            gr: {
                label: "Στοιχεία τραπεζικού λογαριασμού",
            }
        },
    }

    return fields[fieldName][langcode]
}