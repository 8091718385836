import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'

const Languages = ({ agreement }) => {
    var langs = []
    // console.log(agreement)
    agreement.studentMobilitiesForStudies.forEach((i) => {
        i.recommendedLanguageSkills.forEach(j => {
            langs.push({
                code: i?.toInstitution?.erasmusCode,
                subject: j?.subjectAreaWithClarification?.subjectArea?.name,
                lang1: "",
                lang2: j?.language,
                studentMobilitiesForStudies: j?.languageLevel,
                studentMobilitiesForTraineeship: "",
                staffMobilitiesForTeaching: "",
                staffMobilitiesForTraining: ""
            })
        })
    })
    agreement.staffMobilitiesForTraining.forEach((i) => {
        i.recommendedLanguageSkills.forEach(j => {

            langs.push({
                code: i?.toInstitution.erasmusCode,
                subject: j?.subjectAreaWithClarification?.subjectArea?.name,
                lang1: "",
                lang2: j?.language,
                studentMobilitiesForStudies: "",
                studentMobilitiesForTraineeship: "",
                staffMobilitiesForTeaching: "",
                staffMobilitiesForTraining: j?.languageLevel
            })
        })
    })
    agreement.studentMobilitiesForTraineeship.forEach((i) => {
        i.recommendedLanguageSkills.forEach(j => {
            langs.push({
                code: i?.toInstitution?.erasmusCode,
                subject: j?.subjectAreaWithClarification?.subjectArea?.name,
                lang1: "",
                lang2: j?.language,
                studentMobilitiesForStudies: "",
                studentMobilitiesForTraineeship: j?.languageLevel,
                staffMobilitiesForTeaching: "",
                staffMobilitiesForTraining: ""
            })
        })
    })

    agreement.staffMobilitiesForTeaching.forEach((i) => {
        i.recommendedLanguageSkills.forEach(j => {
            langs.push({
                code: i?.toInstitution?.erasmusCode,
                subject: j?.subjectAreaWithClarification?.subjectArea?.name,
                lang1: "",
                lang2: j?.language,
                studentMobilitiesForStudies: "",
                studentMobilitiesForTraineeship: "",
                staffMobilitiesForTeaching: j?.languageLevel,
                staffMobilitiesForTraining: ""
            })
        })
    })

    return (
        <Table
            data={langs}>
            <TableHeader >
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Receiving institution
                    [Erasmus code or city]
                </TableCell >
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Optional: Subject area
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Language of instruction 1
                    (Official Language of Instruction)
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Language of instruction 2
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Student Mobility for Studies
                    [Minimum recommended level: B1]
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Student Mobility for Traineeship
                    [Minimum recommended level: B2]
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Staff Mobility for Teaching
                    [Minimum recommended level: B2]
                </TableCell>
                <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                    Staff Mobility for Training
                    [Minimum recommended level: B2]
                </TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.code} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.subject} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.lang1} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.lang2} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.studentMobilitiesForStudies} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.studentMobilitiesForTraineeship} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.staffMobilitiesForTeaching} />
                <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.staffMobilitiesForTraining} />

            </TableBody>
        </Table>





    );
};



export default Languages