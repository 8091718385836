import { useEffect, useState } from 'react';
import SelectWithLabel from 'components/UI/SelectWithLabel'

export function TextInput({
    parentSetValueSelected,
    parentValueSelected = "",
    inputLabel = "",
    placeholderText = "",
    componentId = "",
    disabled = false,
    exceptThisSymbols = [],
    type = "text",
    required = false
}) {

    // const [valueSelected, setValueSelected] = useState(null);

    // useEffect(() => {
    //     if (valueSelected) {
    //         parentSetValueSelected(valueSelected)
    //     }
    //     else {
    //         parentSetValueSelected(null)
    //     }

    // }, [valueSelected])

    return (
        <div>
            <div className="form-group">
                <label for={componentId}>{inputLabel}</label>
                <input type={type}
                    // value={valueSelected ? valueSelected : ""}
                    value={parentValueSelected ? parentValueSelected : ""}
                    className="form-control"
                    disabled={disabled}
                    id={componentId}
                    placeholder={placeholderText}
                    onChange={(e) => {
                        if (e !== null) {
                            // This only works for multiple values in state
                            parentSetValueSelected(componentId, e.target.value)
                        }
                        else {
                            parentSetValueSelected(componentId, null)
                        }
                    }}
                    onKeyDown={e => {
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }}
                    required={required}
                    min="1"
                    step="1"
                />
            </div>
        </div>
    )

}
