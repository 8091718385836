import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import {
    Text
} from '@react-pdf/renderer';
import {splitCooperationConditionsOnSubjectArea} from 'components/Common/CommonFunctions'


const StudentMobilities = ({ studentMobilities, purpose, styles }) => {
    return (
        <>
            <Text style={styles.h4}>Student Mobility for {purpose}</Text>

            <Table
                data={
                    studentMobilities && splitCooperationConditionsOnSubjectArea(studentMobilities).map(studentMobility => ({
                        from: <Text><Text style={styles.em}>Erasmus Code:</Text>  {studentMobility.fromInstitution.erasmusCode} <Text style={styles.em}>{'\n'} Department: </Text> {studentMobility?.fromDepartment?.name}</Text>,
                    to: <Text><Text style={styles.em}>Erasmus Code:</Text>  {studentMobility.toInstitution.erasmusCode} <Text style={styles.em}>{'\n'} Department: </Text> {studentMobility?.toDepartment?.name}</Text>,
                    subjectArea: studentMobility?.subjectAreaWithClarification?.subjectArea?.name + " [" + studentMobility?.subjectAreaWithClarification?.subjectArea?.code + "]",
                    clarification: studentMobility?.subjectAreaWithClarification?.clarification,
                    studyCycle: studentMobility?.studyCycles?.map((c) =>c.studyCycle).join().replaceAll(',', ' \n'),

                        otherInfoTerms: studentMobility.otherInfoTerms,
                        blended: studentMobility.blended === true ? "Yes" : "No",
                        mobilitiesPerYear: studentMobility.mobilitiesPerYear,
                        totalMonthsPerYear: studentMobility.totalMonthsPerYear
                    }))}>
                <TableHeader >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        FROM
                        [Erasmus code and department if provided]
                    </TableCell >
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        TO
                        [Erasmus code and department if provided]
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Subject area
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Clarification
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Study cycle
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Other Info Terms
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Blended (*)
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Total number of students
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Total number of months
                    </TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.from} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.to} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.subjectArea} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.clarification} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.studyCycle} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.otherInfoTerms} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.blended} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.mobilitiesPerYear} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.totalMonthsPerYear} />
                </TableBody>
            </Table>
            <Text>{"\n"}</Text>
            <Text>{"\n"}</Text>
        </>

    );
};



export default StudentMobilities;