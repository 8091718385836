import { useEffect, useState } from "react";
import { TextInput } from "components/UI/ComplexComponents/TextInput";
import { DropdownSelect } from "components/UI/ComplexComponents/DropdownSelect";
import StudentLanguageSkillsComponentTranslation from "../translations/StudentLanguageSkillsComponentTranslation";

function StudentLanguageSkillsComponent({ children, langCode, view, handleStep, studentStudiesForm, setStudentStudiesForm, languages, saveDraft }) {
    const initialState = [{
        language: "",
        certificateTitle: "",
        certificateLevel: "",
        stateCertificate: "",
        stateCertificateLevel: "",
        id: 1
    }]
    const [studentLanguages, setStudentLanguages] = useState(initialState)

    const certificateOptions = StudentLanguageSkillsComponentTranslation("language_cert_level", langCode)

    const setFieldValue = (item, value) => {
        setStudentLanguages(current =>
            current.map(obj => {
                if (obj.id == item.split('_')[1]) {
                    return { ...obj, [item.split('_')[0]]: value };
                }

                return obj;
            }),
        );
    };

    useEffect(() => {
        if (studentStudiesForm?.languageData !== undefined && studentStudiesForm?.languageData.length > 0) {
            const temp = JSON.parse(JSON.stringify(studentStudiesForm?.languageData))
            console.log(temp)

            let certificateOpts = []
            for (let i of certificateOptions) {
                certificateOpts.push(...Object.values(i))
            }

            temp.forEach((item) => {
                if (item['certificateLevel'] !== "" && item['certificateLevel'] !== null) {
                    item['certificateLevel'] = certificateOpts.filter(level => level.includes(item['certificateLevel']))[0];
                }
                if (item['stateCertificateLevel'] !== "" && item['stateCertificateLevel'] !== null) {
                    item['stateCertificateLevel'] = certificateOpts.filter(level => level.includes(item['stateCertificateLevel']))[0];
                }
                if (langCode === "gr") {
                    item['stateCertificate'] = item['stateCertificate'] == 1 ? 'Ναι' : item['stateCertificate'] == 0 ? 'Οχι' : ''
                    item.language = item.language?.languageNameGR
                } else {
                    item['stateCertificate'] = item['stateCertificate'] == 1 ? 'Yes' : item['stateCertificate'] === 0 ? 'No' : ''
                    item.language = item.language?.languageName
                }
                console.log(item.language)
            })
            setStudentLanguages(temp)
        } else {
            setStudentLanguages(initialState)
        }
    }, [])

    useEffect(() => {
        console.log('studentStudiesForm')
        console.log(studentStudiesForm)
    }, [])

    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={async (event) => {
            event.preventDefault();
            const tempLanguageData = JSON.parse(JSON.stringify(studentLanguages))

            tempLanguageData.forEach((item) => {
                let tempLanguage = ''
                console.log(item)
                if (item['certificateLevel'] !== "" && item['certificateLevel'] !== null) {
                    item['certificateLevel'] = item?.certificateLevel.split(' -')[0].replace(/\s+/g, '');
                }
                if (item['stateCertificateLevel'] !== "" && item['stateCertificateLevel'] !== null) {
                    item['stateCertificateLevel'] = item?.stateCertificateLevel.split(' -')[0].replace(/\s+/g, '');
                }
                if (langCode === "gr") {
                    item['stateCertificate'] = item['stateCertificate'] === 'Ναι' ? 1 : item['stateCertificate'] === 'Οχι' ? 0 : ''
                    tempLanguage = languages.find((i) => i.languageNameGR === item.language)
                }
                else {
                    item['stateCertificate'] = item['stateCertificate'] === 'Yes' ? 1 : item['stateCertificate'] === 'No' ? 0 : ''
                    tempLanguage = languages.find((i) => i.languageName === item.language)
                }
                item['language'] = tempLanguage
            })

            console.log(tempLanguageData)

            setStudentStudiesForm({ ...studentStudiesForm, languageData: tempLanguageData })
            var activeElement = document.activeElement;
            if (activeElement.value === 'draft') {
                saveDraft({ ...studentStudiesForm, languageData: tempLanguageData })
            } else {
                handleStep();
            }
        }}>
            <div className="containter">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h3 className="text-center">{StudentLanguageSkillsComponentTranslation("h3_title", langCode)['label']}</h3>
                            <div className="form-group">
                                {studentLanguages?.length > 0 && studentLanguages?.map((r, idx) => {
                                    return (
                                        <div key={idx + 1}>
                                            <h5 className="text-left">{StudentLanguageSkillsComponentTranslation("language", langCode)['label']} {idx + 1}</h5>
                                            {
                                                DropdownSelect({
                                                    optionsList: languages ? langCode === "gr" ? languages.map(i => ({ label: i?.languageNameGR })) : langCode === "en" ? languages.map(i => ({ label: i?.languageName })) : [] : [],
                                                    parentSetValueSelected: setFieldValue,
                                                    parentValueSelected: studentLanguages[idx].language,
                                                    selectLabel: StudentLanguageSkillsComponentTranslation("language", langCode)['label'],
                                                    componentId: "language_" + Number(r.id),
                                                    disabled: view
                                                })
                                            }
                                            {
                                                TextInput({
                                                    parentSetValueSelected: setFieldValue,
                                                    parentValueSelected: studentLanguages[idx].certificateTitle,
                                                    inputLabel: StudentLanguageSkillsComponentTranslation("languageCertificateTitle", langCode)['label'],
                                                    componentId: "certificateTitle_" + Number(r.id),
                                                    type: "text",
                                                    disabled: view
                                                })
                                            }
                                            {
                                                DropdownSelect({
                                                    optionsList: certificateOptions,
                                                    parentSetValueSelected: setFieldValue,
                                                    parentValueSelected: studentLanguages[idx].certificateLevel,
                                                    selectLabel: StudentLanguageSkillsComponentTranslation("languageCertificateLevel", langCode)['label'],
                                                    componentId: "certificateLevel_" + Number(r.id),
                                                    disabled: view
                                                })
                                            }
                                            {
                                                DropdownSelect({
                                                    optionsList: StudentLanguageSkillsComponentTranslation("language_knowledge_opts", langCode),
                                                    parentSetValueSelected: setFieldValue,
                                                    parentValueSelected: studentLanguages[idx].stateCertificate,
                                                    selectLabel: StudentLanguageSkillsComponentTranslation("certificateOfLanguageProficiency", langCode)['label'],
                                                    componentId: "stateCertificate_" + Number(r.id),
                                                    disabled: view
                                                })
                                            }
                                            {
                                                DropdownSelect({
                                                    optionsList: certificateOptions,
                                                    parentSetValueSelected: setFieldValue,
                                                    parentValueSelected: studentLanguages[idx].stateCertificateLevel,
                                                    selectLabel: StudentLanguageSkillsComponentTranslation("levelOfLanguageProficiency", langCode)['label'],
                                                    componentId: "stateCertificateLevel_" + Number(r.id),
                                                    disabled: view
                                                })
                                            }
                                        </div>
                                    )
                                })}
                                <button className='btn btn-success m-2' type="button" disabled={view} onClick={() => {
                                    var temp = [...studentLanguages]
                                    temp.push({
                                        language: "",
                                        certificateTitle: "",
                                        certificateLevel: "",
                                        stateCertificate: "",
                                        stateCertificateLevel: "",
                                        id: studentLanguages.length + 1
                                    })
                                    setStudentLanguages(temp)
                                }}><i className="fa fa-plus mr-2"></i> {StudentLanguageSkillsComponentTranslation("languageAdd", langCode)['label']}</button>
                                {studentLanguages?.length > 1 && <button className='btn btn-danger m-2' type="button" disabled={view} onClick={() => {
                                    var temp = [...studentLanguages]
                                    temp.splice(studentLanguages.length - 1, 1)
                                    setStudentLanguages(temp)
                                }}><i class="fa fa-trash"></i> {StudentLanguageSkillsComponentTranslation("languageRemove", langCode)['label']}</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </form>
    );
}

export default StudentLanguageSkillsComponent