import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap';
const ActiveFactsheet = ({ activeFactsheet, handleDuplication }) => {
        return (<>{
            activeFactsheet ? 
            <div className="mb-2">
                <div className="row">
                    <p className="font-weight-bold">Active Factsheet</p>
                </div>
                <div className="row filter-sidebar pt-3 pb-3 h-100">
                    <div className="col-md-2 pl-0 text-center align-self-center">{activeFactsheet?.institution_erasmusCode}</div>
                    <div className="col-md-3 pl-0 text-center align-self-center">{activeFactsheet?.institutionalCoordinatorContact_givenName}</div>
                    <div className="col-md-3 pl-0 text-center align-self-center">{activeFactsheet?.institutionalCoordinatorContact_familyName}</div>
                    <div className="col-md-2 pl-0 text-center d-flex justify-content-between align-self-center">
                        <Link to={`/factsheets/edit/${activeFactsheet.id}`}><i className='fa fa-pencil fa-primary'></i></Link>
                        <Link to={`/factsheets/view/${activeFactsheet?.id}`}><i className='fa fa-eye fa-primary'></i></Link>
                        <Link to="/factsheets" onClick={handleDuplication(activeFactsheet?.id)}><i className='fa fa-clone fa-primary' aria-hidden="true"></i></Link>
                    </div>
                    <div className="col-md-2 pl-0 pr-2 text-center align-self-center">
                        <Button size="sm" style={{ backgroundColor: "#CE2D4F", color: "white", border: "none", cursor: "default" }}>
                            Active
                        </Button>
                    </div>
                </div>
            </div> 
            : <></>
        }</>)
}

export default ActiveFactsheet;