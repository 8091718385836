import { useState, useEffect } from "react";
import { TextInput } from "components/UI/ComplexComponents/TextInput";
import StudentMobilityFundingComponentTranslation from "../translations/StudentMobilityFundingComponentTranslation";

function StudentMobilityFundingComponent({ children, langCode, view, handleStep, studentStudiesForm, setStudentStudiesForm, saveDraft }) {
    const initialState = {
        bank: "",
        iban: "",
        bankAccountNumber: "",
        bankAccountHolder: ""
    }
    const [mobilityFunding, setMobilityFunding] = useState(initialState)

    function setFieldValue(setterFunc) {

        return function (item, value) {
            setterFunc({ ...mobilityFunding, [item]: value })
        }
    }

    useEffect(() => {
        if (studentStudiesForm?.paymentData !== undefined && studentStudiesForm?.paymentData.length > 0) {
            setMobilityFunding((studentStudiesForm && studentStudiesForm?.paymentData) ? studentStudiesForm.paymentData[0] : initialState)
        } else {
            setMobilityFunding(initialState)
        }
    }, [])

    useEffect(() => {
        console.log(studentStudiesForm)
    }, [])


    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={async (event) => {
            event.preventDefault();

            setStudentStudiesForm({ ...studentStudiesForm, paymentData: [mobilityFunding] })
            var activeElement = document.activeElement;
            if (activeElement.value === 'draft') {
                saveDraft({ ...studentStudiesForm, paymentData: [mobilityFunding] })
            } else {
                handleStep();
            }
        }}>
            <div className="containter">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h3 className="text-center">{StudentMobilityFundingComponentTranslation("h3_title", langCode)['label']}</h3>
                            <div className="form-group">
                                {
                                    TextInput({
                                        parentSetValueSelected: setFieldValue(setMobilityFunding),
                                        parentValueSelected: mobilityFunding.bank,
                                        inputLabel: StudentMobilityFundingComponentTranslation("bank", langCode)['label'],
                                        componentId: "bank",
                                        type: "text",
                                        disabled: view
                                    })
                                }
                                {
                                    TextInput({
                                        parentSetValueSelected: setFieldValue(setMobilityFunding),
                                        parentValueSelected: mobilityFunding.iban,
                                        inputLabel: StudentMobilityFundingComponentTranslation("iban", langCode)['label'],
                                        componentId: "iban",
                                        type: "text",
                                        disabled: view
                                    })
                                }
                                {
                                    TextInput({
                                        parentSetValueSelected: setFieldValue(setMobilityFunding),
                                        parentValueSelected: mobilityFunding.bankAccountNumber,
                                        inputLabel: StudentMobilityFundingComponentTranslation("accountNumber", langCode)['label'],
                                        componentId: "bankAccountNumber",
                                        type: "text",
                                        disabled: view
                                    })
                                }
                                {
                                    TextInput({
                                        parentSetValueSelected: setFieldValue(setMobilityFunding),
                                        parentValueSelected: mobilityFunding.bankAccountHolder,
                                        inputLabel: StudentMobilityFundingComponentTranslation("accountOwner", langCode)['label'],
                                        componentId: "bankAccountHolder",
                                        type: "text",
                                        disabled: view
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </form>
    );
}

export default StudentMobilityFundingComponent