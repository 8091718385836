import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'

const Institutions = ({ institutions, factsheets, departmentalCoordinators }) => {
    return (
        <>
            <Table
                data={
                    institutions.map(i => {
                        const factsheet = factsheets.find(f => f.institutionId === i.id).factsheet;

                        const departmentalCoordinatorList = departmentalCoordinators.filter(c => c?.department?.institution?.id === i?.id);
                        return ({
                            institution: i.name,
                            erasmusCode: i.erasmusCode,
                            coordinator: ((departmentalCoordinatorList && departmentalCoordinatorList !== null) ?
                                departmentalCoordinatorList.map(departmentalCoordinator => ("Coordinator"
                                    + "\nTitle:" + (departmentalCoordinator.title ? departmentalCoordinator.title : "-")
                                    + "\nFull name: " + (departmentalCoordinator.givenName + " " + departmentalCoordinator.familyName)
                                    + "\nAddress: " + (departmentalCoordinator.address ? departmentalCoordinator.address : "-")
                                    + "\nTel: " + (departmentalCoordinator.phone ? departmentalCoordinator.phone : "-")
                                    + "\nEmail: " + (departmentalCoordinator.email ? departmentalCoordinator.email : "-") + "\n\n")).join('').replace() : ""),
                            general: "Erasmus Office Website: " + ((factsheet && factsheet.institutionalCoordinatorContact?.url) ? factsheet.institutionalCoordinatorContact.url : "-") +
                                "\n General University Webpage: " + (factsheet?.generalUrl ? factsheet.generalUrl : "-") +
                                "\n For Erasmus+ students: " + (factsheet?.facultyUrl ? factsheet.facultyUrl : "-") +
                                "\n Course catalogue: " + (factsheet?.courseCatalogueUrl ? factsheet.courseCatalogueUrl : "-")
                        });
                    })
                }>
                <TableHeader >
                    <TableCell weighting={0.4} style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "1px" }}>
                        Full name of the institution / country
                    </TableCell >
                    <TableCell weighting={0.5} style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Erasmus code or city
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Contact details (email, phone)
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#3A435E", color: "white", fontSize: "10", padding: "2px" }}>
                        Website (eg. of the course catalogue)
                    </TableCell>

                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} weighting={0.4} getContent={(r) => r.institution} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} weighting={0.5} getContent={(r) => r.erasmusCode} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.coordinator} />
                    <DataTableCell style={{ fontSize: "9", padding: "2px" }} getContent={(r) => r.general} />
                </TableBody>
            </Table>

        </>
    );
};




export default Institutions