import { useEffect } from 'react'

/**
 *
 * @param errorMessage
 * @returns {JSX.Element}
 * @constructor
 */
function ErrorPage ({ errorTitle, errorMessage, title }) {
  useEffect(() => {
    document.title = title;
  })

  return (
    <div className="container">
      <div className="row">
        <div className="text-center mx-auto">
            <h1 className="text-danger">{errorTitle}</h1>
            <p className="text-danger">{errorMessage}</p>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;