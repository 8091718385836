import Loader from "react-loader-spinner";
import { useCallback, useRef, useState, useEffect, Fragment } from 'react';
import { Button } from 'react-bootstrap'
import { ReactDataContext } from '@themost/react';
import Institution, { fallbackInstitution as institutionDefaultState } from '../UI/Institution';
import InstitutionalCoordinatorContact, { fallbackCoordinator as institutionalCoordinatorContactDefaultState } from "../UI/InstitutionalCoordinatorContact";
import Deadline, { deadlineDefaultState } from "../UI/Deadline";
import Information, { informationEntryDefaultState } from "../UI/Information";
import Title from "../UI/Title";
import UsefulLinks from "../UI/UsefulLinks";
import AdditionalInformation from "../UI/AdditionalInformation";
import Accessibilities from "../UI/Accessibilities";
import { Redirect } from 'react-router-dom';
import { getFactsheetEndpoint } from "components/Common/CommonFunctions";

const additionalRequirementObjectDefaultState = {
    name: null,
    description: null,
    urls: [
        {
            value: null,
            language: null
        }
    ]
}

const FactsheetView = ({
    userService,
    match,
    numberingType = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'],
    subnumberingType = ['1'],
    editable = false,
    title
}) => {
    const [factsheet, setFactsheet] = useState(null);
    const [error, setError] = useState(false);
    const [ewpFetchError, setEwpFetchError] = useState(false);
    const [ewpFetchLoading, setFetchEwpLoading] = useState(false);
    const [ewpFetchSuccess, setEwpFetchSuccess] = useState(false);

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(userService.getToken());
    const params = match.params

    useEffect(() => {
        document.title = title;
    }, [])

    const handleSingleArithmeticValueChange = useCallback((propertyName) => {
        return (e) => {
            if (!editable)
                return;

            if (/[a-zA-Z]/g.test(e.target.innerText) === true) {
                setFactsheet((prevState) => {
                    return {
                        ...factsheet,
                        [`${propertyName}`]: prevState[`${propertyName}`] === null ? 0 : null
                    }
                })
                return;
            }

            if (isNaN(parseInt(e.target.innerText))) {
                setFactsheet((prevState) => {
                    return {
                        ...factsheet,
                        [`${propertyName}`]: prevState[`${propertyName}`] === null ? 0 : null
                    }
                })
                return;
            }

            setFactsheet({
                ...factsheet,
                [`${propertyName}`]: parseInt(e.target.innerText)
            })
        }
    }, [factsheet]);

    const handleSingleValueChange = (key, newValue) => {
        setFactsheet({
            ...factsheet,
            [`${key}`]: newValue
        })
    }

    const handleObjectValueChange = useCallback((propertyName, defaultState) => {
        return (key, newValue) => {
            setFactsheet((prevState) => {
                return {
                    ...factsheet, // TODO spread prevState instead of factsheet
                    [`${propertyName}`]: {
                        ...(
                            prevState?.[`${propertyName}`]
                                ? prevState[`${propertyName}`]
                                : defaultState
                        ),
                        [`${key}`]: newValue
                    }
                }
            })
        }
    }, [factsheet]);

    const handleArrayOfObjectsValueChange = (propertyName, objectDefaultState, index) => {
        return (key, newValue) => {
            setFactsheet((prevState) => {
                const keyPreviousState = prevState[`${propertyName}`][index];
                const newArray = Array.from(prevState[`${propertyName}`]);
                newArray.splice(index, 1, { ...objectDefaultState, ...keyPreviousState, [`${key}`]: newValue })

                return {
                    ...factsheet, // TODO spread prevState instead of factsheet
                    [`${propertyName}`]: prevState[`${propertyName}`].length === 0
                        ? [{ ...objectDefaultState, ...keyPreviousState, [`${key}`]: newValue }]
                        : [...newArray]
                }
            })
        }
    };

    const updateFactsheetData = async () => {
        if (factsheet.institution.hasOwnProperty('heiId')) {
            console.log(factsheet)
            setFetchEwpLoading(true);
            const response = await fetch(`${getFactsheetEndpoint(factsheet.institution?.heiId)}?heiId=${factsheet.institution?.heiId}&factsheet=${factsheet?.id}`)
            const data = await response.json();
            
            
            if(data.error || data.response.statusCode != 200) {
                setFetchEwpLoading(false);
                setEwpFetchError(true);
                return;
            }
            
            try {
                const newFactsheet = JSON.parse(data.response.body);
                setFactsheet(prevFactsheet => ({
                    ...prevFactsheet,
                    ...newFactsheet
                }))
                setEwpFetchSuccess(true);
                setTimeout(() => {
                    setEwpFetchSuccess(false);
                    setFetchEwpLoading(false);
                }, 2000);
            } catch(error) {
                setFetchEwpLoading(false);
                setEwpFetchError(true);   
            }
        };
 
    }

    useEffect(() => {

        (async () => {
            try {
                const factsheetResult = await context.model('MobilityFactsheets').where('id').equal(params.id).take(1).getList();

                if (factsheetResult.value.length === 0) {
                    setError(true);
                    return;
                }

                setFactsheet(factsheetResult.value[0]);
            } catch (err) {
                setError(true);
                console.log(err);
            }
        })()
    }, [])

    if (error) {
        return (
            <Redirect to="/factsheets" />
        );
    }

    if (factsheet === null) {
        return (
            <Loader
                className="spinner mx-auto text-center"
                type="TailSpin"
                color="#3A435E"
                height={70}
                width={70}
            />
        );
    }

    if (!factsheet?.institution?.isLocal && editable) {
        return (
            <Redirect to="/factsheets" />
        );
    }

    return (
        <div className="py-5 print-container">
            <div className="container">
                <div className="row justify-content-around mb-2">
                    <div className="col-md-8">
                        <h1 className="page-header mb-0" align="center">Mobility Factsheet with id: {factsheet.id}</h1>
                    </div>
                    <div className="col-md-4 align-self-center">
                        <Button size="sm" style={{ backgroundColor: "#CE2D4F", color: "white", border: "none", cursor: "default" }}>
                            {factsheet?.isActive ? 'Active' : 'Not active'}
                        </Button>
                    </div>
                </div>

                 <Institution
                    institution={factsheet.institution}
                    number={numberingType[0]}
                    title={'Institution'}
                    userService={userService}
                    editable={editable}
                    handleTdChange={handleObjectValueChange('institution', institutionDefaultState)}
                />

                <InstitutionalCoordinatorContact
                    coordinator={factsheet.institutionalCoordinatorContact}
                    number={numberingType[1]}
                    title={'Institutional Coordinator Contact'}
                    subnumberingType={subnumberingType}
                    userService={userService}
                    editable={editable}
                    handleTdChange={handleObjectValueChange('institutionalCoordinatorContact', institutionalCoordinatorContactDefaultState)}
                />

                <Deadline
                    number={numberingType[2]}
                    title='Deadline for applications of Incoming students'
                    calendarEntry={factsheet.deadlineIncomingsCalendar}
                    editable={editable}
                    handleTdChange={handleObjectValueChange('deadlineIncomingsCalendar', deadlineDefaultState)}
                />

                <Deadline
                    number={numberingType[3]}
                    title='Deadline for Nominations'
                    calendarEntry={factsheet.deadlineNominationsCalendar}
                    editable={editable}
                    handleTdChange={handleObjectValueChange('deadlineNominationsCalendar', deadlineDefaultState)}
                />

                <Title
                    number={numberingType[4]}
                    title='Information for applications of incoming students'
                />
                <Information
                    informationEntry={factsheet.applicationIncomingsInformationEntry}
                    editable={editable}
                    handleTdChange={handleObjectValueChange('applicationIncomingsInformationEntry', informationEntryDefaultState)}
                />

                <Title
                    number={numberingType[5]}
                    title='Additional Requirements'
                />
                {
                    factsheet.additionalRequirements.length !== 0 && Array.isArray(factsheet.additionalRequirements)
                        ?
                        factsheet.additionalRequirements.map((requirement, idx) => 
                            <Fragment key={idx}>
                                {idx !== 0 ? <hr className="border"></hr> : <></>}
                                <Information
                                    titles={['Name', 'Description', 'URL', 'Language']}
                                    informationEntry={requirement}
                                    editable={editable}
                                    handleTdChange={handleArrayOfObjectsValueChange('additionalRequirements', additionalRequirementObjectDefaultState, idx)}
                                />
                            </Fragment>
                        )
                        : (!editable && <Information
                            titles={['Name', 'Description', 'URL', 'Language']}
                            informationEntry={null}
                            editable={editable}
                            handleTdChange={handleArrayOfObjectsValueChange('additionalRequirements', additionalRequirementObjectDefaultState, 0)}
                        />)
                }

                {editable &&
                    <button className="btn btn-previous mb-5 mt-4 d-block mx-auto col-md-3" onClick={() => {
                        setFactsheet(prevState => {
                            return {
                                ...prevState,
                                additionalRequirements: [
                                    ...prevState.additionalRequirements,
                                    {
                                        name: null,
                                        description: null,
                                        urls: [
                                            {
                                                value: null,
                                                language: null
                                            }
                                        ]
                                    }
                                ]
                            }
                        })
                    }}>
                        <i className="fa fa-plus mr-2"></i> Add Additional Requirement
                    </button>
                }

                <Title
                    inline
                    number={numberingType[6]}
                    title='Weeks limit for decision response'
                />
                <h3
                    className="d-inline-block mx-2 align-middle"
                    contentEditable={editable}
                    onBlur={handleSingleArithmeticValueChange('decisionResponseWeeksLimit')}
                >
                    {factsheet.decisionResponseWeeksLimit ?? '-'}
                </h3>

                <div></div>

                <Title
                    inline
                    number={numberingType[7]}
                    title='Weeks limit for transcript of records'
                />
                <h3
                    className="d-inline-block mx-2 align-middle"
                    contentEditable={editable}
                    onBlur={handleSingleArithmeticValueChange('transcriptOfRecordsWeeksLimit')}
                >
                    {factsheet.transcriptOfRecordsWeeksLimit ?? '-'}
                </h3>

                <Title
                    number={numberingType[8]}
                    title='Accessibilities'
                />
                <Accessibilities accessibilities={factsheet.accessibilities} editable={editable} />

                <Title
                    number={numberingType[9]}
                    title='Housing Information'
                />
                <Information
                    informationEntry={factsheet.housingInformationEntry}
                    editable={editable}
                    handleTdChange={handleObjectValueChange('housingInformationEntry', informationEntryDefaultState)}
                />

                <Title
                    number={numberingType[10]}
                    title='Visa Information'
                />
                <Information
                    informationEntry={factsheet.visaInformationEntry}
                    editable={editable}
                    handleTdChange={handleObjectValueChange('visaInformationEntry', informationEntryDefaultState)}
                />

                <Title
                    number={numberingType[11]}
                    title='Insurance Information'
                />
                <Information
                    informationEntry={factsheet.insuranceInformationEntry}
                    editable={editable}
                    handleTdChange={handleObjectValueChange('insuranceInformationEntry', informationEntryDefaultState)}
                />

                <Title
                    number={numberingType[12]}
                    title='Additional Information'
                />

                <AdditionalInformation infos={factsheet.additionalInfos} editable={editable} />

                <Title
                    inline={factsheet.terminationOfAgreement ? false : true}
                    number={numberingType[15]}
                    title='Termination of Agreement'
                />
                <p
                    className={factsheet.terminationOfAgreement || editable ? 'mw-100' : 'd-inline-block mx-2 align-middle font-weight-bold'}
                    contentEditable={editable}
                    onBlur={e => setFactsheet({
                        ...factsheet,
                        terminationOfAgreement: e.target.innerText
                    })
                    }
                >
                    {factsheet.terminationOfAgreement ?? '-'}
                </p>

                {factsheet.terminationOfAgreement ? <></> : <div></div>}


                <Title
                    inline={factsheet.gradingSystem ? false : true}
                    number={numberingType[16]}
                    title='Grading System'
                />
                <p
                    className={factsheet.gradingSystem || editable ? 'mw-100' : 'd-inline-block mx-2 align-middle font-weight-bold'}
                    contentEditable={editable}
                    onBlur={e => setFactsheet({
                        ...factsheet,
                        gradingSystem: e.target.innerText
                    })
                    }
                >
                    {factsheet.gradingSystem ?? '-'}
                </p>

                <Title
                    number={numberingType[17]}
                    title='Useful Links'
                />
                <UsefulLinks
                    generalUrl={factsheet.generalUrl}
                    facultyUrl={factsheet.facultyUrl}
                    courseCatalogueUrl={factsheet.courseCatalogueUrl}
                    editable={editable}
                    handleValueChange={handleSingleValueChange}
                />
                {
                    ewpFetchSuccess &&
                    <div className="row">
                        <div className="col">
                            <h1 className="text-success text-center">Success</h1>
                            <p className="text-success text-center">Successfully updated Factsheet data from EWP.</p>
                        </div>
                    </div>
                }
                {
                    !factsheet.institution.isLocal && !ewpFetchLoading && !ewpFetchError &&
                    <button className="btn btn-secondary btn-exportpdf text-center d-block mx-auto my-0" onClick={() => updateFactsheetData()}>
                        <i className="fa fa-refresh mr-2"></i> Update Factsheet data from EWP
                    </button>
                }
                {
                    ewpFetchLoading && !ewpFetchError && !ewpFetchSuccess &&
                    <Loader
                        className="spinner mx-auto text-center"
                        type="TailSpin"
                        color="#3A435E"
                        height={70}
                        width={70}
                    />
                }
                {
                    !ewpFetchLoading && ewpFetchError &&
                    <div className="row">
                        <div className="col">
                            <h1 className="text-danger text-center">Error</h1>
                            <p className="text-danger text-center">Error while updating Factsheet data from EWP.</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default FactsheetView;