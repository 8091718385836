import Select from 'react-select';

const SelectWithLabel = ({
    id,
    value,
    onSelectChange,
    clearable,
    disabled,
    isOptionDisabled,
    placeholderText,
    children,
    loading,
    isMulti,
    listOptions,
    styles = {},
}) => {


    return (
        <div className='form-group'>
            <label htmlFor={id}>{children}</label>

            <Select
                getOptionValue={option => option.label}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary: '#3A435E',
                        primary25: 'rgba(58, 67, 94, 0.25)'
                    },
                })}
                isDisabled={disabled}
                isMulti={isMulti}
                isLoading={loading}
                isOptionDisabled={isOptionDisabled}
                isClearable={clearable}
                autoFocus={false}
                isSearchable
                value={[{ label: value ? value : '' }]}
                id={id}
                placeholder={placeholderText}
                onChange={onSelectChange}
                options={listOptions}
                styles={styles}
            />

        </div>
    )
}

export default SelectWithLabel