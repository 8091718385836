import {
    Document,
    Page,
    View,
    Text,
    Image,
    StyleSheet
} from '@react-pdf/renderer';
import Institutions from 'components/Iia/Pdf/components/Institutions';
import Calendar from 'components/Iia/Pdf/components/Calendar';
import StudentMobilities from 'components/Iia/Pdf/components/StudentMobilities';
import StaffMobilities from 'components/Iia/Pdf/components/StaffMobilities';
import InformationEntries from 'components/Iia/Pdf/components/InformationEntries';
import Languages from 'components/Iia/Pdf/components/Languages';
import Signing from 'components/Iia/Pdf/components/Signing';
import logo from 'img/mobisis-logo-vector.png'
import DecisionLimitAndTor from 'components/Iia/Pdf/components/DecisionLimitAndTor';
import Accessibility from 'components/Iia/Pdf/components/Accessibility';
import AdditionalRequirements from 'components/Iia/Pdf/components/AdditionalRequirements';
import AdditionalInfo from 'components/Iia/Pdf/components/AdditionalInfo';



const styles = StyleSheet.create({

    container: {
        flex: 1,
        // paddingTop: 20,
        paddingLeft: 15,
        '@media max-width: 400': {
            // paddingTop: 10,
            paddingLeft: 0,
        },
    },
    h3: {
        fontSize: 15,
        fontWeight: 9,
        margin: 10,
        textAlign: 'center'
    },
    h4: {
        fontSize: 13,
        fontWeight: 8,
        margin: 8,
        textAlign: 'center'
    },
    h5: {
        fontSize: 10,
        fontWeight: 6,
        margin: 6
    },
    entryContainer: {
        marginBottom: 10,
    },
    bulletPoint: {
        width: 10,


    },
    detailContainer: {
        flexDirection: 'row',
    },
    itemContent: {
        flex: 1,
    },
    sup: {
        fontSize: '10',
        lineHeight: 'normal',
        verticalAlign: 'super',

    },

    em: {
        fontWeight: 'bold',

    },
    table: {
        width: '100%',
        borderWidth: 2,
        display: 'flex',
        flexDirection: 'column',
        marginVertical: 12
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    cell: {
        borderWidth: 1,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        flexWrap: 'wrap'
    },
    body: {
        // paddingTop: 35,
        // paddingBottom: 65,
        padding: '30pt',
        paddingBottom: '80pt',
        paddingHorizontal: 35,
        fontFamily: "Times-Roman"
    },
    h1: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: 'Times-Roman'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    h2: {
        fontSize: 18,
        fontWeight: 10,
        textAlign: 'center',
        margin: 8,
    },
    subsubtitle: {
        fontSize: 16,
        fontWeight: 10,
        margin: 12,
    },
    text: {
        margin: 9,
        fontSize: 9,
        //textAlign: 'justify',
        fontFamily: 'Times-Roman'


    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
        height: 100,
        width: 50
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});


export default function PDFDocument({ agreement, factsheets }) {

    return (
        <Document>
            <Page style={styles.body} wrap>
                <Text style={styles.header} fixed>
                    <Image
                        style={styles.image}
                        source={
                            logo
                        } />
                    <Text>{"\n"}</Text>
                    Inter-institutional Agreement between Programme Countries
                </Text>
                <Text style={styles.author}></Text>

                <Text style={styles.h2}>
                    Inter-institutional Agreement between Programme Countries

                </Text>
                <Text style={styles.h3}>
                    Agreement duration: {agreement.startYear.id} - {agreement.endYear.id}
                    {"\n"}
                </Text>
                <Text style={styles.text}>
                    The  institutions  agree  to  cooperate  for  the  exchange  of  students  and/or  staff  in
                    the  context  of  the  Erasmus+
                    programme. They commit  to respect the quality requirements of the
                    Erasmus Charter for Higher Education
                    in all aspects related to the organisation and management of the mobility,
                    including automatic recognition of the credits
                    awarded to students by the partner institution
                    as agreed in the Learning Agreement and confirmed in the Transcript
                    of Records,  or  according  to  the  learning  outcomes  of the  modules  completed  abroad,
                    as described  in  the  Course Catalogue, in line with the European Credit
                    Transfer and Accumulation System. The institutions agree on exchanging
                    their mobility related data in line with the technical standards of
                    the European Student Card Initiative.
                </Text>

                <Text style={styles.h3}>{"\n"}A. Information about higher education institutions{"\n \n"}</Text>


                <Institutions institutions={agreement.institutions}
                    departmentalCoordinators={agreement.departmentalCoordinatorContacts} factsheets={factsheets} />
                <View wrap={false}>
                    <Text style={styles.h3}>
                        B.	Mobility numbers for the academic years {agreement.startYear.id} - {agreement.endYear.id}
                    </Text>
                    <Text style={styles.text}>
                        The partners commit to amend the table below in case of changes in the mobility data by no later than the end
                        of January in the preceding academic year
                    </Text>

                    {agreement.studentMobilitiesForStudies.length > 0 && <StudentMobilities studentMobilities={agreement.studentMobilitiesForStudies} purpose={"Studies"} styles={styles} />}
                </View>
                <View wrap={false}>

                    {agreement.studentMobilitiesForTraineeship.length > 0 && <StudentMobilities studentMobilities={agreement.studentMobilitiesForTraineeship} purpose={"Traineeship"} styles={styles} />}
                    <Text>{"\n"}</Text>

                    <Text style={styles.text}>(*) Blended mobility: the partners confirm that they are willing to exchange students who wish to carry out their
                        mobility in a blended format, a combination of a short-term physical mobility with a virtual component.</Text>

                </View>
                <View wrap={false}>
                    {agreement.staffMobilitiesForTeaching.length > 0 && <StaffMobilities staffMobilities={agreement.staffMobilitiesForTeaching} purpose={"Teaching"} styles={styles} />}
                </View>
                <View wrap={false}>

                    {agreement.staffMobilitiesForTraining.length > 0 && <StaffMobilities staffMobilities={agreement.staffMobilitiesForTraining} purpose={"Training"} styles={styles} />}
                    <Text>{"\n"}</Text>
                    <Text style={styles.text}>

                        Optional: subject area code and name and study cycle are optional. {'\n'}Inter-institutional agreements are not compulsory for Student Mobility for Traineeships
                        or Staff Mobility for Training. Institutions may agree to cooperate on the organisation of traineeships; in this case they should indicate the number of
                        students that they intend to send to the partner. Total duration in months/days of the student/staff mobility periods can be indicated if relevant.


                    </Text>
                </View>


                <View wrap={false}>

                    <Text style={styles.h3}>
                        C.	Recommended language skills
                    </Text>
                    <Text style={styles.text}>
                        The sending institution, following agreement with the receiving institution, is responsible for providing support to its nominated candidates
                        so that they can have the recommended language skills at the start of the study or teaching period:
                    </Text>

                    <Languages agreement={agreement} />
                </View>
                <View wrap={false}>

                    <Text style={styles.h3}>D.	Additional requirements</Text>
                    <Text style={styles.h4}>Inclusion and Accessibility</Text>

                </View>
                <View wrap={false}>
                    {agreement.factsheets.map(f =>
                        f.accessibilities.length > 0 && <Accessibility institution={f.institution.name} accessibilitiesTable={f.accessibilities} styles={styles} />
                    )}
                </View>
                <View wrap={false}>
                    <Text style={styles.h4}>Additional Requirements</Text>
                    {agreement.factsheets.map(f =>
                        f.additionalRequirements.length > 0 && <AdditionalRequirements institution={f.institution.name} additionalRequirementsTable={f.additionalRequirements} styles={styles} />
                    )}
                </View>
                <View wrap={false}>
                    <Text style={styles.h3}>E. Calendar</Text>
                    <Text style={styles.text}>
                        1. Applications/information on nominated students must reach the receiving institution by:
                        {"\n"}
                    </Text>
                    <Calendar institutions={agreement.institutions} factsheets={factsheets} calendarType="deadlineIncomingsCalendar" />
                    <Text>{'\n'}</Text>
                </View>
                <View wrap={false}>
                    <Calendar institutions={agreement.institutions} factsheets={factsheets} calendarType="deadlineNominationssCalendar" />
                </View>
                <View wrap={false}>
                    <DecisionLimitAndTor factsheets={agreement.factsheets} styles={styles} />
                </View>

                <View wrap={false}>

                    <Text style={styles.h3}>F. Information</Text>
                    <Text style={styles.h4}>1.	Grading systems of the institutions</Text>
                    <Text style={styles.text}>
                        Receiving higher education institutions need to provide a link to the statistical distribution of grades or make the
                        information available through EGRACONS according to the descriptions in the <a href="https://ec.europa.eu/education/ects/users-guide/docs/ects-users-guide_en.pdf">ECTS users’ guide</a>. The information
                        will facilitate the interpretation of each grade awarded to students and will facilitate the credit transfer by the sending
                        institution.
                    </Text>

                    <Text style={styles.h4}>2. Visa</Text>
                    <Text style={styles.text}>
                        The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the
                        requirements of the Erasmus Charter for Higher Education. Information and assistance can be provided by the following contact points and
                        information sources:
                    </Text>
                    <InformationEntries institutions={agreement.institutions} factsheets={factsheets} informationEntryType="visaInformationEntry" />

                    <Text style={styles.h4}>3. Insurance</Text>
                    <Text style={styles.text}>
                        The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements
                        of the Erasmus Charter for Higher Education. The receiving institution will inform mobile participants of cases in which insurance cover is
                        not automatically provided. Information and assistance can be provided by the following contact points and information sources:
                    </Text>
                    <InformationEntries institutions={agreement.institutions} factsheets={factsheets} informationEntryType="insuranceInformationEntry" />

                    <Text style={styles.h4}>4. Housing</Text>
                    <Text style={styles.text}>
                        The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for
                        Higher Education. Information and assistance can be provided by the following contact points and information sources:
                    </Text>
                    <InformationEntries institutions={agreement.institutions} factsheets={factsheets} informationEntryType="housingInformationEntry" />
                </View>
                <View wrap={false}>
                    <Text style={styles.h4}>Additional information</Text>
                    {agreement.factsheets.map(f =>
                        f.additionalInfos.length > 0 && <AdditionalInfo institution={f.institution.name} additionalInfoTable={f.additionalInfos} styles={styles} />
                    )}
                </View>
                <View wrap={false}>
                    <Text style={styles.h3}>G. SIGNATURES OF THE INSTITUTIONS (legal representatives)</Text>
                    <Signing institutions={agreement.institutions} signings={agreement.signings} />
                </View>
                <Text style={styles.h3}>
                    {"\n"}
                    {"\n"}
                    Termination of the agreement
                </Text>
                <Text style={styles.text}>
                    {"\n"}
                    It is up to the involved institutions to agree on the procedure for modifying or terminating the inter-institutional agreement.
                    However, in the  event
                    of  unilateral  termination,  a  notice  of  at  least  one  academic  year  should  be  given.  This  means  that  a  unilateral  decision  to
                    discontinue the exchanges notified to the other party by 1
                    September 20XX will only take effect as of 1 September 20XX+1. The termination
                    clauses must include the following disclaimer: "Neither the European Commission nor the National Agencies can be held
                    responsible in case of a conflict."
                </Text>
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </Page>
        </Document >
    );
}