import { useState, useCallback, useRef, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useDebouncedPage } from "./hooks/useDebouncedPage";
import { Link, useHistory } from 'react-router-dom'
import { ReactDataContext } from "@themost/react";
import Restricted from "PermissionProvider/Restricted";
import { useStudiesQuery } from "./hooks/useStudiesQuery";
import { useStudiesFilters } from "./hooks/useStudiesFilters";
import { Pagination, Modal, Button } from "react-bootstrap";
import SelectWithLabel from "components/UI/SelectWithLabel";
import StudentStudiesListTranslation from "./StudentStudiesListTranslation";

export default function StudentStudiesList(props) {

    const initialState = {
        idSelected: null,
        statusSelected: null,
        dateSelected: null,
        typeSelected: null,
        mobilitySelected: null,
        fullNameSelected: null
    }

    const getCookie = (name) => {
        const cookie = {}
        document.cookie.split(';').forEach((e) => {
            const [key, value] = e.split('=');
            cookie[key.trim()] = value;
        })
        return cookie[name];
    }

    const [langCode, setLangCode] = useState(getCookie("lang") ? getCookie("lang") : "en")
    const filters = useStudiesFilters(initialState)
    const {
        state,
        setIdSelected,
        setStatusSelected,
        setDateSelected,
        setMobilitySelected,
        setTypeSelected,
        setFullNameSelected,
        setFullNameGreekSelected,
    } = filters;

    const {
        idSelected,
        statusSelected,
        dateSelected,
        typeSelected,
        mobilitySelected,
        fullNameSelected,
        fullNameGreekSelected,
    } = state;

    const { studies, loading, page, total, setPage, updateStudies, setTotal } = useStudiesQuery(filters, props.userService, langCode)

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(props.userService.getToken());

    const [currentPage, setCurrentPage] = useState(0);
    const setPageDebounced = useCallback(useDebouncedPage((page) => setCurrentPage(page), 500), [])
    const topRef = useRef(null);
    const [displayConfirmDelete, setDisplayConfirmDelete] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState(null);

    const removeObject = (arr, id) => {
        const idx = arr.findIndex((i) => i.id === id)

        if (idx > -1) {
            arr.splice(idx, 1);
        }
        return arr
    }
    const history = useHistory();

    const onDelete = async (id) => {
        console.log(studies.filter(i => i.id === id))
        updateStudies(removeObject(studies, id))
        setTotal(total - 1)
        try {
            var raw = { "isDeleted": true, "id": id };
            const study = await context.model('ApplicationStudentStudiesForms').save(raw)
            history.go(0)
            console.log(study)
            setDisplayConfirmDelete(false)
            setToBeDeleted(null)
        } catch (err) {
            console.log(err)
        }
    }

    const test = async () => {
        try {
            const res = await context.model('ApplicationSettings').asQueryable().where("applicationType").equal('short-term-studies').take(-1).getItems();
            console.log(res[0])
        } catch (err) { console.log(err) }
    }

    useEffect(() => {
        test()
    }, [])

    if (loading) {
        return <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Loader
                className="spinner mx-auto"
                type="TailSpin"
                color="#3A435E"
                height={70}
                width={70}
            />
        </div>
    } else {
        let paginationItems = [];
        const pageLimit = 10;
        if (page === 0) {
            for (let i = 0; i < (Math.ceil(total / pageLimit) > 3 ? 3 : Math.ceil(total / pageLimit)); i++) {
                paginationItems.push(
                    <Pagination.Item key={i} active={i === page} onClick={(event) => { setPageDebounced(i); topRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
                        {i + 1}
                    </Pagination.Item>
                );
            }
        } else if (page === Math.ceil(total / pageLimit) - 1) {
            for (let i = Math.ceil(total / pageLimit) - 1; i >= Math.ceil(total / pageLimit) - 3 && i >= 0; i--) {
                paginationItems.push(
                    <Pagination.Item key={i} active={i === page} onClick={(event) => { setPageDebounced(i); topRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
                        {i + 1}
                    </Pagination.Item>
                );
                paginationItems.sort((element1, element2) => {
                    if (element1.key < element2.key) return -1;
                    if (element1.key > element2.key) return 1;
                    return 0;
                })
            }
        } else {
            for (let i = page - 1; i < page + 2; i++) {
                paginationItems.push(
                    <Pagination.Item key={i} active={i === page} onClick={(event) => { setPageDebounced(i); topRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
                        {i + 1}
                    </Pagination.Item>
                );
            }
        }
        return (
            <div className="py-5" ref={topRef}>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-3 bg-light pt-2 filter-sidebar mx-auto h-100">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="font-weight-bold text-center">{StudentStudiesListTranslation("filters", langCode)['label']}</p>
                                </div>
                                <div className="form-group">
                                    <label for="dateSelected">{StudentStudiesListTranslation("applicationDate", langCode)['label']}</label>
                                    <input type='text'
                                        value={dateSelected ? dateSelected : ""}
                                        className="form-control"
                                        id="dateSelected"
                                        onChange={(e) => {
                                            if (e !== null) {
                                                setDateSelected(e.target.value)
                                            }
                                            else {
                                                setDateSelected(null)
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="idSelected">{StudentStudiesListTranslation("id", langCode)['label']}</label>
                                    <input type='text'
                                        value={idSelected ? idSelected : ""}
                                        className="form-control"
                                        id="idSelected"
                                        onChange={(e) => {
                                            if (e !== null) {
                                                setIdSelected(e.target.value)
                                            }
                                            else {
                                                setIdSelected(null)
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <SelectWithLabel
                                        value={statusSelected ? statusSelected : ""}
                                        onSelectChange={(e) => {
                                            if (e !== null) {
                                                console.log(e)
                                                setStatusSelected(e.value);
                                            } else {
                                                setStatusSelected(null);
                                            }
                                        }}
                                        id="statusSelected"
                                        placeholderText="Status"
                                        clearable={true}
                                        listOptions={StudentStudiesListTranslation("status_opts", langCode)['label']}
                                    >
                                        {StudentStudiesListTranslation("status", langCode)['label']}
                                    </SelectWithLabel>
                                </div>
                                <div className="form-group">
                                    <label for="fullNameSelected">{StudentStudiesListTranslation("fullName", langCode)['label']}</label>
                                    <input type='text'
                                        value={fullNameSelected ? fullNameSelected : ""}
                                        className="form-control"
                                        id="fullNameSelected"
                                        onChange={(e) => {
                                            if (e !== null) {
                                                setFullNameSelected(e.target.value)
                                            }
                                            else {
                                                setFullNameSelected(null)
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="fullNameGreekSelected">{StudentStudiesListTranslation("fullNameGreek", langCode)['label']}</label>
                                    <input type='text'
                                        value={fullNameGreekSelected ? fullNameGreekSelected : ""}
                                        className="form-control"
                                        id="fullNameGreekSelected"
                                        onChange={(e) => {
                                            if (e !== null) {
                                                setFullNameGreekSelected(e.target.value)
                                            }
                                            else {
                                                setFullNameGreekSelected(null)
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <SelectWithLabel
                                        value={typeSelected ? typeSelected : ""}
                                        onSelectChange={(e) => {
                                            if (e !== null) {
                                                console.log(e)
                                                setTypeSelected(e.value);
                                            } else {
                                                setTypeSelected(null);
                                            }
                                        }}
                                        id="typeSelected"
                                        placeholderText="Type"
                                        clearable={true}
                                        listOptions={StudentStudiesListTranslation("type_opts", langCode)['label']}
                                    >
                                        {StudentStudiesListTranslation("type", langCode)['label']}
                                    </SelectWithLabel>
                                </div>
                                <div className="form-group">
                                    <SelectWithLabel
                                        value={mobilitySelected ? mobilitySelected : ""}
                                        onSelectChange={(e) => {
                                            if (e !== null) {
                                                console.log(e)
                                                setMobilitySelected(e.value);
                                            } else {
                                                setMobilitySelected(null);
                                            }
                                        }}
                                        id="mobilitySelected"
                                        placeholderText="Mobility"
                                        clearable={true}
                                        listOptions={StudentStudiesListTranslation("mobility_opts", langCode)['label']}
                                    >
                                        {StudentStudiesListTranslation("mobility", langCode)['label']}
                                    </SelectWithLabel>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 pl-3 mx-auto">
                            {(studies?.length) ? <div id="no-more-tables">
                                <table className="table-striped table-condensed cf table-responsive">
                                    <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                                        <tr>
                                            <th scope="col" className="col-md-1">{StudentStudiesListTranslation("id", langCode)['label']}</th>
                                            <th scope="col" className="col-md-1">{StudentStudiesListTranslation("type", langCode)['label']}</th>
                                            <th scope="col" className="col-md-1">{StudentStudiesListTranslation("applicationDate", langCode)['label']}</th>
                                            <th scope="col" className="col-md-1">{StudentStudiesListTranslation("fullNameList", langCode)['label']}</th>
                                            <th scope="col" className="col-md-1">{StudentStudiesListTranslation("status", langCode)['label']}</th>
                                            <th scope="col" className="col-md-1">{StudentStudiesListTranslation("mobility", langCode)['label']}</th>
                                            <th scope="col" className="col-md-2 text-center">{StudentStudiesListTranslation("date", langCode)['label']}</th>
                                            <th scope="col" className="col-md-1">{StudentStudiesListTranslation("actions", langCode)['label']}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studies.map((i, idx) => {
                                            return (
                                                <tr className="text-center">
                                                    {/* StudentStudiesListTranslation("status_opts", langCode)['label'].filter(e => e.label === 'i.status').label */}
                                                    <td key={"ID_" + idx} data-title="ID">{i?.applicationNumber ? i?.applicationNumber : '-'}</td>
                                                    <td key={"Type_" + idx} data-title="Type">{i.type === 'short-term-studies' ? langCode === 'en' ? 'Short-term mobility for Studies' : 'Βραχυχρόνια κινητικότητα για Σπουδές' : langCode === 'en' ? 'Long-term mobility for Studies' : 'Μακροχρόνια κινητικότητα για Σπουδές'}</td>
                                                    <td key={"AppDate_" + idx} data-title="Application Date">{i.applicationYear}</td>
                                                    <td key={"FullName_" + idx} data-title="Full Name">{langCode === 'en' ? i.studentData[0].familyNameLatin + " " + i.studentData[0].nameLatin : i.studentData[0].familyNameGreek + " " + i.studentData[0].nameGreek}</td>
                                                    <td key={"Status_" + idx} data-title="Status">{langCode === 'gr' ? i.status === 'validated' ? 'Επικυρωμένη' : i.status === 'draft' ? 'Αποθηκευμένη' : 'Καταχωρημένη' : i.status}</td>
                                                    <td key={"MobilityProgram_" + idx} data-title="Mobility Program">{i?.prefferedMobility.length > 0 ? i?.prefferedMobility[0]?.programme : '-'}</td>
                                                    <td key={"Date_" + idx} data-title="Date">{i.dateCreated.toLocaleString()}</td>
                                                    <td key={"Actions_" + idx} data-title="Actions">
                                                        <div style={{ display: "flex" }}>
                                                            <Link to={"student/view/" + i.id}><i className='fa fa-eye fa-primary m-2'></i></Link>&nbsp;
                                                            {(i.status === "draft" && JSON.parse(localStorage.getItem("role")).role === "Student") || JSON.parse(localStorage.getItem("role")).role === "IRO" ?
                                                                <Restricted to="application_edit">
                                                                    <Link to={"student/edit/" + i.id}><i className='fa fa-pencil fa-primary m-2'></i></Link>
                                                                </Restricted>
                                                                :
                                                                <></>
                                                            }
                                                            {((i.status === "submitted" || i.status === "draft") && JSON.parse(localStorage.getItem("role")).role === "Student") || JSON.parse(localStorage.getItem("role")).role === "IRO" ?
                                                                <>
                                                                    <button style={{ border: "none", backgroundColor: "inherit" }} onClick={() => {
                                                                        setDisplayConfirmDelete(true)
                                                                        setToBeDeleted(i.id)
                                                                    }}>
                                                                        <i className="fa fa-trash fa-primary"></i>
                                                                    </button>
                                                                    <Modal show={displayConfirmDelete} onHide={() => {
                                                                        setDisplayConfirmDelete(false)
                                                                        setToBeDeleted(null)
                                                                    }}>
                                                                        <Modal.Body>
                                                                            <h4>Are you sure that you want to delete this Application? This action cannot be reverted!</h4>
                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            <Button className="btn btn-cancel m-2" onClick={() => toBeDeleted ? onDelete(toBeDeleted) : () => { }}>
                                                                                Yes
                                                                            </Button>
                                                                            <Button variant="secondary" onClick={() => {
                                                                                setDisplayConfirmDelete(false)
                                                                                setToBeDeleted(null)
                                                                            }}>
                                                                                No
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                </>
                                                                :
                                                                <></>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div> : <div className="d-flex justify-content-center">
                                <p>{StudentStudiesListTranslation("noStudiesFound", langCode)['label']}</p>
                            </div>}
                            {total !== 0 && <div className=" ml-3 mb-4">{StudentStudiesListTranslation("totalStudies", langCode)['label'] + total}</div>}
                            {
                                total !== 0
                                    ?
                                    <div className="d-flex justify-content-center">
                                        {/* feature idea do not show previous button if the user is in the first or last page etc */}
                                        <Pagination>
                                            <Pagination.First onClick={() => { if (total !== 0) setPageDebounced(0); topRef.current.scrollIntoView({ behavior: 'smooth' }) }} />
                                            <Pagination.Prev onClick={() => { if (page !== 0) setPageDebounced(page - 1); topRef.current.scrollIntoView({ behavior: 'smooth' }) }} />
                                            {paginationItems.length > 3 ? <Pagination.Ellipsis /> : <></>}
                                            {paginationItems}
                                            {paginationItems.length > 3 ? <Pagination.Ellipsis /> : <></>}
                                            <Pagination.Next onClick={() => { if (page !== Math.ceil(total / pageLimit) - 1) setPageDebounced(page + 1); topRef.current.scrollIntoView({ behavior: 'smooth' }) }} />
                                            <Pagination.Last onClick={() => { setPageDebounced(Math.ceil(total / pageLimit) - 1); topRef.current.scrollIntoView({ behavior: 'smooth' }) }} />
                                        </Pagination>
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}