const fallbackAccessibilities = [
    {
        id: null,
        type: null,
        services: null,
        description: null,
        factsheet: null,
        info: {
            id: null,
            email: null,
            phone: null,
            urls: [
                {
                    id: null,
                    value: null,
                    language: null
                }
            ]
        }
    }
];

const Accessibilities = ({ accessibilities }) => {
    return (
        <div className="table-responsive">
            <table className="table cf">
                <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                    <tr>
                        <th scope="col" className="text-center col-md-1" rowSpan="2">Type</th>
                        <th scope="col" className="text-center col-md-2" rowSpan="2">Services</th>
                        <th scope="col" className="text-center col-md-3" rowSpan="2">Description</th>
                        <th scope="col" className="text-center col-md-6" colSpan="5">Information</th>
                    </tr>
                    <tr>
                        <th scope="col" className="text-center col-md-1">Email</th>
                        <th scope="col" className="text-center col-md-1">Phone</th>
                        <th scope="col" className="text-center col-md-2">URL</th>
                        <th scope="col" className="text-center col-md-1">Language</th>
                    </tr>
                </thead>
                <tbody>
                    { 
                        (accessibilities.length !== 0 ? accessibilities : fallbackAccessibilities).map(accessibility => 
                            accessibility.info.urls.length !== 0 && accessibility.info.urls.map((url, idx) =>
                                <tr key={url.id}>
                                    {idx == 0 && <td className="text-center align-middle" rowSpan={accessibility.info.urls.length}>
                                        {accessibility.type ?? '-'}
                                    </td> }
                                    {idx == 0 && <td className="text-center align-middle" rowSpan={accessibility.info.urls.length}>
                                        { accessibility.services ?? '-'}
                                    </td> }
                                    {idx == 0 && <td className="text-center align-middle" rowSpan={accessibility.info.urls.length}>
                                        {accessibility.description ?? '-'}
                                    </td> }
                                    {idx == 0 && <td className="text-center align-middle" rowSpan={accessibility.info.urls.length}>
                                        {idx == 0 && ( accessibility.info.email ?? '-' ) }
                                    </td> }
                                    {idx == 0 && <td className="text-center align-middle" rowSpan={accessibility.info.urls.length}>
                                        {idx == 0 && ( accessibility.info.phone ?? '-' )}
                                    </td> }
                                    <td className="text-center">
                                        {url.value ?? '-'}
                                    </td>
                                    <td className="text-center">
                                        {url.language ?? '-'}
                                    </td>
                                </tr>
                            )
                        ) 
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Accessibilities;