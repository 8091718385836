import React from 'react';
import 'styles/css/login.css'
import profile from "img/mobisis-logo-vector.svg"


export default class LoginCallback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            username: null,
            password: null
        };
        this.login = this.login.bind(this)
    }

    login = () => {
        this.props.userService.doLogin()
    }

    render() {

        return (
            <div className="login">
                <main className="container-fluid">
                    <span className="font-link">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-7">
                                        <div className="card-group justify-content-center">
                                            <div className="login-card-body">
                                                <div className="text-center mb-5 mt-5">
                                                    <img src={profile} alt="User icon" />
                                                </div>
                                                <div className="text-center pt-4">
                                                    <button //type="submit"
                                                        className="btn btn-success btn-login px-4 text-uppercase" onClick={() => this.login()}>Login</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </main>
            </div>
        )
    }
}