import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({ component: Component, permission, userService, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (JSON.parse(localStorage.getItem("role")).permissions.includes(permission)) {
          return <Component userService={userService} {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/401",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};