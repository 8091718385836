const Title = ({ number , title, inline }) => {
    return (
        <div className={inline ? "d-inline-block" : ''}>
            <h3>
                {`${number}. ${title}`}
            </h3>
        </div>
    );
}

export default Title;