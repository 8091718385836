import Pagination from 'components/Common/Pagination';
import LocalFactsheetRow from './LocalFactsheetRow';

const LocalFactsheets = ({ pageLimit, page, setPage, totalLocalNonActiveFactsheets, localFactsheets, handleActivation, handleDuplication }) => {
    return (
        <div>
            <div className="row">
                <p className="font-weight-bold">Local Factsheets</p>
            </div>
            { localFactsheets.map((localFactsheet, index) => 
                <LocalFactsheetRow 
                    className={index === localFactsheets.length - 1 ? "" : "mb-3"}
                    key={localFactsheet.institution_erasmusCode + index} 
                    id={localFactsheet.id}
                    index={index} 
                    erasmusCode={localFactsheet.institution_erasmusCode}
                    givenName={localFactsheet.institutionalCoordinatorContact_givenName} 
                    familyName={localFactsheet.institutionalCoordinatorContact_familyName}
                    handleActivation={handleActivation} 
                    handleDuplication={handleDuplication}
                />
            ) }
            {totalLocalNonActiveFactsheets !== 0 ? <div className="pt-2 pl-0">Total number of local non-active factsheets: {totalLocalNonActiveFactsheets}</div> : <></>}
            { totalLocalNonActiveFactsheets !== 0 && totalLocalNonActiveFactsheets > pageLimit
                ?
                    <div className="d-flex justify-content-center">
                        <Pagination total={totalLocalNonActiveFactsheets} page={page} pageLimit={pageLimit} setPage={setPage} />
                    </div>
                :
                    <></>
            }
        </div>
    );
}

export default LocalFactsheets;