export default function ApplicationSettingsFormTranslation(fieldName, langcode) {

    const fields = {
        applicationTypeLong: {
            en: {
                label: "Student Studies Application"
            },
            gr: {
                label: "Αίτηση Φοιτητή για Σπουδές"
            }
        },
        applicationTypeShort: {
            en: {
                label: "Student Studies Short Term Application"
            },
            gr: {
                label: "Βραχυχρόνια Αίτηση Φοιτητή για Σπουδές"
            }
        },
        applicationCounter: {
            en: {
                label: "Application Counter"
            },
            gr: {
                label: "Αύξων Αριθμός Αίτησης"
            }
        },
        applicationStart: {
            en: {
                label: "Start Date"
            },
            gr: {
                label: "Ημερομηνία Έναρξης"
            }
        },
        applicationStop: {
            en: {
                label: "End Date"
            },
            gr: {
                label: "Ημερομηνία Λήξης"
            }
        },
        applicationYear: {
            en: {
                label: "Application Year"
            },
            gr: {
                label: "Έτος Αίτησης"
            }
        },
        submit: {
            en: {
                label: "Submit"
            },
            gr: {
                label: "Υποβολή"
            }
        },
        resetCounter: {
            en: {
                label: "Reset Counter"
            },
            gr: {
                label: "Επανεκκίνηση"
            }
        }
    }

    return fields[fieldName][langcode]
}