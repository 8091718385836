import { useEffect, useState } from 'react';
import SelectWithLabel from 'components/UI/SelectWithLabel'
import RequiredSelectWithLabel from 'components/UI/RequiredSelectWithLabel'

export function DropdownSelect({
    optionsList = [],
    parentSetValueSelected,
    parentValueSelected = "",
    selectLabel = "",
    placeholderText = "",
    componentId = "",
    disabled = false,
    isOptionDisabled = (option) => false,
    required = false,
    clearable = true,
    loading = false
}) {

    // const [optionSelected, setOptionSelected] = useState(null);

    // useEffect(() => {
    //     if (optionSelected) {
    //         parentSetValueSelected(optionSelected.value)
    //     }
    //     else {
    //         parentSetValueSelected(null)
    //     }

    // }, [optionSelected])

    return (
        <div>
            <div className="form-group">
                {required === false ? <SelectWithLabel
                    disabled={disabled}
                    // value={optionSelected ? optionSelected.label : null}
                    value={parentValueSelected}
                    onSelectChange={(newValue, action) => {
                        if (action.action == "select-option") {
                            if (newValue != null) {
                                parentSetValueSelected(componentId, newValue.label)
                            }
                            else {
                                parentSetValueSelected(componentId, null)
                            }
                        }
                        else if (action.action == "clear") {
                            parentSetValueSelected(componentId, null)
                        }
                    }}
                    id={componentId}
                    placeholderText={placeholderText}
                    clearable={true}
                    listOptions={optionsList}
                    isOptionDisabled={isOptionDisabled}
                    loading={loading}
                >
                    {selectLabel}
                </SelectWithLabel> :
                    <RequiredSelectWithLabel
                        disabled={disabled}
                        // value={optionSelected ? optionSelected.label : null}
                        value={parentValueSelected}
                        onSelectChange={(newValue, action) => {
                            if (action.action == "select-option") {
                                if (newValue != null) {
                                    parentSetValueSelected(componentId, newValue.label)
                                }
                                else {
                                    parentSetValueSelected(componentId, null)
                                }
                            }
                            else if (action.action == "clear") {
                                parentSetValueSelected(componentId, null)
                            }
                        }}
                        id={componentId}
                        placeholderText={placeholderText}
                        clearable={clearable}
                        listOptions={optionsList}
                        isOptionDisabled={isOptionDisabled}
                        required={required}
                        loading={loading}
                    >
                        {selectLabel}
                    </RequiredSelectWithLabel>}
            </div>
        </div>
    )

}
