import { useState, useRef } from "react";
import { Switch, Route } from "react-router-dom";
import IIAList from './Iia/List';
import ProfileView from './Profile/View';
import ViewIIA from './Iia/View';
import IIAForm from './Iia/Form'
import FactsheetsList from './Factsheet/List';
import FactsheetView from './Factsheet/View';
import ExportExcel from './Iia/Excel';
import ECTSCoordinators from 'components/Ects-coordinators/List';
import ECTSCoordinatorForm from 'components/Ects-coordinators/Form';
import EditProfile from './Profile/Edit';
import ErrorPage from "components/ErrorPage";
import Loader from "react-loader-spinner";
import { ProtectedRoute } from 'PermissionProvider/ProtectedRoute';
import FactsheetEdit from "./Factsheet/Edit";
// import IIAActiveList from "./Iia/ActiveList";
import withTitle from "./Common/hocs/withTitle";
// import InstitutionsList from "./Institution/List";
// import Institution from "./Factsheet/UI/Institution";
// import InstitutionForm from "./Institution/Form";
// import DepartmentsList from "./Departments/List";
// import DepartmentForm from "./Departments/Form";
// import TestList from "./TestList";
// import TestForm from "./TestForm";
import StudentStudiesForm from "./Application/Outgoing/Form/StudentStudiesForm";
// import { calculateCurrentAcademicYear } from "./Common/CommonFunctions";
import StudentStudiesList from "./Application/Outgoing/List/StudentStudiesList";
import ApplicationSettingsForm from "./Application/Settings/ApplicationSettingsForm";

function Routes({ userService }) {
  const titleSuffix = useRef(' | mobisis | Aristotle University of Thessaloniki')
  const [currentUser,] = useState(JSON.parse(localStorage.getItem("role")));

  if (currentUser && !userService.isLoggedIn()) {
    return (
      <div style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <Loader
          className="spinner mx-auto"
          type="TailSpin"
          color="#3A435E"
          height={70}
          width={70}
        />
      </div>
    )
  }

  else {
    return (
      <Switch>
        <ProtectedRoute exact path="/" permission="iia_list" userService={userService} component={withTitle(IIAList, `IIA List${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/profile" permission="profile_view" userService={userService} component={withTitle(ProfileView, `Profile${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/profile/edit" permission="profile_edit" userService={userService} component={withTitle(EditProfile, `Edit profile${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/iia/form" permission="iia_create" userService={userService} component={withTitle(IIAForm, `Create IIA${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/iia/view/:name" permission="iia_view" userService={userService} component={withTitle(ViewIIA, `View IIA${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/iia/edit/:agreementId" permission="iia_edit" userService={userService} component={withTitle(IIAForm, `Edit IIA${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/ects-coordinators" permission="ects_coordinators_view" userService={userService} component={withTitle(ECTSCoordinators, `ECTS Coordinators${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/ects-coordinators/form" permission="ects_coordinators_create" userService={userService} component={withTitle(ECTSCoordinatorForm, `Create ECTS Coordinator${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/iia/export" permission="iia_excel_export" userService={userService} component={withTitle(ExportExcel, `Export IIAs to Excel${titleSuffix.current}`)} />
        {/* <ProtectedRoute exact path="/iia/active" permission="iia_active_list" userService={userService} component={withTitle(IIAActiveList, `Search Inter-institutional agreements for ${calculateCurrentAcademicYear()}${titleSuffix.current}`)} /> */}
        <ProtectedRoute exact path="/factsheets" permission="factsheet_list" userService={userService} component={withTitle(FactsheetsList, `Factsheet List${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/factsheets/view/:id" permission="factsheet_view" userService={userService} component={withTitle(FactsheetView, `View Factsheet${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/factsheets/edit/:id" permission="factsheet_edit" userService={userService} component={withTitle(FactsheetEdit, `Edit Factsheet${titleSuffix.current}`)} />
        {/* <ProtectedRoute exact path="/institutions" permission="institution_list" userService={userService} component={withTitle(InstitutionsList, `Institution List${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/institutions/form/:institutionId" permission="institution_edit" userService={userService} component={withTitle(InstitutionForm, `Edit Institution${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/departments" permission="department_list" userService={userService} component={withTitle(DepartmentsList, `Departments List${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/departments/form/:departmentId" permission="department_edit" userService={userService} component={withTitle(DepartmentForm, `Edit Department${titleSuffix.current}`)} /> */}
        {/* <ProtectedRoute exact path="/testlist" permission="test" userService={userService} component={withTitle(TestList, `Test List${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/testform" permission="test" userService={userService} component={withTitle(TestForm, `Test Form${titleSuffix.current}`)} /> */}
        <ProtectedRoute exact path="/application/outgoing/student/new" permission="application_create" userService={userService} component={withTitle(StudentStudiesForm, `Student Studies Form${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/application/outgoing/short/student/new" permission="application_create" userService={userService} component={withTitle(StudentStudiesForm, `Student Studies Short Term Form${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/application/outgoing/student/edit/:formId" permission="application_edit" userService={userService} component={withTitle(StudentStudiesForm, `Edit Student Studies Form${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/application/outgoing/list" permission="application_list" userService={userService} component={withTitle(StudentStudiesList, `Student Studies List${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/application/outgoing/student/view/:formId" permission="application_view" userService={userService} component={withTitle(StudentStudiesForm, `View Student Studies Form${titleSuffix.current}`)} />
        <ProtectedRoute exact path="/application/settings" permission="application_settings" userService={userService} component={withTitle(ApplicationSettingsForm, `Application Settings Form${titleSuffix.current}`)} /> 
        {/*<Route exact path="/iia/form">
              <ErrorPage errorMessage="Sorry, this action is not available." />
    </Route>*/}

        <Route path="/401">
          {
            withTitle(
              (props) => <ErrorPage errorTitle={'Error'} errorMessage="Sorry, you are not allowed to access this page." {...props} />,
              `Unauthorized access${titleSuffix.current}`
            )
          }
        </Route>
        <Route>
          {
            withTitle(
              (props) => <ErrorPage errorTitle={'Page not found'} errorMessage="Sorry, the page you requested could not be found." {...props} />,
              `Page not found${titleSuffix.current}`
            )
          }
        </Route>

      </Switch >

    );
  }
}

export default Routes;