import { useState, useEffect } from "react";
import { TextInput } from "components/UI/ComplexComponents/TextInput";
import { DropdownSelect } from "components/UI/ComplexComponents/DropdownSelect";
import StudentStudiesComponentTranslation from "../translations/StudentStudiesComponentTranslation";

function StudentStudiesComponent({ children, langCode, view, handleStep, studentStudiesForm, setStudentStudiesForm, departments, saveDraft }) {

    const getCookie = (name) => {
        const cookie = {}
        document.cookie.split(';').forEach((e) => {
            const [key, value] = e.split('=');
            cookie[key.trim()] = value;
        })
        return cookie[name];
    }

    function generateYearsBetween(startYear = 2000) {
        const endDate = new Date().getFullYear();
        let years = [];

        for (var i = startYear; i <= endDate; i++) {
            years.push({ label: startYear });
            startYear++;
        }
        return years;
    }

    const enrollmentYearsOptions = generateYearsBetween()

    const [user, setUser] = useState(JSON.parse(getCookie("user").split(';').reduce((cookieObject, cookieString) => {
        let splitCookie = cookieString.split('=').map((cookiePart) => { cookiePart.trim() })
        try {
            cookieObject[splitCookie[0]] = JSON.parse(splitCookie[1])
        } catch (error) {
            cookieObject[splitCookie[0]] = splitCookie[1]
        }
        return cookieObject
    })))

    const initialState = {
        europeanStudentIdentifier: user?.esi,
        universityRegistryNumber: user?.university_registry_number,
        department: "",
        diplomaTitle: "",
        specialization: "",
        studyCycle: "",
        studyStartYear: "",
        semesterCurrent: "",
        semesterDuringMobility: "",
    }

    const [studentStudies, setStudentStudies] = useState(initialState)

    const required = view ? false : true;

    function setFieldValue(setterFunc) {

        return function (item, value) {
            setterFunc({ ...studentStudies, [item]: value })
        }
    }

    useEffect(() => {
        if (studentStudiesForm?.studiesData !== undefined) {
            if (studentStudiesForm?.studiesData[0] !== undefined) {
                const temp = JSON.parse(JSON.stringify(studentStudiesForm?.studiesData[0]))
                let studyCycle = ""
                if (langCode === "gr") {
                    studyCycle = temp.studyCycle === 6 ? 'Προπτυχιακό' : temp.studyCycle === 7 ? 'Μεταπτυχιακό' : temp.studyCycle === 8 ? 'Διδακτορικό' : ""
                    temp['department'] = temp.department.nameGR
                } else {
                    studyCycle = temp.studyCycle === 6 ? 'Undergraduate' : temp.studyCycle === 7 ? 'Postgraduate' : temp.studyCycle === 8 ? 'Ph.D' : ""
                    temp['department'] = temp.department.name
                }

                temp['studyCycle'] = studyCycle

                console.log(temp)
                console.log(studentStudiesForm?.studiesData)
                setStudentStudies(temp)
            }
        } else {
            setStudentStudies(initialState)
        }
    }, [])

    useEffect(() => {
        console.log(studentStudiesForm)
    }, [])

    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={async (event) => {
            event.preventDefault();
            if (!view) {
                let department = ''
                let studyCycle = ""
                if (langCode === "gr") {
                    studyCycle = studentStudies.studyCycle === 'Προπτυχιακό' ? 6 : studentStudies.studyCycle === 'Μεταπτυχιακό' ? 7 : studentStudies.studyCycle === 'Διδακτορικό' ? 8 : 0
                    department = departments.find((i) => i.nameGR === studentStudies.department)
                } else {
                    studyCycle = studentStudies.studyCycle === 'Undergraduate' ? 6 : studentStudies.studyCycle === 'Postgraduate' ? 7 : studentStudies.studyCycle === 'Ph.D' ? 8 : 0
                    department = departments.find((i) => i.name === studentStudies.department)
                }

                const studentStudiesTemp = JSON.parse(JSON.stringify(studentStudies))

                studentStudiesTemp['department'] = department

                studentStudiesTemp['studyCycle'] = studyCycle

                setStudentStudiesForm({ ...studentStudiesForm, studiesData: [studentStudiesTemp] })
                var activeElement = document.activeElement;
                if (activeElement.value === 'draft') {
                    saveDraft({ ...studentStudiesForm, studiesData: [studentStudiesTemp] })
                } else {
                    handleStep();
                }
            } else {
                handleStep();
            }
        }}>
            <div className="containter">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h3 className="text-center">{StudentStudiesComponentTranslation("h3_title", langCode)['label']}</h3>
                            <div className="form-group">
                                {
                                    TextInput({
                                        parentSetValueSelected: setFieldValue(setStudentStudies),
                                        parentValueSelected: studentStudies.universityRegistryNumber,
                                        inputLabel: StudentStudiesComponentTranslation("universityRegistryNumber", langCode)['label'],
                                        componentId: "universityRegistryNumber",
                                        disabled: true,
                                        type: "text",
                                        disabled: true
                                    })
                                }
                                {
                                    TextInput({
                                        parentSetValueSelected: setFieldValue(setStudentStudies),
                                        parentValueSelected: studentStudies.europeanStudentIdentifier,
                                        inputLabel: StudentStudiesComponentTranslation("europeanStudentIdentifier", langCode)['label'],
                                        componentId: "europeanStudentIdentifier",
                                        type: "text",
                                        required: required,
                                        disabled: true
                                    })
                                }
                                {
                                    DropdownSelect({
                                        optionsList: departments ? langCode === "gr" ? departments.map(i => ({ label: i?.nameGR, value: i?.id })) : langCode === "en" ? departments.map(i => ({ label: i?.name, value: i?.id })) : [] : [],
                                        parentSetValueSelected: setFieldValue(setStudentStudies),
                                        parentValueSelected: studentStudies.department,
                                        selectLabel: StudentStudiesComponentTranslation("department", langCode)['label'],
                                        componentId: "department",
                                        required: required,
                                        disabled: view
                                    })
                                }
                                {
                                    TextInput({
                                        parentSetValueSelected: setFieldValue(setStudentStudies),
                                        parentValueSelected: studentStudies.diplomaTitle,
                                        inputLabel: StudentStudiesComponentTranslation("diploma", langCode)['label'],
                                        componentId: "diplomaTitle",
                                        type: "text",
                                        required: required,
                                        disabled: view
                                    })
                                }
                                {
                                    TextInput({
                                        parentSetValueSelected: setFieldValue(setStudentStudies),
                                        parentValueSelected: studentStudies.specialization,
                                        inputLabel: StudentStudiesComponentTranslation("specialization", langCode)['label'],
                                        componentId: "specialization",
                                        type: "text",
                                        disabled: view
                                    })
                                }
                                {
                                    DropdownSelect({
                                        optionsList: StudentStudiesComponentTranslation("education_level_options", langCode),
                                        parentSetValueSelected: setFieldValue(setStudentStudies),
                                        parentValueSelected: studentStudies.studyCycle,
                                        selectLabel: StudentStudiesComponentTranslation("educationLevel", langCode)['label'],
                                        componentId: "studyCycle",
                                        required: required,
                                        disabled: view
                                    })
                                }
                                {
                                    DropdownSelect({
                                        optionsList: enrollmentYearsOptions,
                                        parentSetValueSelected: setFieldValue(setStudentStudies),
                                        parentValueSelected: studentStudies.studyStartYear,
                                        selectLabel: StudentStudiesComponentTranslation("yearOfEntry", langCode)['label'],
                                        componentId: "studyStartYear",
                                        required: required,
                                        disabled: view
                                    })
                                }
                                {/* {
                                    TextInput({
                                        parentSetValueSelected: setFieldValue(setStudentStudies),
                                        parentValueSelected: studentStudies.studyStartYear,
                                        inputLabel: StudentStudiesComponentTranslation("yearOfEntry", langCode)['label'],
                                        componentId: "studyStartYear",
                                        type: "text",
                                        required: required,
                                        disabled: view
                                    })
                                } */}
                                {
                                    TextInput({
                                        parentSetValueSelected: setFieldValue(setStudentStudies),
                                        parentValueSelected: studentStudies.semesterCurrent,
                                        inputLabel: StudentStudiesComponentTranslation("currentSemester", langCode)['label'],
                                        componentId: "semesterCurrent",
                                        type: "number",
                                        required: required,
                                        disabled: view
                                    })
                                }
                                {
                                    TextInput({
                                        parentSetValueSelected: setFieldValue(setStudentStudies),
                                        parentValueSelected: studentStudies.semesterDuringMobility,
                                        inputLabel: StudentStudiesComponentTranslation("semesterOnMobility", langCode)['label'],
                                        componentId: "semesterDuringMobility",
                                        type: "number",
                                        required: required,
                                        disabled: view
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </form>
    );
}

export default StudentStudiesComponent