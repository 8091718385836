import { useEffect, useState } from 'react'
import SelectWithLabel from 'components/UI/SelectWithLabel'
import Excel from "exceljs/dist/es5/exceljs.browser";
import { saveAs } from 'file-saver'
import { ReactDataContext } from '@themost/react';
import Loader from "react-loader-spinner";

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ExportExcel(props) {
    const [selectedId, setSelectedId] = useState(0)
    const [loading, setLoading] = useState(true);
    const [dep, setDep] = useState("")
    const [departments, setDepartments] = useState([])
    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(props.userService.getToken());

    useEffect(() => {
        document.title = props.title
    }, [])

    useEffect(async () => {
        try {
            const authDepartmentsResult = await context.model(`MobilityDepartments`).where('institution/isLocal').equal(true).take(-1).getItems();
            setDepartments(authDepartmentsResult)
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }, [])

    /**
     *
     * @param e
     */
    const handleSelectChange = (e) => {

        if (e !== null && departments.find(i => i.name === e.label)) {
            setDep(e.label)
            setSelectedId(e.value)

        }
        else {
            setDep("")
            setSelectedId(0)
        }

    }
    if (loading) {
        return (<div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Loader
                className="spinner mx-auto"
                type="TailSpin"
                color="#3A435E"
                height={70}
                width={70}
            />
        </div>)
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">

                    <SelectWithLabel
                        id="dep"
                        placeholderText="Type department"
                        clearable={true}
                        value={dep}
                        onSelectChange={handleSelectChange}
                        listOptions={departments.map(i => ({ value: i.id, label: i.name }))}

                    >
                        <h4>Select Department</h4>
                    </SelectWithLabel>


                </div>
            </div>

            <div className="d-flex justify-content-center">
                <button className="btn btn-next mt-4" onClick={async () => {
                    setLoading(true)

                    const wb = new Excel.Workbook()
                    const ws = wb.addWorksheet()
                    ws.columns = [
                        { header: 'ID', key: 'id', width: 10 },
                        { header: 'Υπεύθυνος Συμφωνίας', key: 'coordinator', width: 15 },
                        { header: 'Όνομα Ιδρύματος', key: 'institution', width: 15, outlineLevel: 1 },
                        { header: 'Κωδικός Erasmus', key: 'erasmus', width: 10, outlineLevel: 1 },
                        { header: 'Από', key: 'startYear', width: 7, outlineLevel: 1 },
                        { header: 'Έως', key: 'endYear', width: 7, outlineLevel: 1 },
                        { header: 'Θεματική ενότητα', key: 'subjectaArea', width: 10, outlineLevel: 1 },
                        { header: 'Τμήμα', key: 'department', width: 15, outlineLevel: 1 },
                        { header: 'Δράση', key: 'action', width: 20, outlineLevel: 1 },
                        { header: 'Εξερχόμενοι', key: 'outgoing', width: 5, outlineLevel: 1 },
                        { header: 'Συνολική Διάρκεια', key: 'totalOutgoing', width: 5, outlineLevel: 1 },
                        { header: 'Εισερχόμενοι', key: 'incoming', width: 5, outlineLevel: 1 },
                        { header: 'Συνολική Διάρκεια ', key: 'totalIncoming', width: 5, outlineLevel: 1 }

                    ];
                    var temp = context.model(`MobilityAgreements`).where('departmentalCoordinatorContacts/department').equal(selectedId).and('status').notEqual(1)

                    const resp = await temp.take(-1).getItems()
                    // Add an array of rows
                    resp.forEach((i) => {
                        if (i.institutions.filter(i => i.isLocal === false).length!==0) {
                            i.institutions.filter(i => i.isLocal === false).forEach((j) => {
                                    let studentStudiesIncoming = i.studentMobilitiesForStudies.find(k => k.toInstitution.isLocal === true && k.fromInstitution.id === j.id);
                                    let studentStudiesOutgoing = i.studentMobilitiesForStudies.find(k => k.fromInstitution.isLocal === true && k.toInstitution.id === j.id);
                                    let studentTraineeshipIncoming = i.studentMobilitiesForTraineeship.find(k => k.toInstitution.isLocal === true && k.fromInstitution.id === j.id);
                                    let studentTraineeshipOutgoing = i.studentMobilitiesForTraineeship.find(k => k.fromInstitution.isLocal === true && k.toInstitution.id === j.id);
                                    let staffTeachingIncoming = i.staffMobilitiesForTeaching.find(k => k.toInstitution.isLocal === true && k.fromInstitution.id === j.id);
                                    let staffTeachingOutgoing = i.staffMobilitiesForTeaching.find(k => k.fromInstitution.isLocal === true && k.toInstitution.id === j.id);
                                    let staffTrainingIncoming = i.staffMobilitiesForTraining.find(k => k.toInstitution.isLocal === true && k.fromInstitution.id === j.id);
                                    let staffTrainingOutgoing = i.staffMobilitiesForTraining.find(k => k.fromInstitution.isLocal === true && k.toInstitution.id === j.id);
                                    let departmentalCoordinatorLocal = i.departmentalCoordinatorContacts.find(k => k.department.institution.isLocal === true);
                                    let departmentalCoordinatorPartner = i.departmentalCoordinatorContacts.find(k => k.department.institution.id === j.id);
                                    let subjectAreas = [];
                                    if (studentStudiesIncoming) {
                                        subjectAreas = subjectAreas.concat(studentStudiesIncoming.subjectAreasWithClarifications);
                                    }
                                    if (studentStudiesOutgoing) {
                                        subjectAreas = subjectAreas.concat(studentStudiesOutgoing.subjectAreasWithClarifications);
                                    }
                                    if (studentTraineeshipIncoming) {
                                        subjectAreas = subjectAreas.concat(studentTraineeshipIncoming.subjectAreasWithClarifications);
                                    }
                                    if (studentTraineeshipOutgoing) {
                                        subjectAreas = subjectAreas.concat(studentTraineeshipOutgoing.subjectAreasWithClarifications);
                                    }
                                    if (staffTeachingIncoming) {
                                        subjectAreas = subjectAreas.concat(staffTeachingIncoming.subjectAreasWithClarifications);
                                    }
                                    if (staffTeachingOutgoing) {
                                        subjectAreas = subjectAreas.concat(staffTeachingOutgoing.subjectAreasWithClarifications);
                                    }
                                    if (staffTrainingIncoming) {
                                        subjectAreas = subjectAreas.concat(staffTrainingIncoming.subjectAreasWithClarifications);
                                    }
                                    if (staffTrainingOutgoing) {
                                        subjectAreas = subjectAreas.concat(staffTrainingOutgoing.subjectAreasWithClarifications);
                                    }
                                    subjectAreas = subjectAreas.map(subject => subject.subjectArea.name + " (" + subject.subjectArea.code + ")").filter(onlyUnique).toString();
                                    ws.addRows([
                                        [
                                            i.id,
                                            departmentalCoordinatorLocal ? (departmentalCoordinatorLocal.familyName + " " + departmentalCoordinatorLocal.givenName) : null,
                                            j.name,
                                            j.erasmusCode,
                                            i.startYear.id,
                                            i.endYear.id,
                                            subjectAreas,
                                            departmentalCoordinatorPartner ? departmentalCoordinatorPartner.department.name : null,
                                            "Student Mobility for Studies", studentStudiesOutgoing ? studentStudiesOutgoing.mobilitiesPerYear : null, studentStudiesOutgoing ? studentStudiesOutgoing.totalMonthsPerYear : null, studentStudiesIncoming ? studentStudiesIncoming.mobilitiesPerYear : null, studentStudiesIncoming ? studentStudiesIncoming.totalMonthsPerYear : null],
                                        ['', '', '', '', '', '', '', '', "Student Mobility for Traineeships", studentTraineeshipOutgoing ? studentTraineeshipOutgoing.mobilitiesPerYear : null, studentTraineeshipOutgoing ? studentTraineeshipOutgoing.totalMonthsPerYear : null, studentTraineeshipIncoming ? studentTraineeshipIncoming.mobilitiesPerYear : null, studentTraineeshipIncoming ? studentTraineeshipIncoming.totalMonthsPerYear : null],
                                        ['', '', '', '', '', '', '', '', "Staff Mobility for Teaching", staffTeachingOutgoing ? staffTeachingOutgoing.mobilitiesPerYear : null, staffTeachingOutgoing ? staffTeachingOutgoing.totalDaysPerYear : null, staffTeachingIncoming ? staffTeachingIncoming.mobilitiesPerYear : null, staffTeachingIncoming ? staffTeachingIncoming.totalDaysPerYear : null],
                                        ['', '', '', '', '', '', '', '', "Staff Mobility for Training", staffTrainingOutgoing ? staffTrainingOutgoing.mobilitiesPerYear : null, staffTrainingOutgoing ? staffTrainingOutgoing.totalDaysPerYear : null, staffTrainingIncoming ? staffTrainingIncoming.mobilitiesPerYear : null, staffTrainingIncoming ? staffTrainingIncoming.totalDaysPerYear : null]
                                    ]);
                            })
                        }
                    })

                    const buf = await wb.xlsx.writeBuffer();

                    saveAs(new Blob([buf]), dep + '.xlsx');
                    setLoading(false);

                }}><i className="fa fa-file-excel-o mr-2"></i>Export</button>
            </div>
        </div>
    )
}