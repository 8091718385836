import { useRef, Fragment } from 'react';
import EditInformationEntryForm from './EditInformationEntryForm';

const titles = ['Contact and information about the application process', 'Housing information', 'Visa information', 'Insurance information',]
const mainObjectPropertyNames = ['applicationIncomingsInformationEntry', 'housingInformationEntry', 'visaInformationEntry', 'insuranceInformationEntry'];
const defaultUrls = [{ value: '', language: 'en' }];

const EditCoreInformation = ({ children, handleStep, handleChange, data }) => {
    const language = useRef('en');

    const handleSubmit = (e) => {
        e.preventDefault();

        handleStep();
    }

    const handleLocalChange = (mainObjectPropertyName) => {
        return (subPropertyName, newValue) => {
            handleChange(mainObjectPropertyName, { ...data[mainObjectPropertyName], [subPropertyName]: newValue })
        }
    }

    return (
        <form className="g-3 needs-validation" noValidate={false} onSubmit={handleSubmit}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4">
                            <h3 className="text-center">Contact and information about the application process - Housing information - Visa information - Insurance information</h3>
                            
                            {titles.map((title, index) => {
                                return (
                                    <Fragment key={`${title}`}>
                                        <h5 className="text-center">{title}</h5>
                                        <EditInformationEntryForm 
                                            language={language.current} 
                                            handleChange={handleLocalChange(mainObjectPropertyNames[index])} 
                                            email={data[mainObjectPropertyNames[index]]?.email ?? null} 
                                            phone={data[mainObjectPropertyNames[index]]?.phone} 
                                            urls={(
                                                data[mainObjectPropertyNames[index]] && !data[mainObjectPropertyNames[index]].hasOwnProperty('urls')) || 
                                                (data[mainObjectPropertyNames[index]] && data[mainObjectPropertyNames[index]].hasOwnProperty('urls') && data[mainObjectPropertyNames[index]].urls.length === 0) ||
                                                (!data[mainObjectPropertyNames[index]])
                                                ? defaultUrls 
                                                : data[mainObjectPropertyNames[index]]?.urls
                                            }
                                        />
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                {
                    { ...children }
                }
            </div>
        </form>
    );
}

export default EditCoreInformation;