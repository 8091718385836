import { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import 'styles/css/IIAform.css';
import { languages } from 'languages';
import { languageLevels } from 'languageLevels';
import SelectWithLabel from 'components/UI/SelectWithLabel';
import RequiredSelectWithLabel from "../../../UI/RequiredSelectWithLabel";
import Restricted from "PermissionProvider/Restricted";

function StaffMobility({ children, iia, setIia, handleStep, mobility, isced, localDepartmentList, partnerDepartmentList }) {


    const [staffMobilityConditions, setStaffMobilityConditions] = useState([]);
    const [staffMobility, setStaffMobility] = useState("No");
    let levels = languageLevels;

    var isced4Digits = []

    isced.forEach(i => {
        if (i.code.length == 4) {
            isced4Digits.push(i)
        }
    });

    useEffect(() => {
        setStaffMobility((iia[mobility] && iia[mobility].length !== 0) ? "Yes" : "No");
        if (iia[mobility] && iia[mobility].length !== 0) {
            setStaffMobilityConditions(iia[mobility]);
        }
        else {
            setStaffMobilityConditions([
                {
                    "fromInstitution": null,
                    "toInstitution": null,
                    "fromDepartment": null,
                    "toDepartment": null,
                    "subjectAreasWithClarifications": [{
                        subjectArea: {
                            "code": null,
                            "name": null
                        }, clarification: ""
                    }],
                    "recommendedLanguageSkills": [],
                    "mobilitiesPerYear": null,
                    "totalDaysPerYear": null,
                    "blended": false,
                    "otherInfoTerms": null
                }
            ])

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const institutions = iia && iia.institutions;
    return (
        <form className="g-3 needs-validation"
            noValidate={false}
            onSubmit={(event) => {
                event.preventDefault();
                var issueFound = false;
                if (staffMobility === "Yes") {
                    for (let condition in staffMobilityConditions) {

                        if (((staffMobilityConditions[condition].mobilitiesPerYear * 2 > staffMobilityConditions[condition].totalDaysPerYear || staffMobilityConditions[condition].mobilitiesPerYear * 60 < staffMobilityConditions[condition].totalDaysPerYear))) {
                            alert("Total number of days per staff member must be at least 2 and less than 60");
                            issueFound = true;
                        }
                    }
                    if (!issueFound) {

                        const temp = iia;
                        temp[mobility] = staffMobilityConditions;
                        setIia(temp);
                        handleStep();
                    }
                } else {
                    const temp = iia;
                    temp[mobility] = [];
                    setIia(temp);
                    handleStep();
                }
            }} >

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="text-center">Mobility numbers per academic year - Staff</h2>
                        <Form.Group id="staff">
                            <Form.Label>{mobility === "staffMobilitiesForTeaching" ? "Staff Mobility For Teaching" : "Staff Mobility For Training"}</Form.Label>
                            <Form.Control as="select" custom value={staffMobility} onChange={(e) => setStaffMobility(e.target.value)} disabled={iia?.ewpStatus?.name === "Approved by all"}>
                                <option value={"Yes"}>Yes</option>
                                <option value={"No"}>No</option>

                            </Form.Control>
                        </Form.Group>
                        {staffMobility === "Yes" && staffMobilityConditions.map((condition, index) => {
                            let fromDepartmentList = (condition?.fromInstitution?.isLocal ? localDepartmentList : partnerDepartmentList);
                            let toDepartmentList = (condition?.toInstitution?.isLocal ? localDepartmentList : partnerDepartmentList);
                            return (<>
                                <div className="card p-4">
                                    <div className="d-flex flex-row-reverse">
                                        <button className='btn btn-primary btn-sm btn-danger btn-remove-language' onClick={(e) => {
                                            e.preventDefault();
                                            const values = [...staffMobilityConditions];
                                            values.splice(index, 1);

                                            setStaffMobilityConditions(values);
                                        }}>
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <RequiredSelectWithLabel
                                                value={condition?.fromInstitution?.erasmusCode}
                                                onSelectChange={(e) => {
                                                    var temp = [...staffMobilityConditions];
                                                    if (e !== null) {
                                                        temp[index]['fromInstitution'] = institutions.find(i => i.erasmusCode === e.value);
                                                        setStaffMobilityConditions(temp);
                                                    }
                                                    else {
                                                        const values = [...staffMobilityConditions];
                                                        temp[index]['fromInstitution'] = null;
                                                        setStaffMobilityConditions(temp);
                                                    }
                                                }}
                                                id="institutionSending"
                                                placeholderText="Enter name of the institution"
                                                clearable={true}
                                                listOptions={institutions.filter(i => i.erasmusCode !== condition?.toInstitution?.erasmusCode).map(i => ({ value: i.erasmusCode, label: i.name + " (" + i.erasmusCode + ")" }))}
                                                disabled={iia?.ewpStatus?.name === "Approved by all"}
                                            >
                                                Erasmus Code of the Sending Institution *
                                            </RequiredSelectWithLabel>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <RequiredSelectWithLabel
                                                value={condition?.toInstitution?.erasmusCode}
                                                onSelectChange={(e) => {
                                                    var temp = [...staffMobilityConditions];
                                                    if (e !== null) {
                                                        temp[index]['toInstitution'] = institutions.find(i => i.erasmusCode === e.value);
                                                        setStaffMobilityConditions(temp);
                                                    }
                                                    else {
                                                        temp[index]['toInstitution'] = null;
                                                        setStaffMobilityConditions(temp);
                                                    }
                                                }}
                                                id="institutionReceiving"
                                                placeholderText="Enter name of the institution"
                                                clearable={true}
                                                listOptions={institutions.filter(i => i.erasmusCode !== condition?.fromInstitution?.erasmusCode).map(i => ({ value: i.erasmusCode, label: i.name + " (" + i.erasmusCode + ")" }))}
                                                disabled={iia?.ewpStatus?.name === "Approved by all"}
                                            >
                                                Erasmus Code of the Receiving Institution *
                                            </RequiredSelectWithLabel>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <SelectWithLabel
                                                value={condition?.fromDepartment?.name}
                                                onSelectChange={(e) => {
                                                    var temp = [...staffMobilityConditions];
                                                    if (e !== null) {
                                                        temp[index]['fromDepartment'] = fromDepartmentList.find(i => i.name === e.value);
                                                        setStaffMobilityConditions(temp);
                                                    }
                                                    else {
                                                        temp[index]['fromDepartment'] = null;
                                                        setStaffMobilityConditions(temp);
                                                    }
                                                }}
                                                id="departmentSending"
                                                placeholderText="Enter name of the department"
                                                clearable={true}
                                                listOptions={fromDepartmentList ? fromDepartmentList.map(i => ({ value: i.name, label: i.name + " (" + i?.institution?.erasmusCode + ")" })) : []}
                                                disabled={iia?.ewpStatus?.name === "Approved by all"}
                                            >
                                                Department of the Sending Institution
                                            </SelectWithLabel>
                                            {fromDepartmentList === null && <small style={{ color: "red" }}>Loading Partner's Departments from EWP</small>}

                                        </div>
                                        <div className="form-group col-md-6">
                                            <SelectWithLabel
                                                value={condition?.toDepartment?.name}
                                                onSelectChange={(e) => {
                                                    var temp = [...staffMobilityConditions];
                                                    if (e !== null) {
                                                        temp[index]['toDepartment'] = toDepartmentList.find(i => i?.name === e.value);
                                                        setStaffMobilityConditions(temp);
                                                    }
                                                    else {
                                                        temp[index]['toDepartment'] = null;
                                                        setStaffMobilityConditions(temp);
                                                    }
                                                }}
                                                id="departmentReceiving"
                                                placeholderText="Enter name of the department"
                                                clearable={true}
                                                listOptions={toDepartmentList ? toDepartmentList.map(i => ({ value: i.name, label: i.name + " (" + i?.institution?.erasmusCode + ")" })) : []}
                                                disabled={iia?.ewpStatus?.name === "Approved by all"}
                                            >
                                                Department of the Receiving Institution
                                            </SelectWithLabel>
                                            {toDepartmentList === null && <small style={{ color: "red" }}>Loading Partner's Departments from EWP</small>}

                                        </div>
                                    </div>
                                    <fieldset className="shadow p-2 mt-2">
                                        <h5 className="text-center">Subject Area With Clarification</h5>
                                        {condition.subjectAreasWithClarifications.map((i, idx) =>
                                            <div className="row">
                                                <div className="d-flex justify-content-center">

                                                    <div className="form-group col-md-5">
                                                        <RequiredSelectWithLabel
                                                            clearable={true}
                                                            value={i.subjectArea.name ? (i.subjectArea.name + " [" + i.subjectArea.code + "]") : ""}
                                                            id="subjectArea"
                                                            isMulti={false}
                                                            placeholder="Enter subject area name"
                                                            onSelectChange={(e) => {
                                                                var temp = [...staffMobilityConditions];
                                                                if (e !== null) {
                                                                    const values = [...staffMobilityConditions[index].subjectAreasWithClarifications];
                                                                    values[idx]['subjectArea'] = isced.find(i => i.code === e.value);
                                                                    // values[idx]['clarification'] = isced.find(i => i.code === e.value)?.name;
                                                                    temp[index]['subjectAreasWithClarifications'] = values;
                                                                    setStaffMobilityConditions(temp);
                                                                }
                                                                else {
                                                                    const values = [...staffMobilityConditions[index].subjectAreasWithClarifications];
                                                                    values[idx]['subjectArea'] = { "name": "", "code": "", };
                                                                    values[idx]['clarification'] = "";
                                                                    temp[index]['subjectAreasWithClarifications'] = values;
                                                                    setStaffMobilityConditions(temp);
                                                                }
                                                            }}
                                                            listOptions={isced4Digits.map(i => ({ value: i.code, label: i.name + " (" + i.code + ")" }))}
                                                            disabled={iia?.ewpStatus?.name === "Approved by all"}
                                                        >Subject area *</RequiredSelectWithLabel>
                                                    </div>
                                                    <div className="form-group col-md-5">
                                                        <label>Field of education – Clarification (optional)</label>
                                                        <input
                                                            className="form-control"
                                                            value={i.clarification}
                                                            onChange={(e) => {
                                                                const values = [...staffMobilityConditions[index].subjectAreasWithClarifications];
                                                                values[idx]['clarification'] = e.target.value;
                                                                var temp = [...staffMobilityConditions];
                                                                temp[index]['subjectAreasWithClarifications'] = values;
                                                                setStaffMobilityConditions(temp);
                                                            }}
                                                            disabled={iia?.ewpStatus?.name === "Approved by all"}
                                                        />
                                                    </div>
                                                    <Restricted to="iia_edit" fallback={<></>}>
                                                        <div className="col-md-2 my-auto">
                                                            <button className='btn btn-primary btn-sm btn-danger btn-remove-language' onClick={(e) => {
                                                                e.preventDefault();
                                                                const values = [...staffMobilityConditions[index].subjectAreasWithClarifications];
                                                                values.splice(idx, 1);
                                                                var temp = [...staffMobilityConditions]
                                                                temp[index]['subjectAreasWithClarifications'] = values
                                                                setStaffMobilityConditions(temp);
                                                            }} disabled={iia?.ewpStatus?.name === "Approved by all"}>
                                                                <i class="fa fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </Restricted>
                                                </div>
                                            </div>)}
                                        <Restricted to="iia_edit" fallback={<></>}>
                                            <div className="d-flex justify-content-center">
                                                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={(e) => {
                                                    e.preventDefault()
                                                    const values = [...staffMobilityConditions[index].subjectAreasWithClarifications];
                                                    values.push({
                                                        subjectArea: {
                                                            "code": null,
                                                            "name": null
                                                        }, clarification: ""
                                                    });
                                                    var temp = [...staffMobilityConditions]
                                                    temp[index]['subjectAreasWithClarifications'] = values
                                                    setStaffMobilityConditions(temp)
                                                }} disabled={iia?.ewpStatus?.name === "Approved by all"}>
                                                    <i className="fa fa-plus mr-2"></i>
                                                    Add subject area
                                                </button>
                                            </div>
                                        </Restricted>
                                    </fieldset>
                                    <div className="form-group m-2">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>Number of Staff *</label>
                                                <input type="text" className="form-control has-validation" placeholder="Type number of staff" required
                                                    value={condition?.mobilitiesPerYear}
                                                    onChange={(e) => {
                                                        var temp = [...staffMobilityConditions]
                                                        temp[index]['mobilitiesPerYear'] = e.target.value
                                                        setStaffMobilityConditions(temp)
                                                    }} disabled={iia?.ewpStatus?.name === "Approved by all"}/>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Total number of days *</label>
                                                <input type="text" className="form-control has-validation" placeholder="Total number of days" required
                                                    value={condition?.totalDaysPerYear}
                                                    onChange={(e) => {
                                                        var temp = [...staffMobilityConditions]
                                                        temp[index]['totalDaysPerYear'] = e.target.value
                                                        setStaffMobilityConditions(temp)
                                                    }} disabled={iia?.ewpStatus?.name === "Approved by all"}/>
                                                <small><i>Minimum mobility days per Staff are two (2) and maximum sixty (60)</i></small>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <fieldset className="shadow p-2 mt-2">
                                            <h5 className="text-center">Recommended Language Skill</h5>
                                            {condition.recommendedLanguageSkills.map((field, idx) => {
                                                return (
                                                    <div key={`${field}-${idx}`} >
                                                        <div className="row">

                                                            <div className="col-md-10">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <RequiredSelectWithLabel
                                                                            clearable={true}
                                                                            value={field.language}
                                                                            id="language"
                                                                            isMulti={false}
                                                                            placeholder="Enter language"
                                                                            onSelectChange={(e) => {
                                                                                var temp = [...staffMobilityConditions];
                                                                                if (e !== null) {
                                                                                    const values = [...staffMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['language'] = e.value;
                                                                                    var temp = [...staffMobilityConditions];
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStaffMobilityConditions(temp);
                                                                                } else {
                                                                                    const values = [...staffMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['language'] = null;
                                                                                    var temp = [...staffMobilityConditions];
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStaffMobilityConditions(temp);
                                                                                }
                                                                            }}
                                                                            listOptions={languages.map(i => ({
                                                                                value: i.name,
                                                                                label: i.name
                                                                            }))}
                                                                            disabled={iia?.ewpStatus?.name === "Approved by all"}
                                                                        >Select language *</RequiredSelectWithLabel>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <RequiredSelectWithLabel
                                                                            clearable={true}
                                                                            value={field.languageLevel}
                                                                            id="languageLevel"
                                                                            isMulti={false}
                                                                            placeholder="Enter language level"
                                                                            onSelectChange={(e) => {
                                                                                var temp = [...staffMobilityConditions];
                                                                                if (e !== null) {
                                                                                    const values = [...staffMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['languageLevel'] = e.value;
                                                                                    var temp = [...staffMobilityConditions];
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStaffMobilityConditions(temp);
                                                                                } else {
                                                                                    const values = [...staffMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['languageLevel'] = null;
                                                                                    var temp = [...staffMobilityConditions];
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStaffMobilityConditions(temp);
                                                                                }
                                                                            }}
                                                                            listOptions={levels.map(i => ({ value: i, label: i }))}
                                                                            disabled={iia?.ewpStatus?.name === "Approved by all"}
                                                                        >Select language level *</RequiredSelectWithLabel>
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group col-md-6">
                                                                        <SelectWithLabel
                                                                            clearable={true}
                                                                            value={field?.subjectAreaWithClarification?.subjectArea?.code ? (field?.subjectAreaWithClarification?.subjectArea?.name + " [" + field?.subjectAreaWithClarification?.subjectArea?.code + "]") : ""}
                                                                            id="subjectArea"
                                                                            isMulti={false}
                                                                            placeholder="Enter subject area name"
                                                                            onSelectChange={(e) => {
                                                                                var temp = [...staffMobilityConditions];
                                                                                if (e !== null) {
                                                                                    const values = [...staffMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['subjectAreaWithClarification'] = {
                                                                                        'subjectArea': isced.find(i => i.code === e.value),
                                                                                        // 'clarification': isced.find(i => i.code === e.value)?.name
                                                                                    }
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStaffMobilityConditions(temp);
                                                                                }
                                                                                else {
                                                                                    const values = [...staffMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['subjectAreaWithClarification'] = null;
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStaffMobilityConditions(temp);
                                                                                }
                                                                            }}
                                                                            listOptions={isced4Digits.map(i => ({ value: i.code, label: i.name + " (" + i.code + ")" }))}
                                                                            disabled={iia?.ewpStatus?.name === "Approved by all"}
                                                                        >Subject area</SelectWithLabel>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label>Field of education – Clarification (optional)</label>
                                                                        <input
                                                                            className="form-control"
                                                                            disabled={!field?.subjectAreaWithClarification || iia?.ewpStatus?.name === "Approved by all"}
                                                                            value={field?.subjectAreaWithClarification ?field?.subjectAreaWithClarification.clarification:''}
                                                                            onChange={(e) => {
                                                                                const values = [...staffMobilityConditions[index].recommendedLanguageSkills];
                                                                                values[idx]['subjectAreaWithClarification']['clarification']= e.target.value;
                                                                                var temp = [...staffMobilityConditions];
                                                                                temp[index]['recommendedLanguageSkills'] = values;
                                                                                setStaffMobilityConditions(temp);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-1 my-auto">
                                                                <button
                                                                    className='btn btn-primary btn-sm btn-danger btn-remove-language'
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        const values = [...staffMobilityConditions[index].recommendedLanguageSkills];
                                                                        values.splice(idx, 1);
                                                                        var temp = [...staffMobilityConditions]
                                                                        temp[index]['recommendedLanguageSkills'] = values
                                                                        setStaffMobilityConditions(temp);
                                                                    }} disabled={iia?.ewpStatus?.name === "Approved by all"}>
                                                                    <i className="fa fa-trash fa-2xl"></i>

                                                                </button>
                                                            </div>

                                                        </div>
                                                        <hr />
                                                    </div>
                                                );
                                            })}
                                            <div className="d-flex justify-content-center">
                                                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={(e) => {
                                                    e.preventDefault();
                                                    const values = [...staffMobilityConditions[index].recommendedLanguageSkills];
                                                    values.push({
                                                        language: "", languageLevel: "", subjectAreaWithClarification: null
                                                    });
                                                    var temp = [...staffMobilityConditions]
                                                    temp[index]['recommendedLanguageSkills'] = values
                                                    setStaffMobilityConditions(temp);
                                                }} disabled={iia?.ewpStatus?.name === "Approved by all"}>
                                                    <i className="fa fa-plus mr-2"></i>
                                                    Add language
                                                </button>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="form-group mt-4">

                                        <label>Other Info Terms</label>
                                        <textarea
                                            rows="3"
                                            className="form-control"
                                            style={{ width: "100%" }}
                                            value={condition.otherInfoTerms}
                                            onChange={e => {
                                                const values = [...staffMobilityConditions];
                                                values[index]["otherInfoTerms"] = e.target.value;
                                                setStaffMobilityConditions(values);
                                            }} disabled={iia?.ewpStatus?.name === "Approved by all"}>
                                        </textarea>
                                    </div>
                                </div>
                            </>)
                        })}
                        {staffMobility === "Yes" &&
                            <div className="d-flex justify-content-center">
                                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={() => {
                                    var temp = [...staffMobilityConditions]
                                    temp.push({
                                        "fromInstitution": null,
                                        "toInstitution": null,
                                        "fromDepartment": null,
                                        "toDepartment": null,
                                        "subjectAreasWithClarifications": [{
                                            subjectArea: {
                                                "code": null,
                                                "name": null
                                            }, clarification: ""
                                        }],
                                        "recommendedLanguageSkills": [],
                                        "mobilitiesPerYear": null,
                                        "totalDaysPerYear": null,
                                        "blended": false,
                                        "otherInfoTerms": null
                                    })
                                    setStaffMobilityConditions(temp)
                                }} disabled={iia?.ewpStatus?.name === "Approved by all"}>
                                    <i className="fa fa-plus mr-2"></i>
                                    Add new Cooperation Condition</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {children}
        </form>
    );




}
export default StaffMobility