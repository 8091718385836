import { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import 'styles/css/IIAform.css';
import SelectWithLabel from 'components/UI/SelectWithLabel';
import { languages } from 'languages';
import { languageLevels } from 'languageLevels';
import RequiredSelectWithLabel from "../../../UI/RequiredSelectWithLabel";
import Restricted from "PermissionProvider/Restricted";

function StudentMobility({ children, iia, setIia, handleStep, mobility, isced, localDepartmentList, partnerDepartmentList }) {

    const [studentMobility, setStudentMobility] = useState("No");
    const [studentMobilityConditions, setStudentMobilityConditions] = useState([]);
    let levels = languageLevels;

    var isced4Digits = []

    isced.forEach(i => {
        if (i.code.length == 4) {
            isced4Digits.push(i)
        }
    });

    useEffect(() => {

        setStudentMobility((iia[mobility] && iia[mobility].length !== 0) ? "Yes" : "No")
        if (iia[mobility] && iia[mobility].length !== 0) {
            setStudentMobilityConditions(iia[mobility]);
        }
        else {
            setStudentMobilityConditions([{
                "fromInstitution": null,
                "toInstitution": null,
                "fromDepartment": null,
                "toDepartment": null,
                "subjectAreasWithClarifications": [{
                    subjectArea: {
                        "code": null,
                        "name": null
                    }, clarification: ""
                }],
                "recommendedLanguageSkills": [],
                "studyCycles": [],
                "mobilitiesPerYear": null,
                "totalMonthsPerYear": null,
                "blended": false,
                "otherInfoTerms": null
            }])

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const institutions = iia && iia.institutions;
    return (
        <form className="g-3 needs-validation" noValidate={false}
            onSubmit={(event) => {
                event.preventDefault();
                if (studentMobility === "Yes") {
                    var issueFound = false;
                    for (let condition in studentMobilityConditions) {

                        if (!(studentMobilityConditions[condition].totalMonthsPerYear >= 2 && 60 >= studentMobilityConditions[condition].totalMonthsPerYear)) {
                            alert("Total number of months must be between 2 and 60 months");
                            issueFound = true;
                        }
                        if (studentMobilityConditions[condition].studyCycles.length === 0) {
                            alert("You must select study cycle");
                            issueFound = true;
                        }
                    }
                    if (!issueFound) {
                        const temp = iia;
                        temp[mobility] = studentMobilityConditions;
                        setIia(temp);
                        handleStep();
                    }
                } else {
                    const temp = iia;
                    temp[mobility] = [];
                    setIia(temp);
                    handleStep();
                }
            }} >

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="text-center">Mobility numbers per academic year - Student</h2>
                        <Form.Group id="student">
                            <Form.Label>{mobility === "studentMobilitiesForStudies" ? "Student Mobility For Studies" : "Student Mobility For Traineeship"}</Form.Label>
                            <Form.Control as="select" custom value={studentMobility} onChange={(e) => setStudentMobility(e.target.value)} disabled={iia?.ewpStatus?.name === 'Approved by all'}>
                                <option value={"Yes"} >Yes</option>
                                <option value={"No"}>No</option>
                            </Form.Control>
                        </Form.Group>
                        {studentMobility === "Yes" && studentMobilityConditions?.map((condition, index) => {
                            let fromDepartmentList = (condition?.fromInstitution?.isLocal ? localDepartmentList : partnerDepartmentList);
                            let toDepartmentList = (condition?.toInstitution?.isLocal ? localDepartmentList : partnerDepartmentList);
                            return (<>
                                <div className="card p-4">
                                    <div className="d-flex flex-row-reverse">
                                        <button className='btn btn-primary btn-sm btn-danger btn-remove-language' onClick={(e) => {
                                            e.preventDefault();
                                            const values = [...studentMobilityConditions];
                                            values.splice(index, 1);

                                            setStudentMobilityConditions(values);
                                        }} disabled={iia?.ewpStatus?.name === 'Approved by all'}>
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </div>
                                    {/* <h3 className="text-center">Outgoing Students</h3>*/}
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <RequiredSelectWithLabel
                                                value={condition?.fromInstitution?.erasmusCode}
                                                onSelectChange={(e) => {
                                                    var temp = [...studentMobilityConditions];
                                                    if (e !== null) {
                                                        temp[index]['fromInstitution'] = institutions.find(i => i.erasmusCode === e.value);
                                                        setStudentMobilityConditions(temp);
                                                    }
                                                    else {
                                                        const values = [...studentMobilityConditions];
                                                        temp[index]['fromInstitution'] = null;
                                                        setStudentMobilityConditions(temp);
                                                    }
                                                }}
                                                id="institutionSending"
                                                placeholderText="Enter name of the institution"
                                                clearable={true}
                                                listOptions={institutions.filter(i => i.erasmusCode !== condition?.toInstitution?.erasmusCode).map(i => ({ value: i.erasmusCode, label: i.name + " (" + i.erasmusCode + ")" }))}
                                                disabled={iia?.ewpStatus?.name === 'Approved by all'}
                                            >
                                                Erasmus Code of the Sending Institution *
                                            </RequiredSelectWithLabel>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <RequiredSelectWithLabel
                                                value={condition?.toInstitution?.erasmusCode}
                                                onSelectChange={(e) => {
                                                    var temp = [...studentMobilityConditions];
                                                    if (e !== null) {
                                                        temp[index]['toInstitution'] = institutions.find(i => i.erasmusCode === e.value);
                                                        setStudentMobilityConditions(temp);
                                                    }
                                                    else {
                                                        temp[index]['toInstitution'] = null;
                                                        setStudentMobilityConditions(temp);
                                                    }
                                                }}
                                                id="institutionReceiving"
                                                placeholderText="Enter name of the institution"
                                                clearable={true}
                                                listOptions={institutions.filter(i => i.erasmusCode !== condition?.fromInstitution?.erasmusCode).map(i => ({ value: i.erasmusCode, label: i.name + " (" + i.erasmusCode + ")" }))}
                                                disabled={iia?.ewpStatus?.name === 'Approved by all'}
                                            >
                                                Erasmus Code of the Receiving Institution *
                                            </RequiredSelectWithLabel>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <SelectWithLabel
                                                value={condition?.fromDepartment?.name}
                                                disabled={!condition?.fromInstitution || !fromDepartmentList || iia?.ewpStatus?.name === 'Approved by all'}
                                                onSelectChange={(e) => {
                                                    var temp = [...studentMobilityConditions];
                                                    if (e !== null) {
                                                        temp[index]['fromDepartment'] = fromDepartmentList.find(i => i.name === e.value);
                                                        setStudentMobilityConditions(temp);
                                                    }
                                                    else {
                                                        temp[index]['fromDepartment'] = null;
                                                        setStudentMobilityConditions(temp);
                                                    }
                                                }}
                                                id="departmentSending"
                                                placeholderText="Enter name of the department"
                                                clearable={true}
                                                listOptions={fromDepartmentList ? fromDepartmentList.map(i => ({ value: i.name, label: i.name + " (" + i?.institution?.erasmusCode + ")" })) : []}
                                            >
                                                Department of the Sending Institution
                                            </SelectWithLabel>
                                            {fromDepartmentList === null && <small style={{ color: "red" }}>Loading Partner's Departments from EWP</small>}

                                        </div>
                                        <div className="form-group col-md-6">
                                            <SelectWithLabel
                                                value={condition?.toDepartment?.name}
                                                disabled={!condition?.toInstitution || !toDepartmentList || iia?.ewpStatus?.name === 'Approved by all'}
                                                onSelectChange={(e) => {
                                                    var temp = [...studentMobilityConditions];
                                                    if (e !== null) {
                                                        temp[index]['toDepartment'] = toDepartmentList.find(i => i?.name === e.value);
                                                        setStudentMobilityConditions(temp);
                                                    }
                                                    else {
                                                        temp[index]['toDepartment'] = null;
                                                        setStudentMobilityConditions(temp);
                                                    }
                                                }}
                                                id="departmentReceiving"
                                                placeholderText="Enter name of the department"
                                                clearable={true}
                                                listOptions={toDepartmentList ? toDepartmentList.map(i => ({ value: i.name, label: i.name + " (" + i?.institution?.erasmusCode + ")" })) : []}
                                            >
                                                Department of the Receiving Institution
                                            </SelectWithLabel>
                                            {toDepartmentList === null && <small style={{ color: "red" }}>Loading Partner's Departments from EWP</small>}
                                        </div>
                                    </div>
                                    <fieldset className="shadow p-2 mt-2">

                                        <h5 className="text-center">Subject Area With Clarification</h5>

                                        {condition.subjectAreasWithClarifications.map((i, idx) =>
                                            <div className="row">
                                                <div className="d-flex justify-content-center">

                                                    <div className="form-group col-md-5">
                                                        <RequiredSelectWithLabel
                                                            clearable={true}
                                                            value={i.subjectArea.name ? (i.subjectArea.name + " [" + i.subjectArea.code + "]") : ""}
                                                            id="subjectArea"
                                                            isMulti={false}
                                                            placeholder="Enter subject area name"
                                                            onSelectChange={(e) => {
                                                                var temp = [...studentMobilityConditions];
                                                                if (e !== null) {
                                                                    const values = [...studentMobilityConditions[index].subjectAreasWithClarifications];
                                                                    values[idx]['subjectArea'] = isced.find(i => i.code === e.value);
                                                                    // values[idx]['clarification'] = isced.find(i => i.code === e.value)?.name;
                                                                    temp[index]['subjectAreasWithClarifications'] = values;
                                                                    setStudentMobilityConditions(temp);
                                                                }
                                                                else {
                                                                    const values = [...studentMobilityConditions[index].subjectAreasWithClarifications];
                                                                    values[idx]['subjectArea'] = { "name": "", "code": "", };
                                                                    values[idx]['clarification'] = "";
                                                                    temp[index]['subjectAreasWithClarifications'] = values;
                                                                    setStudentMobilityConditions(temp);
                                                                }
                                                            }}
                                                            listOptions={isced4Digits.map(i => ({ value: i.code, label: i.name + " (" + i.code + ")" }))}
                                                            disabled={iia?.ewpStatus?.name === 'Approved by all'}
                                                        >Subject area *</RequiredSelectWithLabel>
                                                    </div>
                                                    <div className="form-group col-md-5">
                                                        <label>Field of education – Clarification (optional)</label>
                                                        <input
                                                            className="form-control"
                                                            value={i.clarification}
                                                            onChange={(e) => {
                                                                const values = [...studentMobilityConditions[index].subjectAreasWithClarifications];
                                                                values[idx]['clarification'] = e.target.value;
                                                                var temp = [...studentMobilityConditions];
                                                                temp[index]['subjectAreasWithClarifications'] = values;
                                                                setStudentMobilityConditions(temp);
                                                            }}
                                                            disabled={iia?.ewpStatus?.name === 'Approved by all'}
                                                        />
                                                    </div>
                                                    <Restricted to="iia_edit" fallback={<></>}>
                                                        <div className="col-md-2 my-auto">
                                                            <button className='btn btn-primary btn-sm btn-danger btn-remove-language' onClick={(e) => {
                                                                e.preventDefault();
                                                                const values = [...studentMobilityConditions[index].subjectAreasWithClarifications];
                                                                values.splice(idx, 1);
                                                                var temp = [...studentMobilityConditions]
                                                                temp[index]['subjectAreasWithClarifications'] = values
                                                                setStudentMobilityConditions(temp);
                                                            }} disabled={iia?.ewpStatus?.name === 'Approved by all'}>
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </Restricted>
                                                </div>

                                            </div>)}
                                        <Restricted to="iia_edit" fallback={<></>}>
                                            <div className="d-flex justify-content-center">
                                                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={(e) => {
                                                    e.preventDefault()
                                                    const values = [...studentMobilityConditions[index].subjectAreasWithClarifications];
                                                    values.push({
                                                        subjectArea: {
                                                            "code": null,
                                                            "name": null
                                                        }, clarification: ""
                                                    });
                                                    var temp = [...studentMobilityConditions]
                                                    temp[index]['subjectAreasWithClarifications'] = values
                                                    setStudentMobilityConditions(temp)
                                                }} disabled={iia?.ewpStatus?.name === 'Approved by all'}>
                                                    <i className="fa fa-plus mr-2"></i>
                                                    Add subject area
                                                </button>
                                            </div>
                                        </Restricted>
                                    </fieldset>
                                    <div className="form-group m-2">
                                        <label>Select study cycle *</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                checked={condition['studyCycles'].find(i => i.studyCycle === "1st") ? true : false}
                                                onChange={(e) => {
                                                    var temp = [...studentMobilityConditions]
                                                    if (e.target.checked) {
                                                        temp[index]['studyCycles'] = temp[index]['studyCycles'].concat({ "studyCycle": "1st" })
                                                        setStudentMobilityConditions(temp)
                                                    }
                                                    else {
                                                        temp[index]['studyCycles'] = temp[index]['studyCycles'].filter(i => i.studyCycle !== "1st")
                                                        setStudentMobilityConditions(temp)
                                                    }
                                                }} disabled={iia?.ewpStatus?.name === 'Approved by all'}/>
                                            <label className="form-check-label">1st</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                checked={studentMobilityConditions[index]['studyCycles'].find(i => i.studyCycle === "2nd") ? true : false}
                                                onChange={(e) => {
                                                    var temp = [...studentMobilityConditions]
                                                    if (e.target.checked) {
                                                        temp[index]['studyCycles'] = temp[index]['studyCycles'].concat({ "studyCycle": "2nd" })
                                                        setStudentMobilityConditions(temp)
                                                    }
                                                    else {
                                                        temp[index]['studyCycles'] = temp[index]['studyCycles'].filter(i => i.studyCycle !== "2nd")
                                                        setStudentMobilityConditions(temp)
                                                    }
                                                }} disabled={iia?.ewpStatus?.name === 'Approved by all'}/>
                                            <label className="form-check-label">2nd</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                checked={studentMobilityConditions[index]['studyCycles'].find(i => i.studyCycle === "3rd") ? true : false}
                                                onChange={(e) => {
                                                    var temp = [...studentMobilityConditions]
                                                    if (e.target.checked) {
                                                        temp[index]['studyCycles'] = temp[index]['studyCycles'].concat({ "studyCycle": "3rd" })
                                                        setStudentMobilityConditions(temp)
                                                    }
                                                    else {
                                                        temp[index]['studyCycles'] = temp[index]['studyCycles'].filter(i => i.studyCycle !== "3rd")
                                                        setStudentMobilityConditions(temp)
                                                    }
                                                }} disabled={iia?.ewpStatus?.name === 'Approved by all'}/>
                                            <label className="form-check-label">3rd</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                checked={studentMobilityConditions[index]['studyCycles'].find(i => i.studyCycle === "Short-Cycle") ? true : false}
                                                onChange={(e) => {
                                                    var temp = [...studentMobilityConditions]
                                                    if (e.target.checked) {
                                                        temp[index]['studyCycles'] = temp[index]['studyCycles'].concat({ "studyCycle": "Short-Cycle" })
                                                        setStudentMobilityConditions(temp)
                                                    }
                                                    else {
                                                        temp[index]['studyCycles'] = temp[index]['studyCycles'].filter(i => i.studyCycle !== "Short-Cycle")
                                                        setStudentMobilityConditions(temp)
                                                    }
                                                }} disabled={iia?.ewpStatus?.name === 'Approved by all'}/>
                                            <label className="form-check-label">Short-cycle</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>Number of Students *</label>
                                                <input type="text" className="form-control has-validation" placeholder="Type number of students" required
                                                    value={condition?.mobilitiesPerYear}
                                                    onChange={(e) => {
                                                        var temp = [...studentMobilityConditions]
                                                        temp[index]['mobilitiesPerYear'] = e.target.value
                                                        setStudentMobilityConditions(temp)
                                                    }} disabled={iia?.ewpStatus?.name === 'Approved by all'}/>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Total number of months *</label>
                                                <input type="text" className="form-control has-validation" placeholder="Total number of months" required
                                                    value={condition?.totalMonthsPerYear}
                                                    onChange={(e) => {
                                                        var temp = [...studentMobilityConditions]
                                                        temp[index]['totalMonthsPerYear'] = e.target.value
                                                        setStudentMobilityConditions(temp)
                                                    }} disabled={iia?.ewpStatus?.name === 'Approved by all'}/>
                                                <small><i>Minimum total number of months are two (2) and maximum sixty (60)</i></small>

                                            </div>
                                        </div>
                                    </div>
                                    <div>

                                        <fieldset className="shadow p-2 mt-2">
                                            <h5 className="text-center">Recommended Language Skill</h5>
                                            {condition.recommendedLanguageSkills.map((field, idx) => {
                                                return (
                                                    <div key={`${field}-${idx}`}>
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <RequiredSelectWithLabel
                                                                            clearable={true}
                                                                            value={field.language}
                                                                            id="language"
                                                                            isMulti={false}
                                                                            placeholder="Enter language"
                                                                            onSelectChange={(e) => {
                                                                                var temp = [...studentMobilityConditions];
                                                                                if (e !== null) {
                                                                                    const values = [...studentMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['language'] = e.value;
                                                                                    var temp = [...studentMobilityConditions];
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStudentMobilityConditions(temp);
                                                                                }
                                                                                else {
                                                                                    const values = [...studentMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['language'] = null;
                                                                                    var temp = [...studentMobilityConditions];
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStudentMobilityConditions(temp);
                                                                                }
                                                                            }}
                                                                            listOptions={languages.map(i => ({ value: i.name, label: i.name }))}
                                                                            disabled={iia?.ewpStatus?.name === 'Approved by all'}
                                                                        >Select language *</RequiredSelectWithLabel>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <RequiredSelectWithLabel
                                                                            clearable={true}
                                                                            value={field.languageLevel}
                                                                            id="languageLevel"
                                                                            isMulti={false}
                                                                            placeholder="Enter language level"
                                                                            onSelectChange={(e) => {
                                                                                var temp = [...studentMobilityConditions];
                                                                                if (e !== null) {
                                                                                    const values = [...studentMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['languageLevel'] = e.value;
                                                                                    var temp = [...studentMobilityConditions];
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStudentMobilityConditions(temp);
                                                                                }
                                                                                else {
                                                                                    const values = [...studentMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['languageLevel'] = null;
                                                                                    var temp = [...studentMobilityConditions];
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStudentMobilityConditions(temp);
                                                                                }
                                                                            }}
                                                                            listOptions={levels.map(i => ({ value: i, label: i }))}
                                                                            disabled={iia?.ewpStatus?.name === 'Approved by all'}
                                                                        >Select language level *</RequiredSelectWithLabel>
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <div className="form-group col-md-6">
                                                                        <SelectWithLabel
                                                                            clearable={true}
                                                                            value={field?.subjectAreaWithClarification?.subjectArea?.code ? (field?.subjectAreaWithClarification?.subjectArea?.name + " [" + field?.subjectAreaWithClarification?.subjectArea?.code + "]") : ""}
                                                                            id="subjectArea"
                                                                            isMulti={false}
                                                                            placeholder="Enter subject area name"
                                                                            onSelectChange={(e) => {
                                                                                var temp = [...studentMobilityConditions];
                                                                                if (e !== null) {
                                                                                    const values = [...studentMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['subjectAreaWithClarification'] = {
                                                                                        'subjectArea': isced.find(i => i.code === e.value),
                                                                                        // 'clarification': isced.find(i => i.code === e.value)?.name
                                                                                    }
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStudentMobilityConditions(temp);
                                                                                }
                                                                                else {
                                                                                    const values = [...studentMobilityConditions[index].recommendedLanguageSkills];
                                                                                    values[idx]['subjectAreaWithClarification'] = null;
                                                                                    temp[index]['recommendedLanguageSkills'] = values;
                                                                                    setStudentMobilityConditions(temp);
                                                                                }
                                                                            }}
                                                                            listOptions={isced4Digits.map(i => ({ value: i.code, label: i.name + " (" + i.code + ")" }))}
                                                                            disabled={iia?.ewpStatus?.name === 'Approved by all'}
                                                                        >Subject area</SelectWithLabel>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label>Field of education – Clarification (optional)</label>
                                                                        <input
                                                                            className="form-control"
                                                                            disabled={!field?.subjectAreaWithClarification || iia?.ewpStatus?.name === 'Approved by all'}
                                                                            value={field?.subjectAreaWithClarification ? field?.subjectAreaWithClarification.clarification : ''}
                                                                            onChange={(e) => {
                                                                                const values = [...studentMobilityConditions[index].recommendedLanguageSkills];
                                                                                values[idx]['subjectAreaWithClarification']['clarification'] = e.target.value;
                                                                                var temp = [...studentMobilityConditions];
                                                                                temp[index]['recommendedLanguageSkills'] = values;
                                                                                setStudentMobilityConditions(temp);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 my-auto">
                                                                <button className='btn btn-primary btn-sm btn-danger btn-remove-language' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    const values = [...studentMobilityConditions[index].recommendedLanguageSkills];
                                                                    values.splice(idx, 1);
                                                                    var temp = [...studentMobilityConditions]
                                                                    temp[index]['recommendedLanguageSkills'] = values
                                                                    setStudentMobilityConditions(temp);
                                                                }} disabled={iia?.ewpStatus?.name === 'Approved by all'}>
                                                                    <i className="fa fa-trash fa-2xl"></i>
                                                                </button>
                                                            </div>

                                                        </div>
                                                        <hr />

                                                    </div>
                                                );
                                            })}
                                            <div className="d-flex justify-content-center">
                                                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={(e) => {
                                                    e.preventDefault();
                                                    const values = [...studentMobilityConditions[index].recommendedLanguageSkills];
                                                    values.push({
                                                        language: "", languageLevel: "",
                                                        subjectAreaWithClarification: null
                                                    });
                                                    var temp = [...studentMobilityConditions]
                                                    temp[index]['recommendedLanguageSkills'] = values
                                                    setStudentMobilityConditions(temp);
                                                }} disabled={iia?.ewpStatus?.name === 'Approved by all'}>
                                                    <i className="fa fa-plus mr-2"></i>
                                                    Add language
                                                </button>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="form-group mt-4">
                                        <label>Other Info Terms</label>
                                        <textarea rows="3" style={{ width: "100%" }}
                                            value={condition.otherInfoTerms}
                                            className="form-control"
                                            onChange={e => {
                                                const values = [...studentMobilityConditions];
                                                values[index]["otherInfoTerms"] = e.target.value;
                                                setStudentMobilityConditions(values);
                                            }} disabled={iia?.ewpStatus?.name === 'Approved by all'}>
                                        </textarea>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                            checked={condition.blended}
                                            onChange={() => {
                                                var temp = [...studentMobilityConditions]
                                                temp[index]['blended'] = !temp[index].blended
                                                setStudentMobilityConditions(temp)
                                            }} disabled={iia?.ewpStatus?.name === 'Approved by all'}/>
                                        <label className="form-check-label">Blended mobility option for students</label>
                                    </div>
                                </div>
                            </>)
                        })}
                        {studentMobility === "Yes" &&
                            <div className="d-flex justify-content-center">
                                <button className='btn btn-primary btn-sm mb-2 btn-next' onClick={() => {
                                    var temp = [...studentMobilityConditions]
                                    temp.push({
                                        "fromInstitution": null,
                                        "toInstitution": null,
                                        "fromDepartment": null,
                                        "toDepartment": null,
                                        "subjectAreasWithClarifications": [{
                                            subjectArea: {
                                                "code": null,
                                                "name": null
                                            }, clarification: ""
                                        }],
                                        "recommendedLanguageSkills": [],
                                        "studyCycles": [],
                                        "mobilitiesPerYear": null,
                                        "totalMonthsPerYear": null,
                                        "blended": false,
                                        "otherInfoTerms": null
                                    })
                                    setStudentMobilityConditions(temp)
                                }} disabled={iia?.ewpStatus?.name === 'Approved by all'}>
                                    <i className="fa fa-plus mr-2"></i>
                                    Add new Cooperation Condition</button>
                            </div>}
                    </div>
                </div>
            </div>
            {children}
        </form >
    );
}

export default StudentMobility